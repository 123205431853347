import { useEffect, useState } from 'react'
import { GetJobs } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { JobBoard } from 'types/jobs'
import { get, getPagination } from 'utils'
import { getRows } from '../boardConfig/constants'
import useFilterJobStore from './useFilterJobStore'

export default function useFetchJob() {
  const [jobs, setJobs] = useState<JobBoard>({} as JobBoard)
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()
  const { filters } = useFilterJobStore()

  const { isLoading, isFetching, refetch } = useQuery(
    'JOBS',
    () => GetJobs({ page: filters.page, size: filters.size, ...searchParams }),
    {
      onSuccess: data => {
        setJobs(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [JSON.stringify(searchParams)])

  const validatedJobs = getRows(jobs.entries || [])

  const pagination = getPagination(jobs)

  return {
    pagination,
    jobs: validatedJobs,
    isLoading: isLoading || isFetching
  }
}
