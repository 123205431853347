import { createTheme } from '@mui/material/styles'
import { COLORS } from 'styles/colors'
import { getColor, hexToRGB } from 'styles/utils'

declare module '@mui/material/styles' {
  interface Palette {
    other?: Palette['primary']
    bonus?: Palette['primary']
    notSet?: Palette['primary']
    inProgress?: Palette['primary']
    approved?: Palette['primary']
    paid?: Palette['primary']
    canceled?: Palette['primary']
    bonusType?: Palette['primary']
  }
  interface PaletteOptions {
    other?: PaletteOptions['primary']
    bonus?: PaletteOptions['primary']
    empty?: PaletteOptions['primary']
    notSet?: Palette['primary']
    inProgress?: Palette['primary']
    approved?: Palette['primary']
    paid?: Palette['primary']
    canceled?: Palette['primary']
    bonusType?: Palette['primary']
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    other: true
    bonus: true
    empty: true
    notSet: true
    inProgress: true
    approved: true
    paid: true
    canceled: true
    bonusType: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    honey: true
    other: true
    'other-outlined': true
    info: true
  }
}

const { breakpoints } = createTheme()

export const theme = createTheme({
  palette: {
    success: {
      main: COLORS.green2
    },
    primary: {
      main: COLORS.main
    },
    notSet: {
      main: COLORS.gray2,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    canceled: {
      main: COLORS.red1,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    bonusType: {
      main: COLORS.honey2,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    inProgress: {
      main: COLORS.honey4,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    approved: {
      main: COLORS.blue2,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    paid: {
      main: COLORS.green2,
      contrastText: COLORS.white,
      dark: '',
      light: ''
    },
    secondary: {
      main: COLORS.blue6
    },
    other: {
      main: COLORS.gray4,
      contrastText: COLORS.white
    },
    bonus: {
      main: COLORS.honey3,
      contrastText: COLORS.white
    },
    empty: {
      main: COLORS.gray2,
      contrastText: COLORS.white
    }
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      fontSize: '48px',
      color: COLORS.black
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      color: COLORS.black1,
      [breakpoints.up('md')]: {
        fontSize: '24px'
      }
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: COLORS.black,
      [breakpoints.up('md')]: {
        fontSize: '24px'
      }
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '28px',
      color: COLORS.black
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      color: COLORS.gray1
    },
    h6: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 300,
      letterSpacing: '0.4px',
      color: COLORS.gray2
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      color: COLORS.black
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      color: COLORS.black
    },
    button: {
      fontFamily: ['Poppins', 'sans-serif'].join(',')
    }
  },
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: COLORS.gray1,
          ':hover': {
            background: hexToRGB(COLORS.black, '0.1')
          },
          '&.Mui-selected': {
            color: COLORS.gray1,
            background: COLORS.honey2,
            ':hover': {
              background: COLORS.honey2
            }
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input[type=number]': {
            '-moz-appearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: props => ({
          background:
            props.ownerState.color === 'other'
              ? hexToRGB(COLORS.gray4, '0.12')
              : ''
        }),
        label: props => ({
          color: getColor({
            variant: props.ownerState.variant,
            color: props.ownerState.color
          })
        }),
        icon: props => ({
          color: getColor({
            variant: props.ownerState.variant,
            color: props.ownerState.color
          })
        }),
        colorPrimary: {
          color: COLORS.main
        },
        colorSecondary: {
          color: COLORS.blue6
        },
        colorError: {
          color: COLORS.red1
        },
        colorWarning: {
          color: COLORS.honey3
        },
        colorInfo: {
          color: COLORS.blue2
        },
        colorSuccess: {
          color: COLORS.green2
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          border: `1px solid ${hexToRGB(COLORS.black, '0.1')}`,
          boxShadow: `0px 1px 3px ${hexToRGB(COLORS.black, '0.12')}`
        },
        elevation1: {
          padding: '20px'
        },
        elevation2: {
          borderRadius: '8px',
          padding: '16px'
        },
        elevation3: {
          borderRadius: '0',
          boxShadow: 'none',
          border: `1px solid ${hexToRGB(COLORS.black, '0.08')}`
        },
        elevation4: {
          padding: '10px',
          borderRadius: '5px'
        },
        elevation5: {
          borderRadius: 0,
          boxShadow: `0px 1px 3px ${hexToRGB(COLORS.black, '0.12')}`
        },
        elevation6: {
          borderRadius: '8px',
          boxShadow: 'none',
          border: 'none',
          padding: 24
        },
        elevation7: {
          padding: 24,
          borderRadius: 0,
          border: 'none',
          boxShadow: 'none'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1366px !important'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
            color: COLORS.main,
            ':hover': {
              backgroundColor: 'primary'
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            color: COLORS.main,
            border: `1px solid ${COLORS.main}`
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            color: COLORS.white
          }
        },
        {
          props: { variant: 'honey' },
          style: {
            textTransform: 'none',
            color: COLORS.main,
            background: hexToRGB(COLORS.honey1, '0.12'),
            ':hover': {
              background: hexToRGB(COLORS.honey1, '0.8')
            }
          }
        },
        {
          props: { variant: 'other' },
          style: {
            textTransform: 'none',
            color: COLORS.white,
            background: COLORS.gray2,
            ':hover': {
              background: hexToRGB(COLORS.gray2, '0.8')
            }
          }
        },
        {
          props: { variant: 'other-outlined' },
          style: {
            textTransform: 'none',
            color: COLORS.gray2,
            background: 'transparent',
            border: `1px solid ${COLORS.gray3}`,
            ':hover': {
              background: hexToRGB(COLORS.white, '0.8')
            }
          }
        },
        {
          props: { variant: 'info' },
          style: {
            textTransform: 'none',
            color: COLORS.white,
            background: COLORS.blue6,
            ':hover': {
              background: hexToRGB(COLORS.blue6, '0.8')
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          textTransform: 'initial',
          borderRadius: '50px',
          height: '42px'
        }
      }
    }
  }
})
