import { Paper as MUIPaper, PaperProps as MUIPaperProps } from '@mui/material'

interface PaperProps extends MUIPaperProps {
  bgcolor?: string
}

export default function Paper({ children, bgcolor, ...props }: PaperProps) {
  return (
    <MUIPaper
      {...props}
      sx={{
        ...props.sx,
        bgcolor: bgcolor || '#fff'
      }}
    >
      {children}
    </MUIPaper>
  )
}
