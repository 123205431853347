import { LoadingButton } from '@mui/lab'
import {
  Typography,
  Box,
  Button,
  TextField as MUITextField
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Select from 'components/Select'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Formik, Form } from 'formik'
import { formatDate } from 'utils'
import * as yup from 'yup'
import { UpdateEmployeeTemplateParams } from './Types/EmployeeDetail'
import useShowEmployee from './hooks/useShowEmployee'
import useUpdateEmployeeTemplate from './hooks/useUpdateEmployeeTemplate'

const validationSchema = yup.object({
  first_name: yup.string().required('Name required'),
  last_name: yup.string().required('Last name required'),
  birthday: yup.string().required('Birthday required'),
  country: yup.string().required('location required'),
  email: yup.string().required('email required'),
  phone: yup.string().required('phone required'),
  company: yup.string().required('company required'),
  role: yup.string().nullable().notRequired(),
  inserted_at: yup.string().required('dateJoined required'),
  staff: yup.string().required('staff required')
})

export const EmployeeDetailEdit = ({
  EmployeeDetail
}: {
  EmployeeDetail: UpdateEmployeeTemplateParams
}) => {
  const initialValues = {
    first_name: EmployeeDetail.first_name || '',
    last_name: EmployeeDetail.last_name || '',
    birthday: formatDate(EmployeeDetail.birthday || '', 'MM/dd/yyyy') || '',
    country: EmployeeDetail.country || '',
    email: EmployeeDetail.email || '',
    phone: EmployeeDetail.phone || '',
    company: EmployeeDetail.company || '',
    role: EmployeeDetail.role || '',
    inserted_at:
      formatDate(EmployeeDetail.inserted_at || '', 'MM/dd/yyyy') || '',
    staff: EmployeeDetail.staff || '',
    type: EmployeeDetail.type || ''
    //registredInAts: EmployeeDetail.registredInAts || ''
  }
  const { onHandleCloseEdit } = useShowEmployee()
  const { isLoading, onSumbit } = useUpdateEmployeeTemplate(EmployeeDetail.id)
  return (
    <Stack height="100%" justifyContent="space-between">
      <Box component={Paper} margin="22px 16px">
        <Typography variant="h3">General Information</Typography>

        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSumbit}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Firts name:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('first_name')}
                  name="first_name"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Last name:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('last_name')}
                  name="last_name"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Birthday:
                </Typography>
                <Stack
                  spacing={3}
                  sx={{
                    width: '60%'
                  }}
                >
                  <DesktopDatePicker
                    value={values.birthday}
                    onChange={newValue => setFieldValue('birthday', newValue)}
                    renderInput={params => (
                      <MUITextField variant="standard" {...params} />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Location:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('country')}
                  name="country"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Email:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('email')}
                  name="email"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Contact:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('phone')}
                  name="phone"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Company:
                </Typography>
                <TextField
                  sx={{
                    width: '60%'
                  }}
                  variant="standard"
                  onChange={handleChange('company')}
                  name="company"
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Date joined:
                </Typography>
                <Stack
                  spacing={3}
                  sx={{
                    width: '60%'
                  }}
                >
                  <DesktopDatePicker
                    value={values.inserted_at}
                    onChange={newValue =>
                      setFieldValue('inserted_at', newValue)
                    }
                    renderInput={params => (
                      <MUITextField variant="standard" {...params} />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  Staff:
                </Typography>
                <Select
                  name="staff"
                  defaultValue="staff"
                  variant="standard"
                  width="60%"
                  options={[
                    { value: 'internal', label: 'Internal' },
                    { value: 'outsourced', label: 'Outsourced' }
                  ]}
                />
              </Stack>
              <Stack
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                paddingRight={6}
                paddingTop={5}
              >
                <Typography variant="h5" width="40%">
                  User type:
                </Typography>
                <Select
                  name="role"
                  defaultValue="role"
                  variant="standard"
                  width="60%"
                  options={[
                    { value: 'contractor', label: 'Contractor' },
                    { value: 'employee', label: 'Employee' },
                    { value: 'market_manager', label: 'Market Manager' },
                    { value: 'rewards_manager', label: 'Rewards Manager' }
                  ]}
                />
              </Stack>
              <Stack
                height="100%"
                justifyContent="space-between"
                paddingTop={5}
              >
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    margin: '-5px 25px,5px'
                  }}
                >
                  Save
                </LoadingButton>
                <Button
                  onClick={onHandleCloseEdit}
                  variant="text"
                  color="primary"
                  sx={{
                    margin: '-5px 25px'
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  )
}
