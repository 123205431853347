import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Stepper from 'components/stepper'
import { JobDetail } from 'types/jobs'
import BonusDetailsStep from '../BonusDetailsStep'
import BonusConfigStep from '../Steps/BonusConfigStep'
import SelectBonusStep from '../Steps/SelectBonusStep'
import AddUpdateBonus from '../addUpdateBonus'
import useTemplate from '../useTemplate'

export default function Templates({ details }: { details: JobDetail }) {
  const [steps, setSteps] = useState<Array<string>>([])

  useEffect(() => {
    if (bonus) {
      setSteps(['Configuration', 'Review'])
    } else {
      setSteps(['Set up a bonus', 'Configuration'])
    }
  }, [])

  const { isSent } = useTemplate()
  const { bonus } = details

  return (
    <Box height="calc(100% - 96px)">
      {isSent ? (
        <BonusDetailsStep />
      ) : (
        <>
          {bonus ? (
            <AddUpdateBonus />
          ) : (
            <Stepper steps={steps}>
              <SelectBonusStep />
              <BonusConfigStep hasBonus={Boolean(bonus)} />
            </Stepper>
          )}
        </>
      )}
    </Box>
  )
}
