import { hookstate, useHookstate } from '@hookstate/core'
import { LoginAsCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useMutation } from 'react-query'
import store from 'storejs'
import type { Company } from 'types/companies'
import { get } from 'utils'

type CompanyEntry = Company['entries'][0]

const companyStorage = store.get('SESSION_BY_COMPANY') || ''

const initialState = hookstate({
  isLoginAs: Boolean(companyStorage),
  isLoading: false,
  selectedCompany: {} as CompanyEntry
})

export default function useLoginAs() {
  const { onNavigate } = useNavigation()
  const { loginAsCompany } = useAuth()
  const { onNotify } = useNotification()
  const { isLoading, isLoginAs, selectedCompany } = useHookstate(initialState)

  const { mutate } = useMutation(
    (data: { owner_id: number }) => LoginAsCompany(data),
    {
      onSuccess: data => {
        isLoginAs.set(true)
        loginAsCompany(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  const onLoginAsCompany = (ownerId: number) => {
    isLoading.set(true)
    mutate({ owner_id: ownerId })
  }

  const onSelectedCompany = (company: CompanyEntry) => {
    selectedCompany.set(company)
  }

  const onOpenLoginAs = (company: CompanyEntry) => {
    onSelectedCompany(company)
    onLoginAsCompany(company.owner.id)
  }

  const onCleanLoginAs = () => {
    isLoginAs.set(false)
    selectedCompany.set({} as CompanyEntry)
    onNavigate('/wallet')
    window.location.reload()
  }

  const onCloseLoginAs = () => {
    isLoading.set(true)
    store.remove('SESSION_BY_COMPANY')
    setTimeout(() => {
      onCleanLoginAs()
      isLoading.set(false)
    }, 1500)
  }

  return {
    selectedCompany: selectedCompany.value,
    isLoginAs: isLoginAs.value,
    isLoading: isLoading.value,
    onOpenLoginAs,
    onCloseLoginAs
  }
}
