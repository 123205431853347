import { LoadingButton } from '@mui/lab'
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Tooltip,
  Divider
} from '@mui/material'
import useCompanyJobsite from 'app/profile/hooks/useCompanyJobsite'
import DragAndDroFile from 'components/dragAndDropFile'
import { Form, Formik, Field } from 'formik'
import { COLORS } from 'styles/colors'
import { CompanyDetail } from 'types/companies'

export default function EditBegopp({
  onChangeEdit,
  company
}: {
  company: CompanyDetail
  onChangeEdit: () => void
}) {
  const { onHandleRemoveImage, onHandleDrop, onSubmit, isLoading, image } =
    useCompanyJobsite(onChangeEdit)

  const initialValues = {
    imageLogo: '',
    imageHeader: '',
    description: company.description || ''
  }

  return (
    <Box paddingX={2}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleChange, setFieldValue, touched, errors, values }) => (
          <Form>
            <Stack
              paddingBottom="5px"
              justifyContent="space-between"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
              <Typography
                variant="h5"
                fontWeight="500"
                fontSize={{ xs: '18px', sm: '20px' }}
              >
                BeeGopp jobsite information
              </Typography>
              <Stack
                direction="row"
                alignItems="baseline"
                marginTop={{ xs: 2, sm: 0 }}
                width={{ xs: '96%', sm: 'auto' }}
              >
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  onClick={onChangeEdit}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  variant="contained"
                  sx={{ marginLeft: 2 }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
            <Divider />
            <Stack
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              paddingTop={3}
            >
              <Stack
                width="100%"
                paddingY="8px"
                paddingBottom="8px"
                alignItems="flex-start"
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                <Typography width="30%" paddingY="8px">
                  Logo
                </Typography>
                <Stack
                  width={{ xs: '100%', sm: '70%', md: '60%' }}
                  flexDirection="row"
                  alignItems="center"
                >
                  {image.imageLogo ? (
                    <Box width="100%">
                      <Tooltip title="change image" placement="top">
                        <Box
                          width="100%"
                          component="div"
                          onClick={() => onHandleRemoveImage('imageLogo')}
                        >
                          <img
                            alt="Logo"
                            src={image.imageLogo}
                            style={{ maxHeight: '180px', maxWidth: '100%' }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box position="relative" marginBottom={4} width="100%">
                      <Field
                        name="imageLogo"
                        as={DragAndDroFile}
                        accept={{ 'image/*': [] }}
                        onDrop={(file: any) => {
                          onHandleDrop({ file, imageType: 'imageLogo' })
                          setFieldValue('imageLogo', 'imageLogo')
                        }}
                      />
                      {touched.imageLogo && errors.imageLogo && (
                        <Typography
                          variant="h6"
                          color={COLORS.red1}
                          position="absolute"
                          bottom="-25px"
                        >
                          Required
                        </Typography>
                      )}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              alignItems="flex-start"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Stack
                width="100%"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems="flex-start"
                paddingBottom="8px"
              >
                <Typography width="30%" paddingY="8px">
                  Header
                </Typography>
                <Stack
                  width={{ xs: '100%', sm: '70%', md: '60%' }}
                  flexDirection="row"
                  alignItems="center"
                >
                  {image.imageHeader ? (
                    <Box width="100%">
                      <Tooltip title="change image" placement="top">
                        <Box
                          width="100%"
                          component="div"
                          onClick={() => onHandleRemoveImage('imageHeader')}
                        >
                          <img
                            src={image.imageHeader}
                            alt="header"
                            width="100%"
                            style={{ maxHeight: '180px' }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box position="relative" marginBottom={4} width="100%">
                      <Field
                        name="imageHeader"
                        as={DragAndDroFile}
                        accept={{ 'image/*': [] }}
                        onDrop={(file: any) => {
                          onHandleDrop({ file, imageType: 'imageHeader' })
                          setFieldValue('imageHeader', 'imageHeader')
                        }}
                      />
                      {touched.imageHeader && errors.imageHeader && (
                        <Typography
                          variant="h6"
                          color={COLORS.red1}
                          position="absolute"
                          bottom="-25px"
                        >
                          Required
                        </Typography>
                      )}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              width="100%"
              marginY="8px"
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
            >
              <Typography
                variant="h5"
                paddingY="8px"
                alignSelf="flex-start"
                width={{ xs: '100%', sm: '30%' }}
              >
                Company description
              </Typography>
              <TextField
                multiline
                minRows={2}
                name="description"
                variant="outlined"
                value={values.description}
                onChange={handleChange('description')}
                error={touched && Boolean(errors.description)}
                helperText={touched && errors.description}
                sx={{
                  width: { xs: '100%', sm: '70%', md: '60%' },
                  color: COLORS.gray2
                }}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
