import { Typography, Stack, Divider, Box, Button } from '@mui/material'

import Paper from 'components/paper'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import useOverviewData from '../hooks/useOverviewData'
import useOverviewDataE from '../hooks/useOverviewDataE'
export const PlatformOverview = () => {
  const { userRole } = useAuth()
  const { onNavigate } = useNavigation()
  const { dataOverview } =
    userRole == 'super_admin' ? useOverviewData() : useOverviewDataE()

  return (
    <Paper
      elevation={4}
      sx={{
        width: { lg: '45%', xs: '88%', md: '78%' },
        padding: 3,
        height: { xs: '100%', lg: 250 },
        marginRight: { xs: 0, lg: 2 },
        marginBottom: { xs: 2, lg: 2 },
        overflow: 'auto'
      }}
    >
      <Typography
        marginLeft={2}
        marginBottom={2}
        marginTop={2}
        variant="subtitle1"
      >
        Platform overview
      </Typography>
      <Stack
        gridRow={4}
        flexDirection={'row'}
        alignItems={'center'}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-between"
      >
        {userRole !== 'super_admin' && (
          <Stack marginBottom={2}>
            <Box textAlign={'center'} padding={{ xs: 4, lg: 0 }}>
              <Typography variant="subtitle2">Wallet</Typography>
              <Typography variant="h4">
                ${dataOverview.data?.wallet ? dataOverview.data.wallet : 0}
              </Typography>
              <Button
                sx={{ textAlign: 'center', paddingX: { xs: 0, md: 3 } }}
                onClick={() => onNavigate('/wallet')}
              >
                View
              </Button>
            </Box>
          </Stack>
        )}

        {userRole === 'super_admin' && (
          <Stack>
            <Box textAlign={'center'} padding={{ xs: 4, lg: 0 }}>
              <Typography variant="subtitle2">Employees</Typography>
              <Typography variant="h4">
                {dataOverview.data?.employee}
              </Typography>
            </Box>
          </Stack>
        )}

        <Stack>
          <Box textAlign={'center'} padding={{ xs: 4, lg: 0 }}>
            {userRole !== 'employee' ? (
              <Typography variant="subtitle2">Jobs</Typography>
            ) : (
              <Typography variant="subtitle2">Shared jobs</Typography>
            )}
            <Typography variant="h4">{dataOverview.data?.jobs}</Typography>
            {userRole !== 'super_admin' && (
              <Button
                sx={{ textAlign: 'center', paddingX: { xs: 0, md: 3 } }}
                onClick={() => onNavigate('/jobs/jobboard')}
              >
                View
              </Button>
            )}
          </Box>
        </Stack>

        {userRole !== 'employee' ? (
          <Stack>
            <Box textAlign={'center'} padding={{ xs: 4, lg: 0 }}>
              <Typography variant="subtitle2">
                {userRole === 'super_admin' ? 'Companies' : 'Employees'}
              </Typography>
              <Typography variant="h4">
                {dataOverview.data?.employee}
              </Typography>
              {userRole !== 'super_admin' && (
                <Button
                  sx={{ textAlign: 'center', paddingX: { xs: 0, md: 3 } }}
                  onClick={() => onNavigate('/employees')}
                >
                  View
                </Button>
              )}
            </Box>
          </Stack>
        ) : (
          0
        )}
        <Stack>
          <Box textAlign={'center'} padding={{ xs: 4, lg: 0 }}>
            {userRole !== 'employee' ? (
              <Typography variant="subtitle2">Referrals</Typography>
            ) : (
              <Typography variant="subtitle2">Total Referrals</Typography>
            )}
            <Typography variant="h4">{dataOverview.data?.referrals}</Typography>
            {userRole !== 'super_admin' && (
              <Button
                sx={{ textAlign: 'center', paddingX: { xs: 0, md: 3 } }}
                onClick={() => onNavigate('/referrals')}
              >
                View
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}
