import { useEffect, useState } from 'react'
import useFilterStore from 'stores/useFilterStore'
import { JobFilterName } from 'types/jobs'
import type { Filter } from 'types/table'
import type { UseFilter } from 'types/table'
import useFilterJobStore from './useFilterJobStore'

export default function useFilter() {
  const { filters, onAddFilter, onCleanFilters } = useFilterJobStore()
  const { onCloseAnchor } = useFilterStore()

  const [filterJobs, setFilterJobs] = useState({
    company_name: '',
    status: [] as Array<UseFilter>,
    bonus_status: [] as Array<UseFilter>
  })

  useEffect(() => {
    const filterEntries = Object.entries(filters)
      .map(([key, value]) => {
        return {
          key: Array.isArray(value) && value.length > 0 ? key : null,
          value: JSON.parse(JSON.stringify(value)) as Array<string>
        }
      })
      .filter(item => item.key)

    filterEntries.map(item => {
      setFilterJobs(prevState => {
        return {
          ...prevState,
          [item.key as JobFilterName]: item.value.map(item => {
            return {
              id: item,
              label: item,
              checked: true
            }
          })
        }
      })
    })
    onChangeSingleValue(filters.company_name)
  }, [])

  const onClearFilters = (name: JobFilterName) => {
    onCleanFilters(name)
    onCloseAnchor()
  }

  const onAddUrlFilters = (name: JobFilterName) => {
    onAddFilter(filterJobs[name], name)
    onCloseAnchor()
  }

  const onChangeValue = ({
    filter,
    name
  }: {
    filter: Filter
    name: 'status' | 'bonus_status'
  }) => {
    setFilterJobs(prevState => {
      if (!filter.checked) {
        return {
          ...prevState,
          [name]: [...prevState[name].filter(item => item.id !== filter.id)]
        }
      }
      return {
        ...prevState,
        [name]: [
          ...prevState[name].filter(item => item.id !== filter.id),
          filter
        ]
      }
    })
  }

  const onChangeSingleValue = (value: string) => {
    setFilterJobs(prevState => ({
      ...prevState,
      company_name: value
    }))
  }

  return {
    onChangeSingleValue,
    onChangeValue,
    onClearFilters,
    onAddUrlFilters,
    filterJobs
  }
}
