import { UpdateEmployeeTemplate } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'
import { UpdateEmployeeTemplateParams } from '../Types/EmployeeDetail'
import useShowEmployee from './useShowEmployee'
export default function useUpdateEmployee(employeeId: number) {
  const { onNotify } = useNotification()
  const { onHandleCloseEdit, getEmployeeDetailTemplate } = useShowEmployee()
  const query = useQueryClient()

  const { mutate, isLoading } = useMutation(
    (data: { employee: Partial<UpdateEmployeeTemplateParams> }) =>
      UpdateEmployeeTemplate(employeeId, data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Changes has been saved'
        })
        getEmployeeDetailTemplate()
        query.invalidateQueries('EMPLOYEE_BY_ID')
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onHandleCloseEdit()
      }
    }
  )

  const onSumbit = (values: Partial<UpdateEmployeeTemplateParams>) => {
    const data = {
      ...values,
      birthday: new Date(values.birthday as string),
      inserted_at: new Date(values.inserted_at as string),
      email: values.email?.toLocaleLowerCase(),
      role: values.role === 'employee' ? null : values.role
    }
    mutate({
      employee: data
    })
  }

  return {
    isLoading,
    onSumbit
  }
}
