import { hookstate, useHookstate } from '@hookstate/core'
import { UpdateBonusConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { BonusConfig } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from './useBonusTable'

interface UpdateBonusConfigParameters {
  data: BonusConfig
  bonusId: number
  configId: number
}

const initialState = hookstate({
  isLoading: false
})

export default function useUpdateBonusConfig() {
  const { isLoading } = useHookstate(initialState)
  const { onToggleTemplate } = useBonusTable()
  const { onNotify } = useNotification()
  const { mutate } = useMutation(
    (data: UpdateBonusConfigParameters) =>
      UpdateBonusConfig(data.data, data.bonusId, data.configId),
    {
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )
  const onUpdateBonusConfig = async (
    bonusConfig: BonusConfig,
    bonusId: number,
    configId: number
  ) => {
    isLoading.set(true)
    delete bonusConfig.new
    delete bonusConfig.edited
    delete bonusConfig.deleted
    mutate({ data: bonusConfig, bonusId, configId })
  }

  return {
    isLoading: isLoading.value,
    onUpdateBonusConfig,
    onToggleTemplate
  }
}
