import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { CreateJobApplication } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { get } from 'utils'
// export const validationSchema = yup.object({
//   name: yup.string().required('Name required'),
//   lastName: yup.string().required('Last name required'),
//   email: yup.string().required('Email required'),
//   countryCode: yup.string().required('Country code required'),
//   phoneNumber: yup.string().required('Phone number required')
// })
// export const validationSchemaStep2 = yup.object({
//   CV: yup.string().required('Curriculum is required')
// })
export type JobForm = {
  birthday: string
  email: string
  first_name: string
  phone: string
  phone_area_code: string
  last_name: string
  description: string
  file: string | ArrayBuffer | null | undefined
  username: string
  password: string
  employee_id: number
  address_1: string
  address_2: string
  city: string
  state: string
  zip: string
  country_id: string
}
const initialState = hookstate({
  jobForm: {} as JobForm
})
export default function useJobApplication() {
  const { onNotify } = useNotification()
  const { onNavigate, params } = useNavigation()
  const [loading, setLoading] = useState(false)

  const { jobForm } = useHookstate(initialState)

  const initialValues: JobForm = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    phone_area_code: '',
    description: '',
    file: '',
    address_1: '',
    address_2: '',
    birthday: '',
    city: '',
    country_id: '',
    employee_id: Number(params.employeeId),
    password: '',
    state: '',
    username: '',
    zip: ''
  }

  const handleSubmit = () =>
    onNavigate(`/company/${params.id}/jobs/${params.employeeId}`)

  const submit = (jobForm: JobForm) => {
    setLoading(true)
    const data = { ...jobForm, email: jobForm.email.toLowerCase() }
    CreateJobApplication(data, Number(params.id), Number(params.jobId))
      .then(() => {
        onNavigate(
          `/company/${params.id}/jobs/${params.jobId}/${params.employeeId}/jobapplication-step3`
        )
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    setLoading,
    handleSubmit,
    initialValues,
    submit,
    jobForm: jobForm.value,
    setJobForm: jobForm.set
  }
}
