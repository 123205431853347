import { State } from '@hookstate/core'
import { Stack, Typography, Box } from '@mui/material'
import Paper from 'components/paper'

export default function WithdrawStepMessage({
  title,
  subtitle
}: {
  title: State<string>
  subtitle: State<string>
}) {
  return (
    <Box height="70%" padding={2}>
      <Stack
        component={Paper}
        elevation={4}
        paddingY={2}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <img src="/images/check.png" alt="check-image" />
        <Typography
          variant="h3"
          width="80%"
          margin="0 auto"
          textAlign="center"
          marginTop={4}
        >
          {title.value}
        </Typography>
        <Typography
          variant="h5"
          width="80%"
          margin="0 auto"
          textAlign="center"
          marginTop={4}
        >
          {subtitle.value}
        </Typography>
      </Stack>
    </Box>
  )
}
