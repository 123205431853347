import React from 'react'
import { BoxProps } from '@mui/material'
import {
  WalletCardCtn,
  WalletCardBody,
  WallwetCardCircle,
  WalletCardShadow,
  WalletCardChildren
} from './styles'

type WalletProps = BoxProps

export default function WithdrawCard({ children, ...props }: WalletProps) {
  return (
    <WalletCardCtn {...props}>
      <WalletCardBody>
        <WallwetCardCircle />
        <WalletCardChildren>{children}</WalletCardChildren>
      </WalletCardBody>
      <WalletCardShadow />
    </WalletCardCtn>
  )
}
