import { useEffect, useState } from 'react'
import { GetCompanies } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { Company } from 'types/companies'
import { get, getPagination } from 'utils'
import useFilterCompanies from './useFilterCompanies'

export default function useCompanies() {
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()

  const [companies, setCompanies] = useState<Company>({} as Company)

  const { filters } = useFilterCompanies()
  const { isLoading, isFetching, refetch } = useQuery(
    'COMPANIES',
    () =>
      GetCompanies({
        page: filters.page,
        size: filters.size,
        search: searchParams.search
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCompanies(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [JSON.stringify(filters), searchParams.search])

  const pagination = getPagination(companies)

  return {
    isLoading: isLoading || isFetching,
    companies: companies.entries || [],
    pagination,
    refetch
  }
}
