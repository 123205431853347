export default function PlaceholderIcon() {
  return (
    <svg width="20pt" height="13pt" viewBox="0 0 20 13">
      <path
        d="M18.332 13H1.668a1.644 1.644 0 01-1.172-.473A1.618 1.618 0 010 11.371V1.63C.004 1.19.18.777.496.473A1.644 1.644 0 011.668 0h16.664c.438-.004.86.164 1.172.473.316.304.492.718.496 1.156v9.75A1.65 1.65 0 0118.332 13zm0 0"
        fill="#e8ebee"
      />
      <path
        d="M447.975 58h31.05c16.538 0 29.925 13.48 29.925 30.02v2.945c0 16.652-13.387 30.02-29.925 30.02h-31.05c-16.538 0-29.925-13.368-29.925-30.02V88.02c0-16.54 13.387-30.02 29.925-30.02zm0 0"
        transform="scale(.03472 .03448)"
        fill="none"
        strokeWidth="20"
        stroke="#757575"
      />
      <path
        d="M2.117 7.172h3.16c.153 0 .278.125.278.277v.828a.275.275 0 01-.278.274h-3.16a.275.275 0 01-.277-.274V7.45c0-.152.125-.277.277-.277zm0 0M14.617 7.172h3.16c.153 0 .278.125.278.277v.828a.275.275 0 01-.278.274h-3.16a.275.275 0 01-.277-.274V7.45c0-.152.125-.277.277-.277zm0 0M10.453 7.172h3.156a.28.28 0 01.282.277v.828a.276.276 0 01-.282.274h-3.156a.276.276 0 01-.281-.274V7.45a.28.28 0 01.281-.277zm0 0M6.285 7.172h3.16c.153 0 .278.125.278.277v.828a.275.275 0 01-.278.274h-3.16a.275.275 0 01-.277-.274V7.45c0-.152.125-.277.277-.277zm0 0"
        fillRule="evenodd"
        fill="#757575"
      />
    </svg>
  )
}
