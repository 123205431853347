import { LoadingButton } from '@mui/lab'
import { Stack, Box, Typography, TextField, Button } from '@mui/material'
import useRefer from 'app/educationDetails/hooks/useRefer'
import Paper from 'components/paper'
import { Formik, Form } from 'formik'
import { COLORS } from 'styles/colors'
import * as yup from 'yup'

const validationSchema = yup.object({
  subject: yup.string().required('Required'),
  message: yup.string().required('Required')
})

export default function Subject() {
  const { isLoading, onSubmit, onBackStep } = useRefer()

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ subject: '', message: '' }}
      onSubmit={values => {
        onSubmit(values)
      }}
    >
      {({ handleChange, touched, errors }) => (
        <Stack component={Form} height="96%" justifyContent="space-between">
          <Box component={Paper} elevation={2} paddingBottom="34px">
            <Typography variant="h2" marginBottom={3}>
              Select a message
            </Typography>
            {touched.subject && errors.subject && (
              <Typography
                variant="h6"
                color={COLORS.red1}
                position="absolute"
                margin="50px 0 0 0"
              >
                Required
              </Typography>
            )}
            <TextField
              fullWidth
              name="subject"
              label="Subject"
              variant="standard"
              sx={{ marginBottom: 2 }}
              onChange={handleChange('subject')}
            />
            {touched.message && errors.message && (
              <Typography
                variant="h6"
                color={COLORS.red1}
                position="absolute"
                margin="165px 0 0 0"
              >
                Required
              </Typography>
            )}
            <TextField
              multiline
              fullWidth
              minRows={6}
              name="message"
              variant="standard"
              label="Your message"
              onChange={handleChange('message')}
            />
          </Box>
          <Stack>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              disabled={isLoading}
              loading={isLoading}
            >
              Continue
            </LoadingButton>
            <Button
              fullWidth
              variant="text"
              onClick={onBackStep}
              sx={{ marginTop: 2 }}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
