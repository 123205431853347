import { useState } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { AssignPassword } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { LETTERS, get } from 'utils'
import { SPECIAL_CHARACTERS } from 'utils/catalogs'
import * as yup from 'yup'

export const initialValues = {
  newPassword: ''
}

export const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(SPECIAL_CHARACTERS, 'One special character is required')
    .matches(/[A-Z]/, 'One uppercase letter is required')
})

export const checkList = [
  {
    id: 1,
    type: 'characters',
    icon: CheckCircleOutlineIcon,
    name: 'At least 8 characters'
  },
  {
    id: 2,
    type: 'uppercase',
    icon: CheckCircleOutlineIcon,
    name: 'One uppercase letter'
  },
  {
    id: 3,
    type: 'special',
    icon: CheckCircleOutlineIcon,
    name: 'One special character'
  }
]

export const getIconColor = (values: { newPassword: string }) => {
  const hasSpecialCharacter = SPECIAL_CHARACTERS.test(values.newPassword)
  const hasLenghtCharacter = values.newPassword.length >= 8
  const hasLetter = Array.from(values.newPassword).some(letter =>
    LETTERS.includes(letter)
  )

  return {
    characters: hasLenghtCharacter,
    uppercase: hasLetter,
    special: hasSpecialCharacter
  }
}

export default function useNewPassword() {
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { onNotify } = useNotification()
  const { onNavigate, searchParams, params } = useNavigation()

  const onClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const onHandleRedirect = () => {
    onNavigate('/password-changed')
  }

  const onSubmit = (values: { newPassword: string }) => {
    setIsLoading(true)
    AssignPassword({
      token: searchParams.token || params.token,
      password: values.newPassword
    })
      .then(() => {
        onHandleRedirect()
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return {
    onClickShowPassword,
    showPassword,
    onHandleRedirect,
    onSubmit,
    isLoading
  }
}
