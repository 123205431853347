import Stepper from 'components/stepper'
import { JobDetail } from 'types/jobs'
import BuyForm from './buyForm'
import BuyDetail from './detail'

const STEPS = ['Positions', 'Positions details']

export default function BuyBonus({ details }: { details: JobDetail }) {
  return (
    <Stepper steps={STEPS}>
      <BuyForm details={details} />
      <BuyDetail details={details} />
    </Stepper>
  )
}
