import { useState } from 'react'
import { DataGridProps, GridCallbackDetails } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import ColumnHeader from './ColumnHeader'
import { Pagination } from './Pagination'
import TableChip from './TableChip'
import TableHeader from './TableHeader'
import { MUIDataGrid } from './styles'

function DataGrid({ ...props }: DataGridProps) {
  const [selectedRow, setSelectedRow] = useState(0)

  const handleCellClick = (
    rest: any,
    event: any,
    details: GridCallbackDetails
  ) => {
    props.onCellClick?.(rest, event, details)
    setSelectedRow(rest.row.id)
  }

  const handleCellFocusOut = (
    params: any,
    event: any,
    details: GridCallbackDetails
  ) => {
    setSelectedRow(0)
    props.onCellFocusOut?.(params, event, details)
  }

  const handleRowClick = (
    params: any,
    event: any,
    details: GridCallbackDetails
  ) => {
    props.onRowClick?.(params, event, details)
    setSelectedRow(params.id)
  }

  return (
    <MUIDataGrid
      disableColumnMenu
      checkboxSelection
      sx={{
        overflow: 'auto',
        cursor: 'pointer',
        '& .MuiTablePagination-selectLabel': {
          color: COLORS.gray2
        },
        ...StylesScrollBar
      }}
      disableSelectionOnClick
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
      onCellFocusOut={handleCellFocusOut}
      experimentalFeatures={{ newEditingApi: true }}
      getRowClassName={params =>
        params.id === selectedRow
          ? 'selected-row'
          : params.indexRelativeToCurrentPage % 2 === 0
          ? 'Mui-even'
          : 'Mui-odd'
      }
      {...props}
    />
  )
}

export { DataGrid, ColumnHeader, TableChip, TableHeader, Pagination }
