import useAddBonusTemplate from 'app/bonus/templates/hooks/useAddBonusTemplate'
import { FieldArrayRenderProps } from 'formik'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { BonusConfig } from 'types/bonusTemplate'
import { removeEmptyKeys } from 'utils'
import * as yup from 'yup'

export const validationSchema = yup.object({
  entries: yup.object({
    description: yup.string().required('Description is required'),
    bonus_config: yup.array().of(
      yup.object().shape({
        amount: yup
          .number()
          .max(2000, 'Max amount is 2000')
          .typeError('Amount must be a number')
          .required('Amount is required'),
        days: yup.number().min(0).required('Days is required')
      })
    )
  })
})

const getInitialValues = (id: number, userId: number) => {
  return {
    entries: {
      bonus_config: [] as BonusConfig[],
      description: '',
      is_for_referral: false,
      from_template: 0,
      inserted_at: '',
      is_for_referrer: false,
      is_template: false,
      job_id: id,
      status: 'active',
      type: 'flat rate',
      user_id: userId,
      total_amount_referral: 0,
      total_amount_referrer: 0
    }
  }
}

export default function useNewBonus() {
  const { params } = useNavigation()
  const { user } = useAuth()

  const { onAddBonusTemplate, isLoading } = useAddBonusTemplate()

  const onAddBonusConfigToArray = (
    arrayHelpers: FieldArrayRenderProps,
    type: 'referral' | 'referrer',
    isNew?: boolean
  ) => {
    const newBonusTemplate: BonusConfig = {
      amount: '',
      days: '',
      type: type,
      new: isNew
    }
    arrayHelpers.push(newBonusTemplate)
  }

  const onRemoveBonusConfigFromArray = (
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index)
  }

  const initialValues = getInitialValues(Number(params.id), Number(user.id))

  const onSubmit = (values: typeof initialValues) => {
    const parseConfig = values.entries.bonus_config.map(config => {
      return removeEmptyKeys({
        ...config,
        amount: Number(config.amount),
        days: Number(config.days),
        edited: '',
        new: '',
        id: ''
      }) as BonusConfig
    })
    onAddBonusTemplate({
      ...values.entries,
      bonus_config: parseConfig
    })
  }

  return {
    initialValues,
    isLoading,
    onSubmit,
    onAddBonusConfigToArray,
    onRemoveBonusConfigFromArray
  }
}
