import { Bar } from '@nivo/bar'

import useAuth from 'hooks/useAuth'
import useJobsGraph from '../hooks/useJobsGraph'
import useJobsGraphE from '../hooks/useJobsGraphE'

const JOBS_STATUS = {
  active: 'Active',
  not_set: 'Not Set',
  closed_ats: 'Closed A',
  closed_beegopp: 'Closed B',
  new: 'New',
  on_hold_ats: 'On Hold',
  pending: 'Pending',
  published: 'Published'
}
export const BarJobs = () => {
  const { userRole } = useAuth()
  const { dataJobs } =
    userRole == 'super_admin' ? useJobsGraph() : useJobsGraphE()

  const { data } = dataJobs

  const statuses = data ? Object.keys(data) : []

  const datos = data
    ? statuses.map((stat: any) => ({ stat, Status: data[stat] }))
    : [{}]
  const renamedDatos = datos.map((obj: any) => {
    return {
      ...obj,
      stat: JOBS_STATUS[obj.stat as keyof typeof JOBS_STATUS]
    }
  })

  return (
    <Bar
      width={630}
      height={310}
      data={renamedDatos}
      keys={['Status']}
      indexBy="stat"
      margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
      padding={0.55}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#0021AD', '#FFBD31']}
      label={'ATS'}
      enableGridY={false}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#0021AD',
          size: 4,
          padding: 0.55,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#0021AD',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        ariaHidden: false,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,

        legendPosition: 'middle',
        legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
      }}
    />
  )
}
