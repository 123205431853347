import { useState } from 'react'
import { ClearPositions } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'

type ClearPosition = {
  jobId: number
  positionId: string
}

export default function useClearPosition() {
  const { onNotify } = useNotification()
  const { params } = useNavigation()
  const [isOpendialog, setIsOpenDialog] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState<string>('')

  const query = useQueryClient()

  const { mutate, isLoading } = useMutation(
    (data: ClearPosition) => ClearPositions(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Position deleted successfully'
        })
        query.invalidateQueries('BONUSPAYMENT')
        onCloseDialog()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onOpenDialog = () => setIsOpenDialog(true)
  const onCloseDialog = () => setIsOpenDialog(false)

  const onSelectedPosition = (positionId: string) => {
    setSelectedPosition(positionId)
    onOpenDialog()
  }

  const onClearPosition = () => {
    mutate({
      jobId: Number(params.id),
      positionId: selectedPosition
    })
  }

  return {
    selectedPosition,
    isOpendialog,
    isLoading,
    onOpenDialog,
    onCloseDialog,
    onClearPosition,
    onSelectedPosition
  }
}
