import { hookstate, useHookstate } from '@hookstate/core'
import { GetJobDetails } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { JobDetail } from 'types/jobs'
import { get } from 'utils'

const initialState = hookstate({
  details: {} as JobDetail
})
export default function useJobBoardDetail() {
  const { onNotify } = useNotification()
  const { details } = useHookstate(initialState)
  const { params } = useNavigation()

  const { isLoading } = useQuery(
    'JOB_BOARD_DETAIL',
    () => GetJobDetails(Number(params.id)),
    {
      enabled: Boolean(params.id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        details.set(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return { isLoading: isLoading, details: details.value }
}
