import { useEffect, useRef, useState } from 'react'

export default function useJobDetail() {
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [elementHeight, setElementHeight] = useState(0)

  useEffect(() => {
    if (elementRef) {
      setElementHeight(elementRef.current?.clientHeight || 0)
    }
  }, [elementRef])

  return { elementRef, elementHeight }
}
