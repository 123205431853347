import { UpdateCompaniesStatus } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateCompaniesStatusData } from 'types/companies'
import { get } from 'utils'

export default function useBulkActions(callback: () => void) {
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: UpdateCompaniesStatusData) => UpdateCompaniesStatus(data),
    {
      onSuccess: () => {
        onNotify({
          message: 'Status updated successfully',
          severity: 'success'
        })
        callback()
        query.invalidateQueries('COMPANIES')
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onSubmit = ({
    companies,
    status
  }: {
    companies: Array<string | number>
    status: 'active' | 'inactive'
  }) => {
    mutate({ companies: companies as Array<number>, status })
  }

  return { isLoading, onSubmit }
}
