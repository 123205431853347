import { useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import Footer from 'app/layout/footer'
import { SigninLogo } from 'app/signin/styles'
import Spinner from 'components/spinner'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { LinkButton } from 'styles/utils'
import useCompanyPreview from '../hooks/useCompanyJob'
import useJobCounterViews from '../hooks/useJobCounterViews'

export default function JobBoardDetail() {
  const { onNavigate, params } = useNavigation()
  const { details, isLoading } = useCompanyPreview()
  const { onMutate } = useJobCounterViews()

  useEffect(() => {
    onMutate()
  }, [])

  const applyNow = () => {
    onNavigate(
      `/company/${params.id}/jobs/${params.jobId}/${params.employeeId}/jobapplication-step1`
    )
  }

  return (
    <Box bgcolor={COLORS.gray9} minHeight="100vh">
      {isLoading ? (
        <Stack
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgcolor="rgba(0, 0, 0, 0.5)"
          justifyContent="center"
          alignItems="center"
          zIndex={999}
        >
          <Spinner />
        </Stack>
      ) : (
        <>
          <Box
            top={0}
            zIndex={99}
            position="sticky"
            bgcolor={COLORS.white}
            padding="10px 10px 0"
            paddingTop={{ xs: '10px', md: 2 }}
          >
            <Box
              width="100%"
              margin="0 auto"
              maxWidth={{ xs: '100%', md: '80%', lg: '70%' }}
            >
              <SigninLogo
                alt="logo"
                src="/images/logo.png"
                style={{ marginTop: 0, width: '100px' }}
              />
            </Box>
          </Box>
          <Box
            width="100%"
            margin="0 auto"
            paddingX="10px"
            paddingBottom={10}
            boxSizing="border-box"
            maxWidth={{ xs: '100%', md: '80%', lg: '70%' }}
          >
            <Box
              width="100%"
              paddingY={{ xs: 2, md: 2 }}
              sx={{ boxSizing: 'border-box' }}
            >
              <Box
                alt="Header"
                width="100%"
                component="img"
                maxHeight="200px"
                src={details.company.header}
                sx={{ objectFit: 'cover' }}
              />
            </Box>
            <Stack
              width="100%"
              marginTop={2}
              marginBottom={8}
              flexDirection="row"
              boxSizing="border-box"
              justifyContent="space-around"
            >
              <Box marginRight={{ xs: 3 }} width="20%">
                <Box
                  width="100%"
                  maxWidth="180px"
                  margin={{ xs: '0 auto' }}
                  marginBottom={{ xs: '4px', md: '4px' }}
                  justifyContent={{ xs: 'space-between' }}
                >
                  <img src={details.company.logo} alt="cover" width="100%" />
                </Box>
              </Box>
              <Stack
                width="100%"
                height="50%"
                alignItems="baseline"
                direction={{ sm: 'row' }}
                justifyContent={{ xs: 'center', lg: 'space-between' }}
              >
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  width={{ xs: '100%' }}
                >
                  <Box width={{ xs: '100%', md: '50%' }}>
                    <Stack direction="row" marginBottom={1}>
                      <Typography variant="caption" marginRight={1}>
                        Industry:
                      </Typography>
                      <Typography variant="h6">{details.name}</Typography>
                    </Stack>
                    <Stack direction="row" marginBottom={1}>
                      <Typography variant="caption" marginRight={1}>
                        Job type:
                      </Typography>
                      <Typography variant="h6">{details.type}</Typography>
                    </Stack>
                  </Box>
                  <Box width={{ xs: '100%', md: '50%' }}>
                    <Stack direction="row" marginBottom={1}>
                      <Typography variant="caption" marginRight={1}>
                        Open positions:
                      </Typography>
                      <Typography variant="h6">
                        {details.open_positions || 0}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Box
                  marginTop={{ xs: 3, sm: 0 }}
                  alignSelf={{ xs: 'flex-start', sm: 'flex-end' }}
                  width={{ xs: '100%', sm: '50%', md: '40%', lg: 'auto' }}
                >
                  <Box
                    component={Button}
                    variant="contained"
                    width={{ xs: '100%', lg: 250 }}
                    onClick={() => applyNow()}
                  >
                    Apply now
                  </Box>
                </Box>
              </Stack>
            </Stack>
            <Box marginLeft={{ sm: 0, md: 'auto' }}>
              <Typography variant="h3" marginBottom={2}>
                {details.name}
              </Typography>
              <Box dangerouslySetInnerHTML={{ __html: details.description }} />
              <Stack direction="column">
                <Typography variant="h5">
                  Do you want to see all our available jobs?
                </Typography>
                <LinkButton
                  to={`/company/${params.id}/jobs/${params.employeeId}`}
                  sx={{
                    paddingBottom: 3,
                    paddingTop: 1,
                    display: 'inline-block'
                  }}
                >
                  Go to Job Board
                </LinkButton>
              </Stack>
            </Box>
          </Box>
        </>
      )}
      <Box position="fixed" bottom="0" width="100%" bgcolor="white" zIndex={9}>
        <Footer />
      </Box>
    </Box>
  )
}
