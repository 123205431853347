import { hookstate, useHookstate } from '@hookstate/core'
import { GetPublicJobDetails } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { JobDetail } from 'types/jobs'
import { get } from 'utils'

const initialState = hookstate({
  details: {} as JobDetail
})

export default function useCompanyPreview() {
  const { onNotify } = useNotification()
  const { details } = useHookstate(initialState)
  const { params } = useNavigation()

  const { isLoading } = useQuery(
    'JOB_DETAIL',
    () => GetPublicJobDetails(Number(params.id), Number(params.jobId)),
    {
      enabled: Boolean(params.jobId),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        details.set(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return {
    isLoading: isLoading,
    details: details.value
  }
}
