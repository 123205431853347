import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useToggle from 'components/toggleSidebar/useToggle'

const initialState = hookstate({
  isSent: false,
  isLoading: false
})
type Params = { [key in string]: string | unknown }
export default function useTemplate() {
  const { isSent, isLoading } = useHookstate(initialState)
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { onClearSteps } = useStepper()

  useEffect(() => {
    isSent.set(false)
  }, [openToggle.template, openToggle.employee])

  const onToggleTemplate = (
    toggle: 'template' | 'employee' | 'visibility',
    id?: Params
  ) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle, id)
    }
  }

  const onSubmit = () => {
    isLoading.set(true)
    setTimeout(() => {
      isSent.set(true)
      isLoading.set(!isLoading.value)
      onClearSteps()
    }, 2000)
  }

  return {
    openTemplate: openToggle,
    isLoading: isLoading.value,
    isSent: isSent.value,
    setIsSent: isSent.set,
    onToggleTemplate,
    onSubmit
  }
}
