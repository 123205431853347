import { Link, Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ColumnHeader, TableChip } from 'components/table'
import { COLORS } from 'styles/colors'
import type { Referrals } from 'types/referrals'
import { formatDate } from 'utils'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
    editable: false,
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => {
      const ownerName = `${props.row.user_referral.first_name} ${
        props.row.user_referral.last_name || ''
      }`
      return (
        <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
          {ownerName}
        </Typography>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'email',
    headerName: 'Email',
    align: 'left',
    width: 260,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {props.row.user_referral.email}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'cv',
    headerName: 'Resume',
    width: 140,
    editable: false,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />,
    renderCell: (props: GridCellParams) => {
      const hasCv = props.row.cv
      return (
        <>
          {hasCv ? (
            <Link>
              <Typography
                variant="body2"
                overflow="hidden"
                color={COLORS.main}
                textOverflow="ellipsis"
                sx={{ textDecoration: 'underline' }}
              >
                Resume
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="body2"
              overflow="hidden"
              color={COLORS.gray1}
              textOverflow="ellipsis"
            >
              Not upload
            </Typography>
          )}
        </>
      )
    }
  },
  {
    field: 'phone',
    headerName: 'Telephone',
    type: 'number',
    align: 'left',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => {
      const validateAreaCode = props.row.user_referral.phone_area_code
        ? `+${props.row.user_referral.phone_area_code}`
        : ''
      const referralPhone = `${validateAreaCode} ${props.row.user_referral.phone}`
      return (
        <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
          {referralPhone}
        </Typography>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'joined',
    headerName: 'Joined',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.joined, 'MMM dd, yyy')}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip {...props} chipProps={{ variant: 'filled' }} />
    ),
    renderHeader: props => <ColumnHeader {...props} />
  }
]

export const getRows = (referrals: Referrals['entries']) => {
  const filteredReferrals = referrals.map(referral => {
    return {
      ...referral,
      joined: new Date() // pending
    }
  })
  return filteredReferrals
}
