import { Typography, Stack } from '@mui/material'
import Paper from 'components/paper'
import { PaymentCardIcon, VARIANT } from 'components/paymentCard/styles'
import { COLORS } from 'styles/colors'
import { PaymentCardProps } from 'types/paymentCard'

export default function StatusCard({
  variant,
  icon: IconComponent,
  title,
  subtitle = '',
  other = '',
  iconProps,
  typography,
  children,
  paperProps,
  paperColor = 'default'
}: PaymentCardProps) {
  return (
    <Paper elevation={2} {...paperProps} bgcolor={VARIANT[paperColor].bgcolor}>
      <Stack flexDirection="row" justifyContent={'space-between'}>
        <Stack flexDirection="column">
          <Typography
            variant="h6"
            color={
              other == 'Well Done!' ? COLORS.gray1 : VARIANT[variant].bgcolor
            }
            marginBottom={2}
          >
            {title}
          </Typography>

          <PaymentCardIcon
            bgcolor={VARIANT[variant].bgcolor}
            opacity={VARIANT[variant].opacity}
          >
            <IconComponent
              {...iconProps}
              htmlColor={VARIANT[variant].color}
              sx={{ width: 25, ...iconProps?.sx }}
            />
          </PaymentCardIcon>
        </Stack>
        <Stack alignItems={'center'}>
          <Typography
            fontSize="24px"
            fontWeight={500}
            color={other != 'Well Done!' ? COLORS.gray1 : COLORS.green1}
            {...typography}
          >
            {subtitle}
          </Typography>
          <Typography>{other}</Typography>
        </Stack>
      </Stack>

      {children}
    </Paper>
  )
}
