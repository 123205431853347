import { GridColDef } from '@mui/x-data-grid'
import ColumnHeader from 'components/table/ColumnHeader'
import TableChip from 'components/table/TableChip'
import { ListTemplates } from 'types/bonusTemplate'
import { formatDate, getBonusFor } from 'utils'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100, sortable: false },
  {
    field: 'description',
    headerName: 'Name',
    headerAlign: 'left',
    width: 300,
    sortable: false,
    editable: true,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'inserted_at',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Created At',
    width: 170,
    sortable: false,
    editable: true,
    renderCell: props => {
      return (
        <div>{formatDate(new Date(props.row.inserted_at), 'dd LLL, yy')}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'bonusFor',
    headerName: 'Bonus For',
    headerAlign: 'left',
    align: 'left',
    width: 170,
    editable: true,
    sortable: false,
    renderCell: props => {
      const bonusFor = getBonusFor(
        props.row.is_for_referral,
        props.row.is_for_referrer
      )
      return <div>{bonusFor}</div>
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'totalAmount',
    headerName: 'Total amount',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    width: 160,
    sortable: false,
    editable: true,
    renderCell: props => {
      let sum = 0
      for (let i = 0; i < props.row.bonus_config.length; i++) {
        sum += Number(props.row.bonus_config[i].amount)
      }
      return <div>${sum}</div>
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'user_id',
    headerName: 'User',
    width: 240,
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    editable: true,
    sortable: false,
    renderCell: props => {
      return (
        <div>{`${props.row.user_first_name} ${props.row.user_last_name}`}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    editable: true,
    sortable: false,
    renderCell: props => (
      <TableChip {...props} chipProps={{ variant: 'outlined' }}></TableChip>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  }
]

export const BONUS_STEPS = ['Information', 'Configuration', 'Review']
export const TEMPLATES_STATUS = ['Active', 'Inactive']
export const CREATED_AT = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const BONUS_FOR = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const ACTIVE_JOBS = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const USER_FILTER = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const STATUS = [
  {
    id: 'active',
    label: 'Active'
  },
  {
    id: 'inactive',
    label: 'Inactive'
  },
  {
    id: 'cancelled',
    label: 'Cancelled'
  },
  {
    id: 'deleted',
    label: 'Deleted'
  }
]

export const getRows = (data: ListTemplates['entries']) => {
  const filteredJobs = data.map(item => {
    return {
      ...item
    }
  })
  return filteredJobs || []
}
