import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Divider, Stack, Typography } from '@mui/material'
import ImportSearch from 'app/jobs/components/importSearch'
import ModalImport from 'components/modals/import'
import Paper from 'components/paper'
import { JobsImportSkeleton } from 'components/skeleton'
import { COLORS } from 'styles/colors'
import { StylesScrollBar, hexToRGB } from 'styles/utils'
import type { JobProps } from 'types/jobs'
import Job from '../Job'
import Detail from '../detail'
import EmptyList from '../emptyList'
import useMutationImport from '../hooks/useMutationImport'
import useSelected from '../hooks/useSelected'

export default function MobileImport({
  jobsList = [],
  isLoading,
  isDisabled,
  fetchNextPage
}: {
  isLoading: boolean
  fetchNextPage: () => void
  jobsList: Array<JobProps>
  isDisabled: boolean
}) {
  const { type, selectedJob, onUnselectedAllJobs, onSelectedAllJobs } =
    useSelected()

  const { onMobileSubmit, isOpen, isLoading: sending } = useMutationImport()

  const handleJobs = (typeJob: 'jobs') => {
    if (type[typeJob]) {
      return onUnselectedAllJobs(typeJob)
    }
    onSelectedAllJobs(typeJob)
  }

  const hasJob = Boolean(selectedJob.id_source)

  const selected =
    jobsList.length > 0 ? jobsList.filter(item => item.isChecked) : []

  return (
    <Box
      elevation={3}
      component={Paper}
      position="relative"
      bgcolor={hexToRGB(COLORS.gray8, '0.2')}
    >
      <Typography
        padding={2}
        variant="h3"
        bgcolor={COLORS.white}
        fontSize="20px"
      >
        Import ({jobsList.length})
      </Typography>
      <Divider />
      <Stack
        paddingX={1}
        bgcolor={COLORS.white}
        justifyContent="space-between"
        paddingBottom={{ xs: 2, sm: 0 }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <Stack flexDirection="row" alignItems="center">
          <Button variant="text" onClick={() => handleJobs('jobs')}>
            {type.jobs ? 'Remove all' : 'Select all'}
          </Button>
          <Typography padding={2} variant="h5" color={COLORS.gray2}>
            {selected.length || 0} Selected Jobs
          </Typography>
        </Stack>
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          loading={sending}
          onClick={onMobileSubmit}
          disabled={selected.length === 0 || sending}
          sx={{ maxWidth: { xs: '100%', sm: '200px' } }}
        >
          Import selected jobs
        </LoadingButton>
      </Stack>
      <Divider />
      <ImportSearch />
      {isLoading ? (
        <JobsImportSkeleton />
      ) : (
        <Box
          paddingX={1}
          paddingBottom={1}
          height="calc(100vh - 28em)"
          sx={{
            overflow: 'auto',
            ...StylesScrollBar
          }}
        >
          {jobsList.length > 0 ? (
            <>
              {jobsList.map(item => {
                const checkedBgcolor = item.isChecked
                  ? COLORS.honey2
                  : COLORS.white

                return (
                  <Box
                    elevation={2}
                    marginBottom={1}
                    component={Paper}
                    key={item.id_source}
                    bgcolor={checkedBgcolor}
                  >
                    <Job {...item} importType="jobs" />
                  </Box>
                )
              })}
            </>
          ) : (
            <EmptyList title="No results to import jobs" />
          )}
        </Box>
      )}
      {isDisabled ? (
        <Typography left="35%" bottom={12} variant="body2" position="absolute">
          No more results.
        </Typography>
      ) : (
        <Box
          left="40%"
          bottom={12}
          component="div"
          fontSize="14px"
          position="absolute"
          color={isLoading ? COLORS.gray3 : COLORS.main}
          onClick={() => fetchNextPage()}
          sx={{
            cursor: 'pointer',
            pointerEvents: isLoading ? 'none' : 'auto',
            ':hover': { textDecoration: 'underline' }
          }}
        >
          Load more
        </Box>
      )}

      <ModalImport isOpen={isOpen} title="Importing jobs..." />

      <Dialog
        fullWidth
        maxWidth="md"
        open={hasJob}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <Detail isDialog />
      </Dialog>
    </Box>
  )
}
