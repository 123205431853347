import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import MUIStepper from '@mui/material/Stepper'
import useStepper from './useStepper'

export default function Stepper({
  steps,
  children
}: {
  children: any
  steps: Array<string>
}) {
  const { activeStep, stepComplete } = useStepper()
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        alternativeLabel
        paddingY={3}
        margin="0 auto"
        width="calc(100% - 16px)"
        component={MUIStepper}
        activeStep={activeStep}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={stepComplete.includes(index)}
            sx={{
              '&.MuiSvgIcon-root': {
                color: '#E1E2E6'
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: '#373942'
                },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: '#373942'
                },
              '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
                {
                  color: '#A3A5AC'
                },
              '& .MuiStepLabel-root .Mui-active': {
                color: '#0030FF'
              },
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#89A9FD'
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: 'white'
              }
            }}
          >
            <Stack
              padding={2}
              color="inherit"
              component={StepButton}
              disabled={stepComplete.includes(index + 1)}
            >
              {label}
            </Stack>
          </Step>
        ))}
      </Box>
      <Box paddingX={2} paddingTop={2} height="100%">
        {children[activeStep]}
      </Box>
    </Box>
  )
}
