import { Stack, Typography } from '@mui/material'
import { GridColDef, GridCellParams } from '@mui/x-data-grid'
import { ColumnHeader } from 'components/table'
import TableChip from 'components/table/TableChip'
import { ReferralStatus } from 'types/referrals'
import { formatCurrency, formatDate } from 'utils'
import { COLORS } from '../../../../styles/colors'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'first_name',
    headerName: 'Name',
    width: 280,
    editable: true,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />,
    renderCell: props => {
      return <div>{`${props.row.first_name} ${props.row.last_name || ''}`}</div>
    }
  },
  {
    field: 'Object.values(job)',
    headerName: 'Job Name',
    width: 350,
    editable: true,
    sortable: false,
    renderCell: (props: GridCellParams) => {
      const jobName = `${props.row.job.name}`
      return (
        <Stack>
          <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
            {jobName}
          </Typography>
        </Stack>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'bonus',
    headerName: 'Bonus',
    type: 'number',
    width: 160,
    editable: true,
    headerAlign: 'left',
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography
        variant="body2"
        color={COLORS.gray1}
        fontWeight="500"
        marginRight="auto"
      >
        {formatCurrency(Number(props.row.bonus.employee || 0))}
      </Typography>
    )
  },
  {
    field: 'inserted_at',
    headerName: 'Date Joined',
    type: 'date',
    description: 'This column has a value getter and is not sortable.',
    width: 240,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.inserted_at, 'MMM dd, yyy')}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'left',
    width: 162,
    editable: true,
    renderCell: props => (
      <TableChip
        {...props}
        label={STATUS[props.row.status]}
        chipProps={{ variant: 'filled' }}
        labelColor={STATUS[props.row.status] === 'Quit' ? 'black' : 'white'}
      />
    ),
    renderHeader: props => <ColumnHeader {...props} />
  }
]

const STATUS: { [key in ReferralStatus]: string } = {
  hired: 'Hired',
  on_hold: 'On hold',
  in_progress: 'In progress',
  rejected: 'Rejected',
  quit: 'Quit',
  active: 'Active'
}
