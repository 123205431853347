import React from 'react'
import Import from 'app/jobs/import'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { Outlet } from 'react-router-dom'
import { getMenu, routePermissions } from 'utils/catalogs'
import BoardConfig from './boardConfig'
import BoardJob from './boardJob'

type KeyRoute = 'import' | 'boardconfig' | 'jobboard'

const component: { [key in KeyRoute]: React.ReactNode } = {
  import: <Import />,
  boardconfig: <BoardConfig />,
  jobboard: <BoardJob />
}

const JOBS = '/jobs'

export default function Jobs() {
  const { userRole } = useAuth()
  const { pathname } = useNavigation()
  const routePermissionsByRole = routePermissions[userRole]

  const menu = getMenu(userRole)

  const jobs = menu.find(item => item.route === 'jobs')

  const subJobs = jobs?.submenu?.filter(item =>
    routePermissionsByRole.includes(item.route)
  )[0].route as KeyRoute

  const isJobs = pathname === JOBS
  return <>{isJobs ? component[subJobs] : <Outlet />}</>
}
