import { hookstate, useHookstate } from '@hookstate/core'
import { SentCompanyInvite } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import type { SentCompanyData } from 'types/companies'
import { get, trimObjectValues } from 'utils'
import * as yup from 'yup'

const initialState = hookstate({
  open: false
})

export const validationSchema = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Email invalid').required('Required'),
  company: yup.string().required('Required'),
  phone: yup.string().required('Required')
})

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  phone: '',
  phone_area_code: ''
}

export default function useInviteCompanies() {
  const { onNotify } = useNotification()
  const { open } = useHookstate(initialState)

  const query = useQueryClient()

  const { mutate } = useMutation(
    (data: { companies: Array<SentCompanyData> }) => SentCompanyInvite(data),
    {
      onSuccess: () => {
        onNotify({ severity: 'success', message: 'Company added' })
        query.invalidateQueries('COMPANIES')
        onHandleClose()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        onNotify({ severity: 'error', message: JSON.stringify(errorMessage) })
      }
    }
  )

  const onHandleOpen = () => {
    open.set(true)
  }
  const onHandleClose = () => {
    open.set(false)
  }

  const onHandleSumbit = (values: typeof initialValues) => {
    const data = trimObjectValues({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.toLocaleLowerCase(),
      company: values.company,
      phone: values.phone.replace(values.phone_area_code, ''),
      phone_area_code: values.phone_area_code
    }) as SentCompanyData
    mutate({
      companies: [data]
    })
  }

  return {
    open: open.value,
    onHandleSumbit,
    onHandleOpen,
    onHandleClose
  }
}
