import FilterListIcon from '@mui/icons-material/FilterList'
import WorkIcon from '@mui/icons-material/Work'
import { Typography, Stack, IconButton } from '@mui/material'
import SearchBar from 'components/SearchBar'
import SearchFilter from 'components/searchFilter'
import useFilterStore from 'stores/useFilterStore'
import { COLORS } from 'styles/colors'
import Filters from '../filters'

export default function Header() {
  const { onOpenAnchor } = useFilterStore()
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        width="100%"
      >
        <Stack direction="row" alignItems="center">
          <WorkIcon />
          <Typography variant="h2" marginLeft={2}>
            Job board
          </Typography>
        </Stack>
        <Stack
          direction="row"
          width={{ xs: '100%', sm: '50%', md: '300px' }}
          marginLeft={{ xs: 0, sm: 6 }}
          marginTop={{ xs: 2, sm: 0 }}
        >
          <SearchBar boxProps={{ width: '100%' }} />
          <IconButton onClick={event => onOpenAnchor(event, 'id')}>
            <FilterListIcon htmlColor={COLORS.gray1} />
          </IconButton>
        </Stack>
      </Stack>
      <SearchFilter>
        <Filters />
      </SearchFilter>
    </Stack>
  )
}
