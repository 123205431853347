import { useState, useEffect } from 'react'
import { GetBonusTemplatesByCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { ListTemplates } from 'types/bonusTemplate'
import { get, getPagination } from 'utils'
import { getRows } from '../constants'
import useFilterBonusTemplatesStore from './useFilterBonusTemplatesStore'

type Params = { [key in string]: string | unknown }

export default function useBonusTable() {
  const { searchParams } = useNavigation()
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { filters } = useFilterBonusTemplatesStore()
  const { user } = useAuth()
  const [bonusTemplates, setBonusTemplates] = useState<ListTemplates>(
    {} as ListTemplates
  )
  const { onNotify } = useNotification()

  const { isLoading, refetch, isFetching } = useQuery(
    'BONUSTEMPLATES',
    () =>
      GetBonusTemplatesByCompany(Number(user.company_id), {
        ...searchParams,
        is_template: true,
        page: filters.page,
        size: filters.size
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setBonusTemplates(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const getBonusTemplates = () => refetch()
  const onToggleTemplate = (
    toggle: 'bonusTemplate' | 'bonusTemplateDetails',
    id?: Params
  ) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle, id)
    } else {
      onOpenToggle(toggle, id)
    }
  }
  useEffect(() => {
    refetch()
  }, [JSON.stringify(filters), searchParams.search])

  const pagination = getPagination(bonusTemplates)
  const validateRows = getRows(bonusTemplates.entries || [])
  return {
    getBonusTemplates,
    pagination,
    isLoading,
    bonusTemplates: validateRows,
    openToggle,
    onToggleTemplate,
    isFetching
  }
}
