import { useState } from 'react'
import { Login } from 'api'
import useMessage from 'app/notifications/hooks/useMessage'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { get } from 'utils'
import * as yup from 'yup'

export const initialValues = {
  email: '',
  password: ''
}

export const validationSchema = yup.object({
  email: yup.string().email('Email invalid').required('Email required'),
  password: yup
    .string()
    .required('Password required')
    .min(8, 'Minimun 8 characters')
})

export default function useSignin() {
  const { login } = useAuth()
  const { getFirebaseToken } = useMessage()
  const { onNavigate } = useNavigation()
  const { onNotify } = useNotification()

  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const onShowPassword = () => setShowPassword(!showPassword)
  const onRedirect = () => onNavigate('/reset-password')
  const handleRedirect = () => onNavigate('/sign-up')

  const onSubmit = (values: typeof initialValues) => {
    setIsLoading(true)
    Login({ ...values, email: values.email.toLowerCase() })
      .then(response => {
        login(response.data)
        getFirebaseToken(response.data.user.id)
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    showPassword,
    isLoading,
    onSubmit,
    onShowPassword,
    onRedirect,
    handleRedirect
  }
}
