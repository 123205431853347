import { useState } from 'react'
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button
} from '@mui/material'
import useRefer, { validationSchema } from 'app/educationDetails/hooks/useRefer'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Formik, Form } from 'formik'
import PhoneInput from 'react-phone-input-2'
import { COLORS } from 'styles/colors'

const initialValues = {
  via: '',
  name: '',
  lastName: '',
  phone: '',
  email: ''
}

export default function Referrals() {
  const [isFocused, seIsFocused] = useState(false)
  const { onValidateReferrals } = useRefer()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        onValidateReferrals(values)
      }}
    >
      {({
        setFieldValue,
        handleChange,
        setValues,
        touched,
        errors,
        values
      }) => (
        <Stack component={Form} height="90%" justifyContent="space-between">
          <Box component={Paper} elevation={2} paddingBottom={4}>
            <Typography variant="h2">Share this job</Typography>
            <Typography variant="h5" marginY={3}>
              Choose how you’d like to share the job
            </Typography>
            <Typography variant="body2" color={COLORS.gray1} fontWeight={500}>
              Share by
            </Typography>
            <Box>
              <Stack
                width="60%"
                flexWrap="nowrap"
                direction="row"
                component={RadioGroup}
                onChange={event =>
                  setValues({
                    ...values,
                    via: event.target.value,
                    email: '',
                    phone: ''
                  })
                }
              >
                <FormControlLabel
                  label="Email"
                  value="email"
                  control={<Radio />}
                  sx={{ width: '100%' }}
                  componentsProps={{
                    typography: {
                      color: COLORS.gray1,
                      variant: 'subtitle1',
                      fontWeight: 400
                    }
                  }}
                />
                <FormControlLabel
                  label="SMS"
                  value="sms"
                  control={<Radio />}
                  sx={{ width: '100%' }}
                  componentsProps={{
                    typography: {
                      color: COLORS.gray1,
                      variant: 'subtitle1',
                      fontWeight: 400
                    }
                  }}
                />
                <FormControlLabel
                  label="Both"
                  value="both"
                  control={<Radio />}
                  sx={{ width: '100%' }}
                  componentsProps={{
                    typography: {
                      color: COLORS.gray1,
                      variant: 'subtitle1',
                      fontWeight: 400
                    }
                  }}
                />
              </Stack>
              {touched.via && errors.via && (
                <Typography variant="h6" color={COLORS.red1}>
                  Required
                </Typography>
              )}
            </Box>
            <Typography
              variant="body2"
              color={COLORS.gray1}
              fontWeight={500}
              marginY={3}
            >
              Add referral
            </Typography>
            <Stack direction="row" spacing={5}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                variant="standard"
                onChange={handleChange('name')}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                name="lastName"
                variant="standard"
                label="Last name"
                onChange={handleChange('lastName')}
                sx={{ marginBottom: 2 }}
              />
            </Stack>
            {values.via !== 'sms' && (
              <TextField
                fullWidth
                name="email"
                variant="standard"
                label="Email"
                onChange={handleChange('email')}
              />
            )}
            {values.via !== 'email' && (
              <Stack marginTop={3}>
                <Stack
                  flexDirection="column"
                  position="relative"
                  sx={{
                    '.selected-flag': {
                      paddingLeft: 0
                    },
                    '.special-label': {
                      left: 0,
                      paddingX: 0,
                      color: COLORS.main,
                      fontSize: 14
                    }
                  }}
                >
                  <PhoneInput
                    value=""
                    country="us"
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      border: 'none',
                      borderBottom:
                        isFocused || values.phone
                          ? `2px solid ${COLORS.main}`
                          : `1px solid ${COLORS.gray4}`,
                      borderRadius: 0,
                      paddingLeft: 40,
                      boxShadow: 'none'
                    }}
                    onFocus={() => seIsFocused(true)}
                    onBlur={() => seIsFocused(false)}
                    onChange={(value: string) => {
                      setFieldValue('phone', value)
                    }}
                  />
                </Stack>
                {touched.phone && errors.phone && (
                  <Typography
                    variant="h6"
                    color={COLORS.red1}
                    position="absolute"
                    margin="60px 0 0 0"
                  >
                    Required
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{ marginTop: 2 }}
          >
            Continue
          </Button>
        </Stack>
      )}
    </Formik>
  )
}
