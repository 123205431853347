import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'
import { JobBoard } from 'types/jobs'
import useDeleteJob from '../../hooks/useDeleteJob'

export default function DeleteJobModal({
  selectedCheck,
  jobs,
  open
}: {
  open: boolean
  jobs: JobBoard['entries']
  selectedCheck: GridSelectionModel
}) {
  const { onCloseModal, onChecked, onSubmit, isLoading, isChecked } =
    useDeleteJob()

  const filteredJobs = jobs.filter(item => {
    return selectedCheck.includes(item.id)
  })

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent
        sx={{ paddingY: '10px', paddingX: '20px', margin: '20px' }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom={5}
        >
          <Typography variant="h3">Delete job</Typography>
        </Box>
        {filteredJobs.map(({ name, company_name }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box>
                <Typography variant="caption">Job name</Typography>
                <Typography variant="h5" fontSize={14} noWrap>
                  {name}
                </Typography>
              </Box>
              <Divider sx={{ marginY: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="caption">Company name</Typography>
                <Typography
                  variant="h5"
                  fontSize={14}
                  color={COLORS.main}
                  noWrap
                >
                  {company_name}
                </Typography>
              </Box>
              <Divider sx={{ marginY: 1 }} />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <FormControlLabel
        sx={{ paddingX: 4, marginBottom: 3 }}
        control={<Checkbox checked={isChecked} onChange={onChecked} />}
        label={
          <Typography variant="body2" color={COLORS.red1}>
            It is not possible to reverse this action, are you sure?
          </Typography>
        }
      />
      <DialogActions sx={{ paddingX: 4, paddingBottom: 4 }}>
        <Button
          variant="outlined"
          fullWidth
          sx={{ marginRight: '3px' }}
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          disabled={!isChecked || isLoading}
          sx={{ marginLeft: '3px' }}
          onClick={() => onSubmit(selectedCheck)}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
