import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { GetEmployeesById } from 'api'
//import useTemplate from 'app/educationDetails/sideDetail/bonus/useTemplate'
//import { useEmployeeDetail } from 'app/employees/hooks/useEmployeeDetail'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'

import { get } from 'utils'
import { UpdateEmployeeTemplateParams } from '../Types/EmployeeDetail'

const initialState = hookstate({
  edit: false
})

export default function useShowEmployee() {
  const { params } = useNavigation()
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()
  const [employeeInformation, setEmployeeInformation] =
    useState<UpdateEmployeeTemplateParams>({} as UpdateEmployeeTemplateParams)

  const { isLoading, refetch } = useQuery(
    'EMPLOYEE_BY_ID',
    () => GetEmployeesById(params.id as string),
    {
      enabled: Boolean(params.id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setEmployeeInformation(data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onHandleOpenEdit = () => {
    edit.set(true)
  }

  const onHandleCloseEdit = () => {
    getEmployeeDetailTemplate()
    edit.set(false)
  }
  const getEmployeeDetailTemplate = () => refetch()

  return {
    isLoading: isLoading,
    getEmployeeDetailTemplate,
    employeeInformation,
    open,
    edit: edit.value,
    onHandleOpenEdit,
    onHandleCloseEdit
    //onToggleTemplate,
    //isSent
  }
}
