import { COLORS } from 'styles/colors'

export const STATUS_COLOR = {
  new: COLORS.main,
  published: COLORS.green2,
  on_hold: COLORS.gray5,
  closed: COLORS.red1,
  active: COLORS.main,
  inactive: COLORS.gray2,
  empty: COLORS.gray5,
  default: COLORS.gray5,
  'not found': COLORS.main,
  hired: COLORS.green1,
  on_hold_bullhord: COLORS.green2,
  closed_ats: COLORS.red1,
  closed_beegopp: COLORS.red1,
  'in progress': COLORS.main,
  rejected: COLORS.red1,
  pending: COLORS.orange1,
  canceled: COLORS.red1,
  paid: COLORS.green1,
  not_set: COLORS.gray5,
  deleted: COLORS.red1
}
