import { Box, Typography } from '@mui/material'
import { EmptyPage } from 'components/emtpyPage'
import { DataGrid, Pagination } from 'components/table'
import { usePage } from 'hooks/usePage'
import { COLORS } from 'styles/colors'
import { PaginationParse } from 'types/general'
import { PaymentMethod } from 'types/profile'
import { columns } from '../constants'

export default function Funds({
  pagination,
  isLoading,
  funds
}: {
  isLoading: boolean
  pagination: PaginationParse
  funds: Array<PaymentMethod>
}) {
  const { page, handleChangePage } = usePage()
  return (
    <Box width="100%" marginTop={4} height="40%">
      <DataGrid
        rows={funds}
        columns={columns}
        loading={isLoading}
        paginationMode="server"
        rowCount={pagination.totalPages || 0}
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={row => new Date(row.created_date).getTime()}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          NoResultsOverlay: () => (
            <EmptyPage
              logo="/images/magnify.png"
              message={
                <Typography
                  variant="body2"
                  color={COLORS.gray2}
                  fontSize="16px"
                >
                  There are no funds to display yet!
                </Typography>
              }
            />
          )
        }}
      />
    </Box>
  )
}
