import { Box } from '@mui/material'
import useNavigation from '../../../hooks/useNavigation'
import QrCode from './QrCode'
import ShareJob from './ShareJob'
import Bonus from './bonus'
import ByEmail from './byEmail'
import Payments from './payments'

export default function SideDetail({
  elementRef
}: {
  elementRef: unknown | any
}) {
  const { pathname } = useNavigation()

  return (
    <Box ref={elementRef}>
      <Bonus
        type={pathname.includes('referrals') ? 'bonusReferrals' : 'bonus'}
        title="Bonus configuration"
        subtitle="Choose one type of bonus"
      >
        <Payments />
      </Bonus>
      <Bonus
        type="visibility"
        marginTop={3}
        title="Visibility of the job"
        subtitle="Choose who is going to see the job"
      />
      <ByEmail />
      <ShareJob />
      <QrCode />
    </Box>
  )
}
