import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import { Box, Stack } from '@mui/material'
import { GridCellParams, GridSelectionModel } from '@mui/x-data-grid'
import BulkActions from 'components/bulkactions'
import DropDown from 'components/dropdown'
import { EmptyPage } from 'components/emtpyPage'
import { useNotification } from 'components/notification/useNotification'
import Actions from 'components/selectActions/Actions'
import { DataGrid, Pagination } from 'components/table'
import ToggleSidebar from 'components/toggleSidebar'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import useLoginAs from 'stores/useLoginAs'
import { COLORS } from 'styles/colors'
import { commonTableStyles } from 'styles/utils'
import { COMPANY_STATUS, getColumns } from '../constans'
import DialogResendDelete from '../dialogResendDelete'
import useBulkActions from '../hooks/useBulkActions'
import useCompanies from '../hooks/useCompanies'
import CompanyInformationTable from './companyInformationTable'

export default function CompanyTable() {
  const { onNavigate } = useNavigation()
  const { onNotify } = useNotification()
  const { companies, isLoading, pagination, refetch } = useCompanies()
  const {
    onOpenLoginAs,
    isLoginAs,
    isLoading: isLoginByCompany,
    selectedCompany
  } = useLoginAs()

  const [isOpen, setIsOpen] = useState(false)
  const [actionType, setActionType] = useState<'resend' | 'delete'>(
    '' as 'resend'
  )
  const [dialogCompanies, setDialogCompanies] = useState(false)
  const [selectedCheck, setSelectedCheck] = useState<GridSelectionModel>([])

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const cleanSelectedCheck = () => setSelectedCheck([])

  const { onSubmit } = useBulkActions(cleanSelectedCheck)

  const handleOpen = () => setIsOpen(true)

  const toggleDialogCompanies = (type: 'resend' | 'delete') => {
    setActionType(type)

    const isValidCompanyStatus = companies
      .filter(item => selectedCheck.includes(item.id))
      .every(item => item.status === 'pending' || item.status === 'new')

    if (!isValidCompanyStatus) {
      return onNotify({
        message: `Resend invitations is only for pending or new status.`,
        severity: 'info'
      })
    }

    if (selectedCheck.length === 0) {
      const message =
        type === 'resend' ? 'resend invitations' : 'delete companies'
      return onNotify({
        message: `Select one or more items to ${message}.`,
        severity: 'info'
      })
    }
    setDialogCompanies(!dialogCompanies)
  }

  const handleClose = () => {
    setIsOpen(false)
    onNavigate('/companies', {
      search: {
        companyId: ''
      }
    })
    refetch()
  }

  const handleOpenCompany = (props?: GridCellParams) => {
    handleOpen()
    handleShowCompany(props?.row.id)
  }

  const handleShowCompany = (companyId: number) => {
    onNavigate('/companies', { search: { companyId } })
  }

  const handleSelectionModelChange = (newSelection: GridSelectionModel) =>
    setSelectedCheck(newSelection)

  const columns = getColumns({
    isLoading: isLoginByCompany,
    isLoginAs,
    selectedCompany
  })

  return (
    <Box width="100%" height={commonTableStyles.minHeight} paddingBottom={3}>
      <DataGrid
        rows={companies}
        columns={columns}
        checkboxSelection
        loading={isLoading}
        selectionModel={selectedCheck}
        rowCount={pagination.totalPages || 0}
        experimentalFeatures={{ newEditingApi: true }}
        onSelectionModelChange={handleSelectionModelChange}
        onCellClick={props => {
          if (props.field === 'loginAs' && !isLoginAs) {
            onOpenLoginAs(props.row)
            return
          }
          if (props.field === '__check__') {
            return
          }
          handleOpenCompany(props)
        }}
        sx={{ cursor: 'pointer' }}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          Header: () => (
            <BulkActions
              rowsPerPage={rowsPerPage}
              page={pagination.pageNumber}
              totalPages={pagination.totalEntries}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <DropDown
                label="Company status"
                actionUID="bulkaction-companies"
                disabled={selectedCheck.length === 0}
              >
                <Actions
                  options={COMPANY_STATUS}
                  actionUID="bulkaction-companies"
                  onApply={status =>
                    onSubmit({
                      companies: selectedCheck,
                      status: status.id as 'active' | 'inactive'
                    })
                  }
                />
              </DropDown>
              <Stack
                direction="row"
                component="div"
                fontWeight={500}
                alignItems="center"
                color={COLORS.gray2}
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleDialogCompanies('resend')}
              >
                Resend invitation
                <SendIcon
                  htmlColor={COLORS.gray2}
                  sx={{ width: 14, marginLeft: '4px' }}
                />
              </Stack>
              <Stack
                direction="row"
                component="div"
                fontWeight={500}
                alignItems="center"
                color={COLORS.gray2}
                sx={{ cursor: 'pointer', marginLeft: '14px' }}
                onClick={() => toggleDialogCompanies('delete')}
              >
                Delete company
                <DeleteIcon
                  htmlColor={COLORS.gray2}
                  sx={{ width: 14, marginLeft: '4px' }}
                />
              </Stack>
            </BulkActions>
          ),
          NoRowsOverlay: () => (
            <EmptyPage
              message="You do not have registered rows yet"
              logo="/images/Blank_state.svg"
            />
          )
        }}
      />
      <ToggleSidebar
        isOpen={isOpen}
        headerTitle="Company information"
        toggleSidebar={handleClose}
      >
        <CompanyInformationTable handleClose={handleClose} />
      </ToggleSidebar>
      <DialogResendDelete
        companies={companies}
        open={dialogCompanies}
        actionType={actionType}
        selectedCheck={selectedCheck}
        handleClose={(type: 'resend' | 'delete') => toggleDialogCompanies(type)}
      />
    </Box>
  )
}
