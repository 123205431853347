import { Typography, Box, Divider } from '@mui/material'
import Paper from 'components/paper/index'
import { COLORS } from '../../../styles/colors'
import { BonusInformation } from './BonusInformation'
import { GeneralInformation } from './GeneralInformation'
import { TransferDetailHeader } from './TransferDetailHeader'
export const TransferDetail = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  EmployeeDetail,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onToggleTemplate
}: {
  EmployeeDetail: any | null
  onToggleTemplate: () => void
}) => {
  return (
    <Box padding={2}>
      <TransferDetailHeader />
      <Box component={Paper} margin="22px 16px">
        <Typography variant="h3" paddingBottom={4}>
          General Information
        </Typography>
        <GeneralInformation />
        <Divider sx={{ background: COLORS.honey2 }} />
        <Typography variant="h3" paddingBottom={4} marginTop={4}>
          Bonus Information
        </Typography>
        <BonusInformation />
      </Box>
    </Box>
  )
}
