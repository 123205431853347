import { Typography, Box, Stack, Button, Autocomplete } from '@mui/material'
import useJobBoardFilter, { schema } from 'app/jobs/hooks/useJobBoardFilter'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { Formik, Form } from 'formik'
import useFilterStore from 'stores/useFilterStore'

export default function Filters() {
  const { onCloseAnchor } = useFilterStore()
  const {
    onChangeFilter,
    onClearFilters,
    companyOptions,
    jobsTypesOptions,
    initialValues
  } = useJobBoardFilter()

  const handleResetForm = () => {
    onCloseAnchor()
    onClearFilters()
  }

  const handleSubmit = (values: typeof initialValues) => {
    onChangeFilter(values)
    onCloseAnchor()
  }

  return (
    <Box width={{ xs: '100%', md: '700px' }} padding={2}>
      <Typography variant="h5" fontWeight={600} marginBottom={2}>
        Filter by
      </Typography>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <Stack direction="row" gap={3} marginBottom={4}>
              <Autocomplete
                disablePortal
                sx={{ width: '50%' }}
                options={companyOptions}
                value={{
                  label: values.company || '',
                  value: values.company || ''
                }}
                onChange={(_, option) =>
                  setFieldValue('company', option?.value)
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    name="company"
                    label="Company"
                    variant="standard"
                  />
                )}
              />
              <Select
                width="50%"
                name="type"
                variant="standard"
                label="Jobs type"
                options={jobsTypesOptions}
                value={values.type}
                onChange={e => {
                  setFieldValue('type', e.target.value)
                }}
              />
            </Stack>
            <Typography variant="h5" fontWeight={600}>
              Bonus range
            </Typography>
            <Stack
              gap={3}
              marginTop={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                label="Min"
                name="bonusMin"
                variant="standard"
                sx={{ width: '50%' }}
                value={values.bonusMin}
                onChange={handleChange('bonusMin')}
              />
              <TextField
                name="bonusMax"
                variant="standard"
                sx={{ width: '50%' }}
                value={values.bonusMax}
                disabled={!values.bonusMin}
                onChange={handleChange('bonusMax')}
                label={values.bonusMin ? 'Max' : 'Select min first'}
              />
            </Stack>
            <Stack
              gap={2}
              paddingTop={3}
              direction="row"
              paddingBottom={2}
              justifyContent="flex-end"
            >
              <Button variant="text" onClick={() => handleResetForm()}>
                Clear all
              </Button>
              <Button variant="contained" type="submit">
                Apply
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
