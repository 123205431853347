import AttachMoney from '@mui/icons-material/AttachMoney'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CreateIcon from '@mui/icons-material/Create'
import SendIcon from '@mui/icons-material/Send'
import { Typography, Box, Stack } from '@mui/material'
import Paper from 'components/paper'
import PaymentCard from 'components/paymentCard'
import ToggleSidebar from 'components/toggleSidebar/index'
import WithdrawCard from 'components/withdrawCard'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import { formatCurrency, formatDate } from 'utils'
import { EmployeeDetailEdit } from './EmployeeDetailEdit'
import { CountsByEmployeeBoard } from './Types/ReferralByEmployee'
import useCountsByEmployee from './hooks/useCountsByEmployee'
import useShowEmployee from './hooks/useShowEmployee'
import { EmployeeReferralTable } from './referrals/EmployeeReferralTable'

const users = ['market_manager', 'company_owner']

export const EmployeeDetailBody = () => {
  const { userRole } = useAuth()
  const isDisabled = false

  const { edit, onHandleOpenEdit, onHandleCloseEdit, employeeInformation } =
    useShowEmployee()
  const { countsInformation } = useCountsByEmployee()

  const REFERRAL_STATUS: Array<{
    label: string
    field: keyof CountsByEmployeeBoard
  }> = [
    { label: 'In Progress', field: 'active_referrals_count' },
    { label: 'Rejected', field: 'rejected_referrals_count' },
    { label: 'Unanswered', field: 'on_hold_referrals_count' },
    { label: 'Hired referrals', field: 'hired_referrals_count' }
  ]

  return (
    <>
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop={3}
      >
        <Box width={{ xs: '100%', lg: '30%' }} marginRight={{ sx: 0, lg: 8 }}>
          <WithdrawCard marginBottom={4}>
            <Stack height="100%" justifyContent="center">
              <Typography variant="h6" color={COLORS.white} letterSpacing="1px">
                Total referral bonuses
              </Typography>
              <Typography
                fontSize={32}
                variant="h6"
                color={COLORS.white}
                marginBottom={2}
                fontWeight={500}
              >
                {formatCurrency(
                  countsInformation.pending_bonus
                    ? countsInformation.pending_bonus
                    : 0
                )}
              </Typography>
            </Stack>
          </WithdrawCard>
          <PaymentCard
            showInfo
            icon={SendIcon}
            variant="warning"
            subtitle={formatCurrency(
              countsInformation.total_in_process
                ? countsInformation.total_in_process
                : 0
            )}
            title="Pending to be transfered"
            tooltip="Money you’ve earned that hasn’t been transferred yet"
            paperProps={{ sx: { position: 'relative', height: '80px' } }}
            iconProps={{
              sx: {
                transform: 'rotate(-45deg)',
                width: 20,
                marginBottom: '5px',
                marginLeft: '5px'
              }
            }}
          >
            {/*<Button
              variant="text"
              sx={{ position: 'absolute', right: 16, bottom: 8 }}
            >
              View details
          </Button>*/}
          </PaymentCard>
          <PaymentCard
            showInfo
            variant="success"
            icon={AttachMoney}
            title="Total paid"
            subtitle={formatCurrency(
              countsInformation.total_paid ? countsInformation.total_paid : 0
            )}
            tooltip="The total amount of money you’ve earned"
            paperProps={{ sx: { marginTop: 3, marginBottom: 3 } }}
          />
        </Box>

        <Box
          width={{ xs: '100%', lg: '65%' }}
          marginRight={{ sx: 0 }}
          sx={{ display: { xs: 'flex' }, flexDirection: { xs: 'column' } }}
        >
          <Paper
            elevation={4}
            sx={{
              width: { lg: 'auto' },
              padding: 2,
              height: { xs: '100%', lg: '35%' }
            }}
          >
            <Stack flexDirection="row" justifyContent="space-between">
              <Stack flexDirection="row" alignItems="baseline">
                <Typography variant="h4" marginRight={1}>
                  General Information
                </Typography>
              </Stack>
              {users.includes(userRole) && (
                <Stack
                  component="div"
                  direction="row"
                  alignItems="center"
                  onClick={() => onHandleOpenEdit()}
                  color={isDisabled ? COLORS.gray2 : COLORS.main}
                  sx={{
                    cursor: isDisabled ? 'default' : 'pointer',
                    pointerEvents: isDisabled ? 'none' : 'initial'
                  }}
                >
                  <CreateIcon sx={{ width: '16px' }} />
                  <Typography variant="h6" marginLeft={1} fontSize="13px">
                    Edit
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Stack flexDirection="row" justifyContent="space-between" gap={4}>
              <Stack width="50%">
                <Typography variant="h5" marginRight={1} marginTop={4}>
                  Name: {employeeInformation.first_name}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Last name: {employeeInformation.last_name}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Birthday:{' '}
                  {formatDate(employeeInformation.birthday || '', 'MM/dd/yyyy')}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Location: {employeeInformation.country}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Email: {employeeInformation.email}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Phone: {employeeInformation.phone}
                </Typography>
              </Stack>
              <Stack width="50%">
                <Typography
                  variant="h5"
                  marginRight={1}
                  marginTop={4}
                  display="flex"
                >
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ marginRight: 1 }}
                  />{' '}
                  In Bulhorn
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Company: {employeeInformation.company}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Internal role: {employeeInformation.role}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Date joined:{' '}
                  {employeeInformation.inserted_at
                    ? new Date(employeeInformation.inserted_at).toLocaleString(
                        'en-US',
                        {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        }
                      )
                    : null}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  Staff: {employeeInformation.staff}
                </Typography>
                <Typography variant="h5" marginRight={1} marginTop={2}>
                  User type: {employeeInformation.type}
                </Typography>
              </Stack>
            </Stack>
          </Paper>

          <Stack
            justifyContent={'space-between'}
            width={'100%'}
            gap={4}
            sx={{
              flexDirection: { xs: 'column', lg: 'row' },
              alignItems: { xs: 'center' },
              height: { xs: '100%', lg: '100%' }
            }}
          >
            {REFERRAL_STATUS.map(({ label, field }) => (
              <Paper
                elevation={4}
                sx={{
                  width: { xs: '97%', lg: '30%' },
                  marginTop: 3
                }}
              >
                <Typography
                  variant="subtitle2"
                  marginRight={1}
                  marginTop={1}
                  paddingLeft={2}
                >
                  {label}
                </Typography>
                <Typography
                  variant="h5"
                  marginRight={1}
                  paddingRight={2}
                  marginTop={1}
                  align="right"
                >
                  {field && countsInformation[field]
                    ? countsInformation[field]
                    : 0}
                </Typography>
              </Paper>
            ))}
          </Stack>
        </Box>
      </Stack>

      <ToggleSidebar
        isOpen={edit}
        headerTitle="Edit employee information "
        toggleSidebar={() => onHandleCloseEdit()}
      >
        <EmployeeDetailEdit EmployeeDetail={employeeInformation} />
      </ToggleSidebar>
      <EmployeeReferralTable />
    </>
  )
}
