import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useToggle from 'components/toggleSidebar/useToggle'
import * as yup from 'yup'
import useRequestWithdraw from './useRequestWithdraw'
export type Withdraw = {
  id: number
  amount?: number
  method?: string
}

export type RequestWithdraw = {
  request: Array<Withdraw>
}

export const validationSchema = yup.object({
  id: yup.number().required('Required'),
  amount: yup.number().required('Required'),
  method: yup.string().required('Required')
})

const initialState = hookstate({
  withdraws: {} as RequestWithdraw,
  isSent: false,
  isLoading: false,
  message: '',
  content: ''
})

export default function useWithdraw() {
  const { withdraws, isSent, isLoading, message, content } =
    useHookstate(initialState)
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { onNextStep, onBackStep } = useStepper()

  const onToggleWithdrawBy = (toggle: 'wallet') => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle)
    }
  }

  const { onMutation } = useRequestWithdraw(
    onToggleWithdrawBy,
    isSent,
    isLoading,
    message,
    content
  )

  const onWithdraws = (values: Partial<RequestWithdraw>) => {
    withdraws.set(prevState => ({
      ...prevState,
      ...values
    }))
  }

  const onSubmit = () => {
    const request = JSON.parse(JSON.stringify(withdraws.value.request))
    onMutation({ ...withdraws.value, request })
  }

  const onValidateWithdraws = (withdraws: Array<Withdraw>) => {
    onWithdraws({
      request: withdraws
    })
    onNextStep()
  }

  return {
    withdraws: withdraws.value,
    openReferBy: openToggle,
    isLoading: isLoading.value,
    isSent: isSent.value,
    validationSchema,
    message: message,
    content: content,
    onValidateWithdraws,
    onToggleWithdrawBy,
    onNextStep,
    onBackStep,
    onWithdraws,
    onSubmit,
    onCloseToggle
  }
}
