import GetAppIcon from '@mui/icons-material/GetApp'
import Redeem from '@mui/icons-material/Redeem'
import { Typography, Stack, Button } from '@mui/material'
import SearchBar from 'components/SearchBar'
import Notification from 'components/notification'
import ModalCsv from './csv'
import UseDownloadCsv from './csv/useDownloadCsv'

const PaymentsHeader = () => {
  const { active, handleToggle, onSubmit, isLoading } = UseDownloadCsv()
  return (
    <Stack
      marginTop={3}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        marginRight={{ xs: 2, md: 4 }}
        marginBottom={{ xs: 2, md: 0 }}
        justifyContent="flex-start"
      >
        <Redeem />
        <Typography marginLeft={2} marginRight={4} variant="h2">
          Bonus Payments
        </Typography>
        <SearchBar
          boxProps={{ width: { xs: '95%', sm: '250px' }, marginRight: '20px' }}
        />
      </Stack>
      <Button
        onClick={handleToggle}
        variant="contained"
        startIcon={<GetAppIcon fontSize="small" />}
      >
        Export .cvs
      </Button>
      <ModalCsv
        isOpen={active}
        onClose={handleToggle}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
      <Notification severity="success" />
    </Stack>
  )
}

export default PaymentsHeader
