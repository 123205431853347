import Close from '@mui/icons-material/Close'
import { IconButton, Typography, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { COLORS } from 'styles/colors'

type BgHeader = 'info' | 'success' | 'error'
type ToggleSidebarProps = {
  isOpen: boolean
  headerTitle?: string
  ToggleSidebarTitle?: React.ReactNode
  bgheader?: BgHeader
  minWidth?: number
  children: React.ReactNode
  toggleSidebar: () => void
}

const BG_HEADER: { [key in BgHeader]: string } = {
  info: COLORS.blue6,
  success: COLORS.green2,
  error: COLORS.red1
}

export default function ToggleSidebar({
  isOpen,
  toggleSidebar,
  headerTitle,
  children,
  ToggleSidebarTitle,
  minWidth = 550,
  bgheader = 'info'
}: ToggleSidebarProps) {
  return (
    <Drawer
      anchor="right"
      elevation={5}
      open={Boolean(isOpen)}
      sx={{ backdropFilter: 'blur(6px)' }}
      PaperProps={{ sx: { border: 0 } }}
    >
      <Box
        role="presentation"
        sx={{
          width: isOpen ? { xs: '100vw', sm: minWidth } : 250,
          height: '100%'
        }}
      >
        <Stack
          paddingX={2}
          paddingY={1}
          height="80px"
          component="header"
          position="relative"
          justifyContent="flex-end"
          bgcolor={BG_HEADER[bgheader]}
        >
          <IconButton
            onClick={toggleSidebar}
            sx={{ position: 'absolute', right: 6, top: 0 }}
          >
            <Close htmlColor={COLORS.white} />
          </IconButton>
          <Typography fontSize="24px" color={COLORS.white}>
            {headerTitle}
          </Typography>
          {ToggleSidebarTitle}
        </Stack>
        {children}
      </Box>
    </Drawer>
  )
}
