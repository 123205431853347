import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box
} from '@mui/material'
import useDisconnect, {
  validationSchema
} from 'app/profile/integrations/hooks/useDisconnect'
import TextField from 'components/TextField'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'

export default function Disconnect() {
  const {
    openDialog,
    showPassword,
    isLoading,
    onCloseDialog,
    onSubmit,
    onShowPassword
  } = useDisconnect()

  return (
    <Dialog open={openDialog}>
      <DialogContent sx={{ padding: 0 }}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ password: '' }}
          onSubmit={() => onSubmit('false')}
        >
          {({ values }) => (
            <Box component={Form} padding={4}>
              <DialogTitle
                fontSize="16px"
                fontWeight="600"
                sx={{ padding: 0, marginBottom: 3 }}
              >
                Are you sure you want to disconnect?
              </DialogTitle>
              <Typography
                variant="body2"
                marginBottom={2}
                color={values.password ? COLORS.red1 : COLORS.gray1}
              >
                All information related to this ATS will be lost
              </Typography>
              <TextField
                label="Password"
                variant="standard"
                name="password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )
                }}
                sx={{ marginY: 2, width: '96%' }}
              />
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                marginTop={3}
              >
                <Button
                  fullWidth
                  variant="text"
                  onClick={onCloseDialog}
                  sx={{ marginRight: 2 }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{
                    bgcolor: values.password ? COLORS.red1 : COLORS.main,
                    ':hover': {
                      bgcolor: values.password ? COLORS.red1 : COLORS.main,
                      opacity: 0.8
                    }
                  }}
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
