import { Box } from '@mui/material/'
import { styled } from '@mui/material/styles'
import { COLORS } from 'styles/colors'

export const WalletCardCtn = styled(Box)`
  position: relative;
  min-width: 330px;
  height: 192px;
`
export const WalletCardBody = styled('div')`
  background: ${COLORS.blue4};
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: 1;
`
export const WalletCardChildren = styled('div')`
  position: relative;
  padding-left: 20px;
  height: 100%;
`

export const WallwetCardCircle = styled('span')`
  top: 0;
  width: 180px;
  height: 160px;
  position: absolute;
  border-radius: 10px 0 100% 0;
  background: ${COLORS.blue5};
`
export const WalletCardShadow = styled('div')`
  background: ${COLORS.blue7};
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 192px;
`
