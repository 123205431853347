import { Typography, Box, Stack } from '@mui/material'
import useBonusTemplateDetails from 'app/bonus/templates/hooks/useBonusTemplateDetails'
import Paper from 'components/paper'
import useNavigation from 'hooks/useNavigation'
import { formatDate } from 'utils'
export default function Index() {
  const { searchParams } = useNavigation()
  const { bonusTemplate, isLoading, isFetching } = useBonusTemplateDetails(
    Number(searchParams.bonusId)
  )

  if (isLoading || isFetching) {
    return <div>loading</div>
  }
  return (
    <Box paddingX={2} paddingTop={2} height="calc(100vh - 168px)">
      <Stack height="100%" justifyContent="space-between">
        <Box component={Paper} elevation={4}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3" marginBottom={2}>
              General information
            </Typography>
          </Stack>

          <Box>
            <Stack
              marginBottom={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography width="35%" variant="caption">
                Creation Date
              </Typography>
              <Typography width="65%" variant="h5">
                {bonusTemplate.entries.inserted_at &&
                  formatDate(bonusTemplate.entries.inserted_at, "LLL do', 'yy")}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              marginBottom={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography width="35%" variant="caption">
                User create
              </Typography>
              <Typography width="65%" variant="h5">
                {bonusTemplate.entries.user_first_name}
              </Typography>
            </Stack>
            {/* Bonus For Employee */}
            {bonusTemplate.entries.is_for_referrer ? (
              <>
                <Typography variant="body2" marginBottom={2}>
                  Bonus for employee
                </Typography>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Bonus type
                  </Typography>
                  <Typography width="65%" variant="h5">
                    {bonusTemplate.entries.type}
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    User type
                  </Typography>
                  <Typography width="65%" variant="h5">
                    PENDIENTE
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Total amount
                  </Typography>
                  <Typography width="65%" variant="h5">
                    ${bonusTemplate.entries.total_amount_referrer}
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                ></Stack>
                <Stack marginBottom={2} direction="row" alignItems="center">
                  <Typography width="35%" variant="caption">
                    Conditions
                  </Typography>
                  <Stack width="65%">
                    {bonusTemplate.entries.bonus_config?.map(item => {
                      if (item.type === 'referrer') {
                        return (
                          <Typography variant="h5" width="100%" key={item.id}>
                            {`$${item.amount} USD after ${item.days} days`}
                          </Typography>
                        )
                      }
                    })}
                  </Stack>
                </Stack>
              </>
            ) : null}
            {/* Bonus For Employee */}
            {bonusTemplate.entries.is_for_referral ? (
              <>
                <Typography variant="body2" marginBottom={2}>
                  Bonus for referral
                </Typography>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Bonus type
                  </Typography>
                  <Typography width="65%" variant="h5">
                    {bonusTemplate.entries.type}
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    User type
                  </Typography>
                  <Typography width="65%" variant="h5">
                    PENDIENTE
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Total amount
                  </Typography>
                  <Typography width="65%" variant="h5">
                    ${bonusTemplate.entries.total_amount_referral}
                  </Typography>
                </Stack>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                ></Stack>
                <Stack marginBottom={2} direction="row" alignItems="center">
                  <Typography width="35%" variant="caption">
                    Conditions
                  </Typography>
                  <Stack width="65%">
                    {bonusTemplate.entries.bonus_config?.map(item => {
                      if (item.type === 'referral') {
                        return (
                          <Typography variant="h5" width="100%" key={item.id}>
                            {`$${item.amount} USD after ${item.days} days`}
                          </Typography>
                        )
                      }
                    })}
                  </Stack>
                </Stack>
              </>
            ) : null}
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
