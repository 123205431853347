import { Stack, Typography } from '@mui/material'
import SearchBar from 'components/SearchBar'

export default function ReferralHeader() {
  return (
    <Stack
      marginTop={3}
      justifyContent="flex-start"
      direction={{ xs: 'column', sm: 'row' }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        marginRight={{ xs: 2, md: 4 }}
        justifyContent="flex-start"
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <Typography marginLeft={{ xs: 0, sm: 2 }} variant="h2">
          Referral list
        </Typography>
      </Stack>
      <SearchBar boxProps={{ width: { xs: '95%', sm: '250px' } }} />
    </Stack>
  )
}
