import { hookstate, useHookstate } from '@hookstate/core'

const initialNotification = hookstate({
  isOpen: false,
  hasNotification: false
})
export default function useToggleNotification() {
  const { isOpen, hasNotification } = useHookstate(initialNotification)

  const onOpenNotification = () => isOpen.set(true)
  const onCloseNotification = () => isOpen.set(false)

  const onSetNotification = (notifaction: boolean) =>
    hasNotification.set(notifaction)

  return {
    onOpenNotification,
    onCloseNotification,
    onSetNotification,
    isOpen: isOpen.value,
    hasNotification: hasNotification.value
  }
}
