import { Box, Chip, Stack, Typography } from '@mui/material'
import useFetchDetail from 'app/educationDetails/hooks/useFetchDetail'
import Accordion from 'components/accordion'
import { COLORS } from 'styles/colors'
import { formatCurrency } from 'utils'
export default function BonusChips({
  type
}: {
  type: 'bonus' | 'visibility' | 'bonusReferrals'
}) {
  const {
    details: { bonus, employee_visibility }
  } = useFetchDetail()

  const bonusConfigByReferrer = bonus.bonus_config.filter(
    item => item.type === 'referrer'
  )

  const bonusConfigByReferral = bonus.bonus_config.filter(
    item => item.type === 'referral'
  )

  if (type === 'bonus') {
    return (
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={2}
          marginTop={4}
        >
          <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
            Bonus type
          </Typography>
          <Chip
            label={
              <Typography
                variant="body2"
                color={COLORS.gray1}
                textTransform="capitalize"
              >
                {bonus.type}
              </Typography>
            }
            variant="filled"
            sx={{
              height: 24,
              bgcolor: COLORS.honey2,
              fontSize: 13
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" marginBottom={1}>
          <Typography color={COLORS.blue3} variant="h6">
            Conditions
          </Typography>
          <Typography
            variant="body2"
            color={COLORS.green3}
            alignSelf="flex-start"
          >
            Earn
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack direction="row" alignItems="flex-end">
            <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
              Bonus for
            </Typography>
            <Chip
              label="Employee"
              variant="filled"
              color="info"
              sx={{ height: 24, fontSize: 13, bgcolor: COLORS.blue6 }}
            />
          </Stack>
          <Accordion
            color={COLORS.green2}
            title={
              <Stack alignItems="center" direction="row">
                <Typography
                  fontSize={22}
                  variant="body1"
                  fontWeight={500}
                  marginRight={1}
                  color={COLORS.green2}
                >
                  {formatCurrency(Number(bonus?.total_amount_referrer || 0))}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  variant="body1"
                  color={COLORS.green2}
                >
                  Total amount
                </Typography>
              </Stack>
            }
          >
            {bonusConfigByReferrer.map(item => (
              <Typography
                key={item.id}
                variant="body2"
                marginBottom={1}
                fontSize={12}
                textAlign="right"
                display={{ xs: 'none', lg: 'block' }}
              >
                {formatCurrency(Number(item.amount || 0))} After {item.days}{' '}
                days
              </Typography>
            ))}
          </Accordion>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack direction="row" alignItems="flex-end">
            <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
              Bonus for
            </Typography>
            <Chip
              color="info"
              label="Referral"
              variant="filled"
              sx={{ height: 24, fontSize: 13, bgcolor: '#FF8743' }}
            />
          </Stack>
          <Accordion
            color={COLORS.green2}
            title={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={22}
                  variant="body1"
                  fontWeight={500}
                  marginRight={1}
                  color={COLORS.green2}
                >
                  {formatCurrency(Number(bonus?.total_amount_referral || 0))}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  variant="body1"
                  color={COLORS.green2}
                >
                  Total amount
                </Typography>
              </Stack>
            }
          >
            {bonusConfigByReferral.map(item => (
              <Typography
                key={item.id}
                variant="body2"
                marginBottom={1}
                fontSize={12}
                textAlign="right"
                display={{ xs: 'none', lg: 'block' }}
              >
                {formatCurrency(Number(item.amount || 0))} After {item.days}{' '}
                days
              </Typography>
            ))}
          </Accordion>
        </Stack>
      </Stack>
    )
  }
  if (type === 'bonusReferrals') {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack
          direction="column"
          alignItems="start"
          marginTop={2}
          sx={{ width: 'auto' }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={2}
          >
            <Typography
              marginBottom={1}
              marginRight={2}
              fontSize="13px"
              variant="caption"
            >
              Applies for
            </Typography>
            <Stack direction="row">
              <Box
                marginRight={2}
                label="Flat rate"
                variant="filled"
                color="seconda"
                component={Chip}
                bgcolor={COLORS.blue6}
              />
              <Box
                label="Flat rate"
                variant="filled"
                color="warning"
                component={Chip}
                bgcolor={COLORS.blue6}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              marginBottom={1}
              marginRight={2}
              fontSize="13px"
              variant="caption"
            >
              Bonus type
            </Typography>
            <Stack direction="row">
              <Box
                label="Flat rate"
                variant="filled"
                color="warning"
                component={Chip}
                bgcolor={COLORS.honey2}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="flex-end">
          <Typography variant="body2" color={COLORS.green3}>
            Earn Bonus
          </Typography>
          <Typography variant="h3" fontSize="20px" color={COLORS.green2}>
            {formatCurrency(Number(bonus.total_amount_referral) || 0)}
          </Typography>
          <Typography variant="body2" color={COLORS.main} marginTop={2}>
            Conditions
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack direction="row" marginTop={3}>
      {(employee_visibility === 'internal' ||
        employee_visibility === 'all') && (
        <Chip
          label="Internal employees"
          variant="filled"
          color="info"
          sx={{ width: 'auto', maxWidth: '50%' }}
        />
      )}

      {(employee_visibility === 'external' ||
        employee_visibility === 'all') && (
        <Box
          sx={{ width: 'auto', maxWidth: '50%' }}
          marginLeft={2}
          label="Outsourced employees"
          variant="filled"
          color="warning"
          component={Chip}
          bgcolor={COLORS.honey2}
        />
      )}
    </Stack>
  )
}
