import React from 'react'
import {
  Box,
  FormControl,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Typography
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { useField } from 'formik'
import { COLORS } from 'styles/colors'
interface SelectProps extends MUISelectProps {
  options: Array<{ value: any; label: string | React.ReactNode }>
  width?: string
  disabled?: boolean
  backgroundColor?: string
}

export default function Select({
  width = '100%',
  disabled,
  backgroundColor,
  options = [],
  ...props
}: SelectProps) {
  const [field, meta] = useField<any>(props.name || '')
  return (
    <Box width={width}>
      <FormControl fullWidth variant={props.variant}>
        <InputLabel>{props.label}</InputLabel>
        <MUISelect
          {...field}
          {...props}
          margin="none"
          disabled={disabled ? true : false}
          sx={{
            backgroundColor: disabled ? COLORS.gray10 : backgroundColor,
            ...props.sx
          }}
        >
          <MenuItem value="">
            <em></em>
          </MenuItem>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MUISelect>
      </FormControl>
      {meta.touched && Boolean(meta.error) && (
        <Typography
          color="#d32f2f"
          fontSize="12px"
          fontWeight="400"
          padding="3px"
        >
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}
