import * as React from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Box,
  Stack,
  Typography,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { ProfileSkeleton } from 'components/skeleton'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import useShowCompany from '../hooks/useShowCompany'
import BeegoppInformation from './beegoppInformation'
import Credits from './credits'
import GeneralInformation from './generalInformation'
import PaymentMethod from './paymentMethod'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      width={{ xs: '100%', sm: 'calc(100% - 240px)' }}
      paddingX={{ xs: 0, sm: 2 }}
      marginTop={{ xs: 2, sm: 0 }}
      minHeight="100%"
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export default function MyCompany() {
  const theme = useTheme()
  const { onNavigate, pathname, searchParams } = useNavigation()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [value, setValue] = React.useState(0)
  const { user } = useAuth()
  const { isLoading, company } = useShowCompany(user.company_id)

  React.useEffect(() => {
    if (searchParams.tab) {
      setValue(searchParams.tab - 1)
    }
  }, [searchParams.tab])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    onNavigate(pathname, {
      search: {
        tab: newValue + 1
      }
    })
  }

  return (
    <Box minHeight="calc(100vh - 8em)">
      <Stack
        flexDirection="row"
        marginTop={3}
        marginBottom={5}
        alignContent="center"
      >
        <ApartmentIcon htmlColor={COLORS.gray1} />
        <Typography paddingLeft={2} variant="h2">
          Company profile
        </Typography>
      </Stack>
      <Stack
        flexGrow={1}
        bgcolor="background.paper"
        minHeight="calc(100vh - 13em)"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Tabs
          orientation={isDesktop ? 'vertical' : 'horizontal'}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              background: 'transparent'
            }
          }}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            {...a11yProps(0)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                General Information
              </Typography>
            }
            icon={
              isDesktop ? (
                <KeyboardArrowRightIcon htmlColor={COLORS.black} />
              ) : (
                ''
              )
            }
            iconPosition="end"
            sx={{
              width: { xs: '50%', sm: '240px' },
              justifyContent: 'space-between',
              background: value === 0 ? '#FFDA8D' : ''
            }}
          />
          <Tab
            {...a11yProps(1)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                BeeGopp Jobsite
              </Typography>
            }
            icon={
              isDesktop ? (
                <KeyboardArrowRightIcon htmlColor={COLORS.black} />
              ) : (
                ''
              )
            }
            iconPosition="end"
            sx={{
              width: { xs: '50%', sm: '240px' },
              justifyContent: 'space-between',
              background: value === 1 ? '#FFDA8D' : ''
            }}
          />
          <Tab
            {...a11yProps(2)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                Balance / Funding
              </Typography>
            }
            icon={<KeyboardArrowRightIcon htmlColor={COLORS.black} />}
            iconPosition="end"
            sx={{
              width: '240px',
              justifyContent: 'space-between',
              background: value === 2 ? '#FFDA8D' : ''
            }}
          />
          <Tab
            {...a11yProps(3)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                Payment methods
              </Typography>
            }
            icon={<KeyboardArrowRightIcon htmlColor={COLORS.black} />}
            iconPosition="end"
            sx={{
              width: '240px',
              justifyContent: 'space-between',
              background: value === 3 ? '#FFDA8D' : ''
            }}
          />
        </Tabs>
        {isLoading ? (
          <ProfileSkeleton />
        ) : (
          <>
            <TabPanel value={value} index={0}>
              <GeneralInformation company={company} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BeegoppInformation company={company} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Credits />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PaymentMethod company={company} />
            </TabPanel>
          </>
        )}
      </Stack>
    </Box>
  )
}
