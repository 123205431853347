import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { COLORS } from 'styles/colors'
import useResetSettings from './useResetSettings'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ResetSettings({
  atsEnable,
  isOpen,
  onClose
}: {
  isOpen: boolean
  atsEnable: string
  onClose: () => void
}) {
  const { onResetSettings, isLoading } = useResetSettings(onClose)

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      aria-describedby="dialog-reset"
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Typography variant="h2">Reset settings</Typography>
        <Typography
          variant="body1"
          color={COLORS.red1}
          marginY={4}
          fontSize={14}
        >
          Are you sure you want to reset the settings to default?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ marginBottom: '10px', paddingRight: 3, display: 'flex' }}
      >
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => onResetSettings(atsEnable)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
