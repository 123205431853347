import { hookstate, useHookstate } from '@hookstate/core'
import { AddBonusConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { BonusConfig } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from './useBonusTable'
interface AddBonusConfigParameters {
  data: BonusConfig
  id: number
}
const initialState = hookstate({
  isLoading: false
})

export default function useAddBonusConfig() {
  const { isLoading } = useHookstate(initialState)
  const { onToggleTemplate } = useBonusTable()
  const { onNotify } = useNotification()
  const { mutate } = useMutation(
    (data: AddBonusConfigParameters) => AddBonusConfig(data.data, data.id),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Bonus config added successfully'
        })
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )
  const onAddBonusConfig = async (bonusConfig: BonusConfig, id: number) => {
    isLoading.set(true)
    delete bonusConfig.new
    mutate({ data: bonusConfig, id })
  }

  return {
    isLoading: isLoading.value,
    onAddBonusConfig,
    onToggleTemplate
  }
}
