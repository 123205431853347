import { useState } from 'react'
import { GetCompanyEntities } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import type { CompanyEntities, EntityName } from 'types/profile'
import { get } from 'utils'

export default function useCompanyEntities(entityName: EntityName) {
  const { onNotify } = useNotification()
  const { user } = useAuth()
  const [companyEntity, setCompanyEntity] = useState<Array<CompanyEntities>>([])

  const { isLoading } = useQuery(
    'COMPANY_ENTITIES',
    () =>
      GetCompanyEntities(user.company_id, { entity: entityName as EntityName }),
    {
      enabled: Boolean(entityName),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCompanyEntity(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return { isLoading, companyEntity }
}
