import { useState } from 'react'
import { GetUserNotifications, ViewedUserNotification } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NotificationData } from 'types/notification'
import { get } from 'utils'
import useToggleNotification from './useToggleNotification'

export default function useNotifications(isOpen: boolean) {
  const { user } = useAuth()
  const { onNavigate } = useNavigation()
  const { onNotify } = useNotification()
  const { onSetNotification, onCloseNotification } = useToggleNotification()
  const [notifications, setNotifications] = useState<NotificationData[]>(
    [] as NotificationData[]
  )

  const query = useQueryClient()

  const { isLoading, isFetching } = useQuery(
    'NOTIFICATIONS',
    () => GetUserNotifications(user.id),
    {
      enabled: isOpen,
      onSuccess: data => {
        setNotifications(data)
        onSetNotification(data.length > 0)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const { isLoading: submiting, mutate } = useMutation(
    'VIEWED_NOTIFICATIONS',
    (data: { notificationId: number }) =>
      ViewedUserNotification(user.id, data.notificationId),
    {
      onSuccess: () => {
        query.invalidateQueries('NOTIFICATIONS')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onViewedNotification = (notification: NotificationData) => {
    mutate({ notificationId: notification.id })
    setTimeout(() => {
      const route = routeNotification(notification)
      if (route) {
        onNavigate(route)
        onCloseNotification()
      }
    }, 0)
  }

  return {
    isLoading: isLoading || isFetching,
    onViewedNotification,
    notifications,
    submiting
  }
}

const routeNotification = (notification: NotificationData) => {
  if (notification.content.type === 'job' && notification.content.job_id) {
    return `/jobs/jobboard/${notification.content.job_id}`
  }
  return null
}
