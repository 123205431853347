import VisibilityIcon from '@mui/icons-material/Visibility'
import { Stack, Typography, Checkbox, Box, IconButton } from '@mui/material'
import { COLORS } from 'styles/colors'
import type { JobProps } from 'types/jobs'
import { formatCurrency, timeAgo } from 'utils'
import useSelected from './hooks/useSelected'

export default function Job(job: JobProps) {
  const { onCheckJob, onSelectJob } = useSelected()

  const {
    name,
    salary_low,
    salary_high,
    creation_date,
    company_name,
    id,
    type,
    importType,
    id_source
  } = job

  const checkName = `check-${id}`

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idSource: number
  ) => {
    const { checked } = event.target
    onCheckJob({ idSource, isChecked: checked, type: importType })
  }

  const salaryLow = formatCurrency(Number(salary_low || 0))
  const salaryHight = formatCurrency(Number(salary_high || 0))
  const salary = `${salaryLow} - ${salaryHight}`

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-betwen"
      alignItems="baseline"
    >
      <Stack flexDirection="row" alignItems="flex-start" width="95%">
        <Checkbox
          name={checkName}
          sx={{ paddingTop: 0, paddingLeft: 0 }}
          checked={job.isChecked || false}
          onChange={event => handleChange(event, Number(job.id_source))}
        />
        <Box>
          <Typography marginBottom={1} variant="body2">
            {name}
          </Typography>
          <Typography variant="h6" marginBottom={1} color={COLORS.gray1}>
            {company_name}
          </Typography>
          <Stack flexDirection="row" marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Payment:
            </Typography>
            <Typography variant="h6">{salary}</Typography>
          </Stack>
          <Stack flexDirection="row" marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Workday:
            </Typography>
            <Typography variant="h6">{type}</Typography>
          </Stack>
          <Stack flexDirection="row" marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Created:
            </Typography>
            <Typography variant="h6">{timeAgo(creation_date)}</Typography>
          </Stack>
          <Stack flexDirection="row" marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Source id:
            </Typography>
            <Typography variant="h6">{id_source}</Typography>
          </Stack>
        </Box>
      </Stack>
      <IconButton onClick={() => onSelectJob(job)} sx={{ padding: 0 }}>
        <VisibilityIcon htmlColor={COLORS.main} />
      </IconButton>
    </Stack>
  )
}
