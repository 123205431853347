import { useState } from 'react'
import useNavigation from './useNavigation'

export const usePage = () => {
  const { searchParams, onNavigate, pathname } = useNavigation()
  const [page, setPage] = useState(Number(searchParams.page) - 1 || 0)
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(searchParams.size) || 25
  )

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    addUrlPageParams(newPage)
  }

  const addUrlPageParams = (newPage: number) => {
    onNavigate(pathname, {
      search: { ...searchParams, page: newPage + 1 }
    })
  }

  const addUrlSizeParams = (pageSize: number) => {
    onNavigate(pathname, {
      search: { ...searchParams, size: pageSize }
    })
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    setRowsPerPage(perPage)
    addUrlSizeParams(perPage)
  }

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  }
}
