import { Typography, Stack, Box } from '@mui/material'
import useJobDetail from 'app/jobs/hooks/useJobDetail'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { Counter } from 'types/jobs'
import Description from '../description'
import useFetchDetail from '../hooks/useFetchDetail'
import SideDetail from '../sideDetail'

const JOB_STATUS = (counter: Counter & { views: number }) => {
  return [
    { id: 1, label: 'Viewed', views: counter.views || 0 },
    { id: 2, label: 'Active', views: counter.active || 0 },
    { id: 3, label: 'Hired', views: counter.hired || 0 },
    { id: 4, label: 'Rejected', views: counter.rejected || 0 }
  ]
}
export default function Body() {
  const { details, counters } = useFetchDetail()
  const { elementHeight, elementRef } = useJobDetail()

  const filteredCounters = JOB_STATUS({
    ...counters,
    views: details?.job_applyments.length || 0
  })

  return (
    <Stack
      spacing={2}
      width="100%"
      paddingBottom={3}
      direction={{ md: 'row' }}
      height={{ xs: '100%', md: elementHeight }}
    >
      <Box
        component={Stack}
        width={{ xs: '100%', md: '60%' }}
        direction={{ xs: 'column' }}
      >
        <Stack
          width="100%"
          paddingY={2}
          paddingX={0}
          component={Paper}
          direction="row"
        >
          {filteredCounters.map(({ id, label, views }) => {
            const lastIndex = filteredCounters.length - 1
            const lastItem = filteredCounters[lastIndex]
            const isLastItem = lastItem.id === id

            return (
              <Stack
                key={id}
                width="100%"
                alignItems="center"
                justifyContent="center"
                height={{ xs: 'auto', md: '126px' }}
                borderRight={isLastItem ? 'none' : `1px solid ${COLORS.gray8}`}
              >
                <Typography
                  variant="body2"
                  color={COLORS.gray1}
                  marginBottom={2}
                >
                  {label}
                </Typography>
                <Typography variant="h2" fontSize={{ xs: '16px', md: '26px' }}>
                  {views}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
        <Description height={elementHeight} />
      </Box>
      <Stack width={{ xs: '100%', md: '40%' }}>
        <SideDetail elementRef={elementRef} />
      </Stack>
    </Stack>
  )
}
