import { SyncAlt } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Typography, Stack, IconButton } from '@mui/material'
import SearchBar from 'components/SearchBar'
import useFilterStore from 'stores/useFilterStore'
import { COLORS } from 'styles/colors'

export default function Header({ isEmptyConfig }: { isEmptyConfig: boolean }) {
  const { onOpenAnchor } = useFilterStore()
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        width="100%"
      >
        <Stack direction="row" alignItems="center">
          <SyncAlt sx={{ transform: 'rotate(90deg)', width: '1.5rem' }} />
          <Typography variant="h2" marginY={2} marginLeft={2}>
            Imports
          </Typography>
        </Stack>
        {!isEmptyConfig && (
          <Stack
            direction="row"
            width={{ xs: '100%', sm: '50%', md: '300px' }}
            marginLeft={{ xs: 0, sm: 4 }}
            marginTop={{ xs: 2, sm: 0 }}
          >
            <SearchBar boxProps={{ width: '100%' }} />
            <IconButton onClick={event => onOpenAnchor(event, 'id')}>
              <FilterListIcon htmlColor={COLORS.gray1} />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
