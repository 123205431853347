import { Box } from '@mui/material'
import ConfigurationStep from 'app/bonus/templates/configurationStep'
import InformationStep from 'app/bonus/templates/informationStep'
import Stepper from 'components/stepper'
import useStepper from 'components/stepper/useStepper'
import { Formik } from 'formik'
import useAuth from 'hooks/useAuth'
import { NewBonusTemplate } from 'types/bonusTemplate'
import * as yup from 'yup'
import { BONUS_STEPS } from '../constants/index'
import useAddBonus from '../hooks/useAddBonusTemplate'
import ReviewStep from '../reviewStep'
import SentStep from '../sentStep'

const schema = yup
  .object({
    is_for_referral: yup.boolean(),
    is_for_referrer: yup.boolean(),
    bonus_config: yup.array().of(
      yup.object().shape({
        amount: yup.number().required(),
        days: yup.number().min(0).required()
      })
    ),
    description: yup.string().required('Description is required')
  })
  .test('myCustomTest', {}, obj => {
    if (obj.is_for_referral || obj.is_for_referrer) {
      return true // everything is fine
    }
    return new yup.ValidationError(
      'Please select at least one option',
      null,
      'bonusTemplateType'
    )
  })

export default function Templates() {
  const { user } = useAuth()
  const initialValues: NewBonusTemplate = {
    is_template: true,
    company_id: Number(user.company_id),
    is_for_referrer: true,
    job_id: null,
    is_for_referral: false,
    user_id: Number(user.id),
    description: '',
    status: 'active',
    total_amount_referral: 100,
    total_amount_referrer: 100,
    type: 'flat rate',
    bonus_config: []
  }
  const { isSent } = useAddBonus()
  const { onNextStep } = useStepper()
  return (
    <Box>
      {isSent ? (
        <SentStep title="Bonus" subtitle="Added correctly" />
      ) : (
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={() => onNextStep()}
        >
          {({ handleChange, values, setFieldValue, errors, isValid }) => (
            <>
              <Stepper steps={BONUS_STEPS}>
                <InformationStep
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                />
                <ConfigurationStep
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                  isValid={isValid}
                />
                <ReviewStep
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Stepper>
            </>
          )}
        </Formik>
      )}
    </Box>
  )
}
