import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'

export const MUIDataGrid = styled(DataGrid)`
  background: ${COLORS.white};
  opacity: 1 !important;
  border: none;
  .MuiDataGrid-cellContent {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: ${COLORS.gray1};
  }
  .MuiDataGrid-footerContainer {
    justify-content: flex-end;
    border-top: none;
  }
  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }
  .MuiDataGrid-row.Mui-selected {
    background-color: ${COLORS.honey2};
  }
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .selected-row {
    background-color: ${COLORS.honey2};
  }
  .Mui-even {
    background-color: ${COLORS.gray9};
  }
  .Mui-odd {
    background-color: ${COLORS.white};
  }
  .MuiDataGrid-virtualScroller {
    ::-webkit-scrollbar {
      background: transparent;
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.blue6};
      border-radius: 8px;
    }
    scrollbar-color: ${COLORS.blue6} ${COLORS.gray6};
    scrollbar-width: thin;
  }
`
