import { Box, Paper, Typography, Stack } from '@mui/material'
import useAuth from 'hooks/useAuth'
import QRCodeLib from 'react-qr-code'
import { COLORS } from 'styles/colors'
import useFetchDetail from '../hooks/useFetchDetail'

export default function ShareJob() {
  const { details } = useFetchDetail()
  const { user } = useAuth()

  const hasBonus = Boolean(details.bonus)

  const showQR = details.status === 'published' && hasBonus

  return (
    <Box component={Paper} marginTop={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box width="70%">
          <Typography variant="h3" marginBottom={3}>
            QR
          </Typography>
          <Typography variant="body2" color={COLORS.gray2}>
            Scan the code to
          </Typography>
          <Typography variant="body2" color={COLORS.gray2} marginBottom={3}>
            see it on your device
          </Typography>
        </Box>
        {showQR && (
          <Box width="35%">
            <QRCodeLib
              value={`https://resplendent-lily-51567f.netlify.app/company/${user.company_id}/jobs/${details.id}/${user.id}`}
              size={80}
            />
          </Box>
        )}
      </Stack>
    </Box>
  )
}
