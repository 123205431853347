import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Formik, Form } from 'formik'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { COLORS } from 'styles/colors'
import { listImages } from 'utils/catalogs'
import useRegisters, { initialValues, validationSchema } from './useRegisters'

export default function UserRegisters() {
  const [isFocused, seIsFocused] = useState(false)
  const { onSubmit, isLoading } = useRegisters()

  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={{ xs: 0, md: 5 }}>
            <Typography
              variant="h1"
              fontSize={{ sx: '44px', md: '48px' }}
              paddingBottom={4}
            >
              Register on Beegopp
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              Please enter your information
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values, errors }) => (
                <Form>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="standard"
                    name="first_name"
                    sx={{
                      paddingBottom: '30px'
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="standard"
                    name="last_name"
                    sx={{
                      paddingBottom: '30px'
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    variant="standard"
                    name="email"
                    sx={{
                      paddingBottom: '30px'
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Company"
                    variant="standard"
                    name="company"
                    sx={{
                      paddingBottom: '30px'
                    }}
                  />
                  <Stack
                    flexDirection="column"
                    position="relative"
                    sx={{
                      '.selected-flag': {
                        paddingLeft: 0
                      },
                      '.special-label': {
                        left: 0,
                        paddingX: 0,
                        color: COLORS.main,
                        fontSize: 14
                      }
                    }}
                  >
                    <PhoneInput
                      value=""
                      country="us"
                      disableDropdown
                      inputStyle={{
                        width: '100%',
                        border: 'none',
                        borderBottom:
                          isFocused || values.phone
                            ? `2px solid ${COLORS.main}`
                            : `1px solid ${COLORS.gray4}`,
                        borderRadius: 0,
                        paddingLeft: 40,
                        boxShadow: 'none'
                      }}
                      onFocus={() => seIsFocused(true)}
                      onBlur={() => seIsFocused(false)}
                      onChange={(value, country: CountryData) => {
                        setFieldValue(
                          'phone',
                          value.replace(country.dialCode, '')
                        )
                        setFieldValue('phone_area_code', country.dialCode)
                      }}
                    />
                    <Typography variant="caption" color={COLORS.red1}>
                      {errors.phone}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color={COLORS.gray4} marginY={3}>
                    BeeGopp uses the information you provide contact to you
                    about our product and services. Unsubscribe at any time.
                  </Typography>
                  <Box position="relative">
                    <FormControlLabel
                      name="privacy"
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        setFieldValue('privacy', checked)
                      }}
                      label={
                        <Stack direction="row" alignItems="center">
                          <Typography
                            variant="body2"
                            marginRight={1}
                            color={COLORS.gray4}
                          >
                            I agree to the
                          </Typography>
                          <Link
                            component="a"
                            target="_blank"
                            href="https://beegopp.com/privacy-policy/"
                            sx={{ textDecoration: 'none', fontSize: 14 }}
                          >
                            Privacy Policy
                          </Link>
                        </Stack>
                      }
                      sx={{ width: '100%' }}
                    />
                    <Typography variant="caption" color={COLORS.red1}>
                      {errors.privacy}
                    </Typography>
                  </Box>
                  <Stack
                    gap={2}
                    marginTop={3}
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Button
                      children="Back"
                      variant="text"
                      size="large"
                      LinkComponent="a"
                      href="https://beegopp.com"
                      sx={{ width: '100%' }}
                    />
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      Register
                    </LoadingButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
