import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import Carousel from 'components/carousel'
import useNavigation from 'hooks/useNavigation'
import { listImages } from 'utils/catalogs'

export default function VerifiedEmail() {
  const { onNavigate, params } = useNavigation()

  const handleRedirect = () => {
    onNavigate(`/new-password/${params.token}`, {
      search: {
        reset: 'reset'
      }
    })
  }
  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h1" paddingX="8px" paddingBottom={4}>
              Your email has been verified!
            </Typography>
            <Button variant="contained" onClick={handleRedirect} fullWidth>
              Continue
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
