import { LoadingButton } from '@mui/lab'
import { Stack, Typography, Divider, Button, Box } from '@mui/material'

import { Draggable, Droppable } from 'components/drag'
import ModalImport from 'components/modals/import'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { hexToRGB, StylesScrollBar } from 'styles/utils'
import type { JobProps } from 'types/jobs'
import Job from '../Job'
import EmptyList from '../emptyList'
import useMutationImport from '../hooks/useMutationImport'
import useSelected from '../hooks/useSelected'
import SearchBar from '../search'

export default function DragImport({
  importList = [],
  onImportSearch,
  searchValue
}: {
  searchValue: string
  importList: Array<JobProps>
  onImportSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const {
    onSelectedAllJobs,
    onUnselectedAllJobs,
    onRemoveToImport,
    type,
    selectedJob
  } = useSelected()

  const { onSubmit, isOpen, isLoading } = useMutationImport()

  const handleJobs = (typeJob: 'import') => {
    if (type[typeJob]) {
      return onUnselectedAllJobs(typeJob)
    }
    onSelectedAllJobs(typeJob)
  }

  const hasJob = selectedJob.id_source

  const selected =
    importList?.length > 0 ? importList.filter(item => item.isChecked) : []

  const hideSearch = false

  return (
    <Droppable droppableId="Import">
      <Paper
        elevation={3}
        bgcolor={hexToRGB(COLORS.gray8, '0.2')}
        sx={{ height: '100%' }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={COLORS.white}
          paddingX={2}
          paddingY="9px"
        >
          <Typography variant="h3" fontSize="20px">
            Import ({importList.length})
          </Typography>
          <LoadingButton
            loading={isLoading}
            onClick={onSubmit}
            disabled={importList.length === 0}
            variant="contained"
            sx={{ minWidth: 120 }}
          >
            Import
          </LoadingButton>
        </Stack>
        <Divider />
        <Stack
          flexDirection="row"
          alignItems="center"
          bgcolor={COLORS.white}
          justifyContent="space-between"
          paddingX={1}
        >
          <Stack flexDirection="row" alignItems="center">
            <Button variant="text" onClick={() => handleJobs('import')}>
              {type.import ? 'Unselected all' : 'Select all'}
            </Button>
            <Typography padding={2} variant="h5" color={COLORS.gray2}>
              {selected.length || 0} Selected Jobs
            </Typography>
          </Stack>
          <Button
            disabled={selected.length === 0}
            variant="outlined"
            onClick={onRemoveToImport}
          >
            Remove selection
          </Button>
        </Stack>
        <Divider />
        {hideSearch && (
          <SearchBar
            onChange={onImportSearch}
            value={searchValue}
            boxProps={{ marginY: 2, marginX: 1 }}
          />
        )}
        <Box
          paddingX={1}
          paddingBottom={1}
          paddingTop={2}
          height={`calc(100vh - ${hasJob ? '26em' : '19em'})`}
          sx={{
            overflow: 'auto',
            ...StylesScrollBar
          }}
        >
          {importList.length <= 0 ? (
            <EmptyList />
          ) : (
            <>
              {importList.map((item, index) => {
                const checkedBgcolor = item.isChecked
                  ? COLORS.honey2
                  : COLORS.white

                return (
                  <Draggable
                    key={item.id_source}
                    id={String(item.id_source)}
                    index={index}
                    paperProps={{
                      sx: {
                        bgcolor: checkedBgcolor
                      }
                    }}
                  >
                    <Job {...item} importType="import" />
                  </Draggable>
                )
              })}
            </>
          )}
        </Box>
      </Paper>
      <ModalImport isOpen={isOpen} title="Importing jobs..." />
    </Droppable>
  )
}
