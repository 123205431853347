import { Fragment, useState } from 'react'
import { Box, Stack, Button, TextField, Autocomplete } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import useFilter from 'app/jobs/hooks/useFilter'
import useCompanies from './useCompanies'

export default function Company() {
  const [open, setOpen] = useState(false)
  const { filterJobs, onChangeSingleValue, onClearFilters, onAddUrlFilters } =
    useFilter()
  const { companyOptions, isLoading } = useCompanies()

  return (
    <Box padding={2} width={{ xs: '300px', md: '400px' }}>
      <Autocomplete
        open={open}
        id="asynchronous"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, option) => onChangeSingleValue(option?.id as string)}
        options={companyOptions}
        loading={isLoading}
        value={
          filterJobs.company_name
            ? { label: filterJobs.company_name, id: filterJobs.company_name }
            : null
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Companies"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
          />
        )}
      />
      <Stack direction="row" spacing={2} marginTop={5}>
        <Button fullWidth onClick={() => onClearFilters('company_name')}>
          Clear
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onAddUrlFilters('company_name')}
        >
          Apply
        </Button>
      </Stack>
    </Box>
  )
}
