import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import useJobApplication from 'app/profile/hooks/useJobApplication'
import TextField from 'components/TextField'
import { Form, Formik } from 'formik'
import useNavigation from 'hooks/useNavigation'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { COLORS } from 'styles/colors'
import * as yup from 'yup'
import Footer from '../../layout/footer/index'
import useCompanyPreview from '../hooks/useCompanyJob'

const validationSchema = yup.object({
  first_name: yup.string().required('Name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone is required')
})

export default function JobApplication() {
  const { onNavigate, params } = useNavigation()
  const { loading, initialValues, setJobForm } = useJobApplication()
  const { details } = useCompanyPreview()
  const [isFocused, seIsFocused] = useState(false)

  const onSubmit = (values: any) => {
    onNavigate(
      `/company/${params.id}/jobs/${params.jobId}/${params.employeeId}/jobapplication-step2`
    )
    setJobForm(values)
  }

  return (
    <Stack
      width="100%"
      height="90vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height="60%"
        padding="10px"
        margin="0 auto"
        boxSizing="border-box"
        width={{ xs: '100%', md: '70%', lg: '60%' }}
      >
        <Typography variant="h3" textAlign="center" marginBottom={4}>
          {details.name || ''}
        </Typography>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => onSubmit(values)}
        >
          {({ handleChange, setFieldValue, errors, values }) => (
            <Form
              style={{
                height: '80%'
              }}
            >
              <Stack
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Stack flexDirection="column">
                  <Typography variant="h5">First name</Typography>
                  <TextField
                    sx={{
                      width: '100%'
                    }}
                    variant="standard"
                    onChange={handleChange('first_name')}
                    name="first_name"
                  />
                </Stack>
                <Stack flexDirection="column">
                  <Typography variant="h5">Last name</Typography>
                  <TextField
                    sx={{
                      width: '100%'
                    }}
                    variant="standard"
                    onChange={handleChange('last_name')}
                    name="last_name"
                  />
                </Stack>

                <Stack flexDirection="column">
                  <Typography variant="h5">Email</Typography>
                  <TextField
                    sx={{
                      width: '100%'
                    }}
                    variant="standard"
                    onChange={handleChange('email')}
                    name="email"
                  />
                </Stack>
                <Stack
                  flexDirection="column"
                  position="relative"
                  sx={{
                    '.selected-flag': {
                      paddingLeft: 0
                    },
                    '.special-label': {
                      left: 0,
                      paddingX: 0,
                      color: COLORS.main,
                      fontSize: 14
                    }
                  }}
                >
                  <PhoneInput
                    value=""
                    country="us"
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      border: 'none',
                      borderBottom:
                        isFocused || values.phone
                          ? `2px solid ${COLORS.main}`
                          : `1px solid ${COLORS.gray4}`,
                      borderRadius: 0,
                      paddingLeft: 40,
                      boxShadow: 'none'
                    }}
                    onFocus={() => seIsFocused(true)}
                    onBlur={() => seIsFocused(false)}
                    onChange={(value, country: CountryData) => {
                      setFieldValue(
                        'phone',
                        value.replace(country.dialCode, '')
                      )
                      setFieldValue('phone_area_code', country.dialCode)
                    }}
                  />
                  <Typography variant="caption" color={COLORS.red1}>
                    {errors.phone}
                  </Typography>
                </Stack>
                <Box marginRight="auto" marginLeft="auto">
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    variant="contained"
                    color="primary"
                    sx={{
                      width: '250px'
                    }}
                  >
                    Next
                  </LoadingButton>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
      <Footer />
    </Stack>
  )
}
