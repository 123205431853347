import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  DialogActions,
  DialogContent
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'
import { Company } from 'types/companies'
import useResendDelete from './useResendDelete'

export default function DialogResend({
  selectedCheck,
  handleClose,
  companies,
  actionType,
  open
}: {
  open: boolean
  companies: Company['entries']
  selectedCheck: GridSelectionModel
  actionType: 'resend' | 'delete'
  handleClose: (type: 'resend' | 'delete') => void
}) {
  const { isLoading, onSubmit } = useResendDelete(() => handleClose(actionType))

  const filteredCompanies = companies.filter(item => {
    return selectedCheck.includes(item.id)
  })

  const title =
    actionType === 'resend' ? 'Resend invitations' : 'Delete companies'

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent
        sx={{ paddingY: '10px', paddingX: '20px', margin: '20px' }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom={5}
        >
          <Typography variant="h3">{title}</Typography>
        </Box>
        {filteredCompanies.map(({ name, owner }) => (
          <Grid container>
            <Grid item xs={6}>
              <Box>
                <Typography variant="caption">Company name</Typography>
                <Typography variant="h5" fontSize={14}>
                  {name}
                </Typography>
              </Box>
              <Divider sx={{ marginY: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="caption">Company owner</Typography>
                <Typography variant="h5" fontSize={14} color={COLORS.main}>
                  {owner.email}
                </Typography>
              </Box>
              <Divider sx={{ marginY: 1 }} />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions sx={{ paddingX: 4, paddingBottom: 4 }}>
        <Button
          variant="outlined"
          fullWidth
          sx={{ marginRight: '3px' }}
          onClick={() => handleClose(actionType)}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          sx={{ marginLeft: '3px' }}
          onClick={() => onSubmit({ companies: selectedCheck, actionType })}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
