import FolderIcon from '@mui/icons-material/Folder'
import PlaceIcon from '@mui/icons-material/Place'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import Footer from 'app/layout/footer'
import { SigninLogo } from 'app/signin/styles'
import Spinner from 'components/spinner'
import { Pagination } from 'components/table'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { COLORS } from 'styles/colors'
import { Job, JobBoard } from 'types/jobs'
import { formatDate } from '../../utils/index'
import useGetPublicJobsByCompany from './hooks/useGetPublicJobsByCompany'
export default function CompanyPreview() {
  const { jobs, isLoading, pagination } = useGetPublicJobsByCompany()
  const { page, handleChangePage } = usePage()
  const { onNavigate, params } = useNavigation()

  if (isLoading) {
    return (
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <Spinner />
      </Stack>
    )
  }

  const viewMore = (jobId: number) => {
    onNavigate(`/company/${params.id}/jobs/${jobId}/${params.employeeId}`)
  }

  const company = jobs.company || ({} as JobBoard['company'])

  return (
    <Box bgcolor={COLORS.gray9} paddingBottom={8}>
      <Box
        top={0}
        zIndex={99}
        position="sticky"
        bgcolor={COLORS.white}
        padding="10px 10px 0"
        paddingTop={{ xs: '10px', md: 2 }}
      >
        <Box
          width="100%"
          margin="0 auto"
          maxWidth={{ xs: '100%', md: '80%', lg: '70%' }}
        >
          <SigninLogo
            alt="logo"
            src="/images/logo.png"
            style={{ marginTop: 0, width: '100px' }}
          />
        </Box>
      </Box>
      <Box
        width="100%"
        margin="0 auto"
        paddingX="10px"
        boxSizing="border-box"
        maxWidth={{ xs: '100%', md: '80%', lg: '70%' }}
      >
        <Box
          width="100%"
          paddingY={{ xs: 2, md: 2 }}
          sx={{ boxSizing: 'border-box' }}
        >
          <Box
            alt="Header"
            width="100%"
            component="img"
            maxHeight="200px"
            src={company?.header}
            sx={{ objectFit: 'cover' }}
          />
        </Box>
        <Stack paddingY="8px" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginRight={2} display={{ xs: 'none', md: 'block' }}>
            <Box
              maxWidth="180px"
              margin={{ xs: '0 auto' }}
              width={{ xs: '30%', md: '100%' }}
              marginBottom={{ xs: '4px', md: '4px' }}
              justifyContent={{ xs: 'space-between' }}
            >
              <img src={company?.logo} alt="cover" width="100%" />
            </Box>
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            {jobs.entries.map((item: Job, index: number) => (
              <Stack
                key={index}
                marginBottom={4}
                boxSizing="border-box"
                bgcolor={COLORS.white}
                paddingTop={{ xs: 3, md: 0 }}
                paddingX={{ xs: 2, md: 0 }}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                width={{ xs: '100%', md: 'auto' }}
              >
                <Stack marginY={{ xs: 0, md: 3 }}>
                  <Stack
                    flexDirection={{ xs: 'column', md: 'row' }}
                    margin={{ xs: 0, md: '8px 0 14px 18px' }}
                  >
                    <Stack
                      flexDirection={{ xs: 'column', md: 'row' }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        marginRight={{ xs: 0, md: 7 }}
                        margin={{ xs: '0 0 15px 0' }}
                      >
                        <Typography
                          fontSize="20px"
                          fontWeight="400"
                          textAlign={{ xs: 'center', md: 'initial' }}
                        >
                          {item.name}
                        </Typography>
                        <Stack>
                          <Typography
                            variant="body2"
                            color={COLORS.gray2}
                            textAlign={{ xs: 'center', md: 'initial' }}
                          >
                            {formatDate(
                              new Date(item.inserted_at),
                              'dd LLL, yyyy'
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    margin={{ xs: 0, md: '8px 0 14px 18px' }}
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    width="100%"
                  >
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: item.description
                      }}
                      maxHeight="165px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign={{ xs: 'center', md: 'start' }}
                    />
                  </Stack>
                  <Box>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={{ xs: 'center', md: 'flex-start' }}
                    >
                      <Stack
                        marginRight="6px"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <PlaceIcon
                          htmlColor={COLORS.blue8}
                          sx={{ margin: '10px' }}
                        />
                        <Typography variant="body2" color={COLORS.gray2}>
                          Location
                        </Typography>
                      </Stack>
                      <Button variant="text">{item.address}</Button>
                    </Stack>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={{ xs: 'center', md: 'flex-start' }}
                    >
                      <Stack
                        marginRight="13px"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <FolderIcon
                          htmlColor={COLORS.blue8}
                          sx={{ margin: '10px' }}
                        />
                        <Typography variant="body2" color={COLORS.gray2}>
                          Job type
                        </Typography>
                      </Stack>
                      <Typography variant="body2">{item.type}</Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Stack margin={{ xs: '15px auto', md: 4 }}>
                  <Stack
                    alignSelf="end"
                    margin={{ xs: '0 auto 15px', md: '0' }}
                    flexDirection={{ xs: 'column-reverse', md: 'column' }}
                  >
                    <Typography variant="body2" color={COLORS.gray2}>
                      Referrals added
                    </Typography>
                    <Stack
                      marginRight={0}
                      marginBottom={2}
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{
                          background: COLORS.honey4,
                          zIndex: 1,
                          border: `2px solid ${COLORS.white}`,
                          position: 'absolute',
                          marginRight: '32px'
                        }}
                      />
                      <Avatar
                        sx={{
                          background: COLORS.honey4,
                          border: `1px solid ${COLORS.honey4}`,
                          justifyContent: 'center',
                          position: 'relative',
                          marginLeft: '47px'
                        }}
                      >
                        +{item.job_applyments.length}
                      </Avatar>
                    </Stack>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    minWidth="150px"
                    marginTop={{ xs: 0, md: 4 }}
                    margin={{ xs: '10px auto', md: '4px 0 0 0 ' }}
                    justifyContent="end"
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ paddingX: '20px' }}
                      onClick={() => viewMore(item.id)}
                    >
                      View Job
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Box>
        </Stack>
        <Stack
          spacing={2}
          justifyContent="center"
          flexDirection="row"
          padding={2}
          marginBottom={2}
        >
          <Pagination
            handleChangePage={handleChangePage}
            page={page}
            totalPages={pagination.totalPages}
          />
        </Stack>
      </Box>
      <Box position="fixed" bottom="0" width="100%" bgcolor="white" zIndex={9}>
        <Footer />
      </Box>
    </Box>
  )
}
