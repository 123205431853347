import { Delete } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button
} from '@mui/material'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { Form, FieldArray, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { BonusConfig } from 'types/bonusTemplate'
import useNewBonus, { validationSchema } from './useNewBonus'

export default function NewBonus({ hasBonus }: { hasBonus: boolean }) {
  const {
    initialValues,
    isLoading,
    onSubmit,
    onAddBonusConfigToArray,
    onRemoveBonusConfigFromArray
  } = useNewBonus()

  return (
    <Box padding="16px" minHeight="calc(100vh - 236px)">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, handleChange, getFieldProps, setFieldValue }) => {
          const bonus_config = errors?.entries?.bonus_config as BonusConfig[]
          return (
            <Stack
              minHeight="74vh"
              component={Form}
              justifyContent="space-between"
            >
              <Box sx={{ padding: '16px' }}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h3">
                    {hasBonus ? 'Edit bonus' : 'Create bonus'}
                  </Typography>
                </Stack>
                <Stack flexDirection="column" marginY={3}>
                  <Typography variant="h5">Bonus name</Typography>
                  <TextField
                    sx={{
                      width: '100%'
                    }}
                    value={values.entries.description}
                    variant="standard"
                    onChange={handleChange('entries.description')}
                    name="description"
                  />
                  <Typography variant="caption" color={COLORS.red1}>
                    {errors.entries?.description}
                  </Typography>
                </Stack>
                <Box>
                  <Typography
                    variant="h5"
                    marginBottom={1}
                    color={COLORS.gray2}
                  >
                    Choose for who is the bonus...
                  </Typography>
                  <FormControlLabel
                    name="is_for_referrer"
                    disabled={!values.entries.description}
                    control={<Checkbox />}
                    label="Employee"
                    checked={values.entries.is_for_referrer}
                    onChange={(e, checked) => {
                      setFieldValue('entries.is_for_referrer', checked)
                    }}
                    sx={{ width: '100%' }}
                    componentsProps={{
                      typography: { color: COLORS.gray1 }
                    }}
                  />
                  <FormControlLabel
                    name="is_for_referral"
                    disabled={!values.entries.description}
                    control={<Checkbox />}
                    label="Referral"
                    checked={values.entries.is_for_referral}
                    onChange={(e, checked) => {
                      setFieldValue('entries.is_for_referral', checked)
                    }}
                    sx={{ width: '100%' }}
                    componentsProps={{
                      typography: { color: COLORS.gray1 }
                    }}
                  />

                  {values.entries.is_for_referrer ? (
                    <>
                      <Stack flexDirection="column" marginY={2}>
                        <Typography variant="h5">Employee Bonus</Typography>
                        <Select
                          disabled
                          name="Type"
                          value="1"
                          variant="standard"
                          label="Select bonus type"
                          options={[{ value: '1', label: 'Flat rate' }]}
                        />
                      </Stack>
                      <FieldArray
                        name="entries.bonus_config"
                        render={arrayHelpers => (
                          <div>
                            {values.entries.bonus_config.map((item, index) => {
                              if (item.type === 'referrer') {
                                return (
                                  <Box
                                    marginY={2}
                                    key={index}
                                    position="relative"
                                  >
                                    <Stack
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <TextField
                                        sx={{
                                          width: '30%'
                                        }}
                                        variant="standard"
                                        type="number"
                                        value={`${values.entries.bonus_config[index].amount}`}
                                        onChange={args => {
                                          setFieldValue(
                                            `entries.bonus_config[${index}].amount`,
                                            Number(args.target.value)
                                          )
                                          const values = getFieldProps(
                                            `entries.bonus_config[${index}]`
                                          )
                                          if (!values.value.edited)
                                            setFieldValue(
                                              `entries.bonus_config[${index}].edited`,
                                              true
                                            )
                                        }}
                                        name="amount"
                                        placeholder="$ Amount"
                                      />
                                      <Typography
                                        variant="body2"
                                        marginBottom={0.2}
                                      >
                                        USD
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        marginLeft={3}
                                        marginRight={3}
                                        marginBottom={0.2}
                                      >
                                        After
                                      </Typography>
                                      <TextField
                                        sx={{
                                          width: '30%',
                                          marginRight: '10px'
                                        }}
                                        placeholder="Number of days"
                                        variant="standard"
                                        type="number"
                                        value={`${values.entries.bonus_config[index].days}`}
                                        onChange={args => {
                                          setFieldValue(
                                            `entries.bonus_config[${index}].days`,
                                            Number(args.target.value)
                                          )
                                          const values = getFieldProps(
                                            `entries.bonus_config[${index}]`
                                          )
                                          if (!values.value.edited)
                                            setFieldValue(
                                              `entries.bonus_config[${index}].edited`,
                                              true
                                            )
                                        }}
                                        name="days"
                                      />
                                      <Typography
                                        variant="body2"
                                        marginBottom={0.2}
                                        marginRight={1}
                                      >
                                        Days
                                      </Typography>
                                      <Button
                                        onClick={() =>
                                          onRemoveBonusConfigFromArray(
                                            arrayHelpers,
                                            index
                                          )
                                        }
                                      >
                                        <Delete color="error" />
                                      </Button>
                                    </Stack>
                                    {errors?.entries?.bonus_config &&
                                    errors?.entries?.bonus_config[index] ? (
                                      <Typography
                                        variant="h6"
                                        color={COLORS.red1}
                                        position="absolute"
                                        bottom="-16px"
                                      >
                                        {bonus_config[index].amount ||
                                          bonus_config[index].days}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                )
                              }
                            })}
                            <Button
                              onClick={() =>
                                onAddBonusConfigToArray(
                                  arrayHelpers,
                                  'referrer',
                                  true
                                )
                              }
                              sx={{ marginTop: 3 }}
                            >
                              <Add />
                              <Typography
                                variant="body2"
                                color={COLORS.main}
                                marginLeft={2}
                                marginRight={2}
                              >
                                Add Bonus Tier
                              </Typography>
                            </Button>
                          </div>
                        )}
                      />
                    </>
                  ) : null}
                  {values.entries.is_for_referral ? (
                    <>
                      <Stack flexDirection="column" marginY={2}>
                        <Typography variant="h5">Referral Bonus</Typography>
                        <Select
                          disabled
                          name="Type"
                          value="1"
                          variant="standard"
                          label="Select bonus type"
                          options={[{ value: '1', label: 'Flat rate' }]}
                        />
                      </Stack>
                      <FieldArray
                        name="entries.bonus_config"
                        render={arrayHelpers => (
                          <div>
                            {values.entries.bonus_config.map((item, index) => {
                              if (item.type === 'referral') {
                                return (
                                  <Box
                                    marginY={2}
                                    key={index}
                                    position="relative"
                                  >
                                    <Stack
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <TextField
                                        sx={{
                                          width: '30%'
                                        }}
                                        type="number"
                                        value={`${values.entries.bonus_config[index].amount}`}
                                        variant="standard"
                                        onChange={args => {
                                          setFieldValue(
                                            `entries.bonus_config[${index}].amount`,
                                            Number(args.target.value)
                                          )
                                          const values = getFieldProps(
                                            `entries.bonus_config[${index}]`
                                          )
                                          if (!values.value.edited)
                                            setFieldValue(
                                              `entries.bonus_config[${index}].edited`,
                                              true
                                            )
                                        }}
                                        name="amount"
                                        placeholder="$ Amount"
                                      />
                                      <Typography
                                        variant="body2"
                                        marginBottom={0.2}
                                      >
                                        USD
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        marginLeft={3}
                                        marginRight={3}
                                        marginBottom={0.2}
                                      >
                                        After
                                      </Typography>
                                      <TextField
                                        sx={{
                                          width: '30%',
                                          marginRight: '10px'
                                        }}
                                        type="number"
                                        placeholder="Number of days"
                                        variant="standard"
                                        value={`${values.entries.bonus_config[index].days}`}
                                        onChange={args => {
                                          setFieldValue(
                                            `entries.bonus_config[${index}].days`,
                                            Number(args.target.value)
                                          )
                                          const values = getFieldProps(
                                            `entries.bonus_config[${index}]`
                                          )
                                          if (!values.value.edited)
                                            setFieldValue(
                                              `entries.bonus_config[${index}].edited`,
                                              true
                                            )
                                        }}
                                        name="days"
                                      />
                                      <Typography
                                        variant="body2"
                                        marginBottom={0.2}
                                        marginRight={1}
                                      >
                                        Days
                                      </Typography>
                                      <Button
                                        onClick={() =>
                                          onRemoveBonusConfigFromArray(
                                            arrayHelpers,
                                            index
                                          )
                                        }
                                      >
                                        <Delete color="error" />
                                      </Button>
                                    </Stack>
                                    {errors?.entries?.bonus_config &&
                                    errors?.entries?.bonus_config[index] ? (
                                      <Typography
                                        variant="h6"
                                        color={COLORS.red1}
                                        position="absolute"
                                        bottom="-16px"
                                      >
                                        {bonus_config[index].amount ||
                                          bonus_config[index].days}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                )
                              }
                            })}
                            <Button
                              onClick={() =>
                                onAddBonusConfigToArray(
                                  arrayHelpers,
                                  'referral',
                                  true
                                )
                              }
                              sx={{ marginTop: 3 }}
                            >
                              <Add />
                              <Typography
                                variant="body2"
                                color={COLORS.main}
                                marginLeft={2}
                                marginRight={2}
                              >
                                Add Bonus Tier
                              </Typography>
                            </Button>
                          </div>
                        )}
                      />
                    </>
                  ) : null}
                </Box>
              </Box>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={values.entries.bonus_config.length === 0}
                sx={{
                  marginTop: 4
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          )
        }}
      </Formik>
    </Box>
  )
}
