import { LoadingButton } from '@mui/lab'
import { Box, Container, Grid, Typography } from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Form, Formik } from 'formik'
import { listImages } from 'utils/catalogs'
import useResetPassword, { validationSchema } from './useResetPassword'

export default function ResetPassword() {
  const { onSumbit } = useResetPassword()
  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h1" paddingX="8px" paddingBottom={4}>
              Password recovery
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              Please enter your email to recover your password
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={{ email: '' }}
              onSubmit={onSumbit}
            >
              {() => (
                <Form>
                  <TextField
                    sx={{
                      paddingBottom: '30px'
                    }}
                    label="Email"
                    variant="standard"
                    name="email"
                    fullWidth
                  />
                  <LoadingButton variant="contained" type="submit" fullWidth>
                    Send
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
