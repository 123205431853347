import { Box } from '@mui/material'
import Paper from 'components/paper'
import ReferralHeader from './ReferralHeader'
import ReferralTable from './ReferralTable'

export default function BoardConfig() {
  return (
    <Box component={Paper} marginBottom={4} marginTop={4}>
      <ReferralHeader />
      <ReferralTable />
    </Box>
  )
}
