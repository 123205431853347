import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useUpdateJobPositions from 'app/educationDetails/hooks/useUpdateJobPositions'
import useTangoCompany from 'app/profile/myCompany/credits/hooks/useTangoCompany'
import useStepper from 'components/stepper/useStepper'
import useNavigation from 'hooks/useNavigation'
import { JobDetail } from 'types/jobs'
import * as yup from 'yup'

export const validationSchema = yup.object({
  positions: yup
    .number()
    .test(
      'Is positive?',
      'The number must be positive',
      value => value !== undefined && value > 0
    )
    .required('Positions is required')
})

const initialTotal = {
  totalEmployess: 0,
  totalReferral: 0,
  totalBonus: 0,
  totalBalance: 0,
  newBalance: 0
}

const initialState = hookstate({
  positions: 0,
  total: initialTotal
})

export default function useBuyBonus(details: JobDetail) {
  const { onNextStep, onBackStep } = useStepper()
  const { onSubmitPositions, submiting } = useUpdateJobPositions()

  const { searchParams } = useNavigation()
  const { positions, total } = useHookstate(initialState)
  const { tangoCompany, isLoading } = useTangoCompany()

  useEffect(() => {
    if (!searchParams.buybonus) {
      positions.set(0)
    }
  }, [searchParams.buybonus])

  const currentBalance =
    Number(tangoCompany?.beegopp_tango_data.tango_balance) -
    Number(tangoCompany?.beegopp_tango_data.budget)

  const onChangePositions = (positions: number) => {
    const totalEmployess = referrerTotal * positions
    const totalReferral = referralTotal * positions
    const totalBonus = totalEmployess + totalReferral
    const totalBalance = currentBalance
    const newBalance = currentBalance - Number(totalBonus)

    total.set({
      totalEmployess,
      totalReferral,
      totalBonus,
      totalBalance,
      newBalance
    })
  }

  const [referralTotal, referrerTotal] = details?.bonus?.bonus_config.reduce(
    (acc, { type, amount }) => [
      acc[0] + (type === 'referral' ? Number(amount) : 0),
      acc[1] + (type !== 'referral' ? Number(amount) : 0)
    ],
    [0, 0]
  ) ?? [0, 0]

  const bonusForReferrer =
    details?.bonus?.bonus_config.filter(item => item.type === 'referrer') || {}

  const bonusForReferral =
    details?.bonus?.bonus_config.filter(item => item.type === 'referral') || {}

  const onSubmit = () => onSubmitPositions(positions.get())

  const onNextSubmit = (values: { positions: number }) => {
    positions.set(values.positions)
    onNextStep()
  }

  return {
    ...total.get(),
    positions: positions.get(),
    updating: submiting,
    bonusForReferral,
    bonusForReferrer,
    currentBalance,
    referralTotal,
    referrerTotal,
    tangoCompany,
    isLoading,
    details,
    onSubmit,
    onBackStep,
    onNextSubmit,
    onChangePositions
  }
}
