import { useEffect, useState } from 'react'
import { GetJobsByCompany } from 'api'
import { AxiosError } from 'axios'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useInfiniteQuery } from 'react-query'
import type { Job } from 'types/jobs'
import { get, pickBy } from 'utils'

const initialPagination = {
  count: 15,
  start: 0
}

export default function useFetchInfinitive() {
  const { user } = useAuth()
  const { searchParams } = useNavigation()
  const { onNotify } = useNotification()
  const [page, setPage] = useState(1)
  const [totalEntries, setTotalentries] = useState(0)
  const [pagination, setPagination] = useState(initialPagination)

  const [isEmptyConfig, setIsEmptyConfig] = useState(false)
  const [jobs, setJobs] = useState<Array<Job>>([])

  const { isFetching, fetchNextPage, refetch } = useInfiniteQuery(
    'JOBS_BY_COMPANY',
    () =>
      GetJobsByCompany(
        user.company_id,
        pickBy({
          start: page === 1 ? pagination.start : pagination.count * (page - 1),
          count: pagination.count,
          sort: searchParams.sortBy,
          [searchParams.searchBy]: searchParams.search || undefined
        })
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (data) {
          const filteredJobs = data.pages
            .map(item => item.entries)
            .reduce((acc, current) => {
              return [...acc, ...current]
            }, [])
          setPage(page + 1)
          setJobs(filteredJobs)
          setTotalentries(data.pages[0].total_entries)
        }
        setIsEmptyConfig(false)
      },
      onError: (error: AxiosError<any>) => {
        const errorMessage = get(error, 'response.data.errors.detail')
        if (error?.response?.status === 400 && !isEmptyConfig) {
          return setIsEmptyConfig(true)
        }
        onNotify({ message: errorMessage, severity: 'error' })
      },
      getNextPageParam: lastPage => {
        if (lastPage.page_number === lastPage.total_pages) {
          return
        }
        return lastPage.page_number + 1
      }
    }
  )

  useEffect(() => {
    setPage(1)
    setPagination(initialPagination)
    const refechData = setTimeout(() => {
      refetch()
    }, 100)
    return () => clearTimeout(refechData)
  }, [searchParams.sortBy, searchParams.search])

  return {
    jobs,
    isDisabled: jobs.length === totalEntries,
    totalEntries,
    isEmptyConfig,
    fetchNextPage,
    isLoading: isFetching
  }
}
