import { Typography, Box, Stack } from '@mui/material'
import { COLORS } from '../../../styles/colors'
export const BonusInformation = () => {
  return (
    <Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Bonus name
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          ArchDrafterBonus
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Bonus type
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          Gift card
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Bonus company
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          Starbucks
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Total amount
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          $50 USD
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Due date
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          Oct 10th, 22
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Conditions
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          The employee gets the gift card after their referral is hired. They
          have 30 days to redeem it.
        </Typography>
      </Stack>
    </Box>
  )
}
