import DisplaySettings from '@mui/icons-material/DisplaySettings'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography, Chip } from '@mui/material'

import { useNotification } from 'components/notification/useNotification'
import Paper from 'components/paper'
import { IntegrationSkeleton } from 'components/skeleton'
import ToggleSidebar from 'components/toggleSidebar'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import Connect from './connect'
import Disconnect from './disconnect'
import useDisconnect from './hooks/useDisconnect'
import useIntegrations, { atsLogo } from './hooks/useIntegrations'
import useConfigDetail from './settings/hooks/useConfigDetail'

export default function Integrations() {
  const { onNavigate } = useNavigation()
  const { onNotify } = useNotification()
  const { isEnable, atsEnable, isLoading: isLoadingConfig } = useConfigDetail()
  const { integrations, selectedIntegration } = useIntegrations(true)

  const {
    onConnect,
    onCloseSidebar,
    onOpenDialog,
    isLoading,
    openSidebar,
    hasConfigId
  } = useDisconnect()

  const handleRedirect = () => onNavigate('settings')

  const handleConnect = ({
    isEnable,
    atsId
  }: {
    isEnable: boolean
    atsId: number
  }) => {
    if (isEnable) {
      return onNotify({
        message:
          'Only one ATS should be connect at a time. Please disconnect the current ATS to enable a new one.',
        severity: 'info'
      })
    }
    onConnect({ isEnable, atsId })
  }

  return (
    <Box>
      <Stack flexDirection="row" margin="8px 24px" alignItems="center">
        <DisplaySettings />
        <Typography variant="h3" padding="8px 16px">
          Integration
        </Typography>
      </Stack>
      {isLoadingConfig ? (
        <IntegrationSkeleton />
      ) : (
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={4}>
          {integrations.map(({ id, description, name }) => {
            const isAtsEnable =
              isEnable &&
              atsEnable.toLocaleLowerCase() === name.toLocaleLowerCase()

            const loading =
              isLoading &&
              atsEnable.toLocaleLowerCase() === name.toLocaleLowerCase()

            return (
              <Stack
                key={id}
                component={Paper}
                direction="column"
                bgcolor={COLORS.white}
                justifyContent="space-between"
                width={{ xs: 'auto', sm: '380px' }}
              >
                <Stack flexDirection="column" justifyContent="space-around">
                  {hasConfigId && isAtsEnable && (
                    <Box display="flex" justifyContent="flex-end">
                      <Chip label="Connected" color="success" />
                    </Box>
                  )}
                  <Stack alignItems="center" flexDirection="row">
                    <Stack
                      marginRight="10%"
                      width={name === 'Bullhorn' ? '160px' : '130px'}
                    >
                      <img
                        alt="ats-image"
                        width="100%"
                        src={
                          atsLogo[name.toLowerCase() as keyof typeof atsLogo]
                        }
                      />
                    </Stack>
                    <Stack alignSelf="center">
                      <Typography fontSize="20px" fontWeight="500">
                        {name}
                      </Typography>
                      <Typography variant="body2" color={COLORS.gray2}>
                        {description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {hasConfigId && isAtsEnable ? (
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    marginTop={3}
                  >
                    <Button variant="outlined" onClick={handleRedirect}>
                      Settings
                    </Button>
                    <Button variant="honey" onClick={onOpenDialog}>
                      Disconnect
                    </Button>
                  </Stack>
                ) : (
                  <LoadingButton
                    fullWidth
                    disabled={loading}
                    loading={loading}
                    variant="contained"
                    sx={{ marginTop: 3 }}
                    onClick={() => handleConnect({ isEnable, atsId: id })}
                  >
                    {hasConfigId ? 'Connect' : 'Add Credencials'}
                  </LoadingButton>
                )}

                <Disconnect />

                <ToggleSidebar
                  isOpen={openSidebar}
                  headerTitle="Connect with your ATS"
                  toggleSidebar={onCloseSidebar}
                >
                  <Connect atsEnable={selectedIntegration?.name || ''} />
                </ToggleSidebar>
              </Stack>
            )
          })}
        </Stack>
      )}
    </Box>
  )
}
