import { UpdateCompanyConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'

import type { ConfigurationTosend, EntityName } from 'types/profile'
import { get, removeEmptyKeys } from 'utils'
import useSettings from './useSettings'

const cleanFields = (fields: Record<string, string>) => {
  const filteredFields = Object.entries(fields).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value ? [value] : '' }
  }, {})
  return removeEmptyKeys(filteredFields)
}

export default function useCompanyConfig(isUpdate?: boolean) {
  const { onNotify } = useNotification()
  const { dataBySteps, onToggleConfig } = useSettings()
  const { user } = useAuth()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: ConfigurationTosend) =>
      UpdateCompanyConfig({
        configId: user.config_id,
        companyId: user.company_id,
        dataTosend: data
      }),
    {
      onSuccess: () => {
        const closeSidebar = isUpdate ? 'editConfig' : 'addConfig'
        onToggleConfig(closeSidebar)
        onNotify({
          message: 'Successfully updated company',
          severity: 'success'
        })
        query.invalidateQueries('COMPANY_DETAIL_CONFIG')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onSubmit = (data: Record<string, string>) => {
    const filteredCandidate = Object.entries(data).reduce(
      (acc, [key, value]) => {
        return { ...acc, [key]: value ? [value] : '' }
      },
      {}
    )
    const dataTosend = {
      mapped_fields: {
        employee: cleanFields(dataBySteps.employee),
        job: cleanFields(dataBySteps.job),
        candidate: removeEmptyKeys(filteredCandidate)
      }
    }
    mutate({ config: dataTosend })
  }

  const onUpdateStep = (data: Record<string, string>, step: EntityName) => {
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value ? [value] : '' }
    }, {})

    const dataTosend = {
      mapped_fields: {
        ...dataBySteps,
        [step]: removeEmptyKeys(filteredData)
      }
    }
    mutate({ config: dataTosend })
  }
  return { onSubmit, onUpdateStep, isLoading }
}
