import { useState } from 'react'
import { Edit } from '@mui/icons-material'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import {
  Typography,
  Box,
  Stack,
  TextField as MUITextField,
  Button
} from '@mui/material'
import { Divider } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Select from 'components/Select'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { useToggle } from 'hooks/useToggle'
import { COLORS } from 'styles/colors'
import { PaymentStatus } from 'types/bonusPayments'
import { formatCurrency } from 'utils/index'
import { StatusDetail } from '../constants'
import useBonusPaymentsDetail from '../hooks/useBonusPaymentsDetail'
import useUpdateStatusPayment from '../hooks/useUpdateStatusPayment'
import { DialogEditChanges } from './DialogEditChanges'

const paymentStatusColor: { [key in PaymentStatus]: string } = {
  pending: COLORS.honey4,
  completed: COLORS.green2,
  active: COLORS.honey3,
  canceled: COLORS.red1,
  deleted: COLORS.red1,
  approved: COLORS.blue2,
  inProgress: COLORS.honey4,
  not_set: COLORS.gray2,
  reject: COLORS.red1,
  review: COLORS.honey4,
  paid: COLORS.green2
}
export default function PaymentDetail({
  refreshTable,
  onCloseSidebar
}: {
  refreshTable: () => void
  onCloseSidebar: () => void
}) {
  const { bonusPayment, isLoading, isFetching } = useBonusPaymentsDetail()

  const [open, setOpen] = useState(false)
  const { active, handleToggle } = useToggle()
  const initialValues = {
    bonusPayment
  }

  const handleClose = () => {
    refreshTable()
    handleToggle()
    onCloseSidebar()
  }

  const { onApply } = useUpdateStatusPayment(handleClose)

  if (isLoading || !bonusPayment?.entries || isFetching) {
    return <div>loading</div>
  }

  function EditIcon() {
    return <Edit color="primary" />
  }

  const onSubmit = (values: any) => {
    console.log(values)
  }

  return (
    <Box padding="24px 16px" height="calc(100vh - 168px)">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          onSubmit(values)
        }}
      >
        {({ values, setFieldValue }) => (
          <Stack component={Form} height="100%" justifyContent="space-between">
            <Box component={Paper} elevation={4}>
              <Typography variant="h3">Payment information</Typography>
              <Stack
                sx={{ backgroundColor: COLORS.gray9, width: '100%' }}
                marginTop={2}
                marginBottom={2}
              >
                <Stack
                  sx={{ backgroundColor: COLORS.gray9, width: '65%' }}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <WorkspacePremiumIcon
                    sx={{
                      fontSize: '70px',
                      height: '80px',
                      color:
                        paymentStatusColor[
                          bonusPayment.entries.status as PaymentStatus
                        ]
                    }}
                  />
                  <Stack alignItems="center">
                    <Typography
                      variant="body2"
                      color={
                        paymentStatusColor[
                          bonusPayment.entries.status as PaymentStatus
                        ]
                      }
                    >
                      {bonusPayment.entries.status[0].toUpperCase() +
                        bonusPayment.entries.status.slice(1)}
                    </Typography>
                    <Typography variant="h2">
                      {formatCurrency(Number(bonusPayment.entries.amount))}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Box>
                <Stack
                  marginBottom={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Bonus name
                  </Typography>
                  <Typography width="65%" variant="h5">
                    {bonusPayment.entries.job.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  marginBottom={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '90%' }}
                >
                  <Typography width="40%" variant="caption">
                    Payday
                  </Typography>
                  <Stack
                    sx={{
                      width: '60%',
                      borderStyle: 'solid',
                      borderWidth: 0.5,
                      borderColor: COLORS.gray2,
                      borderRadius: 2,
                      padding: 1
                    }}
                  >
                    <DesktopDatePicker
                      disabled={
                        bonusPayment.entries.status != 'review' ? true : false
                      }
                      components={{
                        OpenPickerIcon: EditIcon
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputFormat="MM/dd/yyyy"
                      value={values.bonusPayment.entries.date}
                      onChange={e => {
                        setFieldValue('bonusPayment.entries.date', e)
                      }}
                      renderInput={params => (
                        <MUITextField variant="standard" {...params} />
                      )}
                    />
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  marginBottom={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="35%" variant="caption">
                    Bonus type
                  </Typography>
                  <Typography width="65%" variant="h5">
                    {bonusPayment.entries.bonus_type}
                  </Typography>
                </Stack>

                {bonusPayment.entries.status !== 'not_set' && (
                  <Stack
                    sx={{ width: '90%' }}
                    marginBottom={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography width="40%" variant="caption">
                      Payment status
                    </Typography>
                    {bonusPayment.entries.status != 'review' ? (
                      <Typography width="65%" variant="h5">
                        {bonusPayment.entries.status}
                      </Typography>
                    ) : (
                      <Select
                        backgroundColor={
                          paymentStatusColor[
                            values.bonusPayment.entries.status as PaymentStatus
                          ]
                        }
                        defaultValue={bonusPayment.entries.status}
                        onChange={e => {
                          setFieldValue(
                            'bonusPayment.entries.status',
                            e.target.value
                          )
                        }}
                        width="60%"
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        variant="standard"
                        name="paymentStatus"
                        disableUnderline
                        options={StatusDetail.map(item => {
                          return {
                            value: item.id,
                            label: (
                              <Stack
                                sx={{
                                  height: '100%',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  textAlign="center"
                                  variant="body2"
                                  color={
                                    open &&
                                    !(
                                      item.id ===
                                      values.bonusPayment.entries.status
                                    )
                                      ? 'black'
                                      : 'white'
                                  }
                                >
                                  {item.label}
                                </Typography>
                              </Stack>
                            )
                          }
                        })}
                        sx={{
                          width: '100%',
                          borderRadius: '10px',
                          padding: '5px',
                          '&:after': {
                            borderBottomColor: 'white'
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white'
                          }
                        }}
                      />
                    )}
                  </Stack>
                )}
                <Divider
                  sx={{
                    marginBottom: 4,
                    marginTop: 4,
                    backgroundColor: COLORS.honey2
                  }}
                />
                <Typography variant="h3" marginBottom={2}>
                  Bonus for
                </Typography>
                <Stack direction="row" marginBottom={2} alignItems="center">
                  <Typography width="40%" variant="caption">
                    Name
                  </Typography>
                  <Stack flexDirection="row" sx={{ width: '65%' }}>
                    <Stack
                      width="40px"
                      height="40px"
                      sx={{
                        backgroundColor: COLORS.blue6,
                        justifyContent: 'center',
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 400,
                          fontStyle: 'normal'
                        }}
                        color="white"
                        textAlign="center"
                      >
                        {bonusPayment.entries.user.first_name[0]}
                      </Typography>
                    </Stack>
                    <Stack marginLeft={1}>
                      <Typography
                        variant="h5"
                        sx={{ textDecoration: 'underline' }}
                        color={COLORS.main}
                      >
                        {bonusPayment.entries.user.last_name}
                      </Typography>
                      <Typography variant="body2" textTransform="capitalize">
                        {bonusPayment.entries.type}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    marginBottom: 4,
                    marginTop: 4,
                    backgroundColor: COLORS.honey2
                  }}
                />
                <Typography variant="h3" marginBottom={2}>
                  Job information
                </Typography>
                <Stack
                  direction="row"
                  marginBottom={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="40%" variant="caption">
                    Job
                  </Typography>
                  <Typography
                    width="60%"
                    variant="h5"
                    sx={{ textDecoration: 'underline' }}
                    color={COLORS.main}
                  >
                    {bonusPayment.entries.job.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  marginBottom={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography width="40%" variant="caption">
                    Company
                  </Typography>
                  <Typography width="60%" variant="h5">
                    {bonusPayment.entries.company_name}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Stack marginTop={2}>
              <Button
                fullWidth
                variant="contained"
                //type="submit"
                onClick={handleToggle}
                disabled={bonusPayment.entries.status != 'review'}
              >
                Save
              </Button>
            </Stack>
            <DialogEditChanges
              values={values.bonusPayment}
              open={active}
              title={'Confirm Payment Approval'}
              subtitle={'Are you sure you want to approve this payment?'}
              content={
                'Once approved, if you need to cancel, you will have to do so through the Tango Dashboard'
              }
              onApply={onApply}
              close={handleToggle}
            />
          </Stack>
        )}
      </Formik>
    </Box>
  )
}
