import { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import { formatCurrency, formatDate } from 'utils'
import useJobBoardDetail from '../hooks/useJobBoardDetail'

export default function Description({ height }: { height: number }) {
  const { details } = useJobBoardDetail()
  const [viewMore, setViewMore] = useState(false)

  const salaryLow = formatCurrency(Number(details.salary_low || 0))
  const salaryHight = formatCurrency(Number(details.salary_high || 0))
  const salary = `${salaryLow} - ${salaryHight}`

  const descriptionHeight = `calc(${height}px - 13.1em)`

  return (
    <Box component={Paper}>
      <Typography variant="h3" marginBottom={3}>
        Job detail
      </Typography>
      <Stack direction="row">
        <Box width="50%">
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Source id:
            </Typography>
            <Typography variant="h6">{details.id_source}</Typography>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Company:
            </Typography>
            <Typography variant="h6">{details.company_name}</Typography>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Payment:
            </Typography>
            <Typography variant="h6">{salary}</Typography>
          </Stack>
        </Box>
        <Box width="50%">
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Open positions:
            </Typography>
            <Typography variant="h6">{details.open_positions || 0}</Typography>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Job type:
            </Typography>
            <Typography variant="h6">{details.type || '-'}</Typography>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} marginBottom={1}>
            <Typography variant="caption" marginRight={1}>
              Date created:
            </Typography>
            <Typography variant="h6">
              {formatDate(details.creation_date, 'MMM dd, yyy')}
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Box>
        <Box
          marginTop={4}
          height={{ xs: viewMore ? 'auto' : '200px', md: descriptionHeight }}
          sx={{
            overflow: 'auto',
            ...StylesScrollBar
          }}
        >
          <Typography variant="h6" color={COLORS.gray2} marginBottom={1}>
            Description
          </Typography>
          <Box dangerouslySetInnerHTML={{ __html: details.description }} />
        </Box>
        <Box
          variant="text"
          marginRight={2}
          component={Button}
          display={{ xs: 'block', md: 'none' }}
          onClick={() => setViewMore(!viewMore)}
        >
          {viewMore ? 'View Less' : 'View More'}
        </Box>
      </Box>
    </Box>
  )
}
