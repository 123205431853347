import { useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get } from 'utils'
import { FIRST_DAY } from 'utils/catalogs'
import { formatDate } from 'utils/index'
import { GetJobsGraph } from '../../../api/index'
import { JobsGraph } from '../Types/DashboardTypes'

export default function useJobsGraph() {
  const { user } = useAuth()

  const date = Date.now()

  const { onNotify } = useNotification()
  const [dataJobs, setDataJobs] = useState<JobsGraph>({} as JobsGraph)

  const [initialDateJobs, setInitialDateJobs] = useState<any>(
    formatDate(FIRST_DAY, 'yyyy-MM-dd')
  )

  const [endDateJobs, setEndDateJobs] = useState<any>(
    formatDate(new Date(date), 'yyyy-MM-dd')
  )

  const { isLoading, refetch } = useQuery(
    'GET_JOBS_BY_DATE',
    () =>
      GetJobsGraph(
        user.id,
        formatDate(new Date(initialDateJobs), 'yyyy-MM-dd'),
        formatDate(new Date(endDateJobs), 'yyyy-MM-dd')
      ),
    {
      onSuccess: data => {
        setDataJobs(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onGetDataJobs = () => refetch()

  return {
    isLoading: isLoading,
    dataJobs,
    initialDateJobs,
    endDateJobs,
    open,
    setInitialDateJobs,
    setEndDateJobs,
    onGetDataJobs
  }
}
