import Redeem from '@mui/icons-material/Redeem'
import { Box, Stack, Typography } from '@mui/material'
import { EmptyPage } from 'components/emtpyPage'
import { COLORS } from 'styles/colors'
import useBonusPayments from './hooks/useBonusPayments'
import Positions from './positions'

export default function BonusPayments() {
  const { data, isEmpty, isLoading } = useBonusPayments()
  return (
    <Box paddingX={2}>
      <Stack
        marginTop={3}
        marginBottom={3}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent={{ xs: 'flex-start' }}
      >
        <Stack
          marginBottom={{ xs: 2, sm: 0 }}
          width={{ xs: '95%', sm: '72%', md: 'auto' }}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'baseline' }}
        >
          <Stack direction="row" alignItems="center">
            <Redeem />
            <Typography variant="h2" color={COLORS.gray1} paddingX={2}>
              Available positions({data?.entries.length})
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {isEmpty ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 220px)"
        >
          <EmptyPage
            logo="/images/magnify.png"
            boxProps={{ marginTop: 2 }}
            message="This job has no bonus payments yet"
          >
            <Typography variant="caption">
              Add bonus configurations to start earning bonus
            </Typography>
          </EmptyPage>
        </Stack>
      ) : (
        <Positions positions={data} loadingPositions={isLoading} />
      )}
    </Box>
  )
}
