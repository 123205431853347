import { Alert, AlertProps } from '@mui/material'
import { useSnackbar, SnackbarProps as NotistackProps } from 'notistack'

type Severity = AlertProps['severity']
type UseNotificationProps = {
  message: string | React.ReactNode
  severity: Severity
  options?: NotistackProps
}

export function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const onNotify = ({ message, severity, options }: UseNotificationProps) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content: (
        <Alert
          elevation={6}
          severity={severity}
          onClose={() => closeSnackbar()}
        >
          {message}
        </Alert>
      ),
      ...options
    })
  }

  return {
    onNotify
  }
}
