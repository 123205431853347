import { hookstate, useHookstate } from '@hookstate/core'
import { DeleteBonusConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'
import useBonusTable from './useBonusTable'

interface DeleteBonusConfigParameters {
  bonusId: number
  configId: number
}

const initialState = hookstate({
  isLoading: false
})

export default function useDeleteBonusConfig() {
  const { isLoading } = useHookstate(initialState)
  const { onToggleTemplate } = useBonusTable()
  const { onNotify } = useNotification()
  const { mutate } = useMutation(
    (data: DeleteBonusConfigParameters) =>
      DeleteBonusConfig(data.bonusId, data.configId),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Bonus config deleted successfully'
        })
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )
  const onDeleteBonusConfig = async (bonusId: number, configId: number) => {
    isLoading.set(true)
    mutate({ bonusId, configId })
  }

  return {
    isLoading: isLoading.value,
    onDeleteBonusConfig,
    onToggleTemplate
  }
}
