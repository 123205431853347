import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Stack, Typography } from '@mui/material'
import EditInformation from 'app/profile/personalInfo/detail/editInformation'
import { PersonalIfoSkeleton } from 'components/skeleton'
import { COLORS } from 'styles/colors'
import { formatDate } from 'utils'
import useShowUser from './useShowUser'

export default function Detail() {
  const { edit, isLoading, userInformation, onHandleOpenEdit } = useShowUser()
  return (
    <Box
      paddingLeft="12px"
      paddingBottom="15px"
      marginBottom="10px"
      borderBottom={`1px solid ${COLORS.gray8}`}
    >
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        marginBottom="25px"
      >
        {edit ? (
          <EditInformation user={userInformation} />
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={2}
              borderBottom={`1px solid ${COLORS.gray8}`}
            >
              <Typography
                fontWeight="500"
                fontSize={{ xs: '18px', sm: '20px' }}
                paddingLeft={{ xs: 0, sm: '12px' }}
              >
                General Information
              </Typography>
              <Button
                onClick={onHandleOpenEdit}
                startIcon={<EditIcon htmlColor={COLORS.main} />}
                variant="text"
                color="primary"
              >
                Edit
              </Button>
            </Stack>
            {isLoading ? (
              <PersonalIfoSkeleton />
            ) : (
              <>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                  paddingTop={2}
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Avatar
                  </Typography>
                  <Box width={{ xs: '55%', lg: '70%' }}>
                    <img
                      alt="Logo"
                      src={userInformation.avatar || '/images/user-circle.svg'}
                      style={{
                        maxHeight: '80px',
                        maxWidth: '80%',
                        borderRadius: '50%'
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                  paddingTop={2}
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Name
                  </Typography>
                  <Typography variant="h5" width={{ xs: '55%', lg: '70%' }}>
                    {userInformation.first_name}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Last name
                  </Typography>
                  <Typography variant="h5" width={{ xs: '55%', lg: '70%' }}>
                    {userInformation.last_name || '-'}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Birthday
                  </Typography>
                  <Typography variant="h5" width={{ xs: '55%', lg: '70%' }}>
                    {formatDate(userInformation.birthday, 'MM/dd/yyyy')}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Email
                  </Typography>
                  <Typography
                    variant="h5"
                    overflow="hidden"
                    color={COLORS.main}
                    textOverflow="ellipsis"
                    width={{ xs: '55%', lg: '70%' }}
                  >
                    {userInformation.email}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Phone number
                  </Typography>
                  <Typography
                    variant="h5"
                    width={{ xs: '55%', lg: '70%' }}
                    color={COLORS.gray2}
                  >
                    {userInformation.phone}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Type of user
                  </Typography>
                  <Typography
                    variant="h5"
                    width={{ xs: '55%', lg: '70%' }}
                    color={COLORS.gray2}
                  >
                    {userInformation.type}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Role
                  </Typography>
                  <Typography
                    variant="h5"
                    width={{ xs: '55%', lg: '70%' }}
                    color={COLORS.gray2}
                  >
                    {userInformation.role || '-'}
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  marginBottom="18px"
                  padding="4px"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Last login
                  </Typography>
                  <Typography
                    variant="h5"
                    width={{ xs: '55%', lg: '70%' }}
                    color={COLORS.gray2}
                  >
                    {formatDate(
                      userInformation.last_login,
                      'MMMM dd, yyyy HH:mm:ss'
                    )}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}
