import { Box, Stack, Checkbox, FormControlLabel, Button } from '@mui/material'
import { ROLES } from 'app/employees/constants'
import useFilterEmployee from 'app/employees/hooks/useFilterEmployee'
import type { UseFilter } from 'types/table'

export default function Role() {
  const { filterEmployee, onChangeValue, onClearFilters, onAddUrlFilters } =
    useFilterEmployee()

  return (
    <Box padding={2}>
      {ROLES.map(({ id, label }) => {
        const value =
          filterEmployee.role.find(item => item.id === id) || ({} as UseFilter)
        return (
          <Stack key={id} direction="row" marginBottom={2}>
            <FormControlLabel
              onChange={(_, checked) =>
                onChangeValue({
                  filter: { id, label, checked },
                  name: 'role'
                })
              }
              checked={(value.checked as boolean) || false}
              label={label}
              control={<Checkbox />}
              componentsProps={{
                typography: {
                  variant: 'h5'
                }
              }}
            />
          </Stack>
        )
      })}
      <Stack direction="row" spacing={2}>
        <Button fullWidth onClick={() => onClearFilters('role')}>
          Clear
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onAddUrlFilters('role')}
        >
          Apply
        </Button>
      </Stack>
    </Box>
  )
}
