import { useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get } from 'utils'
import { FIRST_DAY } from 'utils/catalogs'
import { formatDate } from 'utils/index'
import { GetReferralFunnelGraph } from '../../../api/index'
import { ReferralFunnelGraph } from '../Types/DashboardTypes'

export default function useReferralFunnelGraph() {
  const { user } = useAuth()
  const date = Date.now()

  const { onNotify } = useNotification()
  const [dataFunnel, setDataFunnel] = useState<ReferralFunnelGraph>(
    {} as ReferralFunnelGraph
  )
  const [initialDateFunnel, setInitialDateFunnel] = useState<any>(
    formatDate(new Date(FIRST_DAY), 'yyyy-MM-dd')
  )
  const [endDateFunnel, setEndDateFunnel] = useState<any>(
    formatDate(new Date(date), 'yyyy-MM-dd')
  )

  const { isLoading, refetch } = useQuery(
    'GET_REFERRAL_FUNNEL_BY_DATE',
    () =>
      GetReferralFunnelGraph(
        user.id,
        formatDate(new Date(initialDateFunnel), 'yyyy-MM-dd'),
        formatDate(new Date(endDateFunnel), 'yyyy-MM-dd')
      ),
    {
      onSuccess: data => {
        setDataFunnel(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onGetDataFunnel = () => refetch()

  return {
    isLoading: isLoading,
    dataFunnel,
    initialDateFunnel,
    endDateFunnel,
    open,
    setInitialDateFunnel,
    setEndDateFunnel,
    onGetDataFunnel
    //onToggleTemplate,
    //isSent
  }
}
