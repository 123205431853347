import { useEffect, useState } from 'react'
import { ActivePaymentMethod } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { PaymentMethod } from 'types/profile'
import { get } from 'utils'

export default function useActiveCard(paymentMethods: Array<PaymentMethod>) {
  const { onNotify } = useNotification()
  const [checked, setChecked] = useState('')
  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { default: string; cardId: number }) => ActivePaymentMethod(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Updated as default method successfully'
        })
        query.invalidateQueries('PAYMENT_METHODS')
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail.errors[0].message'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onChangeValue = (name: string) => {
    const cardId = name.split('-')[1]
    const method = paymentMethods.filter(item => item.id === Number(cardId))

    if (method[0].default) {
      return onNotify({
        severity: 'info',
        message: 'There should be one method by default.'
      })
    }

    const checkedValue = name === checked ? '' : name
    setChecked(checkedValue)
    onSubmit(name)
  }

  useEffect(() => {
    if (paymentMethods.length) {
      const checkedFiltered = paymentMethods.filter(item => item.default)
      if (checkedFiltered.length > 0 && checkedFiltered[0].default) {
        setChecked(`switch-${checkedFiltered[0].id}`)
      }
    }
  }, [paymentMethods.length])

  const onSubmit = (name: string) => {
    const cardId = name.split('-')[1]
    const method = paymentMethods.filter(item => item.id === Number(cardId))
    mutate({
      default: method[0].default ? 'false' : 'true',
      cardId: Number(cardId)
    })
  }

  return { onChangeValue, onSubmit, checked, isLoading }
}
