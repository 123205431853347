import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { BulkUpdateEmployeeTemplates } from 'api/index'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'
import {
  RoleEmployeesTemplateBulkActions,
  StatusEmployeesTemplateBulkActions
} from '../Types/Employees'
import useEmployees from './useEmployees'

interface UpdateStatusTemplateParameters {
  data: StatusEmployeesTemplateBulkActions | RoleEmployeesTemplateBulkActions
}
const initialState = hookstate({
  isLoading: false
})

export default function useUpdateStatus() {
  const { getEmployeeTemplate } = useEmployees()
  const [selectedEmployeesTemplate, setSelectedEmployeesTemplate] =
    useState<any>([])
  const [
    selectedEmployeesPendingTemplate,
    setSelectedEmployeesPendingTemplate
  ] = useState<any>([])
  const { isLoading } = useHookstate(initialState)
  const { onNotify } = useNotification()

  const onApply = async (
    params:
      | StatusEmployeesTemplateBulkActions
      | RoleEmployeesTemplateBulkActions
  ) => {
    if (selectedEmployeesPendingTemplate.length > 0) {
      onNotify({
        severity: 'error',
        message:
          'Status of selected users can’t be changed, pending  registration in beegopp'
      })
    }
    isLoading.set(true)
    mutate({ data: params })
  }
  const { mutate } = useMutation(
    (data: UpdateStatusTemplateParameters) =>
      BulkUpdateEmployeeTemplates(data.data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Changes have been saved'
        })
        getEmployeeTemplate()
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  return {
    onApply,
    isLoading: isLoading.value,
    setSelectedEmployeesTemplate,
    selectedEmployeesTemplate,
    setSelectedEmployeesPendingTemplate
  }
}
