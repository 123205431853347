import { Notifications } from '@mui/icons-material'
import { Box } from '@mui/material'
import useNotifications from 'app/notifications/hooks/useNotifications'
import useToggleNotification from 'app/notifications/hooks/useToggleNotification'
import Hamburger from 'components/icons/Hamburger'
import { COLORS } from 'styles/colors'
import { TopbarContainer, TopbarUserName } from './styles'

export default function Topbar({ onClick }: { onClick: () => void }) {
  const { onOpenNotification, hasNotification } = useToggleNotification()
  useNotifications(true)

  return (
    <TopbarContainer>
      <Hamburger onClick={onClick} />
      <TopbarUserName>
        <Box
          component="div"
          position="relative"
          sx={{ cursor: 'pointer' }}
          onClick={onOpenNotification}
        >
          <Notifications htmlColor="#0030FF" />
          {hasNotification && (
            <Box
              top={4}
              right={0}
              width="9px"
              height="9px"
              position="absolute"
              borderRadius="50%"
              bgcolor={COLORS.red1}
            />
          )}
        </Box>
      </TopbarUserName>
    </TopbarContainer>
  )
}
