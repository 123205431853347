export type KeyTypeCard =
  | 'visa_electron'
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'diners_cb'
  | 'diners_int'
  | 'jcb'
  | 'laser'
  | 'maestro'
  | 'discover'

type Types = {
  name: string
  regx: RegExp
  length: Array<number>
  type: KeyTypeCard
}
export function cardType(cardNumber: number) {
  const card = cardAbstract(String(cardNumber))
  return card || ({} as Types)
}

const cardTypes: { [key in KeyTypeCard]: Types } = {
  visa_electron: {
    name: 'Visa Electron',
    regx: /^(4026|417500|4508|4844|491(3|7))/,
    length: [16],
    type: 'visa_electron'
  },
  visa: {
    name: 'Visa',
    regx: /^4/,
    length: [16],
    type: 'visa'
  },
  mastercard: {
    name: 'Mastercard',
    regx: /^5[1-5]/,
    length: [16],
    type: 'mastercard'
  },
  amex: {
    name: 'American Express',
    regx: /^3[47]/,
    length: [15],
    type: 'amex'
  },
  diners_cb: {
    name: 'Diners Club Carte Blanche',
    regx: /^30[0-5]/,
    length: [14],
    type: 'diners_cb'
  },
  diners_int: {
    name: 'Diners Club International',
    regx: /^36/,
    length: [14],
    type: 'diners_int'
  },
  jcb: {
    name: 'JCB',
    regx: /^35(2[89]|[3-8][0-9])/,
    length: [16],
    type: 'jcb'
  },
  laser: {
    name: 'Laser',
    regx: /^(6304|670[69]|6771)/,
    length: [16, 17, 18, 19],
    type: 'laser'
  },
  maestro: {
    name: 'Maestro',
    regx: /^(5018|5020|5038|6304|6759|676[1-3])/,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    type: 'maestro'
  },
  discover: {
    name: 'Discover',
    regx: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    length: [16],
    type: 'discover'
  }
}

export function cardAbstract(cardNumber: string) {
  for (const cardId in cardTypes) {
    const card = cardTypes[cardId as KeyTypeCard]
    if (cardNumber.match(card.regx)) {
      return card
    }
  }
  return {} as Types
}
