import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Stack, Typography } from '@mui/material'
import useShowUser from 'app/profile/personalInfo/detail/useShowUser'
import { COLORS } from 'styles/colors'
import AlertDialog from '../dialogPassword'
import useChangePassword from '../useChangePassword'

export default function ResetPassword({ onEdit }: { onEdit: () => void }) {
  const { open, onHandleOpen, onHandleClose, onHandleCloseDialog } =
    useChangePassword()
  const { userInformation } = useShowUser()
  return (
    <Box paddingLeft={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={`1px solid ${COLORS.gray8}`}
      >
        <Typography
          fontSize={{ xs: '18px', sm: '20px' }}
          fontWeight="500"
          paddingBottom={2}
        >
          Password
        </Typography>
        <Button
          variant="text"
          startIcon={<EditIcon htmlColor={COLORS.main} />}
          onClick={onEdit}
          sx={{
            paddingBottom: 2
          }}
        >
          Reset Password
        </Button>
      </Stack>
      <Stack
        alignItems="center"
        flexDirection="row"
        marginTop={2}
        paddingBottom="15px"
        borderBottom={`1px solid ${COLORS.gray8}`}
        justifyContent="space-between"
      >
        <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
          Password
        </Typography>
        <Typography
          variant="h5"
          width={{ xs: '100%', lg: '70%' }}
          color={COLORS.gray2}
        >
          ********
        </Typography>
      </Stack>
      <Stack
        paddingTop="15px"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', lg: 'center' }}
      >
        <Typography variant="h5" color={COLORS.gray2}>
          Did you forget your password?
        </Typography>
        <Button variant="text" onClick={onHandleOpen}>
          Click here
        </Button>
      </Stack>
      <AlertDialog
        closeDialog={onHandleCloseDialog}
        open={open}
        handleClose={onHandleClose}
        userEmail={userInformation.email}
      />
    </Box>
  )
}
