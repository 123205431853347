import NotificationIcon from '@mui/icons-material/Notifications'
import PaymentIcon from '@mui/icons-material/Star'
import StarIcon from '@mui/icons-material/Star'
import WorkIcon from '@mui/icons-material/Work'
import { Stack, Box, Typography } from '@mui/material'
import { COLORS } from 'styles/colors'
import type { NotificationData, NotificationType } from 'types/notification'
import { timeAgo } from 'utils'

export default function Notification({
  onClick,
  notifications
}: {
  onClick: (arg: NotificationData) => void
  notifications: Array<NotificationData>
}) {
  return (
    <Box>
      {notifications.map(notification => (
        <Stack
          padding={2}
          direction="row"
          key={notification.id}
          sx={{ cursor: 'pointer' }}
          justifyContent="space-between"
          borderBottom={`0.5px solid #efeff4`}
          onClick={
            notification.viewed ? undefined : () => onClick(notification)
          }
        >
          <Stack direction="row">
            {icons(COLORS.main)[notification.content.type || 'default']}
            <Box marginLeft={1}>
              <Typography
                variant="h4"
                fontSize={20}
                marginBottom={1}
                color={COLORS.main}
              >
                {notification.content.title}
              </Typography>
              <Typography variant="body2" color={COLORS.gray1} marginBottom={1}>
                {notification.content.message}
              </Typography>
              <Typography variant="caption" color={COLORS.gray1}>
                {timeAgo(notification.content.date)}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      ))}
    </Box>
  )
}

const icons = (
  color?: string
): {
  [key in NotificationType]: React.ReactNode
} => {
  return {
    default: <NotificationIcon htmlColor={color} />,
    referral: <StarIcon htmlColor={color} />,
    job: <WorkIcon htmlColor={color} />,
    payment: <PaymentIcon htmlColor={color} />
  }
}
