import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Typography, Box, Stack, Button, Divider } from '@mui/material'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { formatDate } from 'utils'
import { COLORS } from '../../../../styles/colors'
import EditBonusTemplate from '../editBonusTemplate'

import useBonusTemplateDetails from '../hooks/useBonusTemplateDetails'
export default function Index() {
  const [edit, setEdit] = useState(false)
  const { bonusTemplate, isLoading, isFetching } = useBonusTemplateDetails()
  const initialValues = {
    bonusTemplate
  }
  return (
    <>
      {edit ? (
        <EditBonusTemplate setEdit={setEdit} BonusTemplate={bonusTemplate} />
      ) : isLoading || isFetching || !bonusTemplate.entries ? (
        <div>loading</div>
      ) : (
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={values => {
              console.log('>>>>>>>>>>>values', values)
            }}
          >
            {({ values }) => {
              return (
                <Stack
                  component={Form}
                  justifyContent="space-between"
                  height="calc(100vh - 168px)"
                  padding="32px 16px 0 16px"
                >
                  <Box component={Paper} elevation={4} sx={{ padding: '16px' }}>
                    <Stack
                      padding="6px"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h3">General information</Typography>
                      <Button
                        onClick={() => setEdit(true)}
                        startIcon={<EditIcon htmlColor={COLORS.main} />}
                      >
                        Edit
                      </Button>
                    </Stack>

                    <Box padding="6px">
                      <Stack
                        marginBottom={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography width="50%" variant="caption">
                          Creation Date
                        </Typography>
                        <Typography width="50%" variant="h5">
                          {values.bonusTemplate.entries.inserted_at &&
                            formatDate(
                              values.bonusTemplate.entries.inserted_at,
                              "LLL do', 'yy"
                            )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        marginBottom={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography width="50%" variant="caption">
                          User create
                        </Typography>

                        <Typography width="50%" variant="h5">
                          {values.bonusTemplate.entries.user_first_name}
                        </Typography>
                      </Stack>
                      <Divider
                        sx={{
                          marginBottom: 4,
                          marginTop: 4,
                          backgroundColor: COLORS.honey2
                        }}
                      />
                      {/* Bonus For Employee */}
                      {values.bonusTemplate.entries.is_for_referrer && (
                        <>
                          <Typography variant="body2" marginBottom={2}>
                            Bonus for employee
                          </Typography>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              Bonus type
                            </Typography>
                            <Typography width="50%" variant="h5">
                              {values.bonusTemplate.entries.type}
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              User type
                            </Typography>
                            <Typography width="50%" variant="h5">
                              Employees
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              Total amount
                            </Typography>
                            <Typography width="50%" variant="h5">
                              $
                              {
                                values.bonusTemplate.entries
                                  .total_amount_referrer
                              }
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography width="50%" variant="caption">
                              Conditions
                            </Typography>
                            <Stack width="50%">
                              {values.bonusTemplate.entries.bonus_config?.map(
                                item => {
                                  if (item.type === 'referrer') {
                                    return (
                                      <Typography
                                        variant="h5"
                                        width="100%"
                                        key={item.id}
                                      >
                                        {`$${item.amount} USD after ${item.days} days`}
                                      </Typography>
                                    )
                                  }
                                }
                              )}
                            </Stack>
                          </Stack>
                        </>
                      )}
                      {values.bonusTemplate.entries.is_for_referrer &&
                        values.bonusTemplate.entries.is_for_referral && (
                          <Divider
                            sx={{
                              marginBottom: 4,
                              marginTop: 4,
                              backgroundColor: COLORS.honey2
                            }}
                          />
                        )}
                      {values.bonusTemplate.entries.is_for_referral && (
                        <>
                          <Typography variant="body2" marginBottom={2}>
                            Bonus for referral
                          </Typography>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              Bonus type
                            </Typography>
                            <Typography width="50%" variant="h5">
                              {values.bonusTemplate.entries.type}
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              User type
                            </Typography>
                            <Typography width="50%" variant="h5">
                              Referrals
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography width="50%" variant="caption">
                              Total amount
                            </Typography>
                            <Typography width="50%" variant="h5">
                              $
                              {
                                values.bonusTemplate.entries
                                  .total_amount_referral
                              }
                            </Typography>
                          </Stack>
                          <Stack
                            marginBottom={2}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography width="50%" variant="caption">
                              Conditions
                            </Typography>
                            <Stack width="50%">
                              {values.bonusTemplate.entries.bonus_config?.map(
                                item => {
                                  if (item.type === 'referral') {
                                    return (
                                      <Typography
                                        variant="h5"
                                        width="100%"
                                        key={item.id}
                                      >
                                        {`$${item.amount} USD after ${item.days} days`}
                                      </Typography>
                                    )
                                  }
                                }
                              )}
                            </Stack>
                          </Stack>
                        </>
                      )}
                    </Box>
                  </Box>
                </Stack>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )
}
