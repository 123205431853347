import { ThemeProvider } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Bonus from 'app/bonus'
import Payments from 'app/bonus/payments'
import Templates from 'app/bonus/templates'
import Companies from 'app/companies'
import CompanyPreview from 'app/companyPreview'
import JobApplication from 'app/companyPreview/jobApplication'
import JobApplicationStep2 from 'app/companyPreview/jobApplication-step2'
import JobApplicationStep3 from 'app/companyPreview/jobApplication-step3'
import JobBoardDetail from 'app/companyPreview/jobBoardDetail'
import EducationDetails from 'app/educationDetails'
import Employee from 'app/employees'
import EnterSmsCode from 'app/enterSmsCode'
import ErrorMessage from 'app/error404'
import Jobs from 'app/jobs'
import BoardConfig from 'app/jobs/boardConfig'
import BoardJob from 'app/jobs/boardJob'
import BoardJobDetail from 'app/jobs/boardJob/detail'
import Import from 'app/jobs/import'
import Layout from 'app/layout'
import Logout from 'app/logout'
import Message from 'app/message'
import NewPassword from 'app/newPassword'
import PasswordChanged from 'app/passwordChanged'
import PotencialCustomer from 'app/potencialCustomer'
import Profile from 'app/profile'
import Integrations from 'app/profile/integrations'
import MyCompany from 'app/profile/myCompany'
import PersonalInformation from 'app/profile/personalInfo'
import Referrals from 'app/referrals'
import ResetPassword from 'app/resetPassword'
import SignUp from 'app/signUp'
import Signin from 'app/signin'
import VerifiedEmail from 'app/verifiedEmail'
import Wallet from 'app/wallet'
import { AuthProvider } from 'context/AuthContext'
import useAuth from 'hooks/useAuth'
import { SnackbarProvider } from 'notistack'
import { QueryClientProvider } from 'react-query'
import { Routes as Router, Route, Navigate } from 'react-router-dom'
import { theme } from 'styles/theme'
import { GlobalStyles } from 'styles/utils'
import { queryClient } from 'utils/queryConfig'
import CheckEmail from './checkEmail'
import { Dashboard } from './dashboard'
import { EmployeeDetail } from './employees/EmployeeDetail/index'
import EnterEmailCode from './enterEmailCode'
import Help from './help'
import Settings from './profile/integrations/settings'
import UserRegisters from './userRegisters'

const ProtectedRoutes = () => {
  const { session } = useAuth()
  return session?.token ? <Layout /> : <Navigate to="/login" replace />
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <SnackbarProvider maxSnack={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <Router>
                <Route path="*" element={<ErrorMessage />} />
                <Route path="login" element={<Signin />} />
                <Route path="logout" element={<Logout />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="/register" element={<UserRegisters />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/check-email" element={<CheckEmail />} />
                <Route
                  path="/reset/password/:token"
                  element={<VerifiedEmail />}
                />
                <Route path="/enter-smscode" element={<EnterSmsCode />} />
                <Route path="/enter-emailcode" element={<EnterEmailCode />} />
                <Route path="/new-password" element={<NewPassword />} />
                <Route path="/new-password/:token" element={<NewPassword />} />
                <Route path="/password-changed" element={<PasswordChanged />} />
                <Route
                  path="/potencial-customer"
                  element={<PotencialCustomer />}
                />
                <Route path="/message" element={<Message />} />
                <Route
                  path="/company/:id/jobs/:employeeId"
                  element={<CompanyPreview />}
                />
                <Route
                  path="/company/:id/jobs/:jobId/:employeeId"
                  element={<JobBoardDetail />}
                />
                <Route
                  path="/company/:id/jobs/:jobId/:employeeId/jobapplication-step1"
                  element={<JobApplication />}
                />
                <Route
                  path="company/:id/jobs/:jobId/:employeeId/jobapplication-step2"
                  element={<JobApplicationStep2 />}
                />
                <Route
                  path="company/:id/jobs/:jobId/:employeeId/jobapplication-step3"
                  element={<JobApplicationStep3 />}
                />

                <Route element={<ProtectedRoutes />}>
                  <Route path="/" element={<Wallet />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/wallet" element={<Wallet />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/jobs" element={<Jobs />}>
                    <Route path="import" element={<Import />} />
                    <Route path="boardconfig" element={<BoardConfig />} />
                    <Route path="jobboard" element={<BoardJob />} />
                    <Route path="jobboard/:id" element={<BoardJobDetail />} />
                    <Route
                      path="boardconfig/:id"
                      element={<EducationDetails />}
                    />
                  </Route>
                  <Route path="/bonus" element={<Bonus />}>
                    <Route path="payments" element={<Payments />} />
                    <Route path="templates" element={<Templates />} />
                  </Route>
                  <Route path="/referrals" element={<Referrals />}></Route>
                  <Route path="/referrals/:id" element={<EducationDetails />} />
                  <Route path="/employees" element={<Employee />} />
                  <Route path="/employees/:id" element={<EmployeeDetail />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/profile" element={<Profile />}>
                    <Route path="personal" element={<PersonalInformation />} />
                    <Route path="company" element={<MyCompany />} />
                    <Route path="integrations" element={<Integrations />} />
                    <Route
                      path="integrations/settings"
                      element={<Settings />}
                    />
                  </Route>
                </Route>
              </Router>
            </AuthProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
