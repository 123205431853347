import { Fragment } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { PositionsSkeleton } from 'components/skeleton'
import { COLORS } from 'styles/colors'
import { PositionProps } from 'types/positions'
import { formatCurrency } from 'utils'
import useClearPosition from '../hooks/useClearPosition'

export default function Positions({
  positions,
  loadingPositions
}: {
  loadingPositions: boolean
  positions?: PositionProps
}) {
  const {
    isOpendialog,
    isLoading,
    onCloseDialog,
    onClearPosition,
    onSelectedPosition
  } = useClearPosition()

  const filteredPayments = positions?.entries.map(item => {
    return {
      position: item.position,
      referrer: item.group.filter(group => group.type === 'referrer'),
      referral: item.group.filter(group => group.type === 'referral')
    }
  })

  return (
    <Box paddingBottom={2}>
      {loadingPositions ? (
        <PositionsSkeleton />
      ) : (
        <Fragment>
          {filteredPayments?.map(payment => {
            const status = payment.referrer[0].status
            return (
              <Box
                key={payment.position}
                marginBottom={2}
                component={Paper}
                paddingTop={3}
                position="relative"
              >
                <Typography
                  variant="body2"
                  color={COLORS.gray1}
                  marginBottom={1}
                >
                  Referrer
                </Typography>
                {payment.referrer.map(bonus => (
                  <Fragment>
                    <Stack
                      top={15}
                      left={0}
                      right={0}
                      paddingX={2}
                      direction="row"
                      position="absolute"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Tooltip
                        title="Release bonus"
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: COLORS.red2
                            }
                          }
                        }}
                      >
                        <IconButton
                          onClick={() => onSelectedPosition(payment.position)}
                        >
                          <DeleteIcon htmlColor={COLORS.gray2} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        width="40%"
                        variant="body2"
                        fontWeight="500"
                        marginBottom={1}
                        color={COLORS.gray1}
                      >
                        {formatCurrency(bonus.amount || 0)} USD
                      </Typography>
                      <Typography
                        width="40%"
                        variant="body2"
                        fontWeight="500"
                        color={COLORS.gray1}
                      >
                        Days {bonus.days}
                      </Typography>
                    </Stack>
                  </Fragment>
                ))}
                <Typography variant="body2" color={COLORS.gray1} marginY={1}>
                  Referral
                </Typography>
                {payment.referral.map(bonus => (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      width="40%"
                      variant="body2"
                      fontWeight="500"
                      marginBottom={1}
                      color={COLORS.gray1}
                    >
                      {formatCurrency(bonus.amount || 0)} USD
                    </Typography>
                    <Typography
                      variant="body2"
                      color={COLORS.gray1}
                      fontWeight="500"
                      width="40%"
                    >
                      Days {bonus.days}
                    </Typography>
                  </Stack>
                ))}
                <Stack direction="row" marginTop={2} alignItems="center">
                  <Typography variant="body2" marginRight={2}>
                    Status:
                  </Typography>
                  <Chip
                    label={status == 'disabled' ? 'Not used' : status}
                    color="success"
                    sx={{
                      height: '24px',
                      fontSize: '14px',
                      maxWidth: '20%'
                    }}
                  />
                </Stack>
              </Box>
            )
          })}
        </Fragment>
      )}
      <Dialog open={isOpendialog}>
        <DialogTitle
          fontSize={20}
          color={COLORS.gray1}
          fontWeight={500}
          textAlign="center"
          marginBottom={5}
        >
          Confirm delete position
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color={COLORS.red2} marginBottom={3}>
            This action cannot be undone. The money used for this position will
            return to your wallet to be used in other positions.
          </Typography>
          <Typography variant="body2" color={COLORS.gray1} marginBottom={2}>
            Are you sure you want to delete this bonus position?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            margin: 2
          }}
        >
          <Button fullWidth onClick={onCloseDialog}>
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            variant="contained"
            loading={isLoading}
            disabled={isLoading}
            onClick={onClearPosition}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
