import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  TextField as MUITextField,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Formik, Form } from 'formik'
import { validationSchema, initialValues } from './useDownloadCsv'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalCsv({
  isOpen,
  onClose,
  onSubmit,
  isLoading
}: {
  isOpen: boolean
  onClose: () => void
  onSubmit: (arg: { startDate: Date; endDate: Date }) => void
  isLoading: boolean
}) {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      TransitionComponent={Transition}
      aria-describedby="dialog-slide-description"
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Typography
                variant="h5"
                align="left"
                marginBottom={4}
                fontWeight="500"
                fontSize="18px"
              >
                Please choose time period
              </Typography>
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Stack
                  marginRight={4}
                  direction="row"
                  justifyContent="space-between"
                  margin="0 auto"
                >
                  <DesktopDatePicker
                    label="Start:"
                    maxDate={new Date()}
                    value={values.startDate}
                    onChange={value => setFieldValue('startDate', value)}
                    renderInput={params => (
                      <MUITextField variant="standard" {...params} />
                    )}
                    InputProps={{
                      sx: {
                        marginRight: 4,
                        width: '150px'
                      }
                    }}
                  />
                  <DesktopDatePicker
                    label="End:"
                    maxDate={new Date()}
                    minDate={values.startDate}
                    value={values.endDate}
                    onChange={value => setFieldValue('endDate', value)}
                    renderInput={params => (
                      <MUITextField variant="standard" {...params} />
                    )}
                    InputProps={{
                      sx: {
                        width: '150px'
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 3, margin: '0 auto' }}>
              <Button
                children="Cancel"
                variant="text"
                size="large"
                onClick={onClose}
                sx={{ fontSize: 16, width: '100%', height: 42 }}
              />
              <LoadingButton
                size="large"
                type="submit"
                children="Confirm"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                sx={{ fontSize: 16, width: '100%', height: 42 }}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
