import { useState } from 'react'
import { GetJobDetails } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { JobDetail } from 'types/jobs'
import { get } from 'utils'

export default function useVisibilityDetails() {
  const { onNotify } = useNotification()
  const [details, setDetails] = useState({} as JobDetail)
  const { params } = useNavigation()
  const { isLoading, refetch, isFetching } = useQuery(
    'VISIBILITY',
    () => GetJobDetails(Number(params.id)),
    {
      enabled: Boolean(params.id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setDetails(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onGetDetails = () => refetch()
  return {
    isLoading: isLoading || isFetching,
    visibility: details.employee_visibility,
    isFetching,
    refetch,
    onGetDetails
  }
}
