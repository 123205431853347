import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import { Box, Button, Stack } from '@mui/material'
import BulkActions from 'components/bulkactions'
import DropDown from 'components/dropdown/index'
import { EmptyPage } from 'components/emtpyPage/'
import { ModalInvite } from 'components/modals/'
import { useNotification } from 'components/notification/useNotification'
import Actions from 'components/selectActions/Actions'
import { DataGrid, Pagination } from 'components/table'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { useToggle } from 'hooks/useToggle'
import { COLORS } from 'styles/colors'
import { commonTableStyles } from 'styles/utils'
import { EmployeeDialogs } from './EmployeeDialogs'
import {
  RoleEmployeesTemplateBulkActions,
  StatusEmployeesTemplateBulkActions
} from './Types/Employees'
import { columns, STATUS_ACTIONS, ROLE_ACTIONS } from './constants'
import DialogResendDelete from './dialogResendDelete'
import useEmployees from './hooks/useEmployees'
import useUpdateStatus from './hooks/useUpdateStatus'

const EmployeeTable = () => {
  const [actionType, setActionType] = useState<'resend' | 'delete'>(
    '' as 'resend'
  )
  const [dialogEmployees, setDialogEmployees] = useState(false)

  const { onNotify } = useNotification()
  const {
    selectedEmployeesTemplate,
    setSelectedEmployeesTemplate,
    onApply,
    setSelectedEmployeesPendingTemplate
  } = useUpdateStatus()
  const { employees, isLoading, pagination } = useEmployees()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const { onNavigate } = useNavigation()
  const { active, handleToggle, openDialog, handleCloseDialog } = useToggle()

  const handleSelectedRow = (ids: any[]) => {
    const filteredSelectedPendingEmployee = employees.filter(
      (template: any) =>
        ids.includes(template.id) && template.status === 'pending'
    )

    const filteredSelectedTemplates = employees.filter(
      (template: any) =>
        ids.includes(template.id) && template.status !== 'pending'
    )

    const employeesTemplateIds = filteredSelectedTemplates.map(item => {
      return item.id
    })
    setSelectedEmployeesPendingTemplate(filteredSelectedPendingEmployee)
    setSelectedEmployeesTemplate(employeesTemplateIds)
  }

  const onApplyBulkActions = (args: any) => {
    const data: StatusEmployeesTemplateBulkActions = {
      employees: selectedEmployeesTemplate,
      status: args.id
    }
    onApply(data)
  }

  const onApplyRoleBulkActions = (args: any) => {
    const data = {
      employees: selectedEmployeesTemplate,
      role: args.name === 'employee' ? null : args.name
    }
    onApply(data as unknown as RoleEmployeesTemplateBulkActions)
  }

  const toggleDialogEmployees = (type: 'resend' | 'delete') => {
    setActionType(type)

    const isValidEmployeeStatus = employees
      .filter(item => selectedEmployeesTemplate.includes(item.id))
      .every(item => item.status === 'pending' || item.status === 'new')

    if (!isValidEmployeeStatus && type === 'resend') {
      return onNotify({
        message: `Resend invitations is only for pending or new status.`,
        severity: 'info'
      })
    }

    if (selectedEmployeesTemplate.length === 0) {
      const message =
        type === 'resend' ? 'resend invitations' : 'delete employees'
      return onNotify({
        message: `Select one or more items to ${message}.`,
        severity: 'info'
      })
    }
    setDialogEmployees(!dialogEmployees)
  }

  const onDetails = (id: number | string) => onNavigate(`${id}`)

  return (
    <Box
      width="100%"
      marginTop={4}
      paddingBottom={3}
      height={commonTableStyles.minHeight}
    >
      <DataGrid
        page={page}
        rows={employees}
        columns={columns}
        checkboxSelection
        loading={isLoading}
        disableSelectionOnClick
        rowsPerPageOptions={[10]}
        rowCount={pagination.totalPages || 0}
        onSelectionModelChange={handleSelectedRow}
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={props => onDetails(props.row.id)}
        components={{
          Header: () => (
            <>
              <BulkActions
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                totalPages={pagination.totalEntries}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                <DropDown
                  label="Change Role"
                  actionUID="bulkaction-role"
                  disabled={selectedEmployeesTemplate.length === 0}
                >
                  <Actions
                    onApply={arg => onApplyRoleBulkActions(arg)}
                    options={ROLE_ACTIONS}
                    actionUID="bulkaction-role"
                  />
                </DropDown>
                <DropDown
                  label="Change Status"
                  actionUID="bulkaction-status"
                  disabled={selectedEmployeesTemplate.length === 0}
                >
                  <Actions
                    onApply={arg => onApplyBulkActions(arg)}
                    options={STATUS_ACTIONS}
                    actionUID="bulkaction-status"
                  />
                </DropDown>
                <Stack
                  direction="row"
                  component="div"
                  fontWeight={500}
                  alignItems="center"
                  color={COLORS.gray2}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => toggleDialogEmployees('resend')}
                >
                  Resend invitation
                  <SendIcon
                    htmlColor={COLORS.gray2}
                    sx={{ width: 14, marginLeft: '4px' }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  component="div"
                  fontWeight={500}
                  alignItems="center"
                  color={COLORS.gray2}
                  sx={{ cursor: 'pointer', marginLeft: '14px' }}
                  onClick={() => toggleDialogEmployees('delete')}
                >
                  Delete employee
                  <DeleteIcon
                    htmlColor={COLORS.gray2}
                    sx={{ width: 14, marginLeft: '4px' }}
                  />
                </Stack>
              </BulkActions>
            </>
          ),
          Pagination: () => (
            <Pagination
              handleChangePage={handleChangePage}
              page={page}
              totalPages={pagination.totalPages}
            />
          ),
          NoRowsOverlay: () => (
            <EmptyPage
              message="You do not have registered employees yet"
              logo="/images/Blank_state.svg"
            >
              <Button
                size="medium"
                color="primary"
                onClick={handleToggle}
                children="Click to send email invite"
              />
            </EmptyPage>
          )
        }}
      />
      <ModalInvite
        open={active}
        title={'Invite your employees'}
        content={
          'You can add more than one employee following this example structure “First name, Middle name(optional), Last name, Second sur name (optional), email,phone” it is important to enter one per line and separate each word with a comma'
        }
        close={() => handleToggle()}
      />
      <EmployeeDialogs
        open={openDialog}
        handleClose={handleCloseDialog}
        title={'title'}
        content={
          'This change will be applied to all the selected list elements'
        }
      />
      <DialogResendDelete
        employees={employees}
        open={dialogEmployees}
        actionType={actionType}
        selectedCheck={selectedEmployeesTemplate}
        handleClose={(type: 'resend' | 'delete') => toggleDialogEmployees(type)}
      />
    </Box>
  )
}

export default EmployeeTable
