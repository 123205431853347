import { DashboardBody } from './DashboardBody'
import { DashboardHeader } from './DashboardHeader'
export const Dashboard = () => {
  return (
    <>
      <DashboardHeader />
      <DashboardBody />
    </>
  )
}
