import { Delete } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { FieldArray, Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { BonusConfig } from 'types/bonusTemplate'
import useAddBonus, { validationSchema } from './useAddUpdateBonus'

export default function AddUpdateBonus() {
  const {
    initialValues,
    isLoading,
    onSubmit,
    addBonusConfigToArray,
    onDeleteBonusConfigAction
  } = useAddBonus()

  return (
    <Box padding="16px" minHeight="calc(100vh - 130px)">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, getFieldProps, setFieldValue, handleChange }) => {
          return (
            <Stack
              minHeight="84vh"
              component={Form}
              justifyContent="space-between"
            >
              <Box sx={{ padding: '16px' }}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h3">Edit configuration</Typography>
                </Stack>
                <Stack flexDirection="column" marginY={3}>
                  <Typography variant="h5">Bonus name</Typography>
                  <TextField
                    sx={{
                      width: '100%'
                    }}
                    value={values.entries.description}
                    variant="standard"
                    onChange={handleChange('entries.description')}
                    name="description"
                  />
                  <Typography variant="caption" color={COLORS.red1}>
                    {errors.entries?.description}
                  </Typography>
                </Stack>
                <Box>
                  <Typography
                    variant="h5"
                    marginBottom={1}
                    color={COLORS.gray2}
                  >
                    Choose for who is the bonus...
                  </Typography>
                  <FormControlLabel
                    name="is_for_referrer"
                    control={<Checkbox />}
                    label="Employee"
                    checked={values.entries.is_for_referrer}
                    onChange={(e, checked) => {
                      setFieldValue('entries.is_for_referrer', checked)
                    }}
                    sx={{ width: '100%' }}
                    componentsProps={{
                      typography: { color: COLORS.gray1 }
                    }}
                  />
                  <FormControlLabel
                    name="is_for_referral"
                    control={<Checkbox />}
                    label="Referral"
                    checked={values.entries.is_for_referral}
                    onChange={(e, checked) => {
                      setFieldValue('entries.is_for_referral', checked)
                    }}
                    sx={{ width: '100%' }}
                    componentsProps={{
                      typography: { color: COLORS.gray1 }
                    }}
                  />

                  {values.entries.is_for_referrer && (
                    <>
                      <Stack flexDirection="column" marginY={2}>
                        <Typography variant="h5">Employee Bonus</Typography>
                        <Select
                          disabled
                          name="Type"
                          value="1"
                          variant="standard"
                          label="Select bonus type"
                          options={[{ value: '1', label: 'Flat rate' }]}
                        />
                      </Stack>
                      <FieldArray
                        name="entries.bonus_config"
                        render={arrayHelpers => (
                          <div>
                            {values.entries.bonus_config.map(
                              (item: BonusConfig, index: number) => {
                                if (item.type === 'referrer' && !item.deleted) {
                                  return (
                                    <Box marginY={2} key={index}>
                                      <Stack
                                        flexDirection="row"
                                        alignItems="center"
                                      >
                                        <TextField
                                          sx={{
                                            width: '30%'
                                          }}
                                          variant="standard"
                                          value={`${values.entries.bonus_config[index].amount}`}
                                          onChange={args => {
                                            setFieldValue(
                                              `entries.bonus_config[${index}].amount`,
                                              Number(args.target.value)
                                            )
                                            const values = getFieldProps(
                                              `entries.bonus_config[${index}]`
                                            )
                                            if (values.value.edited === false)
                                              setFieldValue(
                                                `entries.bonus_config[${index}].edited`,
                                                true
                                              )
                                          }}
                                          name="amount"
                                          placeholder="$ Amount"
                                        />
                                        <Typography
                                          variant="body2"
                                          marginBottom={0.2}
                                        >
                                          USD
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          marginLeft={3}
                                          marginRight={3}
                                          marginBottom={0.2}
                                        >
                                          After
                                        </Typography>
                                        <TextField
                                          sx={{
                                            width: '30%',
                                            marginRight: '10px'
                                          }}
                                          placeholder="Number of days"
                                          variant="standard"
                                          value={`${values.entries.bonus_config[index].days}`}
                                          onChange={args => {
                                            setFieldValue(
                                              `entries.bonus_config[${index}].days`,
                                              Number(args.target.value)
                                            )
                                            const values = getFieldProps(
                                              `entries.bonus_config[${index}]`
                                            )
                                            if (values.value.edited === false)
                                              setFieldValue(
                                                `entries.bonus_config[${index}].edited`,
                                                true
                                              )
                                          }}
                                          name="days"
                                        />
                                        <Typography
                                          variant="body2"
                                          marginBottom={0.2}
                                          marginRight={1}
                                        >
                                          Days
                                        </Typography>
                                        <Button
                                          onClick={() =>
                                            onDeleteBonusConfigAction(
                                              setFieldValue,
                                              index
                                            )
                                          }
                                        >
                                          <Delete color="error" />
                                        </Button>
                                      </Stack>
                                    </Box>
                                  )
                                }
                              }
                            )}
                            <Button
                              onClick={() =>
                                addBonusConfigToArray(
                                  arrayHelpers,
                                  'referrer',
                                  true
                                )
                              }
                            >
                              <Add />
                              <Typography
                                variant="body2"
                                color={COLORS.main}
                                marginLeft={2}
                                marginRight={2}
                              >
                                Add Bonus Tier
                              </Typography>
                            </Button>
                          </div>
                        )}
                      />
                    </>
                  )}
                  {values.entries.is_for_referral && (
                    <>
                      <Stack flexDirection="column" marginY={2}>
                        <Typography variant="h5">Referral Bonus</Typography>
                        <Select
                          disabled
                          name="Type"
                          value="1"
                          variant="standard"
                          label="Select bonus type"
                          options={[{ value: '1', label: 'Flat rate' }]}
                        />
                      </Stack>
                      <FieldArray
                        name="entries.bonus_config"
                        render={arrayHelpers => (
                          <div>
                            {values.entries.bonus_config.map(
                              (item: BonusConfig, index: number) => {
                                if (item.type === 'referral' && !item.deleted) {
                                  return (
                                    <Box marginY={2} key={index}>
                                      <Stack
                                        flexDirection="row"
                                        alignItems="center"
                                      >
                                        <TextField
                                          sx={{
                                            width: '30%'
                                          }}
                                          variant="standard"
                                          value={`${values.entries.bonus_config[index].amount}`}
                                          onChange={args => {
                                            setFieldValue(
                                              `entries.bonus_config[${index}].amount`,
                                              args.target.value
                                            )
                                            const values = getFieldProps(
                                              `entries.bonus_config[${index}]`
                                            )
                                            if (values.value.edited === false)
                                              setFieldValue(
                                                `entries.bonus_config[${index}].edited`,
                                                true
                                              )
                                          }}
                                          name="amount"
                                          placeholder="$ Amount"
                                        />

                                        <Typography
                                          variant="body2"
                                          marginLeft={3}
                                          marginRight={3}
                                          marginBottom={0.2}
                                        >
                                          After
                                        </Typography>
                                        <TextField
                                          sx={{
                                            width: '30%',
                                            marginRight: '10px'
                                          }}
                                          placeholder="Number of days"
                                          variant="standard"
                                          value={`${values.entries.bonus_config[index].days}`}
                                          onChange={args => {
                                            setFieldValue(
                                              `entries.bonus_config[${index}].days`,
                                              Number(args.target.value)
                                            )
                                            const values = getFieldProps(
                                              `entries.bonus_config[${index}]`
                                            )
                                            if (values.value.edited === false)
                                              setFieldValue(
                                                `entries.bonus_config[${index}].edited`,
                                                true
                                              )
                                          }}
                                          name="days"
                                        />
                                        <Typography
                                          variant="body2"
                                          marginBottom={0.2}
                                          marginRight={1}
                                        >
                                          Days
                                        </Typography>
                                        <Button
                                          onClick={() =>
                                            onDeleteBonusConfigAction(
                                              setFieldValue,
                                              index
                                            )
                                          }
                                        >
                                          <Delete color="error" />
                                        </Button>
                                      </Stack>
                                    </Box>
                                  )
                                }
                              }
                            )}
                            <Button
                              onClick={() =>
                                addBonusConfigToArray(
                                  arrayHelpers,
                                  'referral',
                                  true
                                )
                              }
                            >
                              <Add />
                              <Typography
                                variant="body2"
                                color={COLORS.main}
                                marginLeft={2}
                                marginRight={2}
                              >
                                Add Bonus Tier
                              </Typography>
                            </Button>
                          </div>
                        )}
                      />
                    </>
                  )}
                </Box>
              </Box>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={values.entries.bonus_config.length === 0}
                sx={{
                  marginTop: '16px'
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          )
        }}
      </Formik>
    </Box>
  )
}
