/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import BulkActions from 'components/bulkactions'
import DropDown from 'components/dropdown'
import { EmptyPage } from 'components/emtpyPage'
import Actions from 'components/selectActions/Actions'
import { DataGrid, Pagination } from 'components/table'
import ToggleSidebar from 'components/toggleSidebar/index'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { commonTableStyles } from 'styles/utils'
import { columns, VALID_CHANGE_STATUS } from './constants'
import usePaymentsTable from './hooks/usePaymentsTable'
import useUpdateStatusPayment from './hooks/useUpdateStatusPayment'
import { PaymentDialogs } from './paymentDialogs'
import PaymentsDetails from './paymentsDetails'
const PaymentsTable = () => {
  const { onNavigate } = useNavigation()
  const {
    selectedBonusTemplates,
    openDialog,
    onToggleDialog,
    onSelectedOption,
    onConfirmBulkActions,
    setSelectedBonusTemplates,
    isLoading: isLoadingUpdate
  } = useUpdateStatusPayment()

  const {
    onToggleTemplate,
    openToggle,
    bonusPayments,
    isLoading,
    pagination,
    refreshTable
  } = usePaymentsTable()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const handleSelectedRow = (ids: any[]) => {
    const filteredSelectedTemplates = bonusPayments.filter((template: any) =>
      ids.includes(template.id)
    )
    const bonusTemplateIds = filteredSelectedTemplates.map(item => {
      return item.id
    })
    setSelectedBonusTemplates(bonusTemplateIds)
  }
  const onApplyBulkActions = (args: any) => {
    onSelectedOption(args)
    onToggleDialog()
  }

  return (
    <>
      <Box
        width="100%"
        height={commonTableStyles.minHeight}
        paddingBottom={3}
        marginTop={4}
      >
        <DataGrid
          checkboxSelection
          columns={columns}
          loading={isLoading}
          rows={bonusPayments}
          rowCount={pagination.totalPages || 0}
          onSelectionModelChange={handleSelectedRow}
          isRowSelectable={params => {
            return params.row.status === 'review'
          }}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onCellClick={props => {
            if (props.field === '__check__') return
            if (props.field === 'job') {
              return onNavigate(`/jobs/boardconfig/${props.row.job.job_id}`)
            }
            onToggleTemplate('payment', { id: props.id })
          }}
          components={{
            NoResultsOverlay: () => (
              <EmptyPage
                message="It looks like there are no payments to made yet"
                logo="/images/bonusPaymentsBlank.svg"
              >
                <span></span>
              </EmptyPage>
            ),
            Pagination: () => (
              <Pagination
                handleChangePage={handleChangePage}
                page={page}
                totalPages={pagination.totalPages}
              />
            ),
            Header: () => (
              <BulkActions
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                totalPages={pagination.totalEntries}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                <DropDown
                  label="Change payment status"
                  actionUID="bulkaction-payments"
                  disabled={selectedBonusTemplates.length === 0}
                >
                  <Actions
                    onApply={arg => onApplyBulkActions(arg)}
                    options={VALID_CHANGE_STATUS}
                    actionUID="bulkaction-payments"
                  />
                </DropDown>
              </BulkActions>
            )
          }}
        />
        <ToggleSidebar
          isOpen={openToggle.payment}
          headerTitle="Bonus payment detail"
          toggleSidebar={() => onToggleTemplate('payment')}
        >
          <PaymentsDetails
            refreshTable={() => refreshTable()}
            onCloseSidebar={() => onToggleTemplate('payment')}
          />
        </ToggleSidebar>
      </Box>
      <PaymentDialogs
        open={openDialog}
        onClose={onToggleDialog}
        onConfirm={onConfirmBulkActions}
        isLoading={isLoadingUpdate}
      />
    </>
  )
}

export default PaymentsTable
