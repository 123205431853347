import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import { SigninLogo } from 'app/signin/styles'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import useDialogPassword from './useDialogPassword'

export default function AlertDialog({ open, handleClose, closeDialog }: any) {
  const { onSumbit } = useDialogPassword(handleClose)
  const { user } = useAuth()
  return (
    <Dialog open={open} onClick={closeDialog}>
      <Stack alignItems="center" margin="0 60px">
        <DialogTitle
          id="alert-dialog-title"
          fontSize="20px"
          fontWeight="500"
          sx={{
            width: '100%',
            textAlign: 'center',
            padding: '16px 24px 0 24px'
          }}
        >
          Password recovery
        </DialogTitle>
        <DialogContent>
          <Stack width="100%" margin="0 auto">
            <SigninLogo src="/images/logo.png" alt="logo" />
            <Typography
              variant="body2"
              color={COLORS.gray2}
              paddingTop="10px"
              textAlign="center"
            >
              We sent an email to
            </Typography>
          </Stack>
          <Stack paddingTop={2} alignContent="center" justifyContent="center">
            <Typography
              textAlign="center"
              fontSize="14px"
              fontWeight="400"
              paddingBottom={2}
            >
              {user.email}
            </Typography>
            <Typography variant="body2" textAlign="center" color={COLORS.gray2}>
              please check your email
            </Typography>
          </Stack>
        </DialogContent>
        <Divider
          variant="middle"
          orientation="horizontal"
          sx={{
            color: COLORS.gray8,
            width: '100%'
          }}
        />
        <DialogActions
          sx={{
            width: '100%',
            paddingBottom: '10px'
          }}
        >
          <Button
            variant="contained"
            onClick={() => onSumbit(user.email)}
            fullWidth
          >
            Accept
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}
