import FramerCarousel from 'framer-motion-carousel'

type CarouselProps = React.ReactComponentElement<typeof FramerCarousel>

export default function Carousel({ ...props }: CarouselProps['props']) {
  return (
    <FramerCarousel
      renderDots={() => <div>...</div>}
      renderArrowLeft={() => <div>...</div>}
      renderArrowRight={() => <div>...</div>}
      {...props}
    >
      {props.children}
    </FramerCarousel>
  )
}
