import { useState } from 'react'
import { GetReferralById } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { ReferralDetail } from 'types/referrals'
import { get } from 'utils'

export default function useReferralDetail() {
  const [referral, setReferral] = useState<ReferralDetail>({} as ReferralDetail)
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()
  const { isLoading, refetch, isFetching } = useQuery(
    'REFERRAL',
    () => GetReferralById(searchParams.id),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setReferral(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  const getReferral = () => refetch()

  return {
    getReferral,
    referral,
    isLoading: isLoading || isFetching
  }
}
