import { Popover, PopoverProps } from '@mui/material'

interface MenuColumnProps extends PopoverProps {
  anchorEl: React.MouseEvent<HTMLButtonElement> | any
  onClose: () => void
  children: React.ReactNode
  id?: string
  open: boolean
}

export default function MenuColumn({
  anchorEl,
  onClose,
  children,
  id,
  open,
  ...props
}: MenuColumnProps) {
  return (
    <Popover
      id={id}
      open={open}
      elevation={4}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    >
      {children}
    </Popover>
  )
}
