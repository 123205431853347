import { Typography } from '@mui/material'

import Paper from 'components/paper'

export const CardsGraph = ({
  children,
  title
}: {
  children: any
  title: string
}) => {
  return (
    <>
      <Paper
        elevation={4}
        sx={{
          width: { lg: '46%', xs: '90%', md: '80%' },
          padding: 2,
          height: { xs: '50%', lg: '50%' },
          marginRight: { xs: 0, lg: 2 },
          marginBottom: { xs: 2, lg: 2 },
          alignContent: 'center',
          overflow: 'auto'
        }}
      >
        <Typography marginLeft={2} marginBottom={2} variant="subtitle1">
          {title}
        </Typography>
        {children}
      </Paper>
    </>
  )
}
