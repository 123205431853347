import { useState } from 'react'
import { ValidateEmail, ValidateSms } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import store from 'storejs'
import { get } from 'utils'
import { REGEX_NUMBERS } from 'utils/catalogs'
import * as yup from 'yup'

export const validationSchema = yup.object({
  codeSms: yup
    .string()
    .matches(REGEX_NUMBERS, 'Only numbers')
    .required('Code required')
    .min(4, 'Minimun 4 characters')
    .max(4, 'Maximun 4 characters')
})

export default function useEnterSmsCode() {
  const { onNavigate, searchParams } = useNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const { onNotify } = useNotification()

  const onHandleRedirect = (token: string) => {
    onNavigate('/new-password', {
      search: {
        token
      }
    })
  }

  const onSubmit = (values: { codeSms: string }) => {
    setIsLoading(true)
    ValidateSms({ email: searchParams.email, nip_phone: values.codeSms })
      .then(response => {
        onHandleRedirect(response.data.reset_token)
        store.remove('TEMPORAL_SESSION')
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onRequestNewCode = () => {
    const data = store.get('TEMPORAL_SESSION') || {}
    ValidateEmail(data)
      .then(() => {
        onNotify({
          message: 'New code requested successfully.',
          severity: 'success'
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const screen = searchParams.screen
  return {
    screen,
    onSubmit,
    isLoading,
    onRequestNewCode
  }
}
