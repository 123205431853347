import { Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ColumnHeader, TableChip } from 'components/table'
import { COLORS } from 'styles/colors'
import type { JobBoard } from 'types/jobs'
import { formatDate } from 'utils'
import BonusStatus from '../filters/BonusStatus'
import Company from '../filters/Company'
import JobStatus from '../filters/JobStatus'

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
    editable: false,
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Job name',
    width: 280,
    editable: false,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'company_name',
    headerName: 'Company',
    width: 260,
    editable: false,
    sortable: false,
    renderHeader: props => (
      <ColumnHeader {...props}>
        <Company />
      </ColumnHeader>
    )
  },
  {
    field: 'applicants',
    headerName: 'Applicants',
    type: 'number',
    align: 'left',
    width: 100,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {props.row.job_applyments.length || 0}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'inserted_at',
    headerName: 'Imported',
    width: 140,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.inserted_at, 'MMM dd, yyy')}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'id_source',
    headerName: 'Source id',
    width: 120,
    editable: false,
    sortable: false
  },
  {
    field: 'status',
    headerName: 'Job status',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip {...props} chipProps={{ variant: 'filled' }} />
    ),
    renderHeader: props => (
      <ColumnHeader {...props}>
        <JobStatus />
      </ColumnHeader>
    )
  },
  {
    field: 'bonusStatus',
    headerName: 'Bonus status',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip
        {...props}
        chipProps={{
          variant: 'outlined',
          sx: { background: 'transparent' }
        }}
      />
    ),
    renderHeader: props => (
      <ColumnHeader {...props}>
        <BonusStatus />
      </ColumnHeader>
    )
  }
]

export const getRows = (data: JobBoard['entries']) => {
  const filteredJobs = data.map(item => {
    return {
      ...item,
      bonusStatus: item.bonus ? item.bonus.status : 'Empty' // what's that?
    }
  })
  return filteredJobs || []
}

export const COMPANY_FILTERS = [
  { id: 1, label: 'AppleOne' },
  { id: 2, label: 'Arch staffing & Consulting' },
  { id: 3, label: 'Cornerstone staffing & Con' },
  { id: 4, label: 'Hire Dynamics' },
  { id: 5, label: 'Insight Global' },
  { id: 6, label: 'Kforce' }
]

export const JOB_STATUS = [
  { id: 'new', label: 'New' },
  { id: 'pending', label: 'Pending' },
  { id: 'closed_beegopp', label: 'Closed beegopp' },
  { id: 'closed_ats', label: 'Closed ats' },
  { id: 'published', label: 'Published' },
  { id: 'on_hold_ats', label: 'On hold ats' }
]

export const BONUS_STATUS = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'inactive' },
  { id: 'cancelled', label: 'Cancelled' },
  { id: 'deleted', label: 'Deleted' }
]

export const STATUS_COLOR = {
  published: COLORS.green1,
  closed: COLORS.red1
}
