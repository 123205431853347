import BonusHeader from './BonusHeader'
import BonusTable from './BonusTable'
export default function Templates() {
  return (
    <>
      <BonusHeader />
      <BonusTable />
    </>
  )
}
