import { Box, Typography, Stack, Button } from '@mui/material'
import SearchBar from 'components/SearchBar/index'
import { EmptyPage } from 'components/emtpyPage'
import { DataGrid, Pagination } from 'components/table'
import { TableHeader } from 'components/table'
import useNavigation from 'hooks/useNavigation'
import { commonTableStyles } from 'styles/utils'

import { usePage } from '../../../hooks/usePage'
import { columns } from '../constants'
import useBonusPayments from '../hooks/useBonusPayments'

export const BonusReferalTable = () => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const { bonusPayments, pagination } = useBonusPayments()
  const { onNavigate } = useNavigation()
  return (
    <Box
      width="100%"
      marginTop={4}
      paddingBottom={3}
      height={commonTableStyles.minHeightSubtable}
    >
      <DataGrid
        rows={bonusPayments}
        columns={columns}
        pageSize={rowsPerPage}
        checkboxSelection={false}
        rowsPerPageOptions={[10]}
        rowCount={pagination.totalPages || 0}
        onCellClick={props => {
          if (props.field === 'referral_names') {
            return onNavigate('/referrals', {
              search: {
                id: props.row.referral_id,
                referrals: 'open'
              }
            })
          }
          if (props.field === 'job_role') {
            return onNavigate(`/jobs/jobboard/${props.row.job_id}`)
          }
        }}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          Header: () => (
            <>
              <Stack flexDirection="row" alignItems="center" marginTop={3}>
                <Typography marginLeft={2} variant="h2" marginRight={4}>
                  Bonus Record
                </Typography>
                <SearchBar />
              </Stack>
              <TableHeader
                hideBulk
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                totalPages={pagination.totalEntries}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                &nbsp;
              </TableHeader>
            </>
          ),
          NoRowsOverlay: () => (
            <EmptyPage
              message="You haven't earned any bonus yet"
              logo="/images/iconWalletEmpty.svg"
            >
              <Button
                size="medium"
                color="primary"
                onClick={() => onNavigate('/jobs')}
                children="Start referring!"
              />
            </EmptyPage>
          )
        }}
      />
    </Box>
  )
}
