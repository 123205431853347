import PersonIcon from '@mui/icons-material/Person'
import { Box, Typography, Stack, Avatar, Button, Divider } from '@mui/material'
import ReferBy from 'app/educationDetails/sideDetail/byEmail/referBy'
import { EmptyPage } from 'components/emtpyPage'
import MoneyIcon from 'components/icons/Money'
import Paper from 'components/paper'
import { JobsBoardSkeleton } from 'components/skeleton'
import ToggleSidebar from 'components/toggleSidebar'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { LinkButton } from 'styles/utils'
import { formatCurrency } from 'utils'
import useInfinitiveJobs from '../hooks/useInfinitiveJobs'
import Header from './header'

export default function BoardJob() {
  const {
    jobs,
    isLoading,
    openReferStartBy,
    onToggleStartReferBy,
    fetchNextPage
  } = useInfinitiveJobs()

  const { onNavigate } = useNavigation()

  const handleDetails = (id: number) => onNavigate(`${id}`)

  const bonusJobs = jobs.filter(item => item.bonus)

  return (
    <Box paddingTop={2} paddingBottom={6} position="relative">
      <Header />
      <Box marginTop={2}>
        {isLoading ? (
          <JobsBoardSkeleton />
        ) : (
          <>
            {bonusJobs.length > 0 ? (
              bonusJobs.map(item => {
                const salaryLow = formatCurrency(Number(item.salary_low || 0))
                const salaryHight = formatCurrency(
                  Number(item.salary_high || 0)
                )
                const salary = `${salaryLow} - ${salaryHight}`
                return (
                  <Box
                    component={Paper}
                    elevation={6}
                    key={item.id}
                    marginBottom={2}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent="space-between"
                    >
                      <Box width={{ sx: '100%', md: '50%' }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          marginBottom={{ xs: 2, md: 0 }}
                        >
                          <Typography
                            variant="h3"
                            marginRight={{ xs: 0, md: 4 }}
                          >
                            {item.name}
                          </Typography>
                        </Stack>
                        <Stack marginTop={1} direction="column">
                          <Typography variant="caption" color={COLORS.black}>
                            {item.company_name}
                          </Typography>
                          <Typography variant="caption" color={COLORS.main}>
                            {item.address}
                          </Typography>
                        </Stack>
                        <Stack direction="row" marginTop={{ xs: 2, md: 0 }}>
                          <Typography
                            variant="caption"
                            color={COLORS.black}
                            marginRight={1}
                          >
                            Source id:
                          </Typography>
                          <Typography variant="caption" color={COLORS.black}>
                            {item.id_source}
                          </Typography>
                        </Stack>
                      </Box>
                      <Stack
                        alignItems={{ xs: 'flex-start', md: 'flex-end' }}
                        direction={{ xs: 'column' }}
                        justifyContent={{ xs: 'flex-end', sm: 'initial' }}
                        marginBottom={{ xs: 2, sm: 0 }}
                        marginTop={{ xs: 2, md: 0 }}
                        width={{ xs: 'auto', md: '50%' }}
                      >
                        <Typography
                          variant="body2"
                          color={COLORS.green3}
                          marginBottom={2}
                        >
                          Earn Bonus
                        </Typography>
                        <Stack direction="row" alignItems="center">
                          <Stack direction="row" alignItems="center">
                            <MoneyIcon />
                            <Typography variant="h2" color={COLORS.green2}>
                              {formatCurrency(
                                Number(item.bonus?.total_amount_referrer)
                              )}
                            </Typography>
                          </Stack>
                          <Typography
                            ml={2}
                            variant="body2"
                            color={COLORS.green2}
                          >
                            For employee
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Stack direction="row" alignItems="center">
                            <MoneyIcon />
                            <Typography variant="h2" color={COLORS.green2}>
                              {formatCurrency(
                                Number(item.bonus?.total_amount_referral)
                              )}
                            </Typography>
                          </Stack>
                          <Typography
                            ml={2}
                            variant="body2"
                            color={COLORS.green2}
                          >
                            For referral
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent="space-between"
                      marginTop={2}
                    >
                      <Stack
                        direction="row"
                        justifyContent={{
                          xs: 'space-between',
                          md: 'initial'
                        }}
                      >
                        <Box paddingRight={{ xs: 0, md: 2 }}>
                          <Typography variant="caption">Referrals</Typography>
                          <Stack direction="row" alignItems="center">
                            <Box
                              width={30}
                              height={30}
                              marginRight={1}
                              component={Avatar}
                              variant="circular"
                              bgcolor={COLORS.honey2}
                            >
                              <PersonIcon fontSize="small" />
                            </Box>
                            {item.job_applyments.length || 0}
                          </Stack>
                        </Box>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          sx={{ height: { xs: 'auto', md: '80%' } }}
                        />
                        <Box paddingX={{ xs: 0, md: 2 }}>
                          <Typography variant="caption">Job type</Typography>
                          <Typography variant="body2" mt={1}>
                            {item.type || 'fulltime'}
                          </Typography>
                        </Box>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          sx={{ height: { xs: 'auto', md: '80%' } }}
                        />
                        <Box paddingX={{ xs: 0, md: 2 }}>
                          <Typography variant="caption">Salary</Typography>
                          <Typography variant="body2" mt={1}>
                            {salary}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        marginTop={{ xs: 6, md: 0 }}
                        justifyContent={{ xs: 'flex-end', md: 'initial' }}
                      >
                        <Box
                          variant="text"
                          marginRight={2}
                          component={Button}
                          onClick={() => handleDetails(item.id)}
                        >
                          View More
                        </Box>
                        <Button
                          sx={{ minWidth: 150 }}
                          variant="contained"
                          onClick={() =>
                            onToggleStartReferBy('referStartBy', item.id)
                          }
                        >
                          Start Referring
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                )
              })
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 15em)"
              >
                <EmptyPage
                  message="You haven’t imported jobs yet"
                  logo="/images/sync.svg"
                  boxProps={{
                    marginY: 10
                  }}
                >
                  <LinkButton to="/jobs/import">
                    Click to import jobs
                  </LinkButton>
                </EmptyPage>
              </Stack>
            )}
          </>
        )}
      </Box>
      {bonusJobs.length > 0 && (
        <Box
          left="40%"
          bottom={20}
          component={Button}
          fontSize="14px"
          color={COLORS.main}
          position="absolute"
          disabled={isLoading}
          onClick={() => fetchNextPage()}
          sx={{
            cursor: 'pointer',
            ':hover': { textDecoration: 'underline', background: 'transparent' }
          }}
        >
          Load more...
        </Box>
      )}
      <ToggleSidebar
        isOpen={openReferStartBy.referStartBy}
        headerTitle="Refer by SMS or Email"
        toggleSidebar={() => onToggleStartReferBy('referStartBy')}
      >
        <ReferBy />
      </ToggleSidebar>
    </Box>
  )
}
