import React, { useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionBody, AccordionHeader, AccordionWrapper } from './styles'

export default function Accordion({
  title,
  color,
  children
}: {
  color?: string
  title: string | React.ReactNode
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={handleToggle}>
        <span>{title}</span>
        {isOpen ? (
          <ExpandLessIcon htmlColor={color} />
        ) : (
          <ExpandMoreIcon htmlColor={color} />
        )}
      </AccordionHeader>
      <AccordionBody isOpen={isOpen}>{children}</AccordionBody>
    </AccordionWrapper>
  )
}
