import { useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get } from 'utils'
import { FIRST_DAY } from 'utils/catalogs'
import { formatDate } from 'utils/index'
import { GetReferralGraphE } from '../../../api/index'
import { ReferralGraph } from '../Types/DashboardTypes'

export default function useReferralGraphE() {
  const { user } = useAuth()
  const date = Date.now()

  const { onNotify } = useNotification()
  const [dataReferral, setDataReferral] = useState<ReferralGraph>(
    {} as ReferralGraph
  )
  const [initialDate, setInitialDate] = useState<any>(
    formatDate(FIRST_DAY, 'MM/dd/yyyy')
  )
  const [endDate, setEndDate] = useState<any>(
    formatDate(new Date(date), 'MM/dd/yyyy')
  )

  const { isLoading, refetch } = useQuery(
    'GET_REFERRALS_BY_DATE',
    () =>
      GetReferralGraphE(
        user.id,
        formatDate(new Date(initialDate), 'yyyy-MM-dd'),
        formatDate(new Date(endDate), 'yyyy-MM-dd')
      ),
    {
      onSuccess: data => {
        setDataReferral(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onGetData = () => refetch()

  return {
    isLoading: isLoading,
    dataReferral,
    initialDate,
    endDate,
    open,
    setInitialDate,
    setEndDate,
    onGetData
    //onToggleTemplate,
    //isSent
  }
}
