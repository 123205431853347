import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ChevronRight from '@mui/icons-material/ChevronRight'
import HelpIcon from '@mui/icons-material/Help'
import Person from '@mui/icons-material/Person'
import { Avatar, Typography, Box, useTheme, useMediaQuery } from '@mui/material'
import { Stack } from '@mui/system'
import useSidebar from 'app/layout/sidebar/useSidebar'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import { hexToRGB } from 'styles/utils'
import type { Route, SubRoute } from 'types/general'
import { getMenu } from 'utils/catalogs'
import MenuProfile from './menuProfile'
import {
  SidebarContainer,
  SidebarList,
  SidebarLogo,
  SidebarListItem,
  SidebarSubmenu,
  SidebarSubmenuItem
} from './styles'

const Sidebar = ({
  isOpen,
  onClick
}: {
  isOpen: boolean
  onClick: () => void
}) => {
  const {
    onSelectedRoute,
    onSelectedSubRoute,
    onCloseProfile,
    onOpenProfile,
    selectedRoute,
    selectedSubRoute,
    openSubmenu,
    anchorEl
  } = useSidebar()

  const { user, userRole } = useAuth()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleSelected = (route: Route) => {
    if (isMobile && (route === 'jobs' || route === 'bonus')) {
      onSelectedRoute(route)
    } else {
      onSelectedRoute(route)
      onClick()
    }
  }

  const handleCloseProfile = () => {
    if (isMobile) {
      onCloseProfile()
      onClick()
    } else {
      onCloseProfile()
    }
  }

  const handleSelectedSubMenu = ({
    route,
    subRoute
  }: {
    route: Route
    subRoute: SubRoute
  }) => {
    if (isMobile) {
      onSelectedSubRoute(route, subRoute)
      onClick()
    } else {
      onSelectedSubRoute(route, subRoute)
    }
  }

  return (
    <SidebarContainer isOpen={isOpen}>
      <Stack justifyContent="center" flexDirection="row">
        <SidebarLogo>
          <img src="/images/menulogo.png" alt="logo" />
        </SidebarLogo>
        <ArrowBackIosNewIcon
          onClick={onClick}
          htmlColor={COLORS.white}
          sx={{
            marginTop: '15px',
            marginRight: '10px',
            display: { xs: 'block', md: 'none' }
          }}
        />
      </Stack>
      <SidebarList>
        {getMenu(userRole).map(
          ({ id, title, route, submenu, icon: IconComponent, show }) => {
            const isOpenSub = openSubmenu === route
            return (
              show && (
                <Box key={id}>
                  <SidebarListItem
                    selected={selectedRoute.includes(route)}
                    onClick={() => handleSelected(route)}
                  >
                    <Stack
                      flexDirection="row"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Stack flexDirection="row">
                        <IconComponent />
                        <Typography marginLeft={2}>{title}</Typography>
                      </Stack>
                      {submenu &&
                        (isOpenSub ? <ArrowDropUp /> : <ArrowDropDown />)}
                    </Stack>
                  </SidebarListItem>
                  {submenu && (
                    <SidebarSubmenu isOpen={isOpenSub}>
                      {submenu.map(sub => {
                        return (
                          sub.show && (
                            <SidebarSubmenuItem
                              key={sub.id}
                              selected={selectedSubRoute.includes(sub.route)}
                              onClick={() =>
                                handleSelectedSubMenu({
                                  route,
                                  subRoute: sub.route
                                })
                              }
                            >
                              {sub.title}
                            </SidebarSubmenuItem>
                          )
                        )
                      })}
                    </SidebarSubmenu>
                  )}
                </Box>
              )
            )
          }
        )}
      </SidebarList>
      <Stack bgcolor={COLORS.blue1}>
        <SidebarListItem
          selected={selectedRoute.includes('help')}
          onClick={() => handleSelected('help')}
        >
          <Stack flexDirection="row">
            <HelpIcon />
            <Typography marginLeft={2}>Help</Typography>
          </Stack>
        </SidebarListItem>
        <SidebarListItem
          selectedBgcolor={hexToRGB(COLORS.white, '0.08')}
          selected={selectedRoute.includes('profile')}
          onClick={onOpenProfile}
        >
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Avatar sx={{ background: COLORS.blue6 }}>
                <Person />
              </Avatar>
              <Typography color={COLORS.white} marginX={2}>
                {user.first_name}
              </Typography>
            </Stack>
            <ChevronRight sx={{ color: COLORS.white }} />
          </Stack>
        </SidebarListItem>
        <MenuProfile onCloseProfile={handleCloseProfile} anchorEl={anchorEl} />
        <Typography fontSize={12} color="white">
          Version: 0.1.2
        </Typography>
      </Stack>
    </SidebarContainer>
  )
}
export default Sidebar
