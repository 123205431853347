import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { GetCompanyIntegrations } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { Integration } from 'types/integrations'
import { get } from 'utils'

const initialState = hookstate({
  integrations: [] as Integration[],
  selectedIntegration: {} as Integration | null
})

export const atsLogo = {
  bullhorn: '/images/toroBegopp.png',
  zenople: '/images/zenople.png'
}

export default function useIntegrations(enabled = false) {
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()

  const { integrations, selectedIntegration } = useHookstate(initialState)

  const { isLoading } = useQuery(
    'COMPANY_BY_ID',
    () => GetCompanyIntegrations(),
    {
      enabled,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        integrations.set(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    onSelectedIntegration(Number(searchParams.atsId))
  }, [searchParams.atsId])

  const onSelectedIntegration = (atsId: Integration['id']) => {
    const parseIntegration = JSON.parse(
      JSON.stringify(integrations.get())
    ) as Integration[]

    const filteredIntegration = parseIntegration.find(
      integration => integration.id === atsId
    )
    selectedIntegration.set(filteredIntegration || null)
  }

  return {
    selectedIntegration: selectedIntegration.get(),
    integrations: integrations.get(),
    isLoading,
    onSelectedIntegration
  }
}
