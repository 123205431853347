import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { BulkUpdateBonusTemplates } from 'api/index'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { StatusBonusTemplateBulkActions } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from './useBonusTable'

interface UpdateBonusTemplateParameters {
  data: StatusBonusTemplateBulkActions
}

const initialState = hookstate({
  isLoading: false
})

export default function useApplyBulkActions() {
  const { getBonusTemplates } = useBonusTable()
  const [selectedBonusTemplates, setSelectedBonusTemplates] = useState<any>([])
  const { isLoading } = useHookstate(initialState)
  const { onNotify } = useNotification()
  const onApply = async (params: StatusBonusTemplateBulkActions) => {
    isLoading.set(true)
    mutate({ data: params })
  }
  const { mutate } = useMutation(
    (data: UpdateBonusTemplateParameters) =>
      BulkUpdateBonusTemplates(data.data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Bonus templates updated successfully'
        })
        getBonusTemplates()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  return {
    onApply,
    isLoading: isLoading.value,
    setSelectedBonusTemplates,
    selectedBonusTemplates
  }
}
