import { BuyJobsPositions } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'

export default function useUpdateJobPositions() {
  const { onNotify } = useNotification()
  const { params } = useNavigation()
  const { onCloseToggle } = useToggle()

  const query = useQueryClient()

  const { mutate, isLoading } = useMutation(
    (data: { positions: number }) => BuyJobsPositions(data, Number(params.id)),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Positions updated successfully'
        })
        query.invalidateQueries('JOB_DETAIL')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onCloseToggle('buybonus')
      }
    }
  )

  const onSubmitPositions = (data: number) =>
    mutate({ positions: Number(data) })

  return { onSubmitPositions, submiting: isLoading }
}
