import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material'

export default function Chat({
  onClose,
  open
}: {
  onClose: () => void
  open: boolean
}) {
  return (
    <Dialog fullWidth open={open} maxWidth="md">
      <DialogContent>
        <Stack
          direction="row"
          marginBottom={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h2" fontSize={18}>
            Chat
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          borderRadius={2}
          height="600px"
          padding={4}
          style={{
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
          }}
        >
          <Box>
            <Typography>Chat</Typography>
          </Box>
        </Box>
        <Stack
          height={30}
          marginTop={2}
          borderRadius={2}
          direction="row"
          alignItems="center"
        >
          <Box
            padding={1}
            width="100%"
            height="100%"
            marginRight={2}
            borderRadius={2}
            component="textarea"
            placeholder="message"
            sx={{
              border: 'none',
              resize: 'none',
              boxShadow: '0px 1px 11px rgba(0, 0, 0, 0.12)'
            }}
          />
          <Stack component="div" alignItems="center" sx={{ cursor: 'pointer' }}>
            <SendIcon color="primary" sx={{ paddingLeft: '12px' }} />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
