import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import ColumnHeader from 'components/table/ColumnHeader'
import TableChip from 'components/table/TableChip'
import { COLORS } from 'styles/colors'
import { ListPayments } from 'types/bonusPayments'
import { formatDate } from 'utils/index'
import PaymentStatusFilters from '../filters/Status'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80, sortable: false },
  {
    field: 'type',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Bonus For',
    width: 140,
    editable: true,
    sortable: false,
    renderCell: props => {
      return (
        <div>{`${
          props.row.type === 'referrer' ? 'Employee' : 'Referral'
        }`}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'user',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Name',
    width: 220,
    editable: true,
    sortable: false,
    renderCell: props => {
      return (
        <div>{`${props.row.user.first_name} ${
          props.row.user.last_name || ''
        }`}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'bonus',
    headerName: 'Bonus',
    headerAlign: 'left',
    align: 'left',
    width: 130,
    editable: true,
    sortable: false,
    renderCell: props => {
      return <div>${props.row.amount} usd</div>
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'job',
    headerName: 'Job Name',
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left',
    width: 260,
    filterable: false,
    sortable: false,
    renderCell: props => {
      return (
        <Typography color={COLORS.main} variant="body2">
          {props.row.job.name}
        </Typography>
      )
    },
    renderHeader: props => {
      return <ColumnHeader {...props} />
    }
  },
  {
    field: 'inserted_at',
    headerName: 'Due date',
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left',
    width: 140,
    filterable: false,
    sortable: false,
    renderCell: props => {
      return <div>{formatDate(new Date(props.row.date), 'dd LLL, yy')}</div>
    },
    renderHeader: props => {
      return <ColumnHeader {...props} />
    }
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    width: 180,
    editable: true,
    renderCell: props => {
      return (
        <div>{formatDate(new Date(props.row.updated_at), 'dd LLL, yy')}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    editable: true,
    sortable: false,
    renderCell: props => (
      <TableChip {...props} chipProps={{ variant: 'filled' }} />
    ),
    renderHeader: props => (
      <ColumnHeader {...props}>
        <PaymentStatusFilters />
      </ColumnHeader>
    )
  }
]

export const BONUS_FOR = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const BONUS_FILTERS = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const METHOD_FILTERS = [
  {
    id: 1,
    label: 'filtro 1'
  }
]

export const DUE_DATE_FILTERS = [
  {
    id: 1,
    label: 'active'
  },
  {
    id: 1,
    label: 'inactive'
  }
]
export const PAYMENT_STATUS_FILTERS = [
  {
    id: 'active',
    label: 'Active'
  },
  {
    id: 'inactive',
    label: 'Inactive'
  }
]
export const PAYMENT_STATUS = [
  {
    id: 'approved',
    label: 'Approved'
  },
  {
    id: 'pending',
    label: 'Pending'
  },
  {
    id: 'canceled',
    label: 'Canceled'
  },
  {
    id: 'deleted',
    label: 'Deleted'
  },
  {
    id: 'review',
    label: 'Review'
  },
  {
    id: 'rejected',
    label: 'Rejected'
  }
]
export const StatusDetail = [
  {
    id: 'review',
    label: 'Review'
  },
  {
    id: 'approved',
    label: 'Approved'
  },
  {
    id: 'reject',
    label: 'Rejected'
  }
]

export const VALID_CHANGE_STATUS = [
  {
    id: 'approved',
    label: 'Approved'
  },
  {
    id: 'reject',
    label: 'Rejected'
  }
]

export const PaymentMethod = [
  {
    id: 'giftcard',
    label: 'GiftCard'
  },
  {
    id: 'payroll',
    label: 'Payroll'
  }
]

export const getRows = (data: ListPayments['entries']) => {
  const filteredJobs = data.map(item => {
    return {
      ...item
    }
  })
  return filteredJobs || []
}
