import { Box, Stack, Checkbox, FormControlLabel, Button } from '@mui/material'
import type { UseFilter } from 'types/table'
import { PAYMENT_STATUS } from '../constants'
import useFilter from '../hooks/useFilter'

export default function EmployeeStatus() {
  const { filterPayment, onChangeValue, onAddUrlFilters, onClearFilters } =
    useFilter()

  return (
    <Box padding={2}>
      {PAYMENT_STATUS.map(({ id, label }) => {
        const value =
          filterPayment.status.find(item => item.id === id) || ({} as UseFilter)
        return (
          <Stack key={id} direction="row" marginBottom={2}>
            <FormControlLabel
              onChange={(_, checked) =>
                onChangeValue({
                  filter: { id, label, checked },
                  name: 'status'
                })
              }
              checked={(value.checked as boolean) || false}
              label={label}
              control={<Checkbox />}
              componentsProps={{
                typography: {
                  variant: 'h5'
                }
              }}
            />
          </Stack>
        )
      })}
      <Stack direction="row" spacing={2}>
        <Button fullWidth onClick={() => onClearFilters('status')}>
          Clear
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onAddUrlFilters('status')}
        >
          Apply
        </Button>
      </Stack>
    </Box>
  )
}
