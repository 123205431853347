import { GetPositions } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { get } from 'utils'

export default function useBonusPayments() {
  const { onNotify } = useNotification()
  const { params } = useNavigation()

  const { isLoading, data, isFetching } = useQuery(
    `BONUSPAYMENT`,
    () => GetPositions(Number(params.id)),
    {
      refetchOnWindowFocus: false,
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return {
    data,
    isLoading: isFetching || isLoading,
    isEmpty: data?.entries.length === 0
  }
}
