import { Box, Stack, Typography } from '@mui/material'
import { Funnel } from '@nivo/funnel'
import useAuth from 'hooks/useAuth'
import useReferralFunnelGraph from '../hooks/useReferralFunnelGraph'
import useReferralFunnelGraphE from '../hooks/useReferralFunnelGraphE'
export const FunnelReferrals = () => {
  const { userRole } = useAuth()
  const { dataFunnel } =
    userRole == 'super_admin'
      ? useReferralFunnelGraph()
      : useReferralFunnelGraphE()

  const { data } = dataFunnel

  const ordData = data?.map((item: any) => {
    if (item.id == 'new') {
      item.ord = 0
    }
    if (item.id == 'progress') {
      item.ord = 1
    }
    if (item.id == 'hired') {
      item.ord = 2
    }
    return item
  })

  const datos = ordData
    ? ordData?.sort((a: any, b: any) => a.ord - b.ord)
    : [{ id: '', label: '', value: 0 }]

  return (
    <Stack width={564} height={310} position={'relative'}>
      <Box position={'absolute'} right={10}>
        <Funnel
          width={330}
          height={270}
          data={datos}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          colors={['#0080E8', '#FFBD31', '#2D9D78']}
          borderWidth={20}
          labelColor={{
            from: 'color',
            modifiers: [['darker', 3]]
          }}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      </Box>
      <Box position={'absolute'} left={10} bottom={90}>
        <Typography marginLeft={2} variant="body2">
          <img src="/images/Rectangle1342.svg" alt="logo" /> Hired
        </Typography>
      </Box>
      <Box position={'absolute'} left={10} bottom={110}>
        <Typography marginLeft={2} variant="body2">
          <img src="/images/Rectangle1341.svg" alt="logo" /> In progress
        </Typography>
      </Box>
      <Box position={'absolute'} left={10} bottom={130}>
        <Typography marginLeft={2} variant="body2">
          <img src="/images/Rectangle1339.svg" alt="logo" /> New
        </Typography>
      </Box>
    </Stack>
  )
}
