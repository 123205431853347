import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import { JobDetail } from 'types/jobs'
import { formatCurrency } from 'utils'
import useBuyBonus from '../useBuyBonus'

export default function BuyDetail({ details }: { details: JobDetail }) {
  const {
    bonusForReferral,
    bonusForReferrer,
    currentBalance,
    totalEmployess,
    referralTotal,
    totalReferral,
    referrerTotal,
    totalBalance,
    newBalance,
    totalBonus,
    isLoading,
    updating,
    positions,
    onBackStep,
    onSubmit
  } = useBuyBonus(details)

  return (
    <Box padding="16px" minHeight="50vh">
      {bonusForReferrer.length && (
        <Stack>
          <Typography variant="body2">Bonus for employee</Typography>
          <Stack flexDirection="row" marginY={2}>
            <Stack sx={{ width: '40%' }}>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Bonus type
              </Typography>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                User type
              </Typography>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Total amount
              </Typography>

              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Conditions
              </Typography>
            </Stack>
            <Stack>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                Flat rate
              </Typography>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                Employees
              </Typography>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                ${referrerTotal} USD
              </Typography>

              <Stack>
                {bonusForReferrer.map((item, index) => (
                  <Typography
                    key={index}
                    marginBottom={2}
                    variant="h5"
                    color={COLORS.black}
                  >
                    {`$${item.amount} USD after ${item.days} days`}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      {bonusForReferral.length && (
        <Stack>
          <Typography variant="body2">Bonus for referral</Typography>
          <Stack flexDirection="row" marginY={2}>
            <Stack sx={{ width: '40%' }}>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Bonus type
              </Typography>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                User type
              </Typography>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Total amount
              </Typography>

              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Conditions
              </Typography>
            </Stack>
            <Stack>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                Flate rate
              </Typography>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                Referral
              </Typography>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                ${referralTotal} USD
              </Typography>

              <Stack>
                {bonusForReferral.map((item, index) => (
                  <Typography
                    key={index}
                    marginBottom={2}
                    variant="h5"
                    color={COLORS.black}
                  >
                    {`$${item.amount} USD after ${item.days} days`}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      {isLoading ? (
        <span>loading...</span>
      ) : (
        <>
          <Typography variant="body2">Payment information</Typography>
          <Stack direction="row" justifyContent="space-between" marginTop={2}>
            <Typography
              marginBottom={2}
              variant="body2"
              color={COLORS.gray2}
              width="40%"
            >
              Current balance
            </Typography>
            <Typography
              marginBottom={2}
              variant="h5"
              color={COLORS.main}
              width="60%"
            >
              {formatCurrency(currentBalance || 0)} USD
            </Typography>
          </Stack>
          {bonusForReferrer.length && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="body2"
                  color={COLORS.gray2}
                  width="40%"
                >
                  Employees
                </Typography>
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="h5"
                  color={COLORS.black}
                  width="60%"
                >
                  {positions} Job positions X {referrerTotal} USD
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                  width="40%"
                >
                  Total for employees
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="h5"
                  color={COLORS.black}
                  width="60%"
                >
                  {formatCurrency(totalEmployess)} USD
                </Typography>
              </Stack>
            </>
          )}
          {bonusForReferral.length && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="body2"
                  color={COLORS.gray2}
                  width="40%"
                >
                  Referrals
                </Typography>
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="h5"
                  color={COLORS.black}
                  width="60%"
                >
                  positions {positions} X {referralTotal} USD
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                  width="40%"
                >
                  Total for referrals
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="h5"
                  color={COLORS.black}
                  width="60%"
                >
                  {formatCurrency(totalReferral)} USD
                </Typography>
              </Stack>
            </>
          )}
          {(totalEmployess > 0 || totalReferral > 0) && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="body2"
                  color={COLORS.gray2}
                  width="40%"
                >
                  Total to pay
                </Typography>
                <Typography
                  marginBottom={2}
                  marginTop={2}
                  variant="h5"
                  color={COLORS.black}
                  width="60%"
                  fontWeight={700}
                >
                  {formatCurrency(totalBonus)} USD
                </Typography>
              </Stack>
              {newBalance > 0 && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    New balance
                  </Typography>
                  <Typography
                    marginBottom={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                    fontWeight={700}
                  >
                    {formatCurrency(newBalance)} USD
                  </Typography>
                </Stack>
              )}
            </>
          )}

          {totalBonus > totalBalance && (
            <Typography
              variant="h6"
              color={COLORS.red1}
              fontSize={14}
              marginY={2}
            >
              To continue you need{' '}
              <Link to="/profile/company">Fund your account</Link>
            </Typography>
          )}
          {totalBalance > totalBonus && (
            <Typography
              variant="h6"
              color={COLORS.red1}
              fontSize={14}
              marginY={2}
            >
              You are going to pay{' '}
              <strong>{formatCurrency(totalBonus)} </strong> USD dollars
            </Typography>
          )}
        </>
      )}
      <LoadingButton
        fullWidth
        variant="contained"
        loading={isLoading || updating}
        disabled={isLoading || totalBalance <= 0 || updating}
        onClick={onSubmit}
        sx={{ marginTop: 2 }}
      >
        Buy positions
      </LoadingButton>
      <Button
        fullWidth
        variant="text"
        disabled={isLoading}
        onClick={onBackStep}
        sx={{ marginTop: 2 }}
      >
        Back
      </Button>
    </Box>
  )
}
