import { styled, css } from '@mui/material/styles'
import { COLORS } from 'styles/colors'
import { hexToRGB } from 'styles/utils'
import { Variant, VariantProps } from 'types/paymentCard'

export const PaymentCardIcon = styled('div')<{
  bgcolor: string
  opacity: string
}>`
  width: 3em;
  height: 2.5em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.bgcolor &&
    css`
      background: ${hexToRGB(props.bgcolor, props.opacity)};
    `}
`

export const VARIANT: { [key in Variant]: VariantProps } = {
  warning: { bgcolor: COLORS.honey7, color: COLORS.orange1, opacity: '0.1' },
  warning2: { bgcolor: COLORS.honey4, color: COLORS.honey1, opacity: '0.2' },
  error: { bgcolor: COLORS.redRose, color: COLORS.red1, opacity: '0.6' },
  error2: { bgcolor: COLORS.red2, color: COLORS.red1, opacity: '0.2' },
  success: { bgcolor: COLORS.green2, color: COLORS.green1, opacity: '0.2' },
  primary: { bgcolor: COLORS.blue10, color: COLORS.blue10, opacity: '0.2' },
  other: { bgcolor: COLORS.gray6, color: COLORS.gray7, opacity: '0.2' },
  default: { bgcolor: COLORS.white, color: COLORS.white, opacity: '0.2' }
}
