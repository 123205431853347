import { useState } from 'react'
import { GridSelectionModel } from '@mui/x-data-grid'
import { UpdateJobsStatus } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'
import type { UpateJobStatusData } from 'types/jobs'

import { get } from 'utils'

export default function useJobStatus() {
  const { onNotify } = useNotification()
  const { params } = useNavigation()
  const [selectedJobs, setSelectedJobs] = useState<GridSelectionModel>([])

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: UpateJobStatusData) => UpdateJobsStatus(data),
    {
      onSuccess: () => {
        onNotify({
          message: 'Status updated successfully',
          severity: 'success'
        })
        cleanSelectedJobs()
        query.invalidateQueries('JOBS')
        query.invalidateQueries('JOB_DETAIL')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const cleanSelectedJobs = () => setSelectedJobs([])

  const onSelectedJobs = (jobs: GridSelectionModel) => {
    setSelectedJobs(jobs)
  }

  const onSubmit = (status: UpateJobStatusData['status']) => {
    if (params.id) {
      mutate({ jobs: [Number(params.id)], status })
    }
  }

  return { selectedJobs, isLoading, onSubmit, onSelectedJobs }
}
