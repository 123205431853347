import PaymentsHeader from 'app/bonus/payments/PaymentsHeader'
import PaymentsTable from 'app/bonus/payments/PaymentsTable'
export default function Payments() {
  return (
    <>
      <PaymentsHeader />
      <PaymentsTable />
    </>
  )
}
