import React from 'react'
import Payments from 'app/bonus/payments'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { Outlet } from 'react-router-dom'
import { getMenu, routePermissions } from 'utils/catalogs'
import Templates from './templates'

type KeyRoute = 'payments' | 'templates'

const component: { [key in KeyRoute]: React.ReactNode } = {
  payments: <Payments />,
  templates: <Templates />
}

const BONUS = '/bonus'

export default function Bonus() {
  const { userRole } = useAuth()
  const { pathname } = useNavigation()
  const routePermissionsByRole = routePermissions[userRole]

  const menu = getMenu(userRole)

  const bonus = menu.find(item => item.route === 'bonus')

  const subBonus = bonus?.submenu?.filter(item =>
    routePermissionsByRole.includes(item.route)
  )[0].route as KeyRoute

  const isPayments = pathname === BONUS

  return <>{isPayments ? component[subBonus] : <Outlet />}</>
}
