import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { stringifyQuery, isEmpty, parseQuery } from 'utils'

export default function useNavigation() {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const params = useParams<any>()
  const searchParams = parseQuery(search, { arrayFormat: 'bracket' })

  const onNavigate = (
    route: string,
    params?: { search: { [K: string]: string | number | any } }
  ) => {
    const stringifyParams = !isEmpty(params?.search || {})
      ? stringifyQuery(params?.search || {})
      : ''

    navigate({
      pathname: route,
      search: stringifyParams
    })
  }

  return {
    onNavigate,
    pathname,
    searchParams: searchParams as { [K: string]: string | number | any },
    params
  }
}
