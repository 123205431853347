import { styled } from '@mui/material/styles'

export const SigninImg = styled('img')`
  width: 100%;
  padding: 0;
  margin: 0;
`

export const SigninLogo = styled('img')`
  margin-top: 42px;
  width: 144px;
`
