import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Paper, Typography, Stack, IconButton } from '@mui/material'
import FaceBookIcon from 'components/icons/FaceBook'
import LinkedinIcon from 'components/icons/Linkedin'
import TwitterIcon from 'components/icons/Twitter'
import useAuth from 'hooks/useAuth'
import useCopy from 'hooks/useCopy'
import { COLORS } from 'styles/colors'
import useJobBoardDetail from '../hooks/useJobBoardDetail'

const urlToApply = process.env.REACT_APP_URL_APPLY_JOB

export default function ShareJob() {
  const { copy } = useCopy()
  const { details } = useJobBoardDetail()
  const { user } = useAuth()

  const companyUrl = `${urlToApply}/company/${user.company_id}/jobs/${details.id}/${user.id}`

  const handleOpenUrl = (event: any) => {
    window.open(event.target.href, '_blank')
  }

  return (
    <Box component={Paper} marginTop={3}>
      <Typography variant="h3" marginBottom={3}>
        Share Job Link
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography
          component="a"
          target="_blank"
          href={companyUrl}
          textOverflow="ellipsis"
          onClick={handleOpenUrl}
          overflow={{ xs: 'hidden', sm: 'auto' }}
        >
          {companyUrl}
        </Typography>
        <IconButton onClick={() => copy(companyUrl)}>
          <ContentCopyIcon htmlColor={COLORS.main} />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        marginTop={2}
        borderTop={`1px solid ${COLORS.honey2}`}
      >
        <Box
          component="a"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${companyUrl}`}
        >
          <FaceBookIcon sx={{ marginRight: 1 }} />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`http://twitter.com/share?text=${companyUrl}`}
        >
          <TwitterIcon sx={{ marginRight: 1 }} />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${companyUrl}`}
        >
          <LinkedinIcon />
        </Box>
      </Stack>
    </Box>
  )
}
