import EditIcon from '@mui/icons-material/Edit'

import { Box, Stack, Typography, Button, Divider } from '@mui/material'
import { COLORS } from 'styles/colors'
import { CompanyDetail } from 'types/companies'
import useBegoppInformation from '../beegoppInformation/useBegoppInformation'
import EditCompany from './editCompany'

const HEADQUATER = 'headquarter'
const BILLING = 'billing'

export default function GeneralInformation({
  company
}: {
  company: CompanyDetail
}) {
  const { onHandleOpenEdit, edit } = useBegoppInformation()

  const headquarterAddress =
    company.addresses?.filter(address => address.type === HEADQUATER) || []

  const billingAddress =
    company.addresses?.filter(address => address.type === BILLING) || []

  return (
    <Box
      height="100%"
      sx={{
        overflow: 'auto'
      }}
    >
      {edit ? (
        <EditCompany company={company} />
      ) : (
        <>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft={2}
            paddingBottom="5px"
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h5"
                fontWeight="500"
                fontSize={{ xs: '18px', sm: '20px' }}
              >
                General Information
              </Typography>
            </Stack>
            <Button
              onClick={onHandleOpenEdit}
              startIcon={<EditIcon htmlColor={COLORS.main} />}
              variant="text"
              color="primary"
              sx={{
                marginRight: '10px'
              }}
            >
              Edit
            </Button>
          </Stack>
          <Divider />
          <Box padding={2}>
            <Stack
              paddingBottom="8px"
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            >
              <Typography
                width={{ xs: '100%', lg: '30%' }}
                paddingY="8px"
                variant="h5"
              >
                ID
              </Typography>
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '70%' }}
                paddingY="8px"
              >
                {company.id}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              justifyContent={{ xs: 'start', md: 'space-between' }}
              paddingBottom="8px"
            >
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '30%' }}
                paddingY="8px"
              >
                company name
              </Typography>
              <Typography
                variant="h5"
                paddingY="8px"
                width={{ xs: '100%', lg: '70%' }}
                color={COLORS.gray2}
              >
                {company.name}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              justifyContent={{ xs: 'start', md: 'space-between' }}
              paddingBottom="8px"
            >
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '30%' }}
                paddingY="8px"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                company URL
              </Typography>
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '70%' }}
                paddingY="8px"
                color={COLORS.main}
              >
                {company.url}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              justifyContent={{ xs: 'start', md: 'space-between' }}
            >
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '30%' }}
                paddingY="8px"
              >
                Phone number
              </Typography>
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '70%' }}
                paddingY="8px"
              >
                {company.phone || '-'}
              </Typography>
            </Stack>
          </Box>
          <Divider
            sx={{
              background: COLORS.honey2
            }}
          />
          <Box padding={2}>
            <Typography fontSize="14px" fontWeight="500" paddingY="8px">
              Company address
            </Typography>
            {headquarterAddress.length > 0
              ? headquarterAddress.map(address => (
                  <Box key={address.id}>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Street
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.street}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        State
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.state}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Zipcode
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.zipcode}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Country
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.country}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        City
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.city}
                      </Typography>
                    </Stack>
                  </Box>
                ))
              : 'Not direction'}
          </Box>
          <Divider
            sx={{
              background: COLORS.honey2
            }}
          />
          <Box padding={2}>
            <Typography fontSize="14px" fontWeight="500" paddingY="8px">
              Billing address
            </Typography>
            {billingAddress.length > 0
              ? billingAddress.map(address => (
                  <Box key={address.id}>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Street
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.street}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        State
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.state}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                      paddingBottom="8px"
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Zipcode
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.zipcode}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        Country
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.country}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      alignItems={{ xs: 'flex-start', lg: 'center' }}
                      justifyContent={{ xs: 'start', md: 'space-between' }}
                    >
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '30%' }}
                        paddingY="8px"
                      >
                        City
                      </Typography>
                      <Typography
                        variant="h5"
                        width={{ xs: '100%', lg: '70%' }}
                        paddingY="8px"
                      >
                        {address.city}
                      </Typography>
                    </Stack>
                  </Box>
                ))
              : 'Not direction'}
          </Box>
          <Divider />
        </>
      )}
    </Box>
  )
}
