import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useNavigation from 'hooks/useNavigation'
import type { ToggleSidebar } from 'types/general'
import { isEmpty } from 'utils'

type Toggle = { [key in ToggleSidebar]: boolean }
type SearchParams = { [key in string]: string | unknown }

const initialState = hookstate({
  openToggle: {} as Toggle
})

export default function useToggle() {
  const { onClearSteps } = useStepper()
  const { onNavigate, pathname, searchParams } = useNavigation()
  const { openToggle } = useHookstate(initialState)

  useEffect(() => {
    if (!isEmpty(searchParams)) {
      openToggle.set(searchParams as Toggle)
    }
  }, [])

  const onToggleSidebar = ({
    type,
    toggle,
    searchParams
  }: {
    type: 'open' | 'close'
    toggle: ToggleSidebar
    searchParams?: SearchParams
  }) => {
    if (type === 'open') {
      openToggle.set(prevState => ({
        ...prevState,
        [toggle]: true
      }))
      onNavigate(pathname, {
        search: { [toggle]: type, ...searchParams }
      })
    } else {
      openToggle.set(prevState => ({
        ...prevState,
        [toggle]: false
      }))
      onNavigate(pathname, {
        search: { ...searchParams }
      })
      onClearSteps()
    }
  }

  const onOpenToggle = (toggle: ToggleSidebar, searchParams?: SearchParams) =>
    onToggleSidebar({ type: 'open', toggle, searchParams })
  const onCloseToggle = (toggle: ToggleSidebar, searchParams?: SearchParams) =>
    onToggleSidebar({ type: 'close', toggle, searchParams })

  return { openToggle: openToggle.value, onOpenToggle, onCloseToggle }
}
