import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

type EmployeeDialogsModel = {
  open: boolean
  handleClose: () => void
  title: string
  content: string
}

export const EmployeeDialogs = ({
  open,
  handleClose,
  title,
  content
}: EmployeeDialogsModel) => {
  const handleClick = () => {
    handleClose()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
          margin: 2
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClick} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
