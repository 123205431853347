import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { listImages } from 'utils/catalogs'
import useEnterEmailCode, { validationSchema } from './useEnterEmailCode'

export default function EnterEmailCode() {
  const { onSubmit, onRequestNewCode, searchParams } = useEnterEmailCode()
  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h1" paddingX="8px" paddingBottom={4}>
              {searchParams.reset ? 'Password recovery' : 'Email verification'}
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              {searchParams.reset
                ? 'We sent a verification code to your email. Please type it below.'
                : 'We sent a code to your email in order to validate your email. This code is valid only for 5 minutes. Please enter it below'}
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={{ codeEmail: '' }}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <TextField
                    sx={{
                      paddingBottom: '30px'
                    }}
                    label="Code"
                    variant="standard"
                    name="codeEmail"
                    fullWidth
                  />
                  <Box
                    marginY={2}
                    component="div"
                    justifyContent="end"
                    display="flex"
                    sx={{ cursor: 'pointer' }}
                    onClick={onRequestNewCode}
                  >
                    <Typography
                      fontSize={14}
                      color={COLORS.main}
                      sx={{ textDecoration: 'underline' }}
                    >
                      I didn’t get the code
                    </Typography>
                  </Box>
                  <Button variant="contained" type="submit" fullWidth>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
