import styled, { css } from 'styled-components'
import { COLORS } from 'styles/colors'
import { device, hexToRGB } from 'styles/utils'

export const SidebarSubmenu = styled.div<{ isOpen: boolean }>`
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0.875em;
  transition: max-height 0.3s ease-out;
  ${props =>
    props.isOpen &&
    css`
      max-height: 150px;
    `};
`

export const SidebarSubmenuItem = styled.div<{ selected: boolean }>`
  color: ${COLORS.white};
  position: relative;
  margin: 0;
  padding: 0.375em;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 1em;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      background: ${hexToRGB(COLORS.honey4, '0.2')};
      ::before {
        content: '';
        background: ${COLORS.honey4};
        position: absolute;
        width: 0.1875em;
        left: 0.625em;
        height: 24px;
      }
    `};
`

export const SidebarContainer = styled.nav<{ isOpen: boolean }>`
  width: 0;
  overflow: hidden;
  position: fixed;
  background: ${COLORS.blue1};
  height: 100%;
  z-index: 3;
  transition: all 0.3s;
  overflow: auto;
  ${props =>
    props.isOpen &&
    css`
      top: 0;
      bottom: 0;
      padding: 0 0.5em;
      width: 14.375em;
      overflow: visible;
    `};
  ${device('md')} {
    padding: 0 0.5em;
    width: 14.375em;
    position: static;
  }
  ::-webkit-scrollbar {
    background: 'transparent';
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.blue6};
    border-radius: 8px;
  }
  scrollbar-color: ${COLORS.blue6} ${COLORS.gray6};
  scrollbar-width: 'thin';
  opacity: '0.8';
`

export const SidebarList = styled.ul`
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 17.75em);
`
export const SidebarLogo = styled.figure`
  margin: 0;
  padding: 1.5em 2em;
  width: 146px;
  & > img {
    width: 146px;
  }
`
export const EmptyLogo = styled.figure`
  margin: 0;
  width: 82px;
  & > img {
    width: 82px;
  }
`

export const SidebarListItem = styled.li<{
  selected: boolean
  selectedBgcolor?: string
}>`
  cursor: pointer;
  font-size: 0.875em;
  padding: 0 0.8em;
  height: 4em;
  margin-bottom: 0.875em;
  text-decoration: none;
  border-radius: 0.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.white};
  &:hover {
    color: ${COLORS.gray1};
    background: ${props => props.selectedBgcolor || COLORS.honey4};
  }
  ${props =>
    props.selected &&
    css`
      background: ${props.selectedBgcolor || COLORS.honey4};
      color: ${COLORS.gray1};
    `};
`
