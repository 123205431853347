import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  DialogActions,
  DialogContent
} from '@mui/material'
import { COLORS } from 'styles/colors'
import { Referrals } from 'types/referrals'
import useDeleteReferral from './useDeleteReferral'

export default function DialogDelete({
  referrals
}: {
  referrals: Referrals['entries']
}) {
  const {
    selectedReferralCheck,
    isOpenDialog,
    isLoading,
    onSubmit,
    onCloseDialog
  } = useDeleteReferral()

  const filteredReferrals = referrals.filter(item => {
    return selectedReferralCheck.includes(item.id)
  })

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpenDialog}>
      <DialogContent
        sx={{ paddingY: '10px', paddingX: '20px', margin: '20px' }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom={5}
        >
          <Typography variant="h3">Delete Referrals</Typography>
        </Box>
        {filteredReferrals.map(
          ({ first_name: name, last_name: lastName, email, id }) => (
            <Grid container key={id}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Referral name</Typography>
                  <Typography variant="h5" fontSize={14}>
                    {name} {lastName}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Referral email</Typography>
                  <Typography variant="h5" fontSize={14} color={COLORS.main}>
                    {email}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
            </Grid>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: 4, paddingBottom: 4 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={onCloseDialog}
          sx={{ marginRight: '3px' }}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          sx={{ marginLeft: '3px' }}
          onClick={onSubmit}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
