import { EmployeeReferralTable } from 'app/employees/EmployeeDetail/referrals/EmployeeReferralTable'
import useAuth from 'hooks/useAuth'
import ReferralsHeader from './ReferralsHeader'
import ReferralsTable from './ReferralsTable'

const ReferralsList = () => {
  return (
    <>
      <ReferralsHeader />
      <ReferralsTable />
    </>
  )
}

export default function Referrals() {
  const { userRole } = useAuth()
  return userRole === 'employee' ? <EmployeeReferralTable /> : <ReferralsList />
}
