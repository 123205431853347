import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Divider,
  Stack,
  Typography,
  IconButton,
  Link
} from '@mui/material'
import ButtonGroup from 'components/buttonGroup'
import Paper from 'components/paper'
import { CompanyDetailSkeleton } from 'components/skeleton'
import useCopy from 'hooks/useCopy'
import { useLoginAs } from 'stores'
import { COLORS } from 'styles/colors'
import { CompanyDetail } from 'types/companies'
import { formatDate } from 'utils'
import { STATUS_COLORS } from '../constans'
import useCompanyDetail from '../hooks/useCompanyDetail'
import useUpdateStatus from '../hooks/useUpdateStatus'

const HEADQUATER = 'headquarter'
const BILLING = 'billing'

export default function CompanyInformationTable({
  handleClose
}: {
  handleClose: () => void
}) {
  const { copy } = useCopy()
  const { onOpenLoginAs, isLoginAs, isLoading: loadingLoginAs } = useLoginAs()
  const { company, isLoading } = useCompanyDetail()
  const { onSubmit } = useUpdateStatus()

  const handleLoginAs = () => {
    onOpenLoginAs(company)
    handleClose()
  }

  const owner = company.owner || ({} as CompanyDetail['owner'])

  const headquarterAddress = company?.addresses?.filter(
    address => address.type === HEADQUATER
  )

  const billingAddress = company?.addresses?.filter(
    address => address.type === BILLING
  )

  return (
    <Box padding={{ xs: 1, sm: 2 }}>
      <Stack alignItems="end">
        <ButtonGroup
          loadingOption={isLoading}
          selectedValue={{ id: company.status, label: company?.status }}
          bgcolor={STATUS_COLORS[company.status]}
          onChange={option => onSubmit(option, company.id)}
          options={[
            { id: 'active', label: 'Active' },
            { id: 'inactive', label: 'Inactive' }
          ]}
        />
      </Stack>
      <Box component={Paper} marginTop={4}>
        {isLoading ? (
          <CompanyDetailSkeleton />
        ) : (
          <>
            <Typography variant="h3" paddingBottom={4}>
              {company.name}
            </Typography>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="8px"
            >
              <Typography
                variant="body2"
                width="40%"
                paddingY="8px"
                color={COLORS.gray2}
              >
                Company URL
              </Typography>
              <Link
                href={company.url}
                target="_blank"
                sx={{
                  width: '50%',
                  paddingY: '8px'
                }}
              >
                {company.url}
              </Link>
              <IconButton
                onClick={() => copy(company.url)}
                disabled={!company.url}
              >
                <ContentCopyIcon
                  htmlColor={company.url ? COLORS.main : COLORS.gray3}
                  sx={{ width: '100%' }}
                />
              </IconButton>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="8px"
            >
              <Typography
                variant="body2"
                width="40%"
                paddingY="8px"
                color={COLORS.gray2}
              >
                Joined date
              </Typography>
              <Typography variant="h5" width="60%" paddingY="8px">
                {formatDate(company.inserted_at)}
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="8px"
            >
              <Typography
                variant="body2"
                width="40%"
                paddingY="8px"
                color={COLORS.gray2}
              >
                Phone number
              </Typography>
              <Typography variant="h5" width="60%" paddingY="8px">
                {company.phone || '-'}
              </Typography>
            </Stack>
            <Divider sx={{ background: COLORS.honey2 }} />
            <Typography
              fontSize="14px"
              fontWeight="500"
              paddingY="8px"
              marginTop="8px"
            >
              Owner information
            </Typography>
            <Box>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  ID
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {company.id}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Name
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {owner.first_name || '-'}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Last name
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {owner.last_name || '-'}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Email
                </Typography>
                <Link
                  href={`mailto:${owner.email}`}
                  sx={{
                    width: '60%',
                    paddingY: '8px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {owner.email}
                </Link>
              </Stack>
            </Box>
            <Divider sx={{ background: COLORS.honey2 }} />
            <Box marginTop="8px">
              <Typography fontSize="14px" fontWeight="500" paddingY="8px">
                Company address
              </Typography>
              {headquarterAddress?.map(address => (
                <Box key={address.id}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Street
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.street}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      State
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.state}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Zipcode
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.zipcode}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    paddingBottom="8px"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Country
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.country}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Box>
            <Divider sx={{ background: COLORS.honey2 }} />
            <Box marginTop="8px">
              <Typography fontSize="14px" fontWeight="500" paddingY="8px">
                Billing Address
              </Typography>
              {billingAddress?.map(address => (
                <Box key={address.id}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Street
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.street}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      State
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.state}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="8px"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Zipcode
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.zipcode}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    paddingBottom="8px"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body2"
                      width="40%"
                      paddingY="8px"
                      color={COLORS.gray2}
                    >
                      Country
                    </Typography>
                    <Typography variant="h5" width="60%" paddingY="8px">
                      {address.country}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Box>
            <Divider sx={{ background: COLORS.honey2 }} />
            <Box marginTop="8px">
              <Typography fontSize="14px" fontWeight="500" paddingY="8px">
                Company overview
              </Typography>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Paid bonuses/Credits
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {company.paid_bonuses}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Jobs
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {company.jobs}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Employees
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {company.employes}
                </Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                paddingBottom="8px"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.gray2}
                >
                  Referrals
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {company.referrals}
                </Typography>
              </Stack>
            </Box>
            <Stack margin="16px 22px">
              <LoadingButton
                loading={loadingLoginAs}
                disabled={loadingLoginAs || isLoginAs}
                variant="contained"
                onClick={handleLoginAs}
              >
                Login as this company
              </LoadingButton>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  )
}
