import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { OnToggleConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'
import * as yup from 'yup'

const initialGlobalState = hookstate({
  openDialog: false,
  openSidebar: false
})

export const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password required')
    .min(8, 'Minimun 8 characters')
})

export default function useDisconnect() {
  const { user } = useAuth()
  const { onNotify } = useNotification()
  const { openDialog, openSidebar } = useHookstate(initialGlobalState)
  const [showPassword, setShowPassword] = useState(false)
  const { onNavigate, pathname } = useNavigation()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { config: { enable: 'true' | 'false' } }) =>
      OnToggleConfig(data, user.config_id),
    {
      onSuccess: () => {
        onNotify({ severity: 'success', message: 'Enable success' })
        onCloseDialog()
        query.invalidateQueries('COMPANY_DETAIL_CONFIG')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onOpenSidebar = () => openSidebar.set(true)
  const onCloseSidebar = () => {
    openSidebar.set(false)
    onNavigate(pathname)
  }

  const onShowPassword = () => setShowPassword(!showPassword)

  const onOpenDialog = () => openDialog.set(true)
  const onCloseDialog = () => openDialog.set(false)

  const onSubmit = (enable: 'true' | 'false') => {
    mutate({ config: { enable } })
  }

  const onConnect = ({
    atsId,
    isEnable
  }: {
    isEnable: boolean
    atsId: number
  }) => {
    if (user.config_id && !isEnable) {
      return mutate({ config: { enable: 'true' } })
    }
    onNavigate(pathname, {
      search: { atsId }
    })
    onOpenSidebar()
  }

  return {
    onSubmit,
    onOpenDialog,
    onOpenSidebar,
    onCloseSidebar,
    onCloseDialog,
    onShowPassword,
    onConnect,
    showPassword,
    isLoading,
    hasConfigId: user.config_id,
    openDialog: openDialog.value,
    openSidebar: openSidebar.value
  }
}
