import { LoadingButton } from '@mui/lab'
import { Button, Typography, Box, Stack, Divider } from '@mui/material'
import Paper from 'components/paper'
import useStepper from 'components/stepper/useStepper'
import { Form } from 'formik'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import { NewBonusTemplateProps, BonusConfig } from 'types/bonusTemplate'
import { formatDate } from 'utils'
import { isEmpty } from 'utils/index'
import useAddBonus from '../hooks/useAddBonusTemplate'
import useBonusTable from '../hooks/useBonusTable'

export default function ReviewStep({ values }: NewBonusTemplateProps) {
  const { onToggleTemplate } = useBonusTable()
  const { onBackStep } = useStepper()
  const { user } = useAuth()
  const { onAddBonusTemplate, isLoading } = useAddBonus()

  const [referralTotal, referrerTotal] = values.bonus_config.reduce(
    (acc, { type, amount }) => [
      acc[0] + (type === 'referral' ? Number(amount) : 0),
      acc[1] + (type !== 'referral' ? Number(amount) : 0)
    ],
    [0, 0]
  )

  const validateIsForReferrer = () => {
    return (
      !isEmpty(
        values.bonus_config.find(item => item.type === 'referrer') || {}
      ) && values.is_for_referrer
    )
  }

  const validateIsForReferral = () => {
    return (
      !isEmpty(
        values.bonus_config.find(item => item.type === 'referral') || {}
      ) && values.is_for_referral
    )
  }
  return (
    <Stack
      component={Form}
      height="100%"
      justifyContent="space-between"
      padding="0 16px 16px 16px"
    >
      <Box component={Paper} elevation={4} sx={{ padding: '16px' }}>
        <Stack>
          <Typography variant="h3">General information</Typography>
          <Stack flexDirection="row" marginY={4}>
            <Stack sx={{ width: '50%' }} spacing={1.5}>
              <Typography variant="body2" color={COLORS.gray2}>
                Name of the bonus
              </Typography>
              <Typography variant="body2" color={COLORS.gray2}>
                Creation Date
              </Typography>
              <Typography variant="body2" color={COLORS.gray2}>
                User create
              </Typography>
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="h5" color={COLORS.black}>
                {values.description || '-'}
              </Typography>
              <Typography variant="h5" color={COLORS.black}>
                {formatDate(new Date(), "LLL do', 'yy")}
              </Typography>
              <Typography variant="h5" color={COLORS.black}>
                {user.first_name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {validateIsForReferrer() && (
          <Stack>
            <Divider sx={{ marginBottom: 4, backgroundColor: COLORS.honey2 }} />
            <Typography variant="body2">Bonus for employee</Typography>
            <Stack flexDirection="row" marginY={2}>
              <Stack sx={{ width: '50%' }} spacing={1.5}>
                <Typography variant="body2" color={COLORS.gray2}>
                  Bonus type
                </Typography>
                <Typography variant="body2" color={COLORS.gray2}>
                  User type
                </Typography>
                <Typography variant="body2" color={COLORS.gray2}>
                  Total amount
                </Typography>

                <Typography variant="body2" color={COLORS.gray2}>
                  Conditions
                </Typography>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="h5" color={COLORS.black}>
                  Flat rate
                </Typography>
                <Typography variant="h5" color={COLORS.black}>
                  Employees
                </Typography>
                <Typography variant="h5" color={COLORS.black}>
                  ${referrerTotal} USD
                </Typography>

                <Stack>
                  {values.bonus_config.map(
                    (item: BonusConfig, index: number) => {
                      if (item.type === 'referrer') {
                        return (
                          <Typography
                            key={index}
                            marginBottom={1.5}
                            variant="h5"
                            color={COLORS.black}
                          >
                            {`$${item.amount} USD after ${item.days} days`}
                          </Typography>
                        )
                      }
                    }
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {validateIsForReferral() && (
          <Stack>
            <Divider sx={{ marginBottom: 4, backgroundColor: COLORS.honey2 }} />
            <Typography variant="body2">Bonus for referral</Typography>
            <Stack flexDirection="row" marginY={2}>
              <Stack sx={{ width: '50%' }} spacing={1.5}>
                <Typography variant="body2" color={COLORS.gray2}>
                  Bonus type
                </Typography>
                <Typography variant="body2" color={COLORS.gray2}>
                  User type
                </Typography>
                <Typography variant="body2" color={COLORS.gray2}>
                  Total amount
                </Typography>

                <Typography variant="body2" color={COLORS.gray2}>
                  Conditions
                </Typography>
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="h5" color={COLORS.black}>
                  Flate rate
                </Typography>
                <Typography variant="h5" color={COLORS.black}>
                  Referral
                </Typography>
                <Typography variant="h5" color={COLORS.black}>
                  ${referralTotal} USD
                </Typography>

                <Stack>
                  {values.bonus_config.map(
                    (item: BonusConfig, index: number) => {
                      if (item.type === 'referral') {
                        return (
                          <Typography
                            key={index}
                            marginBottom={1.5}
                            variant="h5"
                            color={COLORS.black}
                          >
                            {`$${item.amount} USD after ${item.days} days`}
                          </Typography>
                        )
                      }
                    }
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Box>
      <Stack marginTop={2} alignItems="center">
        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={() => {
            onAddBonusTemplate(values)
            onToggleTemplate('bonusTemplate')
          }}
        >
          Continue
        </LoadingButton>
        <Button onClick={onBackStep}>
          <Typography
            variant="body2"
            marginLeft={2}
            marginRight={2}
            color={COLORS.main}
          >
            Back
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
