import { LoadingButton } from '@mui/lab'
import { Button, Typography, Box, Stack, Divider } from '@mui/material'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import useWithdraw from '../hooks/useWithdraw'

const initialValues = {
  id: '',
  method: ''
}

export default function WithdrawStepTwo() {
  const { onSubmit, onBackStep, withdraws, onNextStep, isLoading } =
    useWithdraw()

  const total = withdraws.request.reduce(
    (prev: any, next: any) => prev + next.amount,
    0
  )

  return (
    <Formik
      // validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={() => {
        onNextStep()
      }}
    >
      {() => (
        <Stack
          component={Form}
          height="100%"
          sx={{ textAlign: 'center' }}
          justifyContent="space-between"
        >
          <Box
            component={Paper}
            sx={{ justifyContent: 'center' }}
            margin="22px 16px"
          >
            <img src={'/images/Billete.svg'} alt="logo" />
            <Typography variant="body1" marginTop={5} marginBottom={3}>
              This is the total amount you’ll receive:
            </Typography>
            {withdraws.request?.map(({ method, amount }) => (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="8px"
                marginBottom={2}
              >
                <Typography
                  variant="body2"
                  width="40%"
                  paddingY="8px"
                  color={COLORS.black}
                >
                  {method}
                </Typography>
                <Typography variant="h5" width="60%" paddingY="8px">
                  {amount + ' USD'}
                </Typography>
              </Stack>
            ))}

            <Divider sx={{ background: COLORS.black, marginTop: 2 }} />
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="8px"
            >
              <Typography
                variant="body2"
                width="40%"
                paddingY="8px"
                color={COLORS.black}
              >
                Total
              </Typography>
              <Typography variant="h3" width="60%" paddingY="8px">
                {total} USD
              </Typography>
            </Stack>
          </Box>
          <Stack>
            <LoadingButton
              fullWidth
              variant="contained"
              type="submit"
              loading={isLoading}
              onClick={onSubmit}
            >
              Continue
            </LoadingButton>
            <Button
              fullWidth
              variant="text"
              onClick={onBackStep}
              sx={{ marginTop: 2 }}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
