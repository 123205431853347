import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import Notifications from 'app/notifications'
import Unauthorized from 'components/unauthorized'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { Navigate, Outlet } from 'react-router-dom'
import useLoginAs from 'stores/useLoginAs'
import { COLORS } from 'styles/colors'
import { StylesScrollBar, hexToRGB } from 'styles/utils'
import { routePermissions } from 'utils/catalogs'
import Sidebar from './sidebar'
import { LayoutContainer, LayoutContent, LayoutBody } from './styles'
import Topbar from './topbar'

export default function Layout() {
  const { userRole } = useAuth()
  const { pathname } = useNavigation()
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(!isOpen)

  const { onCloseLoginAs, isLoginAs, isLoading, selectedCompany } = useLoginAs()

  const splitPathname = pathname.split('/')
  const [route, subroute] = splitPathname.filter(item => item)

  const hasRoutePermission = routePermissions[userRole].includes(route)
  const hasSubroutePersmission = routePermissions[userRole].includes(subroute)

  const hasPermission =
    route === 'profile'
      ? hasRoutePermission && hasSubroutePersmission
      : hasRoutePermission

  if (!hasPermission) {
    const route = userRole === 'super_admin' ? '/companies' : '/wallet'
    return <Navigate to={route} replace />
  }

  return (
    <LayoutContainer>
      <Sidebar isOpen={isOpen} onClick={handleToggle} />
      <LayoutBody>
        <Topbar onClick={handleToggle} />
        {isLoginAs && (
          <Stack
            paddingRight={2}
            paddingLeft={2}
            paddingY={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            bgcolor={hexToRGB('#1E1E1E', '60%')}
          >
            <Typography variant="body2" fontWeight="500" color={COLORS.white}>
              You are viewing this content as: {selectedCompany.name}
            </Typography>
            <LoadingButton
              variant="contained"
              disabled={isLoading}
              loading={isLoading}
              onClick={onCloseLoginAs}
            >
              Return to your account
            </LoadingButton>
          </Stack>
        )}
        <LayoutContent
          sx={{ ...StylesScrollBar }}
          padding={{ xs: '0 0.5em 1.5em', md: '0 1.5em 1.5em' }}
        >
          {hasPermission ? <Outlet /> : <Unauthorized />}
        </LayoutContent>
      </LayoutBody>
      <Notifications />
    </LayoutContainer>
  )
}
