import { useState } from 'react'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material'
// import ChatIcon from 'components/icons/Chat'
import { COLORS } from 'styles/colors'
import Chat from './chat'
import { FAQS } from './faqs'

export default function Help() {
  const [isOpen, setIsOpen] = useState(false)

  const toggleChat = () => setIsOpen(!isOpen)

  return (
    <Box marginTop={3}>
      <Stack
        flexDirection="row"
        alignItems="center"
        marginBottom={{ xs: 2, sm: 0, md: 4 }}
      >
        <ContactSupportIcon />
        <Typography marginLeft={2} variant="h2">
          Help center
        </Typography>
      </Stack>
      <Box bgcolor="white" padding={2} marginTop={4}>
        <Typography variant="h3" marginBottom={1}>
          FAQs
        </Typography>
        <Typography variant="body2" color={COLORS.gray2} marginBottom={5}>
          Everything you need to know about the product.
        </Typography>
        <Box>
          {FAQS.map(item => (
            <Accordion key={item.id} sx={{ border: 'none' }}>
              <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
                <Typography variant="body2">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{item.response}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>

      {/* <Box position="sticky" bottom={0} textAlign="right">
        <ChatIcon onClick={toggleChat} />
      </Box> */}

      <Chat open={isOpen} onClose={toggleChat} />
    </Box>
  )
}
