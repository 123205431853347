import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Formik, Form } from 'formik'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { COLORS } from 'styles/colors'
import { LinkButton } from 'styles/utils'
import { listImages } from 'utils/catalogs'
import useSignUp, { validationSchema } from './useSignUp'

export default function SignUp() {
  const [isFocused, seIsFocused] = useState(false)
  const { initialValues, onSubmit, isLoading } = useSignUp()
  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h1" paddingX="8px" paddingBottom={4}>
              Welcome to BeeGopp!
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              Please enter your information
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <Form>
                  <TextField
                    sx={{
                      paddingBottom: '30px'
                    }}
                    label="Email"
                    variant="standard"
                    name="email"
                    fullWidth
                  />
                  <Stack marginBottom={2}>
                    <Stack
                      flexDirection="column"
                      paddingBottom={4}
                      position="relative"
                      sx={{
                        '.selected-flag': {
                          paddingLeft: 0
                        },
                        '.special-label': {
                          left: 0,
                          paddingX: 0,
                          color: COLORS.main,
                          fontSize: 14
                        }
                      }}
                    >
                      <PhoneInput
                        value=""
                        country="us"
                        inputStyle={{
                          width: '100%',
                          border: 'none',
                          borderBottom:
                            isFocused || values.phone
                              ? `2px solid ${COLORS.main}`
                              : `1px solid ${COLORS.gray4}`,
                          borderRadius: 0,
                          paddingLeft: 40,
                          boxShadow: 'none'
                        }}
                        onFocus={() => seIsFocused(true)}
                        onBlur={() => seIsFocused(false)}
                        onChange={(value: string, country: CountryData) => {
                          setFieldValue('phone', value)
                          setFieldValue('phone_area_code', country.dialCode)
                        }}
                      />
                    </Stack>
                    {touched.phone && errors.phone && (
                      <Typography
                        variant="h6"
                        color={COLORS.red1}
                        position="absolute"
                        margin="60px 0 0 0"
                      >
                        Required
                      </Typography>
                    )}
                  </Stack>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ marginBottom: '24px' }}
                    loading={isLoading}
                  >
                    Continue
                  </LoadingButton>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body2">
                      Already have an account?
                    </Typography>
                    <LinkButton to="/login"> Log in</LinkButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
