import styled, { keyframes } from 'styled-components'

export const AccordionWrapper = styled.div`
  margin-bottom: 1rem;
`

export const AccordionHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
`

export const expand = keyframes`
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    height: auto;
  }
`

export const collapse = keyframes`
  from {
    height: auto;
  }
  to {
    height: 0;
    overflow: hidden;
  }
`

export const AccordionBody = styled.div<{ isOpen: boolean }>`
  animation: ${({ isOpen }) => (isOpen ? expand : collapse)} 0.3s forwards;
  overflow: hidden;
`
