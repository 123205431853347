import { ChipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { COLORS } from 'styles/colors'

type Size = 'sm' | 'md' | 'lg' | 'xl' | string
const sizes: { [key in Size]: string } = {
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1536px'
}

export const device = (size: Size) => {
  const mediaRegex = /(d*\.?\d+)(px|em|%|rem|vw|pt+)/g.test(size)
  const breakpoint = Object.keys(sizes).includes(size)

  if (!mediaRegex && !breakpoint) {
    console.error(`Error: Media query ${size} not supported`)
  }

  const media = Object.keys(sizes).includes(size) ? sizes[size] : size
  return `@media (min-width: ${media})`
}

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export const getColor = ({
  variant,
  color
}: {
  variant: ChipProps['variant']
  color: ChipProps['color']
}) => {
  return variant === 'filled' && color === 'other'
    ? COLORS.gray4
    : variant === 'filled' && color !== 'other'
    ? COLORS.white
    : ''
}

export const FlexBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const LinkButton = styled(Link)`
  color: ${COLORS.main};
  text-decoration: none;
  font-size: 14px;
  margin-left: 6px;
  :hover {
    text-decoration: underline;
  }
`
export const StylesScrollBar = {
  '::-webkit-scrollbar': {
    background: 'transparent',
    width: 8,
    height: 8
  },
  '::-webkit-scrollbar-thumb': {
    background: COLORS.blue6,
    borderRadius: 8
  },
  scrollbarColor: `${COLORS.blue6} ${COLORS.gray6}`,
  scrollbarWidth: 'thin',
  opacity: '0.8'
}

export const commonTableStyles = {
  minHeight: 'calc(100vh - 15em)',
  minHeightSubtable: 'calc(100vh - 22em)'
}

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  div.MuiBox-root {
    opacity: 1;
  }
`
