import { Stack, Box } from '@mui/material'
import useJobDetail from 'app/jobs/hooks/useJobDetail'
import Bonus from '../bonus'
import ByEmail from '../byEmail'
import Description from '../description'
import QRCode from '../qrCode'
import Share from '../share'

export default function Body() {
  const { elementHeight, elementRef } = useJobDetail()

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={2} paddingBottom={5}>
      <Box width={{ xs: '100%', md: '60%' }}>
        <Description height={elementHeight} />
      </Box>
      <Box width={{ xs: '100%', md: '40%' }} ref={elementRef}>
        <Bonus />
        <Share />
        <ByEmail />
        <QRCode />
      </Box>
    </Stack>
  )
}
