import { useState } from 'react'
import { AddImportJobs } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'react-query'
import { JobImport } from 'types/jobs'
import { get, pickBy } from 'utils'
import useSelected from './useSelected'

export default function useMutationImport() {
  const { user } = useAuth()
  const { onNotify } = useNotification()
  const { importList, jobsList, onClearImport } = useSelected()
  const [isOpen, setIsOpen] = useState(false)

  const { isLoading, mutate } = useMutation(
    (data: JobImport) => AddImportJobs(user.company_id, data),
    {
      onSuccess: () => {
        onNotify({ severity: 'success', message: 'Successfully imported' })
        onClearImport()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onCloseModal()
      }
    }
  )

  const onSubmit = () => {
    onOpenModal()
    const jobsToImport = {
      user_id: user.id,
      jobs: importList.map(job => {
        return pickBy({
          ...job,
          isChecked: null,
          status: 'new',
          id_source: String(job.id_source) || ''
        })
      })
    }

    mutate({
      ...jobsToImport
    })
  }

  const onMobileSubmit = () => {
    onOpenModal()
    const jobsToImport = jobsList
      .filter(job => job.isChecked)
      .map(job => {
        return pickBy({
          ...job,
          isChecked: null,
          status: 'new',
          id_source: String(job.id_source) || ''
        })
      })
    mutate({
      jobs: jobsToImport
    })
  }

  const onOpenModal = () => setIsOpen(true)
  const onCloseModal = () => setIsOpen(false)

  return {
    onSubmit,
    onMobileSubmit,
    onOpenModal,
    onCloseModal,
    isOpen,
    isLoading
  }
}
