import Close from '@mui/icons-material/Close'
import { Divider, IconButton, Stack, Typography, Box } from '@mui/material'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import { timeAgo } from 'utils'
import useSelected from '../hooks/useSelected'

export default function Detail({ isDialog }: { isDialog?: boolean }) {
  const { selectedJob, onCleanSelectedJobs } = useSelected()

  const payment = `${selectedJob.salary_low || 100} - ${
    selectedJob.salary_high || 300
  }`

  return (
    <Paper elevation={3}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={COLORS.white}
        paddingLeft={2}
        paddingRight={1}
        paddingX={2}
        paddingY="10px"
      >
        <Typography variant="h3" fontSize="20px">
          Job detail
        </Typography>
        <IconButton onClick={onCleanSelectedJobs}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Box
        paddingX={isDialog ? 2 : 1}
        marginTop={3}
        height={isDialog ? '100%' : 'calc(100vh - 17.2em)'}
        sx={{
          overflow: 'auto',
          ...StylesScrollBar
        }}
      >
        <Typography variant="h3" marginBottom={3}>
          {selectedJob.name}
        </Typography>
        <Stack flexDirection="row" marginBottom={1}>
          <Typography variant="caption" marginRight={1}>
            Company:
          </Typography>
          <Typography variant="h6">{selectedJob.name}</Typography>
        </Stack>
        <Stack flexDirection="row" marginBottom={1}>
          <Typography variant="caption" marginRight={1}>
            Job type:
          </Typography>
          <Typography variant="h6">{selectedJob.type}</Typography>
        </Stack>
        <Stack flexDirection="row" marginBottom={1}>
          <Typography variant="caption" marginRight={1}>
            Payment:
          </Typography>
          <Typography variant="h6">{payment}</Typography>
        </Stack>
        <Stack flexDirection="row" marginBottom={1}>
          <Typography variant="caption" marginRight={1}>
            Date created:
          </Typography>
          <Typography variant="h6">
            {selectedJob?.creation_date
              ? timeAgo(selectedJob.creation_date)
              : ''}
          </Typography>
        </Stack>
        <Stack flexDirection="row" marginBottom={1}>
          <Typography variant="caption" marginRight={1}>
            Source id:
          </Typography>
          <Typography variant="h6">{selectedJob.id_source}</Typography>
        </Stack>
        <Box marginTop={4}>
          <Typography variant="h6" color={COLORS.gray2} marginBottom={1}>
            Description
          </Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: selectedJob.description }}
            height={isDialog ? '410px' : 'inherit'}
            sx={{
              overflow: isDialog ? 'auto' : 'initial',
              ...StylesScrollBar
            }}
          />
        </Box>
      </Box>
    </Paper>
  )
}
