import { styled } from '@mui/material/styles'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { COLORS } from 'styles/colors'

export const CurrencyStyled = styled(CurrencyInput)`
  width: 100%;
  height: 42px;
  border: none;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #000000de;
  box-sizing: border-box;
  border-bottom: 1px solid #0000006b;
  :hover {
    border-bottom: 2px solid #000000;
  }
  :focus {
    outline: none;
    border-bottom: 2px solid ${COLORS.main};
  }
`

type CurrencyFieldProps = CurrencyInputProps

export default function CurrencyField({ style, ...props }: CurrencyFieldProps) {
  return (
    <CurrencyStyled
      style={style}
      prefix={props.prefix || '$'}
      decimalsLimit={props.decimalsLimit || 2}
      {...props}
    />
  )
}
