import { useState } from 'react'
import { SignUp } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import store from 'storejs'
import { get } from 'utils'
import * as yup from 'yup'

export const initialValues = {
  email: '',
  phone: '',
  phone_area_code: ''
}

export const validationSchema = yup.object({
  email: yup.string().email('Email invalid').required('Email required'),
  phone: yup.number().required('Phone required'),
  phone_area_code: yup.string().required('Code required')
})

export default function useSignUp() {
  const { onNotify } = useNotification()
  const { onNavigate } = useNavigation()
  const [isLoading, setIsLoading] = useState(false)

  const onRedirect = () => onNavigate('/login')

  const onValidateCode = (userEmail: string) =>
    onNavigate('/enter-emailcode', {
      search: {
        screen: 'signup',
        email: userEmail
      }
    })

  const onSubmit = (values: typeof initialValues) => {
    setIsLoading(true)
    const data = {
      email: values.email.toLowerCase(),
      code: values.phone_area_code,
      phone: values.phone.replace(values.phone_area_code, '')
    }
    SignUp(data)
      .then(() => {
        onValidateCode(values.email)
        store.set('TEMPORAL_SESSION', data)
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    isLoading,
    onSubmit,
    onRedirect,
    validationSchema,
    initialValues
  }
}
