import { RequestSendWithdraw } from 'api'
import useStepper from 'components/stepper/useStepper'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'
import { RequestWithdraw } from './useWithdraw'

export default function useRequestWithdraw(
  callback: (arg: 'wallet') => void,
  isSent: any,
  isLoading: any,
  message: any,
  content?: any
) {
  const { onClearSteps } = useStepper()

  const { user } = useAuth()

  const query = useQueryClient()

  const { mutate } = useMutation(
    (data: RequestWithdraw) => RequestSendWithdraw(user.id, data),
    {
      onSuccess: () => {
        onClearSteps()
        callback('wallet')
        message.set('Your request was sent.')
        content.set('Your bonus will be paid within 10 business days')

        isLoading.set(true)
        isSent.set(true)
        setTimeout(() => {
          isSent.set(!isSent.value)
          isLoading.set(!isLoading.value)
          onClearSteps()
        }, 9000)
        query.invalidateQueries('JOB_DETAIL')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        message.set(errorMessage)
        isLoading.set(true)
        isSent.set(true)
        setTimeout(() => {
          isSent.set(!isSent.value)
          isLoading.set(!isLoading.value)
          onClearSteps()
        }, 3000)
      }
    }
  )

  const onMutation = (data: RequestWithdraw) => {
    const dataToSend = {
      request: data.request.map(item => ({
        id: item.id,
        method: item.method
      }))
    }
    mutate(dataToSend)
  }

  return { isLoading, onMutation, message }
}
