import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  DialogActions,
  DialogContent
} from '@mui/material'
import { COLORS } from 'styles/colors'
import { ListTemplates } from 'types/bonusTemplate'
import useDeleteTemplates from './useDeleteTemplates'

export default function DialogDelete({
  bonusTemplates
}: {
  bonusTemplates: ListTemplates['entries']
}) {
  bonusTemplates
  const {
    selectedTemplateCheck,
    isOpenDialog,
    isLoading,
    onSubmit,
    onCloseDialog
  } = useDeleteTemplates()

  const filteredTemplates = bonusTemplates.filter(item => {
    return selectedTemplateCheck.includes(item.id)
  })

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpenDialog}>
      <DialogContent
        sx={{ paddingY: '10px', paddingX: '20px', margin: '20px' }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom={5}
        >
          <Typography variant="h3">Delete templates</Typography>
        </Box>
        {filteredTemplates.map(
          ({
            user_first_name: name,
            user_last_name: lastName,
            description,
            id
          }) => (
            <Grid container key={id}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Bonus for</Typography>
                  <Typography variant="h5" fontSize={14}>
                    {name} {lastName}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">Template name</Typography>
                  <Typography variant="h5" fontSize={14} color={COLORS.main}>
                    {description}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
            </Grid>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: 4, paddingBottom: 4 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={onCloseDialog}
          sx={{ marginRight: '3px' }}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          sx={{ marginLeft: '3px' }}
          onClick={onSubmit}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
