import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
//import useTemplate from 'app/educationDetails/sideDetail/bonus/useTemplate'
//import { useEmployeeDetail } from 'app/employees/hooks/useEmployeeDetail'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'

import { useParams } from 'react-router'
import { get } from 'utils'
import { GetCountsByUserId } from '../../../../api/index'
import { CountsByEmployeeBoard } from '../Types/ReferralByEmployee'

const initialState = hookstate({
  edit: false
})

export default function useCountsByEmployee() {
  const { id } = useParams()
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()
  //const { onToggleTemplate, isSent } = useTemplate()
  const [countsInformation, setCountsInformation] =
    useState<CountsByEmployeeBoard>({} as CountsByEmployeeBoard)

  const { isLoading } = useQuery(
    'COUNTS_BY_EMPLOYEE_ID',
    () => GetCountsByUserId(id as string),
    {
      onSuccess: data => {
        setCountsInformation(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onHandleOpenEdit = () => {
    edit.set(true)
  }

  const onHandleCloseEdit = () => {
    edit.set(false)
  }

  return {
    isLoading: isLoading,
    countsInformation,
    open,
    edit: edit.value,
    onHandleOpenEdit,
    onHandleCloseEdit
    //onToggleTemplate,
    //isSent
  }
}
