import { GetCompaniesByFilterJobs } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import { get } from 'utils'

export default function useCompanies() {
  const { onNotify } = useNotification()

  const { isLoading, data } = useQuery(
    'ALL_BOARD_COMPANIES',
    () => GetCompaniesByFilterJobs({ status: ['published'] }),
    {
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const companyOptions =
    data?.map(item => ({
      label: item,
      id: item
    })) || ([] as Array<{ label: string; id: string }>)

  return { isLoading, companyOptions }
}
