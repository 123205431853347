import Box from '@mui/material/Box'

import MUIStepper from '@mui/material/Stepper'
import useStepper from 'components/stepper/useStepper'

export default function StepperWithdraw({
  children
}: {
  children: any
  steps: Array<string>
}) {
  const { activeStep } = useStepper()
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        paddingY={3}
        margin="0 auto"
        width="calc(100% - 16px)"
        component={MUIStepper}
        activeStep={activeStep}
      ></Box>
      <Box paddingX={2} paddingTop={2} height="calc(100vh - 168px)">
        {children[activeStep]}
      </Box>
    </Box>
  )
}
