import { useEffect, useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { get, getPagination } from 'utils'
import { GetReferalByEmployeeId } from '../../../../api/index'
import { ReferralByEmployeeBoard } from '../Types/ReferralByEmployee'
import useFilterReferralByEmpStore from './useFilterReferralByEmpStore'

type Params = { [key in string]: string | unknown }

export default function useReferralByEmployees() {
  const { user } = useAuth()
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()

  const { params } = useNavigation()
  const [referrals, setReferrals] = useState<ReferralByEmployeeBoard>(
    {} as ReferralByEmployeeBoard
  )
  const { onNotify } = useNotification()
  const { filters } = useFilterReferralByEmpStore()

  const { isLoading, refetch } = useQuery(
    'REFERRAL_BY_EMPLOYEE_ID',
    () =>
      GetReferalByEmployeeId((params.id as unknown as number) || user.id, {
        page: filters.page,
        size: filters.size
      }),
    {
      enabled: Boolean(params.id),
      onSuccess: data => {
        setReferrals(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  useEffect(() => {
    refetch()
  }, [JSON.stringify(filters)])

  const onToggleTemplate = (toggle: 'referrals', id?: Params) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle, id)
    }
  }

  const pagination = getPagination(referrals)

  return {
    pagination,
    onToggleTemplate,
    openToggle,
    referrals: referrals.entries || [],
    isLoading: isLoading
  }
}
