import { Chip } from '@mui/material'
import type { TableChipProps, Color } from 'types/table'
import { STATUS_COLOR } from './constants'

export default function TableChip({
  row,
  chipProps,
  label,
  labelColor,
  ...props
}: TableChipProps) {
  const status = row[props.field].toLocaleLowerCase() as Color
  const filteredLabel = row[props.field].includes('_')
    ? row[props.field].replace(/_/g, ' ')
    : row[props.field]
  return (
    <Chip
      sx={{
        width: '80%',
        textTransform: 'capitalize',
        '& .MuiChip-label': {
          color: labelColor
        }
      }}
      label={label ? label : filteredLabel}
      color={STATUS_COLOR[status || 'default']}
      {...chipProps}
    />
  )
}
