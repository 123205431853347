import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useToggle from 'components/toggleSidebar/useToggle'

const initialState = hookstate({
  isSent: false,
  isLoading: false
})

export default function useTemplate() {
  const { isSent, isLoading } = useHookstate(initialState)
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { onClearSteps } = useStepper()

  useEffect(() => {
    isSent.set(false)
  }, [openToggle.wallet])

  const onToggleTemplate = (
    toggle: 'wallet' | 'transferDetail' | 'referBy'
  ) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle)
    }
  }

  const onSubmit = () => {
    isLoading.set(true)
    setTimeout(() => {
      isSent.set(true)
      isLoading.set(!isLoading.value)
      onClearSteps()
    }, 2000)
  }

  return {
    openTemplate: openToggle,
    isLoading: isLoading.value,
    isSent: isSent.value,
    onToggleTemplate,
    onSubmit
  }
}
