export const COLORS = {
  main: '#0030FF',
  blue1: '#00115C',
  blue2: '#0080E8',
  blue3: '#0D4B96',
  blue4: '#0621ad',
  blue5: '#102ef6',
  blue6: '#89A9FD',
  blue7: '#88a9fd',
  blue8: '#0021AD',
  blue9: '#001984',
  blue10: '#6884FF',
  honey1: '#DDA01F',
  honey2: '#FFDA8D',
  honey3: '#FF9431',
  honey4: '#FFBD31',
  honey5: 'E16D02',
  honey6: '#FFF2E6',
  honey7: '#FFF8E8',
  honey8: '#FFA34F',
  green1: '#1B9F72',
  green2: '#2D9D78',
  green3: '#1C5F48',
  orange1: '#E16D02',
  orange2: '#FFBD31',
  red1: '#D41818',
  red2: '#ff5045',
  redRose: '#FFEBEB',
  gray1: '#373942',
  gray2: '#A3A5AC',
  gray3: '#DDDEE1',
  gray4: '#0000008a',
  gray5: '#424242',
  gray6: '#A4A5AC',
  gray7: '#808384',
  gray8: '#E1E2E6',
  gray9: '#F9FBFC',
  gray10: '#0000000f',
  white: '#FFFFFF',
  black: '#282828',
  black1: '#303030'
}
