import { useEffect, useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get, getPagination } from 'utils'
import { GetBonusPaymentsWallet } from '../../../api/index'
import { getRows } from '../constants'
import { BonusPayments } from '../types'
import useFilterBonusPayments from './useFilterBonusPayments'

export default function useBonusPayments() {
  const [bonusPayments, setBonusPayments] = useState<BonusPayments>(
    {} as BonusPayments
  )
  const { onNotify } = useNotification()
  const { user } = useAuth()
  const { filters } = useFilterBonusPayments()

  const { isLoading, isStale, refetch } = useQuery(
    'BONUS_PAYMENTS',
    () =>
      GetBonusPaymentsWallet(
        { page: filters.page, size: filters.size },
        user.id
      ),
    {
      onSuccess: data => {
        setBonusPayments(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  const getEmployeeTemplate = () => refetch()
  useEffect(() => {
    refetch()
  }, [JSON.stringify(filters)])

  const validatedBonusPayments = getRows(bonusPayments.entries || [])

  const pagination = getPagination(bonusPayments)

  return {
    getEmployeeTemplate,
    pagination,
    bonusPayments: validatedBonusPayments,
    isLoading: isLoading || isStale
  }
}
