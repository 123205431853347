import React from 'react'
import { hookstate, useHookstate } from '@hookstate/core'

const initialState = hookstate({
  anchorEl: null,
  popoverUid: '0'
})

export default function useFilterStore() {
  const { anchorEl, popoverUid } = useHookstate(initialState)

  const onOpenAnchor = (
    event: React.MouseEvent<HTMLButtonElement>,
    popoverId: string
  ) => {
    anchorEl.set(event.currentTarget as any)
    popoverUid.set(popoverId)
  }

  const onCloseAnchor = () => {
    anchorEl.set(null)
    popoverUid.set('')
  }

  const open = Boolean(anchorEl.value)
  const filterId = open ? 'popover' : undefined

  return {
    onCloseAnchor,
    onOpenAnchor,
    open,
    filterId: filterId,
    anchorEl: anchorEl.value,
    popoverUid: popoverUid.value
  }
}
