import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import {
  ListEmployees,
  SendEmployeeRequest
} from 'app/employees/Types/Employees'
import useCreateEmployees from 'app/employees/hooks/useCreateEmployees'

import { style } from './Style'

type ModalInviteProps = {
  loading?: boolean
  open: boolean
  title: string
  content: string
  close: () => void
  //onSumbit: () => void
}

export const ModalInvite = ({
  open,
  title,
  content,
  close,
  //onSumbit,
  loading
}: ModalInviteProps) => {
  const [value, setValue] = useState('')
  const userSplited: any = []
  const reciveUsers: ListEmployees[] = []
  const Employee: SendEmployeeRequest = { employees: reciveUsers }
  const { onCreateEmployeeRequest, onNotify } = useCreateEmployees()
  const handleSubmmit = () => {
    close()
    splitValues()
    //onSumbit()
  }
  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.currentTarget.value)
  }
  const splitValues = () => {
    const users = value.split('\n')

    users.forEach(user => {
      userSplited.push(user.split(','))
    })

    userSplited.forEach((key: any) => {
      if (key.length == 5) {
        reciveUsers.push({
          first_name: key[2],
          last_name: key[3],
          email: key[4],
          phone: key[5],
          phone_area_code: '1'
        })
      } else {
        onNotify({
          severity: 'warning',
          message: 'invalid structure in line ' + (userSplited.indexOf(key) + 1)
        })
      }
    })
    if (reciveUsers.length != 0) {
      onCreateEmployeeRequest(Employee.employees[0])
    }
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 530 }}>
          <Typography variant="h3" textAlign="center" marginBottom={8}>
            {title}
          </Typography>
          <Typography variant="h6">{content}</Typography>
          <TextField
            fullWidth
            id="fullWidth"
            multiline
            maxRows={10}
            margin={'dense'}
            rows={10}
            onChange={onChangeHandle}
            value={value}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              margin: 2
            }}
          >
            <Button
              children="Cancel"
              variant="text"
              size="large"
              onClick={close}
              sx={{ fontSize: 16, width: 237, height: 42 }}
            />
            <LoadingButton
              loading={loading}
              children="Send"
              variant="contained"
              size="large"
              sx={{ fontSize: 16, width: 237, height: 42 }}
              disabled={!value.length}
              onClick={handleSubmmit}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
