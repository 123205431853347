import { Box, Button, Paper, Typography } from '@mui/material'
import useFetchDetail from 'app/educationDetails/hooks/useFetchDetail'
import useRefer from 'app/educationDetails/hooks/useRefer'
import ToggleSidebar from 'components/toggleSidebar'
import ReferBy from './referBy'

export default function ByEmail() {
  const { openReferBy, onToggleReferBy } = useRefer()
  const { details } = useFetchDetail()

  const hasBonus = Boolean(details.bonus)

  return (
    <Box component={Paper} marginTop={3}>
      <Typography variant="h3" marginBottom={3}>
        Refer by SMS or Email
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => onToggleReferBy('referBy')}
        disabled={!hasBonus || details.status !== 'published'}
      >
        Email or SMS
      </Button>
      <ToggleSidebar
        isOpen={openReferBy.referBy}
        headerTitle="Refer by SMS or Email"
        toggleSidebar={() => onToggleReferBy('referBy')}
      >
        <ReferBy />
      </ToggleSidebar>
    </Box>
  )
}
