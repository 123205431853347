import { useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { JOB_STATUS } from 'app/jobs/boardConfig/constants'
import useJobStatus from 'app/jobs/hooks/useJobStatus'
import Breadcrumbs from 'components/breadcrums'
import ButtonGroup from 'components/buttonGroup'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { formatDate } from 'utils'
import { STATUS_COLOR } from '../constants'
import useFetchDetail from '../hooks/useFetchDetail'

export default function Header() {
  const { params } = useNavigation()
  const { details, isLoading } = useFetchDetail()
  const { onSelectedJobs, onSubmit } = useJobStatus()

  useEffect(() => {
    if (details.id) {
      onSelectedJobs([details.id])
    }
  }, [isLoading])

  const name = `${details.name} ${params.id}`

  const labelStatus = details?.status.includes('_')
    ? details.status.replace(/_/g, ' ')
    : details.status

  return (
    <Stack
      marginY={3}
      width="100%"
      alignItems="flex-start"
      justifyContent={{ xs: 'initial', md: 'space-between' }}
      direction={{ xs: 'column', lg: 'row' }}
    >
      <Box width={{ xs: '100%', lg: '70%' }}>
        <Stack
          width="100%"
          alignItems="baseline"
          marginBottom={2}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Typography
            variant="h3"
            fontSize={{ xs: '18px', md: '24px' }}
            bgcolor={COLORS.honey4}
            height="40px"
            paddingX={3}
            lineHeight="40px"
            borderRadius="6px"
            display={{ xs: 'none', md: 'block' }}
          >
            {params.id}
          </Typography>
          <Stack
            width={{ xs: '100%' }}
            marginLeft={{ xs: 0, md: 2 }}
            marginBottom={{ xs: 2, md: 0 }}
          >
            <Typography variant="h2">{details.name}</Typography>
            <Typography variant="caption" marginTop="6px">
              imported by
              <Typography
                variant="caption"
                component="span"
                marginX={0.5}
                color={COLORS.main}
              >
                {details.user.first_name + ' ' + details.user.last_name || '-'}
              </Typography>
              {formatDate(details.inserted_at, 'MM/dd/yy HH:mm:ss')}
            </Typography>
          </Stack>
        </Stack>
        <Breadcrumbs
          routes={[
            { route: '/jobs/boardconfig', label: 'Board Configuration' }
          ]}
        >
          {name}
        </Breadcrumbs>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        width={{ xs: '100%', lg: '30%' }}
      >
        <Typography variant="h6" marginRight={1}>
          Job Status
        </Typography>
        <ButtonGroup
          loadingOption={isLoading}
          selectedValue={{ id: details.status, label: labelStatus }}
          bgcolor={STATUS_COLOR[details.status]}
          onChange={option => onSubmit(option.id as typeof details['status'])}
          options={JOB_STATUS}
        />
      </Stack>
    </Stack>
  )
}
