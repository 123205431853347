import PeopleIcon from '@mui/icons-material/People'
import SendIcon from '@mui/icons-material/Send'
import { Typography, Stack, Button } from '@mui/material'

import SearchBar from 'components/SearchBar'
import Notification from 'components/notification'
import useAuth from 'hooks/useAuth'
import { useToggle } from 'hooks/useToggle'
import Invitation from './invitation'

const users = ['market_manager', 'company_owner']

const EmployeeHeader = () => {
  const { userRole } = useAuth()
  const { active, handleToggle, activeMessage, handleToggleMessage } =
    useToggle()

  return (
    <>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        marginTop={3}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          marginBottom={{ xs: 2, sm: 0 }}
        >
          <PeopleIcon />
          <Typography marginLeft={2} variant="h2" marginRight={4}>
            Employees
          </Typography>
        </Stack>
        <Stack
          gap={3}
          alignItems="flex-end"
          width={{ xs: '100%' }}
          justifyContent="space-between"
          direction={{ xs: 'column', sm: 'row' }}
        >
          <SearchBar boxProps={{ width: { xs: '95%', md: '250px' } }} />
          {users.includes(userRole) && (
            <Button
              fullWidth
              variant="contained"
              startIcon={<SendIcon />}
              children="Add employee"
              sx={{ maxWidth: '180px' }}
              onClick={handleToggle}
            />
          )}
        </Stack>
      </Stack>

      <Invitation isOpen={active} onClose={handleToggle} />

      <Notification
        //message={message}
        severity="success"
        open={activeMessage}
        onClose={handleToggleMessage}
      />
    </>
  )
}

export default EmployeeHeader
