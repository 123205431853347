import {
  Box,
  Stack,
  Typography,
  Paper,
  Radio,
  FormControlLabel
} from '@mui/material'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { BonusTemplate } from 'types/bonusTemplate'
import { getBonusFor } from 'utils'
import useTemplate from '../useTemplate'
type Status = 'active' | 'inactive' | 'canceled' | 'deleted' | string

const borderColor: { [key in Status]: string } = {
  active: COLORS.honey2,
  inactive: COLORS.gray6,
  canceled: COLORS.red1,
  deleted: COLORS.redRose
}

export default function BonusCard({
  description,
  total_amount_referral,
  total_amount_referrer,
  status,
  id,
  selectedBonus,
  setSelectedBonus,
  is_for_referral,
  is_for_referrer
}: BonusTemplate) {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const { setIsSent } = useTemplate()
  const handleViewBonus = () => {
    onNavigate(pathname, {
      search: { ...searchParams, bonusId: id }
    })
    setIsSent(true)
  }

  return (
    <Box
      padding={0}
      borderRadius={1}
      marginBottom={2}
      component={Paper}
      borderLeft={`8px solid ${borderColor[status as Status]}}`}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          paddingY={1}
          paddingX={2}
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <FormControlLabel
              value={id}
              checked={id === selectedBonus}
              control={<Radio />}
              onChange={() => setSelectedBonus(id)}
              label={
                <Typography variant="h3" color={COLORS.gray1} fontSize="20px">
                  {description}
                </Typography>
              }
            />
            <Typography variant="h5" color={COLORS.gray1}>
              {getBonusFor(is_for_referral, is_for_referrer)}
            </Typography>
          </Stack>
          <Typography width="30%">
            ${Number(total_amount_referral) + Number(total_amount_referrer)}
          </Typography>
        </Stack>
        <Stack
          width="80px"
          height="80px"
          component="div"
          paddingX={1}
          alignItems="center"
          color={COLORS.main}
          bgcolor={COLORS.gray9}
          justifyContent="center"
          borderRadius="0 8px 8px 0"
          sx={{ cursor: 'pointer' }}
          onClick={handleViewBonus}
        >
          View
        </Stack>
      </Stack>
    </Box>
  )
}
