import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material'
import { COLORS } from 'styles/colors'

type PaymentDialogsModel = {
  open: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
}

export const PaymentDialogs = ({
  open,
  isLoading,
  onClose,
  onConfirm
}: PaymentDialogsModel) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        fontSize={20}
        color={COLORS.gray1}
        fontWeight={500}
        textAlign="center"
        marginBottom={5}
      >
        Confirm Payment Approval
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body2" color={COLORS.gray1} marginBottom={2}>
            Are you sure you want to approve this payment?
          </Typography>
          <Typography variant="body2" color={COLORS.gray1} marginBottom={1}>
            Once approved, if you need to cancel, you will have to do trhougth
            the Tremedous dashboard.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
          margin: 2
        }}
      >
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          onClick={onConfirm}
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
