import { Popover } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useFilterStore from 'stores/useFilterStore'

type SearchFilterProps = {
  children: React.ReactNode
}

export default function SearchFilter({ children }: SearchFilterProps) {
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { filterId, anchorEl, open, onCloseAnchor } = useFilterStore()
  return (
    <Popover
      id={filterId}
      open={open}
      elevation={4}
      anchorEl={anchorEl}
      onClose={onCloseAnchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isMatch ? 'left' : 'center'
      }}
      sx={{
        'div.MuiPopover-paper': {
          marginTop: 2,
          width: isMobile ? '85%' : 'auto'
        }
      }}
    >
      {children}
    </Popover>
  )
}
