import { DropResult } from '@hello-pangea/dnd'
import type { Job } from 'types/jobs'

export type TypeJob = 'import' | 'jobs'

export const emptyJobs = [
  { groupName: 'Jobs', jobs: [] as Array<Job> },
  { groupName: 'Import', jobs: [] as Array<Job> }
]

export const getInitialJobs = (data: Array<Job>) => {
  return [
    {
      groupName: 'Jobs',
      jobs: data.map(item => ({ ...item, isChecked: false }))
    },
    { groupName: 'Import', jobs: [] }
  ]
}

export const onDragAndDrop = ({
  result,
  jobs
}: {
  result: DropResult
  jobs: typeof emptyJobs
}) => {
  if (!result.destination) return emptyJobs

  /// A different way!
  const { draggableId, source = {} as any, destination = {} as any } = result
  const [sourceGroup] = jobs.filter(
    column => column.groupName === source.droppableId
  )

  const [destinationGroup]: any = destination
    ? jobs.filter(column => column.groupName === destination.droppableId)
    : { ...sourceGroup }

  // We save the task we are moving
  const [movingJobs] = sourceGroup.jobs.filter(
    t => String(t.id_source) === draggableId
  )

  const newSourceGroupJobs = sourceGroup.jobs.splice(source.index, 1)
  const newDestinationGroupJobs = destinationGroup.jobs.splice(
    destination.index,
    0,
    movingJobs
  )

  // add new columns
  const newJobsList = jobs.map(column => {
    if (column.groupName === source.groupName) {
      return {
        groupName: column.groupName,
        jobs: newSourceGroupJobs
      }
    }
    if (column.groupName === destination.groupName) {
      return {
        groupName: column.groupName,
        jobs: newDestinationGroupJobs
      }
    }
    return column
  })
  return newJobsList.map(item => ({ ...item, jobs: item.jobs }))
}
