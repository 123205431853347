import { Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ColumnHeader, TableChip } from 'components/table'
import { COLORS } from 'styles/colors'
import { formatAmount, formatDate } from 'utils'

export const columns: GridColDef[] = [
  {
    field: 'account_number',
    headerName: 'Account',
    width: 160,
    editable: false,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'origin',
    headerName: 'Method',
    hideable: false,
    width: 160,
    editable: false,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'created_date',
    headerName: 'Created',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.created_date, 'MMM dd, yyy')}
      </Typography>
    )
  },
  {
    field: 'amount',
    headerName: 'Recharge',
    type: 'number',
    align: 'left',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatAmount(Number(props.row.amount) || 0)} USD
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'fee_percentage',
    headerName: 'Taxes',
    width: 80,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {props.row.fee_percentage} %
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'amount_charged',
    headerName: 'Total',
    type: 'number',
    align: 'left',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatAmount(Number(props.row.amount_charged) || 0)} USD
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip
        {...props}
        chipProps={{
          variant: 'outlined',
          sx: { background: 'transparent' }
        }}
      />
    ),
    renderHeader: props => <ColumnHeader {...props} />
  }
]
