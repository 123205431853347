import { useState } from 'react'
import { GetEntities } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import type { BeegoppEntity, EntityName } from 'types/profile'
import { get } from 'utils'

export default function useEntities(entityName: EntityName) {
  const { onNotify } = useNotification()
  const [entity, setEntity] = useState<BeegoppEntity>([] as BeegoppEntity)

  const { isLoading } = useQuery(
    'BEEGOPP_ENTITIES',
    () => GetEntities({ entity: entityName as EntityName }),
    {
      enabled: Boolean(entityName),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setEntity(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return { isLoading, entity }
}
