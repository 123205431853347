import { Dispatch, SetStateAction } from 'react'
import { Delete } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button
} from '@mui/material'

import Select from 'components/Select'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Form, Formik, FieldArray, FieldArrayRenderProps } from 'formik'
import { COLORS } from 'styles/colors'
import {
  BonusTemplateDetails,
  BonusConfig,
  UpdateBonusTemplateParams
} from 'types/bonusTemplate'
import useAddBonusConfig from '../hooks/useAddBonusConfig'
import useDeleteBonusConfig from '../hooks/useDeleteBonusConfig'
import useUpdateBonusConfig from '../hooks/useUpdateBonusConfig'
import useUpdateBonusTemplate from '../hooks/useUpdateBonusTemplate'
type props = {
  setEdit: Dispatch<SetStateAction<boolean>>
  BonusTemplate: BonusTemplateDetails
}
export default function EditBonusTemplate({ BonusTemplate, setEdit }: props) {
  const addBonusConfigToArray = (
    arrayHelpers: FieldArrayRenderProps,
    type: 'referral' | 'referrer'
  ) => {
    const newBonusTemplate: BonusConfig = {
      amount: 0,
      days: 0,
      type: type,
      new: true
    }
    arrayHelpers.push(newBonusTemplate)
  }
  const { onAddBonusConfig, onToggleTemplate, isLoading } = useAddBonusConfig()
  const { onUpdateBonusConfig } = useUpdateBonusConfig()
  const { onUpdateBonusTemplate } = useUpdateBonusTemplate()
  const { onDeleteBonusConfig } = useDeleteBonusConfig()
  const initialValues = {
    BonusTemplate
  }
  const onSubmit = async (values: any) => {
    updateBonusTemplate(values)
    updateBonusConfig(values)
    addBonusConfig(values)
    deleteBonusConfig(values)
    onToggleTemplate('bonusTemplateDetails')
  }
  const updateBonusTemplate = async (values: any) => {
    const bonusTemplate = values.BonusTemplate.entries
    const updatedBonusTemplate: UpdateBonusTemplateParams = {
      is_for_referrer: bonusTemplate.is_for_referrer,
      is_for_referral: bonusTemplate.is_for_referral,
      description: bonusTemplate.description,
      total_amount_referral: Number(bonusTemplate.total_amount_referral),
      total_amount_referrer: Number(bonusTemplate.total_amount_referrer),
      type: 'flat rate'
    }
    onUpdateBonusTemplate(updatedBonusTemplate, Number(bonusTemplate.id))
  }
  const updateBonusConfig = async (values: any) => {
    for (let i = 0; i < values.BonusTemplate.entries.bonus_config.length; i++) {
      if (
        values.BonusTemplate.entries.bonus_config[i].edited &&
        !values.BonusTemplate.entries.bonus_config[i].deleted
      ) {
        onUpdateBonusConfig(
          values.BonusTemplate.entries.bonus_config[i],
          Number(values.BonusTemplate.entries.id),
          Number(values.BonusTemplate.entries.bonus_config[i].id)
        )
      }
    }
  }
  const deleteBonusConfig = async (values: any) => {
    for (let i = 0; i < values.BonusTemplate.entries.bonus_config.length; i++) {
      if (
        values.BonusTemplate.entries.bonus_config[i].deleted &&
        !values.BonusTemplate.entries.bonus_config[i].new
      ) {
        onDeleteBonusConfig(
          Number(values.BonusTemplate.entries.id),
          Number(values.BonusTemplate.entries.bonus_config[i].id)
        )
      }
    }
  }
  const addBonusConfig = async (values: any) => {
    for (let i = 0; i < values.BonusTemplate.entries.bonus_config.length; i++) {
      if (
        values.BonusTemplate.entries.bonus_config[i].new &&
        !values.BonusTemplate.entries.bonus_config[i].deleted
      ) {
        onAddBonusConfig(
          values.BonusTemplate.entries.bonus_config[i],
          Number(values.BonusTemplate.entries.id)
        )
      }
    }
  }
  const setDeleteBonusConfig = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    index: number
  ) => {
    setFieldValue(`BonusTemplate.entries.bonus_config[${index}].deleted`, true)
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values)
      }}
    >
      {({ handleChange, values, setFieldValue, getFieldProps }) => (
        <Stack
          component={Form}
          justifyContent="space-between"
          height="calc(100vh - 168px)"
          padding="32px 16px 0 16px"
        >
          <Box component={Paper} elevation={4} sx={{ padding: '16px' }}>
            <Stack
              padding="6px"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h3">Edit information</Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => setEdit(false)}
              >
                cancel
              </Button>
            </Stack>

            <Stack flexDirection="column" marginY={3}>
              <Typography variant="h5">Template name</Typography>
              <TextField
                sx={{
                  width: '100%'
                }}
                defaultValue={values.BonusTemplate.entries.description}
                variant="standard"
                onChange={handleChange('BonusTemplate.entries.description')}
                name="description"
              />
            </Stack>
            <Box>
              <Typography variant="h5" marginBottom={1} color={COLORS.gray2}>
                Choose for who is the bonus...
              </Typography>
              <FormControlLabel
                name="is_for_referrer"
                control={<Checkbox />}
                label="Employee"
                checked={values.BonusTemplate.entries.is_for_referrer}
                onChange={(e, checked) => {
                  setFieldValue(
                    'BonusTemplate.entries.is_for_referrer',
                    checked
                  )
                }}
                sx={{ width: '100%' }}
                componentsProps={{
                  typography: { color: COLORS.gray1 }
                }}
              />
              <FormControlLabel
                name="is_for_referral"
                control={<Checkbox />}
                label="Referral"
                checked={values.BonusTemplate.entries.is_for_referral}
                onChange={(e, checked) => {
                  setFieldValue(
                    'BonusTemplate.entries.is_for_referral',
                    checked
                  )
                }}
                sx={{ width: '100%' }}
                componentsProps={{
                  typography: { color: COLORS.gray1 }
                }}
              />

              {values.BonusTemplate.entries.is_for_referrer ? (
                <>
                  <Stack flexDirection="column" marginY={2}>
                    <Typography variant="h5">Employee Bonus</Typography>
                    <Select
                      disabled
                      name="Type"
                      value="1"
                      variant="standard"
                      label="Select bonus type"
                      options={[{ value: '1', label: 'Flat rate' }]}
                    />
                  </Stack>
                  <FieldArray
                    name="BonusTemplate.entries.bonus_config"
                    render={arrayHelpers => (
                      <div>
                        {values.BonusTemplate.entries.bonus_config.map(
                          (item: BonusConfig, index: number) => {
                            if (item.type === 'referrer' && !item.deleted) {
                              return (
                                <Box marginY={2} key={index}>
                                  <Stack
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <TextField
                                      sx={{
                                        width: '30%'
                                      }}
                                      variant="standard"
                                      defaultValue={item.amount}
                                      onChange={args => {
                                        setFieldValue(
                                          `BonusTemplate.entries.bonus_config[${index}].amount`,
                                          args.target.value
                                        )
                                        const values = getFieldProps(
                                          `BonusTemplate.entries.bonus_config[${index}]`
                                        )
                                        if (values.value.edited === false)
                                          setFieldValue(
                                            `BonusTemplate.entries.bonus_config[${index}].edited`,
                                            true
                                          )
                                      }}
                                      name="amount"
                                      placeholder="$ Amount"
                                    />
                                    <Typography
                                      variant="body2"
                                      marginBottom={0.2}
                                    >
                                      USD
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      marginLeft={3}
                                      marginRight={3}
                                      marginBottom={0.2}
                                    >
                                      After
                                    </Typography>
                                    <TextField
                                      sx={{
                                        width: '30%',
                                        marginRight: '10px'
                                      }}
                                      defaultValue={item.days}
                                      placeholder="Number of days"
                                      variant="standard"
                                      onChange={args => {
                                        setFieldValue(
                                          `BonusTemplate.entries.bonus_config[${index}].days`,
                                          args.target.value
                                        )
                                        const values = getFieldProps(
                                          `BonusTemplate.entries.bonus_config[${index}]`
                                        )
                                        if (values.value.edited === false)
                                          setFieldValue(
                                            `BonusTemplate.entries.bonus_config[${index}].edited`,
                                            true
                                          )
                                      }}
                                      name="days"
                                    />
                                    <Typography
                                      variant="body2"
                                      marginBottom={0.2}
                                      marginRight={1}
                                    >
                                      Days
                                    </Typography>
                                    <Button
                                      onClick={() => {
                                        setDeleteBonusConfig(
                                          setFieldValue,
                                          index
                                        )
                                      }}
                                    >
                                      <Delete color="error" />
                                    </Button>
                                  </Stack>
                                </Box>
                              )
                            }
                          }
                        )}
                        <Button
                          onClick={() =>
                            addBonusConfigToArray(arrayHelpers, 'referrer')
                          }
                        >
                          <Add />
                          <Typography
                            variant="body2"
                            color={COLORS.main}
                            marginLeft={2}
                            marginRight={2}
                          >
                            Add Bonus Tier
                          </Typography>
                        </Button>
                      </div>
                    )}
                  />
                </>
              ) : null}
              {values.BonusTemplate.entries.is_for_referral ? (
                <>
                  <Stack flexDirection="column" marginY={2}>
                    <Typography variant="h5">Referral Bonus</Typography>
                    <Select
                      disabled
                      name="Type"
                      value="1"
                      variant="standard"
                      label="Select bonus type"
                      options={[{ value: '1', label: 'Flat rate' }]}
                    />
                  </Stack>
                  <FieldArray
                    name="BonusTemplate.entries.bonus_config"
                    render={arrayHelpers => (
                      <div>
                        {values.BonusTemplate.entries.bonus_config.map(
                          (item: BonusConfig, index: number) => {
                            if (item.type === 'referral' && !item.deleted) {
                              return (
                                <Box marginY={2} key={index}>
                                  <Stack
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <TextField
                                      sx={{
                                        width: '30%'
                                      }}
                                      variant="standard"
                                      defaultValue={item.amount}
                                      onChange={args => {
                                        setFieldValue(
                                          `BonusTemplate.entries.bonus_config[${index}].amount`,
                                          args.target.value
                                        )
                                        const values = getFieldProps(
                                          `BonusTemplate.entries.bonus_config[${index}]`
                                        )
                                        if (values.value.edited === false)
                                          setFieldValue(
                                            `BonusTemplate.entries.bonus_config[${index}].edited`,
                                            true
                                          )
                                      }}
                                      name="amount"
                                      placeholder="$ Amount"
                                    />
                                    <Typography
                                      variant="body2"
                                      marginBottom={0.2}
                                    >
                                      USD
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      marginLeft={3}
                                      marginRight={3}
                                      marginBottom={0.2}
                                    >
                                      After
                                    </Typography>
                                    <TextField
                                      sx={{
                                        width: '30%',
                                        marginRight: '10px'
                                      }}
                                      defaultValue={item.days}
                                      placeholder="Number of days"
                                      variant="standard"
                                      onChange={args => {
                                        setFieldValue(
                                          `BonusTemplate.entries.bonus_config[${index}].days`,
                                          args.target.value
                                        )
                                        const values = getFieldProps(
                                          `BonusTemplate.entries.bonus_config[${index}]`
                                        )
                                        if (values.value.edited === false)
                                          setFieldValue(
                                            `BonusTemplate.entries.bonus_config[${index}].edited`,
                                            true
                                          )
                                      }}
                                      name="days"
                                    />
                                    <Typography
                                      variant="body2"
                                      marginBottom={0.2}
                                      marginRight={1}
                                    >
                                      Days
                                    </Typography>
                                    <Button
                                      onClick={() => {
                                        setDeleteBonusConfig(
                                          setFieldValue,
                                          index
                                        )
                                      }}
                                    >
                                      <Delete color="error" />
                                    </Button>
                                  </Stack>
                                </Box>
                              )
                            }
                          }
                        )}
                        <Button
                          onClick={() =>
                            addBonusConfigToArray(arrayHelpers, 'referral')
                          }
                        >
                          <Add />
                          <Typography
                            variant="body2"
                            color={COLORS.main}
                            marginLeft={2}
                            marginRight={2}
                          >
                            Add Bonus Tier
                          </Typography>
                        </Button>
                      </div>
                    )}
                  />
                </>
              ) : null}
            </Box>
          </Box>
          <Stack marginTop={4}>
            <LoadingButton
              loading={isLoading}
              fullWidth
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
