import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Search = styled(Box)(() => ({
  borderRadius: 25,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
  height: 48,
  display: 'flex',
  alignItems: 'center'
}))
