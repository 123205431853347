import { useState } from 'react'
import { AddCompanyConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { ConfigurationTosend } from 'types/profile'
import { get } from 'utils'
import { atsDefaultValues } from 'utils/catalogs'
import * as yup from 'yup'
import useDisconnect from './useDisconnect'
import useIntegrations from './useIntegrations'

const schema = (values: Record<string, string>) => {
  const validateFields = Object.keys(values).reduce((acc, field) => {
    return {
      ...acc,
      [field]: yup.string().required('Required')
    }
  }, {} as Record<string, yup.StringSchema>)
  return yup.object(validateFields)
}

export default function useConnect() {
  const { selectedIntegration } = useIntegrations()
  const { user, session, onUpdateUser } = useAuth()
  const { onNotify } = useNotification()
  const { onCloseSidebar } = useDisconnect()

  const fieldsToShow =
    selectedIntegration?.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: false
      }
    }, {}) || ({} as Record<string, boolean>)

  const initialValues =
    selectedIntegration?.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: ''
      }
    }, {}) || ({} as Record<string, string>)

  const [showField, setShowField] =
    useState<Record<string, boolean>>(fieldsToShow)

  const validationSchema = schema(initialValues)

  const query = useQueryClient()

  const { mutate } = useMutation(
    (data: ConfigurationTosend) => AddCompanyConfig(user.company_id, data),
    {
      onSuccess: data => {
        onNotify({ severity: 'success', message: 'Conexion success' })

        onUpdateUser({
          token: session.token,
          user: {
            ...user,
            config_id: data.id
          }
        })

        query.invalidateQueries('COMPANY_DETAIL_CONFIG')
      },

      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onCloseSidebar()
      }
    }
  )

  const onShowField = (field: string) => {
    setShowField(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }))
  }

  const onSubmit = (values: typeof initialValues, atsEnable: string) => {
    const data = {
      api_name: atsEnable,
      enable: 'false',
      mapped_fields: {
        job: atsDefaultValues[atsEnable.toLocaleLowerCase() as string].job,
        employee: {} as Record<string, Array<string>>,
        candidate: {} as Record<string, Array<string>>
      },
      env: values
    }
    mutate({ config: data })
  }

  return {
    onCloseSidebar,
    onShowField,
    onSubmit,
    showField,
    initialValues,
    validationSchema,
    selectedIntegration
  }
}
