import { useEffect, useState } from 'react'
import { GetJobs } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useNavigation from 'hooks/useNavigation'
import { useInfiniteQuery } from 'react-query'
import type { Job } from 'types/jobs'
import { get } from 'utils'
import { PAGE, SIZE } from 'utils/catalogs'

export default function useInfinitiveJobs() {
  const { searchParams } = useNavigation()
  const [jobs, setJobs] = useState<Array<Job>>([] as Job[])
  const { onNotify } = useNotification()
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()

  const { isFetching, isFetchingNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      'INFINITIVE_JOBS',
      ({ pageParam = PAGE }) =>
        GetJobs({
          ...searchParams,
          company_name: searchParams.company,
          page: pageParam,
          size: SIZE,
          has_bonus: true,
          status: ['published'],
          type: searchParams.type ? [searchParams.type] : ''
        }),
      {
        refetchOnWindowFocus: false,
        onSuccess: data => {
          if (data) {
            const filteredJobs = data.pages
              .map(item => item.entries)
              .reduce((acc, current) => {
                return [...acc, ...current]
              }, [])
            setJobs(filteredJobs)
          }
        },
        onError: error => {
          const errorMessage = get(error, 'response.data.errors.detail')
          onNotify({ message: errorMessage, severity: 'error' })
        },
        getNextPageParam: lastPage => {
          if (lastPage.page_number === lastPage.total_pages) {
            return
          }
          return lastPage.page_number + 1
        }
      }
    )

  useEffect(() => {
    refetch()
  }, [JSON.stringify(searchParams)])

  const onToggleStartReferBy = (toggle: 'referStartBy', jobId?: number) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle, { jobId })
    }
  }

  return {
    jobs,
    fetchNextPage,
    onToggleStartReferBy,
    openReferStartBy: openToggle,
    isLoading: isFetching || isFetchingNextPage
  }
}
