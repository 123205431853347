import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { GetUser } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { UserInformation } from 'types/profile'
import { get } from 'utils'

const initialState = hookstate({
  edit: false
})

export default function useShowUser() {
  const { user } = useAuth()
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()
  const [userInformation, setUserInformation] = useState<UserInformation>(
    {} as UserInformation
  )

  const { isLoading } = useQuery('USER_BY_ID', () => GetUser(user.id), {
    onSuccess: data => {
      setUserInformation(data.data)
    },
    onError: error => {
      const errorMessage = get(error, 'response.data.errors.detail')
      onNotify({ severity: 'error', message: errorMessage })
    }
  })

  const onHandleOpenEdit = () => edit.set(true)

  const onHandleCloseEdit = () => edit.set(false)

  return {
    isLoading: isLoading,
    userInformation,
    edit: edit.value,
    onHandleOpenEdit,
    onHandleCloseEdit,
    user
  }
}
