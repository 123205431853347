import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useNavigation from 'hooks/useNavigation'
import type { FilterEmployeeName } from 'types/referrals'
import type { UseFilter } from 'types/table'
import { removeEmptyKeys } from 'utils'
import { PAGE, SIZE } from 'utils/catalogs'

const initialState = hookstate({
  status: [] as Array<UseFilter>,
  joined: [] as Array<string>,
  paginationParams: {
    page: PAGE,
    size: SIZE
  }
})

export default function useFilterPaymentStore() {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const state = useHookstate(initialState)

  useEffect(() => {
    state.status.set(searchParams.status || [])
    state.joined.set(searchParams.joined || [])
    state.paginationParams.set({
      page: searchParams.page || PAGE,
      size: searchParams.size || SIZE
    })
  }, [JSON.stringify(searchParams)])

  const onAddFilter = (filter: Array<UseFilter>, name: FilterEmployeeName) => {
    const ids = filter.map(item => item.id).filter(id => id)
    onNavigate(pathname, {
      search: {
        ...searchParams,
        [name]: ids
      }
    })
  }

  const onCleanFilters = (name: FilterEmployeeName) => {
    onNavigate(pathname, {
      search: removeEmptyKeys({
        ...searchParams,
        [name]: ''
      })
    })
  }

  return {
    onAddFilter,
    onCleanFilters,
    filters: {
      status: state.status.value,
      joined: state.joined.value,
      ...state.paginationParams.value
    }
  }
}
