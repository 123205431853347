export default function VisaIcon() {
  return (
    <svg width="20pt" height="13pt" viewBox="0 0 20 13">
      <path
        d="M1.805 0h16.39C19.191 0 20 .797 20 1.785v9.43c0 .988-.809 1.785-1.805 1.785H1.805A1.794 1.794 0 010 11.215v-9.43C0 .797.809 0 1.805 0zm0 0"
        fill="#26337a"
      />
      <path
        d="M7.672 9.191l.75-5.382h1.195L8.871 9.19h-1.2M13.7 4.027a3.173 3.173 0 00-1.177-.218c-1.3 0-2.214.707-2.222 1.718-.008.75.652 1.164 1.152 1.414.512.254.684.418.68.649-.004.348-.406.508-.785.508-.528 0-.805-.078-1.239-.274l-.168-.082-.183 1.164c.305.145.875.274 1.46.278 1.384 0 2.278-.696 2.29-1.778.004-.593-.344-1.043-1.102-1.418-.46-.238-.742-.398-.738-.644 0-.215.238-.45.75-.45.43-.007.742.094.984.204l.118.058.18-1.129M15.566 7.281c.114-.312.54-1.508.54-1.508-.008.016.113-.312.183-.511l.09.46.312 1.56zm1.672-3.472h-1.047c-.324 0-.566.093-.71.445L13.472 9.19h1.422s.23-.664.285-.808l1.734.004c.04.187.164.804.164.804h1.254zm0 0M6.594 3.809L5.246 7.484l-.144-.746c-.25-.863-1.032-1.797-1.907-2.261L4.43 9.19h1.457L8.055 3.81H6.594"
        fill="#fffffe"
      />
      <path
        d="M3.957 3.809H1.652l-.02.113c1.794.453 2.981 1.55 3.473 2.871L4.602 4.27c-.086-.348-.336-.45-.645-.461"
        fill="#fff"
      />
    </svg>
  )
}
