import { IconButtonProps, IconButton } from '@mui/material'
import { COLORS } from 'styles/colors'
export default function Twitter({ ...props }: IconButtonProps) {
  const defaultColor = props.disabled ? COLORS.gray8 : COLORS.main
  return (
    <IconButton {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 3.99902C21.22 4.46202 19.655 5.09302 18.735 5.27502C18.708 5.28202 18.686 5.29102 18.66 5.29802C17.847 4.49602 16.733 3.99902 15.5 3.99902C13.015 3.99902 11 6.01402 11 8.49902C11 8.63002 10.989 8.87102 11 8.99902C7.647 8.99902 5.095 7.24302 3.265 4.99902C3.066 5.49902 2.979 6.28902 2.979 7.03102C2.979 8.43202 4.074 9.80802 5.779 10.661C5.465 10.742 5.119 10.8 4.759 10.8C4.178 10.8 3.563 10.647 3 10.183C3 10.2 3 10.216 3 10.234C3 12.192 5.078 13.525 6.926 13.896C6.551 14.117 5.795 14.139 5.426 14.139C5.166 14.139 4.246 14.02 4 13.974C4.514 15.579 6.368 16.481 8.135 16.513C6.753 17.597 5.794 17.999 2.964 17.999H2C3.788 19.145 6.065 20 8.347 20C15.777 20 20 14.337 20 8.99902C20 8.91302 19.998 8.73302 19.995 8.55202C19.995 8.53402 20 8.51702 20 8.49902C20 8.47202 19.992 8.44602 19.992 8.41902C19.989 8.28302 19.986 8.15602 19.983 8.09002C20.773 7.52002 21.458 6.80902 22 5.99902C21.275 6.32102 20.497 6.53702 19.68 6.63502C20.514 6.13502 21.699 4.94302 22 3.99902ZM18 8.99902C18 13.079 15.043 17.398 9.534 17.942C10.28 17.413 11 16.662 11 16.662C11 16.662 8 14 7.775 10.522C8.81 10.838 9.888 10.999 11 10.999H13V8.49902C13 8.49802 13 8.49802 13 8.49802C13.002 7.11802 14.12 6.00002 15.5 6.00002C16.881 6.00002 18 7.11902 18 8.50002C18 8.50002 18 8.92002 18 8.99902Z"
          fill={props.color || defaultColor}
        />
      </svg>
    </IconButton>
  )
}
