import { Box, Button, Stack, Typography } from '@mui/material'
import useFetchDetail from 'app/educationDetails/hooks/useFetchDetail'
import ToggleSidebar from 'components/toggleSidebar'
import useToggle from 'components/toggleSidebar/useToggle'
import { COLORS } from 'styles/colors'
import { JobDetail } from 'types/jobs'
import BonusPayments from './bonusPayments'
import BuyBonus from './buyBonus'

type Sidebar = 'buybonus' | 'bonuspayment'

export default function Payments() {
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { details } = useFetchDetail()

  const parseDetails = JSON.parse(JSON.stringify(details)) as JobDetail

  const openSidebar = (sidebar: Sidebar) => onOpenToggle(sidebar)

  const closeSidebar = (sidebar: Sidebar) => onCloseToggle(sidebar)

  const hasBonus = Boolean(parseDetails.bonus)

  return (
    <Box marginTop={2}>
      <Typography variant="h3" marginBottom={2}>
        Bonus positions
      </Typography>
      {hasBonus ? (
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            variant="contained"
            disabled={!hasBonus}
            onClick={() => openSidebar('buybonus')}
          >
            Buy
          </Button>
          <Button
            fullWidth
            variant="outlined"
            disabled={!hasBonus}
            onClick={() => openSidebar('bonuspayment')}
          >
            Available
          </Button>
        </Stack>
      ) : (
        <Typography variant="body2" color={COLORS.gray1} marginBottom={1}>
          Add bonus for this job to see the positions
        </Typography>
      )}
      <ToggleSidebar
        isOpen={openToggle.buybonus}
        headerTitle="Buy bonus"
        toggleSidebar={() => closeSidebar('buybonus')}
      >
        <BuyBonus details={parseDetails} />
      </ToggleSidebar>
      <ToggleSidebar
        minWidth={600}
        isOpen={openToggle.bonuspayment}
        headerTitle="Positions"
        toggleSidebar={() => closeSidebar('bonuspayment')}
      >
        <BonusPayments />
      </ToggleSidebar>
    </Box>
  )
}
