import { useState } from 'react'
import { UpdateUser } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import store from 'storejs'
import { UserInformation } from 'types/profile'
import { formatDate, get, toBase64 } from 'utils'
import useShowUser from '../useShowUser'

const sessionStorage = store.get('SESSION') || ''

export default function useUpdateUser(userId: number) {
  const { onNotify } = useNotification()
  const { onHandleCloseEdit } = useShowUser()
  const { onUpdateUser } = useAuth()
  const query = useQueryClient()

  const [image, setImage] = useState('')

  const { mutate, isLoading } = useMutation(
    (data: { user: Partial<UserInformation> }) => UpdateUser(userId, data),
    {
      onSuccess: data => {
        onNotify({
          severity: 'success',
          message: 'User update succesfully'
        })

        const updatedUser = {
          token: sessionStorage.token,
          user: {
            ...sessionStorage.user,
            ...data
          }
        }

        if (sessionStorage.token) {
          onUpdateUser(updatedUser)
        }
        query.invalidateQueries('USER_BY_ID')
        onHandleCloseEdit()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onHandleDrop = async ({ file }: { file: File[] }) => {
    const imageResult = await toBase64(file[0])
    setImage(imageResult)
  }

  const onHandleRemoveImage = () => setImage('')

  const onSumbit = (values: Partial<UserInformation>) => {
    mutate({
      user: {
        ...values,
        avatar: image,
        birthday: formatDate(
          values.birthday as Date,
          'yyyy-MM-dd'
        ) as unknown as Date
      }
    })
  }

  return {
    isLoading,
    image,
    onSumbit,
    onHandleDrop,
    onHandleRemoveImage
  }
}
