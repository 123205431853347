import { Stack, Box, Typography, Divider } from '@mui/material'
import { SxProps } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { COLORS } from 'styles/colors'

type TableHeaderModel = {
  sx?: SxProps
  page: number
  hideBulk?: boolean
  rowsPerPage: number
  totalPages: number
  children?: React.ReactNode
  handleChangePage: (newPage: number) => void
  handleChangeRowsPerPage: (arg: number) => void
}
export default function TableHeader({
  sx,
  page,
  children,
  totalPages,
  rowsPerPage,
  hideBulk = false,
  handleChangePage,
  handleChangeRowsPerPage
}: TableHeaderModel) {
  const handleRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value)
    handleChangeRowsPerPage(perPage)
  }
  return (
    <Stack
      sx={sx}
      height={52}
      paddingLeft="10px"
      flexDirection="row"
      paddingTop={{ xs: 2, sm: 0 }}
      marginBottom={{ xs: 6, sm: 0 }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      justifyContent={hideBulk ? 'flex-end' : 'space-between'}
    >
      {!hideBulk && (
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" fontWeight={500} color={COLORS.gray1}>
            Bulk actions
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ marginLeft: 1, height: '20px', alignSelf: 'center' }}
          />
          {children}
        </Stack>
      )}
      <Box
        display="table"
        marginLeft={{ xs: '-10px !important', sm: 'inherit' }}
        sx={{
          '& p': {
            marginY: 0
          },
          'div.MuiInputBase-colorPrimary': {
            marginRight: { xs: 1, sm: 0 }
          },
          'div.MuiTablePagination-actions': {
            marginLeft: { xs: '2px', sm: 0 }
          }
        }}
      >
        <TablePagination
          component="div"
          page={page || 0}
          count={totalPages || 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageChange={(_, value) => handleChangePage(value)}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Box>
    </Stack>
  )
}
