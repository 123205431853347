import { Fragment } from 'react'
import {
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Stack
} from '@mui/material'
import { useNotification } from 'components/notification/useNotification'
import Paper from 'components/paper'
import Spinner from 'components/spinner'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { formatCurrency } from '../../../utils/index'
import useReadyToPay from '../hooks/useRedyToPay'
import useWithdraw, { Withdraw } from '../hooks/useWithdraw'

export default function WithdrawStepOne() {
  const { onNotify } = useNotification()
  const { onValidateWithdraws, onToggleWithdrawBy } = useWithdraw()

  const { readyToPayment, isLoading } = useReadyToPay()

  const hasEntries =
    readyToPayment.entries.length > 0 &&
    readyToPayment.entries.some(entry => entry.amount > 0)

  return (
    <Formik
      initialValues={[] as Array<Withdraw>}
      onSubmit={values => {
        const hasValueSelected = values.some(value => value.amount)
        if (hasValueSelected) {
          onValidateWithdraws(values)
        } else {
          if (!hasEntries) {
            return onToggleWithdrawBy('wallet')
          }
          onNotify({
            message:
              'You must select at least one value and type before continuing.',
            severity: 'info'
          })
          console.log(
            'You must select at least one value and type before continuing.'
          )
        }
      }}
    >
      {({ setValues, values }) => {
        const selectNewValue = (
          id: number,
          amount?: number,
          method?: string
        ) => {
          const isChangingMethod = !!method

          // if exists should be equal or greater than 0
          // if does not exists should be equal to -1
          const isValueAddedIndex = values.findIndex(
            (value: Withdraw) => value.id === id
          )

          let newValues = [...values]

          // in case the value does not exists
          if (isValueAddedIndex < 0) {
            newValues = [
              ...newValues,
              {
                id,
                ...(isChangingMethod ? { method } : null),
                ...(!isChangingMethod ? { amount } : null)
              }
            ]

            // in case the value already exists
          } else {
            newValues[isValueAddedIndex] = {
              ...newValues[isValueAddedIndex],
              ...(isChangingMethod ? { method } : null),
              ...(!isChangingMethod ? { amount } : null)
            }
          }
          setValues(newValues)
        }

        return (
          <Stack
            component={Form}
            justifyContent="space-between"
            paddingBottom={5}
            height="calc(100% - 40px)"
          >
            {isLoading ? (
              <Stack
                component={Paper}
                alignItems="center"
                justifyContent="center"
                height="80%"
              >
                <Spinner />
              </Stack>
            ) : (
              <Fragment>
                {hasEntries ? (
                  <Box component={Paper}>
                    <Typography variant="h4" marginBottom={7}>
                      Select the bonus you want to withdraw
                    </Typography>
                    {readyToPayment.entries?.map(
                      ({ amount, id, job_name, referral }) => {
                        const referralName = `${referral.first_name} ${
                          referral.last_name || ''
                        }`
                        return (
                          amount > 0 && (
                            <Box
                              alignItems="center"
                              paddingBottom="8px"
                              elevation={1}
                              marginBottom="10px"
                              component={Paper}
                            >
                              <FormControlLabel
                                name="amount"
                                control={<Checkbox />}
                                onChange={(_, checked) => {
                                  if (checked) {
                                    selectNewValue(id, amount)
                                  } else {
                                    const newValues = [...values]
                                    const indexById = values.findIndex(
                                      value => value.id === id
                                    )
                                    newValues.splice(indexById, 1)
                                    setValues(newValues)
                                  }
                                }}
                                label={
                                  <Stack
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Stack direction="row" alignItems="center">
                                      <Typography
                                        color={COLORS.gray2}
                                        paddingRight="10px"
                                        variant="body2"
                                      >
                                        Referral
                                      </Typography>
                                      <Typography fontWeight="500">
                                        {referralName}
                                      </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center">
                                      <Typography
                                        color={COLORS.gray2}
                                        variant="body2"
                                      >
                                        Bonus
                                      </Typography>
                                      <Typography
                                        fontWeight="500"
                                        paddingLeft="10px"
                                      >
                                        {formatCurrency(amount ? amount : 0) +
                                          ' USD'}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                }
                                sx={{ width: '100%' }}
                                componentsProps={{
                                  typography: {
                                    color: COLORS.gray1,
                                    width: '100%'
                                  }
                                }}
                              />
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                marginLeft="30px"
                              >
                                <Typography
                                  paddingRight="10px"
                                  color={COLORS.gray2}
                                  variant="body2"
                                >
                                  Job
                                </Typography>
                                <Typography>{job_name}</Typography>
                              </Stack>
                            </Box>
                          )
                        )
                      }
                    )}
                  </Box>
                ) : (
                  <Stack
                    component={Paper}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      width={80}
                      component="img"
                      src="/images/magnify.png"
                      alt="icon-magnify"
                    />
                    <Typography
                      variant="h4"
                      marginY={7}
                      textAlign="center"
                      width="90%"
                    >
                      Your availabe balance is $0 no money to withdraw
                    </Typography>
                  </Stack>
                )}
                <Button fullWidth type="submit" variant="contained">
                  {hasEntries ? 'Continue' : 'Ok'}
                </Button>
              </Fragment>
            )}
          </Stack>
        )
      }}
    </Formik>
  )
}
