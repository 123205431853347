import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'
import { EmployeeBoard } from '../Types/Employees'
import useResendDeleteEmployee from './useResendDeleteEmployee'

export default function DialogResendDelete({
  selectedCheck,
  handleClose,
  employees,
  actionType,
  open
}: {
  open: boolean
  employees: EmployeeBoard['entries']
  selectedCheck: GridSelectionModel
  actionType: 'resend' | 'delete'
  handleClose: (type: 'resend' | 'delete') => void
}) {
  const { isLoading, isChecked, onChecked, onSubmit } = useResendDeleteEmployee(
    () => handleClose(actionType)
  )

  const filteredEmployees = employees.filter(item => {
    return selectedCheck.includes(item.id)
  })

  const title =
    actionType === 'resend' ? 'Resend invitations' : 'Delete employees'

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent
        sx={{ paddingY: '10px', paddingX: '20px', margin: '20px' }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom={5}
        >
          <Typography variant="h3">{title}</Typography>
        </Box>
        {filteredEmployees.map(
          ({ first_name: name, last_name: lastName, email }) => (
            <Grid container>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">User name</Typography>
                  <Typography variant="h5" fontSize={14}>
                    {name} {lastName}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="caption">User email</Typography>
                  <Typography variant="h5" fontSize={14} color={COLORS.main}>
                    {email}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 1 }} />
              </Grid>
            </Grid>
          )
        )}
      </DialogContent>
      {actionType === 'delete' && (
        <FormControlLabel
          sx={{ paddingX: 4, marginBottom: 3 }}
          control={<Checkbox checked={isChecked} onChange={onChecked} />}
          label={
            <Typography variant="body2" color={COLORS.red1}>
              It is not possible to reverse this action, are you sure?
            </Typography>
          }
        />
      )}
      <DialogActions sx={{ paddingX: 4, paddingBottom: 4 }}>
        <Button
          variant="outlined"
          fullWidth
          sx={{ marginRight: '3px' }}
          onClick={() => handleClose(actionType)}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          disabled={(actionType === 'delete' && !isChecked) || isLoading}
          sx={{ marginLeft: '3px' }}
          onClick={() => onSubmit({ employees: selectedCheck, actionType })}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
