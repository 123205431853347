import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import ResetPassword from 'app/profile/changePassword/resetPassword'
import TextField from 'components/TextField'
import { Field, Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import AlertDialog from './dialogPassword'
import useChangePassword, {
  initialValues,
  validationSchema
} from './useChangePassword'

export default function ChangePassword() {
  const {
    onClickShowPassword,
    onSubmit,
    onHandleEnterEMailCode,
    showPassword,
    onHandleClose,
    onHandleOpen,
    onHandleEdit,
    isLoading,
    canEdit,
    open
  } = useChangePassword()

  return (
    <Box height="65vh">
      {canEdit ? (
        <Box paddingLeft={2}>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {() => (
              <Form>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom={`1px solid ${COLORS.gray8}`}
                >
                  <Typography
                    fontWeight="500"
                    paddingBottom={2}
                    fontSize={{ xs: '18px', sm: '20px' }}
                  >
                    Password
                  </Typography>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    paddingRight={3}
                    paddingBottom={2}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      onClick={onHandleEdit}
                      sx={{
                        margin: '-5px 25px'
                      }}
                    >
                      cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={isLoading}
                      loading={isLoading}
                      sx={{
                        margin: '-5px auto',
                        paddingX: '25px'
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="start"
                  marginY={2}
                  alignItems="center"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Last password
                  </Typography>
                  <Field
                    as={TextField}
                    label="Last password"
                    variant="standard"
                    name="lastPassword"
                    id="outlined-adornment-password"
                    type={showPassword.lastPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => onClickShowPassword('lastPassword')}
                          edge="end"
                        >
                          {showPassword.lastPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      )
                    }}
                    fullWidth
                    sx={{
                      width: { xs: '100%', lg: '70%' }
                    }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="start"
                  marginY={2}
                  alignItems="center"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    New password
                  </Typography>
                  <Field
                    as={TextField}
                    label="New password"
                    variant="standard"
                    name="newPassword"
                    id="outlined-adornment-password"
                    type={showPassword.newPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => onClickShowPassword('newPassword')}
                          edge="end"
                        >
                          {showPassword.newPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      )
                    }}
                    fullWidth
                    sx={{
                      width: { xs: '100%', lg: '70%' }
                    }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="start"
                  marginY={2}
                  alignItems="center"
                >
                  <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                    Confirm new password
                  </Typography>
                  <Field
                    as={TextField}
                    label="Confirm new password"
                    variant="standard"
                    name="confirmPassword"
                    id="outlined-adornment-password"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => onClickShowPassword('confirmPassword')}
                          edge="end"
                        >
                          {showPassword.confirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      )
                    }}
                    fullWidth
                    sx={{
                      width: { xs: '100%', lg: '70%' }
                    }}
                  />
                </Stack>
              </Form>
            )}
          </Formik>
          <Stack
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', lg: 'center' }}
            style={{
              paddingTop: '10px',
              borderTop: `1px solid ${COLORS.gray8}`
            }}
          >
            <Typography variant="h5" color={COLORS.gray2}>
              Did you forget your password?
            </Typography>
            <Button variant="text" onClick={onHandleOpen}>
              Click here
            </Button>
          </Stack>
          <AlertDialog
            open={open}
            handleClose={onHandleClose}
            handleRdirect={onHandleEnterEMailCode}
          />
        </Box>
      ) : (
        <ResetPassword onEdit={onHandleEdit} />
      )}
    </Box>
  )
}
