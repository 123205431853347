import { useNotification } from 'components/notification/useNotification'

export default function useCopy() {
  const { onNotify } = useNotification()

  const copy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        onNotify({ message: 'Link copied', severity: 'success' })
      })
      .catch(err => {
        console.error('Copy failed!: ', err)
        onNotify({ message: 'Copy failed', severity: 'error' })
      })
  }

  return { copy }
}
