import React from 'react'
import { Draggable as DNDDraggable } from '@hello-pangea/dnd'
import { Paper, PaperProps } from '@mui/material'

export default function Draggable({
  id,
  index,
  children,
  paperProps,
  isDragDisabled
}: {
  id: string
  index: number
  children: React.ReactNode
  paperProps?: PaperProps
  isDragDisabled?: boolean
}) {
  return (
    <DNDDraggable
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {provided => (
        <Paper
          {...paperProps}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ marginBottom: 1, ...paperProps?.sx }}
          ref={provided.innerRef}
          elevation={2}
        >
          {children}
        </Paper>
      )}
    </DNDDraggable>
  )
}
