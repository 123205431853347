import { IconButtonProps, IconButton } from '@mui/material'
import { COLORS } from 'styles/colors'

export default function FaceBook({ ...props }: IconButtonProps) {
  return (
    <IconButton {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2788_195816"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2788_195816)">
          <path
            d="M3 20C2.45 20 1.97933 19.8043 1.588 19.413C1.196 19.021 1 18.55 1 18V7H3V18H20V20H3ZM7 16C6.45 16 5.97933 15.804 5.588 15.412C5.196 15.0207 5 14.55 5 14V6C5 5.45 5.196 4.97933 5.588 4.588C5.97933 4.196 6.45 4 7 4H21C21.55 4 22.021 4.196 22.413 4.588C22.8043 4.97933 23 5.45 23 6V14C23 14.55 22.8043 15.0207 22.413 15.412C22.021 15.804 21.55 16 21 16H7ZM9 14C9 13.45 8.804 12.979 8.412 12.587C8.02067 12.1957 7.55 12 7 12V14H9ZM19 14H21V12C20.45 12 19.979 12.1957 19.587 12.587C19.1957 12.979 19 13.45 19 14ZM14 13C14.8333 13 15.5417 12.7083 16.125 12.125C16.7083 11.5417 17 10.8333 17 10C17 9.16667 16.7083 8.45833 16.125 7.875C15.5417 7.29167 14.8333 7 14 7C13.1667 7 12.4583 7.29167 11.875 7.875C11.2917 8.45833 11 9.16667 11 10C11 10.8333 11.2917 11.5417 11.875 12.125C12.4583 12.7083 13.1667 13 14 13ZM7 8C7.55 8 8.02067 7.804 8.412 7.412C8.804 7.02067 9 6.55 9 6H7V8ZM21 8V6H19C19 6.55 19.1957 7.02067 19.587 7.412C19.979 7.804 20.45 8 21 8Z"
            fill={props.color || COLORS.green2}
          />
        </g>
      </svg>
    </IconButton>
  )
}
