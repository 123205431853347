import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Stack } from '@mui/material'
import BulkActions from 'components/bulkactions/index'
import DropDown from 'components/dropdown'
import { EmptyPage } from 'components/emtpyPage/index'
import Actions from 'components/selectActions/Actions'
import { DataGrid, Pagination } from 'components/table'
import ToggleSidebar from 'components/toggleSidebar/index'
import ToggleSidebarTitle from 'components/toggleSidebarTitle'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { COLORS } from 'styles/colors'
import { commonTableStyles } from 'styles/utils'
import { StatusBonusTemplateBulkActions } from 'types/bonusTemplate'
import { columns } from './constants'
import { STATUS } from './constants/index'
import DialogDelete from './dialogDelete'
import useDeleteTemplates from './dialogDelete/useDeleteTemplates'
import useApplyBulkActions from './hooks/useApplyBulkActions'
import useBonusTable from './hooks/useBonusTable'
import TemplatesDetails from './templatesDetails'
const BonusTable = () => {
  const {
    onToggleTemplate,
    openToggle,
    bonusTemplates,
    isLoading,
    isFetching,
    pagination
  } = useBonusTable()
  const { selectedBonusTemplates, setSelectedBonusTemplates, onApply } =
    useApplyBulkActions()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const { onOpenDialog, onSelectedTemplates } = useDeleteTemplates()

  const handleSelectedRow = (ids: any[]) => {
    const filteredSelectedTemplates = bonusTemplates.filter((template: any) =>
      ids.includes(template.id)
    )
    const bonusTemplateIds = filteredSelectedTemplates.map(item => {
      return item.id
    })
    setSelectedBonusTemplates(bonusTemplateIds)
    onSelectedTemplates(bonusTemplateIds)
  }
  const onApplyBulkActions = (args: any) => {
    const data: StatusBonusTemplateBulkActions = {
      action: 'update',
      ids: selectedBonusTemplates,
      status: args.id
    }
    onApply(data)
  }

  const { searchParams } = useNavigation()
  const params = searchParams
  delete params['bonusTemplateDetails']
  delete params['id']

  return (
    <Box
      width="100%"
      height={commonTableStyles.minHeight}
      paddingBottom={3}
      marginTop={4}
    >
      <DataGrid
        rows={bonusTemplates}
        columns={columns}
        paginationMode="server"
        loading={isLoading || isFetching}
        rowCount={pagination.totalPages || 0}
        onSelectionModelChange={handleSelectedRow}
        checkboxSelection
        onRowClick={props => {
          onToggleTemplate('bonusTemplateDetails', { id: props.id })
        }}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          NoResultsOverlay: () => (
            <EmptyPage
              message="Create your first template"
              logo="/images/bonusTemplatesBlank.svg"
            >
              <span></span>
            </EmptyPage>
          ),
          Pagination: () => (
            <Pagination
              handleChangePage={handleChangePage}
              page={page}
              totalPages={pagination.totalPages}
            />
          ),
          Header: () => (
            <BulkActions
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalEntries}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <DropDown
                label="Change template Status"
                actionUID="bulkaction-templates"
                disabled={selectedBonusTemplates.length === 0}
              >
                <Actions
                  onApply={arg => onApplyBulkActions(arg)}
                  options={STATUS}
                  actionUID="bulkaction-templates"
                />
              </DropDown>
              <Stack
                direction="row"
                component="div"
                fontWeight={500}
                alignItems="center"
                color={COLORS.gray2}
                onClick={onOpenDialog}
                sx={{ cursor: 'pointer', marginLeft: '14px' }}
              >
                Delete template
                <DeleteIcon
                  htmlColor={COLORS.gray2}
                  sx={{ width: 14, marginLeft: '4px' }}
                />
              </Stack>
            </BulkActions>
          )
        }}
      />
      <ToggleSidebar
        isOpen={openToggle.bonusTemplateDetails}
        ToggleSidebarTitle={<ToggleSidebarTitle />}
        toggleSidebar={() => onToggleTemplate('bonusTemplateDetails', params)}
      >
        <TemplatesDetails />
      </ToggleSidebar>
      <DialogDelete bonusTemplates={bonusTemplates} />
    </Box>
  )
}

export default BonusTable
