import InfoIcon from '@mui/icons-material/Info'
import { Typography, Stack, Tooltip } from '@mui/material'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { PaymentCardProps } from 'types/paymentCard'
import { PaymentCardIcon, VARIANT } from './styles'

export default function PaymentCard({
  variant,
  icon: IconComponent,
  title,
  subtitle = '',
  iconProps,
  tooltip,
  typography,
  showInfo = false,
  children,
  paperProps,
  paperColor = 'default'
}: PaymentCardProps) {
  return (
    <Paper elevation={2} {...paperProps} bgcolor={VARIANT[paperColor].bgcolor}>
      <Stack flexDirection="row">
        <PaymentCardIcon
          bgcolor={VARIANT[variant].bgcolor}
          opacity={VARIANT[variant].opacity}
        >
          <IconComponent
            {...iconProps}
            htmlColor={VARIANT[variant].color}
            sx={{ width: 25, ...iconProps?.sx }}
          />
        </PaymentCardIcon>
        <Stack
          width="100%"
          marginLeft={2}
          justifyContent={subtitle ? 'flex-start' : 'center'}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h6" color={COLORS.gray1}>
              {title}
            </Typography>
            {showInfo && (
              <Tooltip title={tooltip}>
                <InfoIcon color="primary" />
              </Tooltip>
            )}
          </Stack>
          <Typography
            fontSize="24px"
            fontWeight={500}
            color={COLORS.gray1}
            {...typography}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
      {children}
    </Paper>
  )
}
