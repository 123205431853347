import { Box } from '@mui/material'
import styled from 'styled-components'
import { COLORS } from 'styles/colors'
import { device, hexToRGB } from 'styles/utils'

export const LayoutContainer = styled.section`
  max-width: 1650px;
  width: 100%;
  display: flex;
  height: 100vh;
`

export const LayoutContent = styled(Box)`
  height: calc(100% - 6.5em);
  overflow: auto;
  background-color: ${hexToRGB(COLORS.gray8, '0.21')};
`

export const LayoutBody = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  ${device('md')} {
    width: calc(100% - 14.375em);
  }
`
