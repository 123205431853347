import { useState } from 'react'
import { ResetPassword } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { get } from 'utils'
import * as yup from 'yup'

export const validationSchema = yup.object({
  email: yup.string().email('Email invalid').required('Email required')
})

export default function useResetPassword() {
  const { onNavigate } = useNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const { onNotify } = useNotification()

  const onHandleRedirect = () => {
    onNavigate('/check-email')
  }

  const onSumbit = (values: { email: string }) => {
    setIsLoading(true)
    ResetPassword({ email: values.email.toLocaleLowerCase() })
      .then(() => {
        onHandleRedirect()
      })
      .catch((error: any) => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { isLoading, onSumbit }
}
