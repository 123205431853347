import { useState, useEffect } from 'react'
import { GetBonusTemplatesByCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { ListTemplates } from 'types/bonusTemplate'
import { get, getPagination } from 'utils'
type Params = { [key in string]: string | unknown }

export default function useGetBonusByCompany() {
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { user } = useAuth()
  const [bonusTemplates, setBonusTemplates] = useState<ListTemplates>(
    {} as ListTemplates
  )
  const { onNotify } = useNotification()
  const { isLoading, isFetching, refetch } = useQuery(
    'BONUSTEMPLATESBYCOMPANY',
    () =>
      GetBonusTemplatesByCompany(Number(user.company_id), {
        is_template: true
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const filteredBonus = data.data.entries.filter(item =>
          item.status === 'cancelled' || item.status === 'deleted'
            ? false
            : true
        )
        setBonusTemplates({
          entries: filteredBonus,
          page_number: data.data.page_number,
          page_size: data.data.page_size,
          total_entries: data.data.total_entries,
          total_pages: data.data.total_pages
        })
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onToggleTemplate = (
    toggle: 'bonusTemplate' | 'bonusTemplateDetails',
    id?: Params
  ) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle, id)
    } else {
      onOpenToggle(toggle, id)
    }
  }
  const getRows = (data: ListTemplates['entries']) => {
    const filteredJobs = data.map(item => {
      return {
        ...item
      }
    })
    return filteredJobs
  }
  useEffect(() => {
    refetch()
  }, [])
  const pagination = getPagination(bonusTemplates)
  const validateRows = getRows(bonusTemplates.entries || [])

  return {
    pagination,
    isLoading,
    bonusTemplates: validateRows,
    openToggle,
    onToggleTemplate,
    isFetching
  }
}
