import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Stack } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid'
import BulkActions from 'components/bulkactions'
import DropDown from 'components/dropdown'
import { EmptyPage } from 'components/emtpyPage/'
import Actions from 'components/selectActions/Actions'
import { DataGrid, Pagination } from 'components/table'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { COLORS } from 'styles/colors'
import { LinkButton, commonTableStyles } from 'styles/utils'
import useDeleteJob from '../hooks/useDeleteJob'
import useJob from '../hooks/useFetchJob'
import useJobStatus from '../hooks/useJobStatus'
import { JOB_STATUS, columns } from './constants'
import DeleteJobModal from './deleteJobModal'

export default function BoardTable() {
  const { onNavigate } = useNavigation()

  const { jobs, isLoading, pagination } = useJob()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const { selectedJobs, onSelectedJobs, onSubmit } = useJobStatus()
  const { isOpenModal, onDeleteJob } = useDeleteJob()

  const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
    onSelectedJobs(newSelection)
  }

  const onDetails = (id: number | string) => onNavigate(`${id}`)

  return (
    <Box
      width="100%"
      height={commonTableStyles.minHeight}
      paddingBottom={3}
      marginTop={4}
    >
      <DataGrid
        rows={jobs}
        columns={columns}
        checkboxSelection
        loading={isLoading}
        paginationMode="server"
        disableSelectionOnClick
        selectionModel={selectedJobs}
        rowCount={pagination.totalPages || 0}
        experimentalFeatures={{ newEditingApi: true }}
        onSelectionModelChange={handleSelectionModelChange}
        sx={{
          '.MuiDataGrid-virtualScroller': {
            zIndex: jobs.length > 0 ? 9 : -1
          }
        }}
        onCellClick={props => {
          if (props.field === '__check__') {
            return
          }
          onDetails(props.row.id)
        }}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          Header: () => (
            <BulkActions
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalEntries}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <DropDown
                label="Change job Status"
                actionUID="bulkaction-jobs"
                disabled={selectedJobs.length === 0}
              >
                <Actions
                  onApply={arg => onSubmit(arg.id as typeof jobs[0]['status'])}
                  options={JOB_STATUS}
                  actionUID="bulkaction-jobs"
                />
              </DropDown>
              <Stack
                direction="row"
                component="div"
                fontWeight={500}
                alignItems="center"
                color={COLORS.gray2}
                sx={{ cursor: 'pointer', marginLeft: '8px' }}
                onClick={() => onDeleteJob(Boolean(selectedJobs.length))}
              >
                Delete job
                <DeleteIcon
                  htmlColor={COLORS.gray2}
                  sx={{ width: 14, marginLeft: '4px' }}
                />
              </Stack>
            </BulkActions>
          ),
          NoResultsOverlay: () => (
            <EmptyPage
              message="You haven’t imported jobs yet"
              logo="/images/sync.svg"
              boxProps={{
                marginTop: 2,
                marginBottom: 4
              }}
            >
              <LinkButton to="/jobs/import">Click to import jobs</LinkButton>
            </EmptyPage>
          ),
          NoRowsOverlay: () => (
            <EmptyPage
              message="You haven’t imported jobs yet"
              logo="/images/sync.svg"
              boxProps={{
                marginTop: 2,
                marginBottom: 4
              }}
            >
              <LinkButton to="/jobs/import">Click to import jobs</LinkButton>
            </EmptyPage>
          )
        }}
      />
      <DeleteJobModal
        jobs={jobs}
        open={isOpenModal}
        selectedCheck={selectedJobs}
      />
    </Box>
  )
}
