import { JobCounterViews } from 'api'
import useNavigation from 'hooks/useNavigation'
import { useMutation } from 'react-query'

export default function useJobCounterViews() {
  const { params } = useNavigation()

  const { isLoading, mutate } = useMutation(
    'COUNTER_VIEWS',
    () => JobCounterViews(Number(params.jobId), Number(params.id)),
    {
      onError: error => {
        console.error(error)
      }
    }
  )

  const onMutate = () => {
    mutate()
  }

  return {
    isLoading: isLoading,
    onMutate
  }
}
