import { useEffect } from 'react'
import { Stack, Box, Typography } from '@mui/material'
import { STATUS } from 'app/bonus/templates/constants'
import useApplyBulkActions from 'app/bonus/templates/hooks/useApplyBulkActions'
import useBonusTemplateDetails from 'app/bonus/templates/hooks/useBonusTemplateDetails'
import ButtonGroup from 'components/buttonGroup'
import { COLORS } from 'styles/colors'
import { StatusBonusTemplateBulkActions } from 'types/bonusTemplate'

const STATUS_COLOR = {
  active: COLORS.main,
  inactive: COLORS.gray2,
  deleted: COLORS.red1,
  cancelled: COLORS.black1
}
export default function index() {
  const { bonusTemplate, getBonusTemplate } = useBonusTemplateDetails()

  useEffect(() => {
    getBonusTemplate()
  }, [])
  const { onApply } = useApplyBulkActions()
  const onChange = (args: any) => {
    const data: StatusBonusTemplateBulkActions = {
      action: 'update',
      ids: [Number(bonusTemplate.entries.id)],
      status: args.label.charAt(0).toLowerCase() + args.label.slice(1)
    }
    onApply(data)
  }

  return (
    <Stack>
      <Stack flexDirection="row">
        <Box
          sx={{
            backgroundColor: COLORS.honey4,
            width: 104,
            height: 40,
            borderRadius: '8px',
            marginRight: 1
          }}
        >
          <Typography textAlign="center" fontSize="24px">
            {bonusTemplate.entries?.id}
          </Typography>
        </Box>
        <Typography fontSize="24px" color={COLORS.white}>
          {bonusTemplate.entries?.description}
        </Typography>
      </Stack>
      <Stack
        alignItems="end"
        sx={{ position: 'absolute', left: '372px', bottom: '-16px' }}
      >
        <ButtonGroup
          selectedValue={{
            id: bonusTemplate.entries?.status,
            label: bonusTemplate.entries?.status
          }}
          bgcolor={
            STATUS_COLOR[
              bonusTemplate.entries?.status as keyof typeof STATUS_COLOR
            ]
          }
          options={STATUS}
          onChange={args => onChange(args)}
        />
      </Stack>
    </Stack>
  )
}
