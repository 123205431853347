import { Divider, Stack, Typography } from '@mui/material'
import AddAch from './AddAch'

export default function WireTransfer({
  accountNumber
}: {
  accountNumber: string
}) {
  return (
    <>
      <Typography variant="h5" paddingBottom={4}>
        Payment information
      </Typography>
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          Beneficiary:
        </Typography>
        <Typography variant="body2" width="65%">
          Tango Card Inc Collections, 4700 42nd Ave SW, #430A, Seattle, WA 98116
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          Beneficiary account number:
        </Typography>
        <Typography variant="body2" width="65%" fontWeight={700}>
          4945710978
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          Routing number (US domestic):
        </Typography>
        <Typography variant="body2" width="65%" fontWeight={700}>
          121000248
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          SWIFT code (International):
        </Typography>
        <Typography variant="body2" width="65%" fontWeight={700}>
          WFBIUS6SSEA
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          Beneficiary bank:
        </Typography>
        <Typography variant="body2" width="65%">
          Wells Fargo Bank, 420 Montgomery Street, San Francisco, CA 94104
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Stack alignItems="flex-start" justifyContent="start" direction="row">
        <Typography variant="body2" width="35%">
          Reason/memo:
        </Typography>
        <Typography variant="body2" width="65%">
          Reference Tango account <strong>{accountNumber}</strong> for
          depositing funds.
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Typography variant="h5" paddingY={4}>
        Send wire transfer information
      </Typography>
      <Typography variant="body2" marginBottom={2}>
        Funds will be applied to your account within 1-2 business days.
      </Typography>
      <AddAch />
    </>
  )
}
