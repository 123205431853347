import { UpdateCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateCompanyInfo } from 'types/companies'
import { get } from 'utils'

export default function useUpdateStatus() {
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: UpdateCompanyInfo & { companyId: number }) =>
      UpdateCompany(data.companyId, { company: data.company }),
    {
      onSuccess: () => {
        onNotify({
          message: 'Status updated successfully',
          severity: 'success'
        })
        query.invalidateQueries('COMPANY_BY_ID')
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onSubmit = (
    option: { id: string | number; label: string },
    companyId: number
  ) => {
    mutate({
      companyId,
      company: {
        status: option.id as 'active' | 'inactive'
      }
    })
  }

  return { isLoading, onSubmit }
}
