import { JobDetailSkeleton } from 'components/skeleton'
import Body from './body'
import Header from './header'
import useJobBoardDetail from './hooks/useJobBoardDetail'

export default function Detail() {
  const { isLoading } = useJobBoardDetail()
  return (
    <>
      {isLoading ? (
        <JobDetailSkeleton />
      ) : (
        <>
          <Header />
          <Body />
        </>
      )}
    </>
  )
}
