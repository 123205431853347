import { Stack, Typography, Box } from '@mui/material'

import { DragDropContext } from 'components/drag'
import { EmptyPage } from 'components/emtpyPage'
import Paper from 'components/paper'
import { LinkButton } from 'styles/utils'
import Detail from './detail'
import DragImport from './dragImport'
import DragJobs from './dragJobs'
import Header from './header'
import useFetchInfinitive from './hooks/useFetchInfinitive'
import useSelected from './hooks/useSelected'
import MobileImport from './mobileImport'

export default function Imports() {
  const { jobs, isLoading, isDisabled, isEmptyConfig, fetchNextPage } =
    useFetchInfinitive()

  const {
    jobsList,
    importList,
    selectedJob,
    searchValue,
    onDragEnd,
    onImportSearch
  } = useSelected(jobs)

  const hasJob = selectedJob.id_source

  return (
    <Box height="calc(100% - 1em)">
      <Stack flexDirection="row" alignItems="center">
        <Header isEmptyConfig />
      </Stack>
      {isEmptyConfig ? (
        <Stack
          component={Paper}
          elevation={6}
          alignItems="center"
          justifyContent="center"
          minHeight="calc(100vh - 260px)"
        >
          <EmptyPage
            boxProps={{
              marginTop: 2
            }}
            message={
              <Typography
                variant="h3"
                alignItems="center"
                textAlign="center"
                display="flex"
                position="relative"
                width={{ xs: '100%', sm: '50%', lg: '60%' }}
              >
                Almost there!, you have to connect your ATS
              </Typography>
            }
            logo="/images/integration.png"
          >
            <LinkButton to="/profile/integrations">
              Go to integrations
            </LinkButton>
          </EmptyPage>
        </Stack>
      ) : (
        <>
          <Box display={{ xs: 'block', md: 'none' }}>
            <MobileImport
              jobsList={jobsList}
              isLoading={isLoading}
              isDisabled={isDisabled}
              fetchNextPage={fetchNextPage}
            />
          </Box>

          <Box display={{ xs: 'none', md: 'block' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Stack flexDirection="row" gap={1}>
                <Box width={hasJob ? '35%' : '50%'}>
                  <DragJobs
                    jobsList={jobsList}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    hasImportJobs={importList.length > 0}
                    fetchNextPage={fetchNextPage}
                  />
                </Box>

                {hasJob && (
                  <Box width={hasJob ? '30%' : '0'}>
                    <Detail />
                  </Box>
                )}

                <Box width={hasJob ? '35%' : '50%'}>
                  <DragImport
                    searchValue={searchValue}
                    importList={importList}
                    onImportSearch={onImportSearch}
                  />
                </Box>
              </Stack>
            </DragDropContext>
          </Box>
        </>
      )}
    </Box>
  )
}
