import { ReferralByEmailSms } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useStepper from 'components/stepper/useStepper'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'
import { ReferralByEmailData } from 'types/referrals'
import { get } from 'utils'
import { Referrals } from './useRefer'

export default function useInvitation(callback: (arg: 'referBy') => void) {
  const { searchParams, params } = useNavigation()
  const { onNotify } = useNotification()
  const { onClearSteps } = useStepper()

  const { user } = useAuth()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: ReferralByEmailData) => ReferralByEmailSms(data),
    {
      onSuccess: () => {
        onClearSteps()
        callback('referBy')

        onNotify({
          message: 'Invitation sent successfully',
          severity: 'success'
        })

        query.invalidateQueries('JOB_DETAIL')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onMutation = (data: Referrals) => {
    const dataToSend = {
      to: data.referred.map(item => ({
        phone: item.phone,
        email: item.email.toLocaleLowerCase()
      })),
      employee_id: user.id,
      company_id: user.company_id,
      job_id: Number(searchParams.jobId) || Number(params.id),
      via: data.via,
      subject: data.subject,
      message: data.message
    }
    mutate(dataToSend)
  }

  return { isLoading, onMutation }
}
