import AddIcon from '@mui/icons-material/Add'
import FilterList from '@mui/icons-material/FilterList'
import { Box, Typography, Stack, Divider, Button } from '@mui/material'
import SearchBar from 'components/SearchBar'
import useStepper from 'components/stepper/useStepper'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import useFetchDetail from '../../../../hooks/useFetchDetail'
import BonusCard from '../../bonusCard'
import useGetBonusByCompany from '../../hooks/useGetBonusByCompany'

export default function BonusStep() {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const { onNextStep } = useStepper()
  const { bonusTemplates } = useGetBonusByCompany()
  const { selectedBonus, setSelectedBonus } = useFetchDetail()

  const handleContinue = () => {
    onNavigate(pathname, {
      search: { ...searchParams, bonusId: selectedBonus }
    })
    onNextStep()
  }

  return (
    <Box height="100%">
      <Typography variant="h3">Select a bonus template</Typography>
      <Stack
        marginY={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <FilterList />
        <SearchBar boxProps={{ width: '100%', marginLeft: 2 }} />
      </Stack>
      <Divider sx={{ bgcolor: COLORS.honey2 }} />
      <Stack
        paddingY={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">Bonus list</Typography>
        <Stack
          component="div"
          direction="row"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          color={COLORS.main}
        >
          <Button onClick={handleContinue}>
            <AddIcon sx={{ width: '13px', fontWeight: 500 }} />
            <Typography fontWeight="500" variant="caption" color={COLORS.main}>
              New bonus
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Box
        height="100%"
        sx={{
          overflow: 'auto',
          ...StylesScrollBar
        }}
      >
        {bonusTemplates.map(item => {
          return (
            item.status === 'active' && (
              <BonusCard
                key={item.id}
                {...item}
                selectedBonus={selectedBonus}
                setSelectedBonus={setSelectedBonus}
              />
            )
          )
        })}
      </Box>
      <Button
        fullWidth
        variant="contained"
        disabled={!selectedBonus}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Box>
  )
}
