import { useEffect, useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { get, getPagination } from 'utils'
import { GetEmployees } from '../../../api/index'
import { EmployeeBoard } from '../Types/Employees'
import { getRows } from '../constants'
import useFilterEmployeeStore from './useFilterEmployeeStore'

export default function useEmployees() {
  const [employees, setEmployees] = useState<EmployeeBoard>({} as EmployeeBoard)
  const { onNotify } = useNotification()
  const { filters } = useFilterEmployeeStore()
  const { searchParams } = useNavigation()

  const { isLoading, refetch } = useQuery(
    'EMPLOYEE',
    () =>
      GetEmployees({ page: filters.page, size: filters.size, ...searchParams }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setEmployees(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const getEmployeeTemplate = () => refetch()

  useEffect(() => {
    refetch()
  }, [JSON.stringify(searchParams)])

  const validatedEmployees = getRows(employees.entries || [])

  const pagination = getPagination(employees)

  return {
    getEmployeeTemplate,
    pagination,
    employees: validatedEmployees,
    isLoading: isLoading
  }
}
