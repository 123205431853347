import { useState, useEffect } from 'react'
import { GetReferrals } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { Referrals } from 'types/referrals'
import { get, getPagination } from 'utils'
import { getRows } from '../constants'
import useFilterStatusStore from './useFilterStatusStore'
type Params = { [key in string]: string | unknown }

export default function useReferralTable() {
  const { user } = useAuth()
  const { onNotify } = useNotification()
  const { filters } = useFilterStatusStore()
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { searchParams } = useNavigation()
  const [referrals, setReferrals] = useState<Referrals>({} as Referrals)

  const { isLoading, refetch, isFetching } = useQuery(
    'REFERRALS',
    () =>
      GetReferrals(user.company_id, {
        page: filters.page,
        size: filters.size,
        search: searchParams.search,
        ...searchParams
      }),
    {
      enabled: Boolean(user.company_id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setReferrals(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onToggleTemplate = (toggle: 'referrals', id?: Params) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle, id)
    }
  }
  useEffect(() => {
    refetch()
  }, [JSON.stringify(searchParams)])

  const pagination = getPagination(referrals)

  const validateRows = getRows(referrals.entries || [])

  return {
    onToggleTemplate,
    pagination,
    isLoading: isLoading || isFetching,
    openReReferralDetail: openToggle,
    referrals: validateRows
  }
}
