import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import useJobApplication from 'app/profile/hooks/useJobApplication'
import TextField from 'components/TextField'
import DragAndDroFile from 'components/dragAndDropFile'
import { Form, Formik } from 'formik'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { JobApplication } from 'types/jobs'
import Footer from '../../layout/footer/index'
export default function JobApplicationStep2() {
  const { params } = useNavigation()

  const [base64, setBase64] = useState<
    string | ArrayBuffer | null | undefined
  >()

  const { loading, jobForm, submit } = useJobApplication()

  const handleDrop = async ({ file }: { file: File[] }) => {
    const fileToLoad = file[0]
    const fileReader = new FileReader()
    let base64
    fileReader.onload = function (fileLoadedEvent) {
      base64 = fileLoadedEvent?.target?.result
      setBase64(base64)
    }
    fileReader.readAsDataURL(fileToLoad)
  }

  const onSubmit = (values: any) => {
    const application: JobApplication = {
      first_name: jobForm.first_name,
      last_name: jobForm.last_name,
      email: jobForm.email,
      phone: jobForm.phone,
      phone_area_code: jobForm.phone_area_code,
      description: values.description,
      file: base64,
      address_1: '',
      address_2: '',
      birthday: '',
      city: '',
      country_id: jobForm.country_id,
      employee_id: Number(params.employeeId),
      password: '',
      state: '',
      username: '',
      zip: ''
    }
    submit(application)
  }

  return (
    <Stack
      width="100%"
      height="90vh"
      justifyContent="space-between"
      alignItems="center"
      marginTop={2}
    >
      <Box
        height="60%"
        padding="10px"
        margin="0 auto"
        boxSizing="border-box"
        width={{ xs: '100%', md: '70%', lg: '60%' }}
      >
        <Typography variant="h3" textAlign="center" marginBottom={4}>
          Increase your chances!
        </Typography>
        <Typography variant="h5" textAlign="center" marginBottom={4}>
          Please provide your information below to complete your application
        </Typography>
        <Formik enableReinitialize initialValues={{}} onSubmit={onSubmit}>
          {({ handleChange }) => (
            <Form
              style={{
                height: '80%'
              }}
            >
              <Stack
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Stack
                  flexDirection="column"
                  sx={{ width: '100%' }}
                  marginBottom={2}
                >
                  <Typography variant="h6" color={COLORS.gray2}>
                    Why do you believe that you are the ideal candidate for this
                    position? (Optional)
                  </Typography>
                  <Box overflow="hidden" sx={{ border: 1 }} marginBottom={2}>
                    <TextField
                      sx={{
                        width: '100%'
                      }}
                      multiline
                      rows={4}
                      variant="standard"
                      onChange={handleChange('description')}
                      name="description"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Box>
                  <DragAndDroFile
                    accept={{
                      'application/pdf': ['.pdf'],
                      'image/*': ['.jpeg', '.png', '.jpeg', '.webp']
                    }}
                    onDrop={file => handleDrop({ file })}
                  />
                </Stack>

                <Box marginRight="auto" marginLeft="auto" marginTop={2}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    type="submit"
                    color="primary"
                    sx={{
                      width: '250px'
                    }}
                  >
                    Send Application
                  </LoadingButton>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
      <Footer />
    </Stack>
  )
}
