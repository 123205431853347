import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import useJobApplication from 'app/profile/hooks/useJobApplication'
import { COLORS } from 'styles/colors'
import Footer from '../../layout/footer/index'
export default function JobApplicationStep3() {
  const { loading, handleSubmit } = useJobApplication()
  return (
    <Stack
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height="60%"
        padding="10px"
        margin="0 auto"
        boxSizing="border-box"
        width={{ xs: '100%', md: '70%', lg: '60%' }}
      >
        <Stack direction="row" justifyContent="center" marginBottom={4}>
          <CheckCircleOutline
            sx={{ color: COLORS.green2 }}
            style={{ marginRight: '10px' }}
          />
          <Typography variant="h3" textAlign="center">
            Application sent
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5" marginBottom={4} textAlign="center">
            We’ll get back to you
          </Typography>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '350px'
            }}
          >
            View more jobs
          </LoadingButton>
        </Stack>
      </Box>
      <Footer />
    </Stack>
  )
}
