import Add from '@mui/icons-material/Add'
import Star from '@mui/icons-material/Star'
import { Typography, Stack, Button } from '@mui/material'
import Templates from 'app/bonus/templates/templates'
import SearchBar from 'components/SearchBar'
import ToggleSidebar from 'components/toggleSidebar/index'
import useNavigation from 'hooks/useNavigation'
import useBonusTable from './hooks/useBonusTable'

const BonusHeader = () => {
  const { onToggleTemplate, openToggle } = useBonusTable()
  const { searchParams } = useNavigation()
  const params = searchParams
  delete params['bonusTemplate']

  return (
    <>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        marginTop={3}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          marginBottom={{ xs: 2, sm: 0 }}
          width="100%"
        >
          <Stack flexDirection="row" alignItems="center" width="40%">
            <Star />
            <Typography marginLeft={2} marginRight={4} variant="h2">
              Bonus Template
            </Typography>
          </Stack>
          <Stack
            gap={3}
            alignItems="flex-end"
            width={{ xs: '100%' }}
            justifyContent="space-between"
            direction={{ xs: 'column', sm: 'row' }}
          >
            <SearchBar boxProps={{ maxWidth: { xs: '250px' } }} />
            <Button
              fullWidth
              variant="contained"
              startIcon={<Add />}
              children="Add Template"
              sx={{ maxWidth: '190px' }}
              onClick={() => onToggleTemplate('bonusTemplate', searchParams)}
            />
          </Stack>
        </Stack>
      </Stack>
      <ToggleSidebar
        headerTitle="New bonus template"
        isOpen={openToggle.bonusTemplate}
        toggleSidebar={() => onToggleTemplate('bonusTemplate', params)}
      >
        <Templates />
      </ToggleSidebar>
    </>
  )
}

export default BonusHeader
