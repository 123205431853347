import AttachMoney from '@mui/icons-material/AttachMoney'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import Paper from 'components/paper'
import PaymentCard from 'components/paymentCard'
import { BalanceSkeleton } from 'components/skeleton'
import WithdrawCard from 'components/withdrawCard'
import { COLORS } from 'styles/colors'
import { formatCurrency, formatDate } from 'utils'
import useFunds from './hooks/useFunds'
import useTangoCompany from './hooks/useTangoCompany'
import useTransactions from './hooks/useTransactions'
import RechargeDialog from './rechargeDialog'
import useCreditCard from './rechargeDialog/creditCard/useCreditCard'
import Funds from './rechargeDialog/funds'

export default function Credits() {
  const { isOpen, onOpenDialog } = useCreditCard()

  const { tangoCompany, isLoading: loadingTangoCompany } = useTangoCompany()
  const { transactions, isLoading } = useTransactions()
  const { funds, pagination, isLoading: isLoadingFunds } = useFunds()

  return (
    <>
      <Stack
        paddingLeft={2}
        paddingBottom="5px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h5"
          fontWeight="500"
          fontSize={{ xs: '18px', sm: '20px' }}
        >
          BeeGopp balance
        </Typography>
        <Button
          variant="text"
          onClick={onOpenDialog}
          startIcon={<EditIcon htmlColor={COLORS.main} />}
          sx={{
            marginRight: '10px',
            alignSelf: 'flex-end'
          }}
        >
          Add funds
        </Button>
      </Stack>
      <Divider />
      {loadingTangoCompany || isLoading ? (
        <BalanceSkeleton />
      ) : (
        <Stack
          gap={6}
          elevation={1}
          paddingTop={4}
          marginTop={4}
          component={Paper}
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
        >
          <Box width={{ xs: '100%', lg: '50%' }}>
            <Typography
              variant="h5"
              fontWeight="500"
              fontSize={{ xs: '18px', sm: '20px' }}
            >
              Summury
            </Typography>
            <WithdrawCard marginTop={4}>
              <Stack height="100%" justifyContent="center">
                <Typography
                  variant="h6"
                  color={COLORS.white}
                  letterSpacing="1px"
                >
                  YOUR AVAILABLE BALANCE
                </Typography>
                <Typography
                  fontSize={32}
                  variant="h6"
                  color={COLORS.white}
                  marginBottom={2}
                  fontWeight={500}
                >
                  {formatCurrency(
                    Number(tangoCompany?.beegopp_tango_data.tango_balance) || 0
                  )}{' '}
                  USD
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  color={COLORS.white}
                >
                  {formatDate(
                    tangoCompany?.tango_response.createdAt || '',
                    'MMMM - yyyy'
                  )}
                </Typography>
              </Stack>
            </WithdrawCard>
          </Box>
          <Stack width={{ xs: '100%', lg: '50%' }}>
            <PaymentCard
              variant="success"
              icon={AttachMoney}
              title="Credit card funds"
              typography={{ fontSize: '14px', fontWeight: 500 }}
              paperProps={{ sx: { marginBottom: 2 } }}
              subtitle={
                formatCurrency(transactions.total_credit_card_funds || 0) +
                ' USD'
              }
            />
            <PaymentCard
              variant="success"
              icon={AttachMoney}
              title="ACH wire transfer funds"
              paperProps={{ sx: { marginBottom: 2 } }}
              typography={{ fontSize: '14px', fontWeight: 500 }}
              subtitle={
                formatCurrency(transactions.total_ach_funds || 0) + ' USD'
              }
            />
            <PaymentCard
              variant="success"
              icon={AttachMoney}
              title="Total paid"
              paperProps={{ sx: { marginBottom: 2 } }}
              typography={{ fontSize: '14px', fontWeight: 500 }}
              subtitle={formatCurrency(transactions.total_paid || 0) + ' USD'}
            />
          </Stack>
        </Stack>
      )}
      <Funds funds={funds} pagination={pagination} isLoading={isLoadingFunds} />
      <RechargeDialog
        open={isOpen}
        accountNumber={
          String(tangoCompany?.tango_response.accounts[0].accountNumber) || '0'
        }
      />
    </>
  )
}
