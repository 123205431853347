import { useState } from 'react'
import { ReportPayments } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { downloadCSV, formatDate } from 'utils'
import * as yup from 'yup'

export const validationSchema = yup.object({
  startDate: yup.date().required('Date required'),
  endDate: yup.date().required('Date required')
})

export const initialValues = {
  startDate: new Date(),
  endDate: new Date()
}

export default function UseDownloadCsv() {
  const [active, setActive] = useState(false)
  const { onNotify } = useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const handleToggle = () => {
    setActive(!active)
  }

  const onSubmit = (values: { startDate: Date; endDate: Date }) => {
    setIsLoading(true)
    ReportPayments({
      init_date: `${formatDate(
        new Date(values.startDate),
        'yyyy-MM-dd'
      )} 00:00:00` as unknown as Date,
      end_date: `${formatDate(
        new Date(values.endDate),
        'yyyy-MM-dd'
      )} 00:00:00` as unknown as Date
    })
      .then(data => {
        const fileURL = URL.createObjectURL(data)
        downloadCSV({ url: fileURL, name: 'repport.csv' })
        handleToggle()
      })
      .catch(error => {
        console.error(error)
        onNotify({ message: 'No payments to report', severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return {
    onSubmit,
    isLoading,
    handleToggle,
    active
  }
}
