import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ColumnHeader, TableChip } from 'components/table'
import { COLORS } from 'styles/colors'
import { LinkButton } from 'styles/utils'
import { Company } from 'types/companies'
import { formatDate } from 'utils'

export const STATUS_COLORS = {
  active: COLORS.main,
  inactive: COLORS.gray3,
  new: COLORS.main,
  pending: COLORS.orange1
}

export const getColumns = ({
  isLoading,
  isLoginAs,
  selectedCompany
}: {
  isLoading: boolean
  isLoginAs: boolean
  selectedCompany: Company['entries'][0]
}): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      editable: false,
      sortable: false,
      renderHeader: props => <ColumnHeader {...props} />
    },
    {
      field: 'name',
      headerName: 'Company',
      width: 240,
      editable: false,
      sortable: false,
      renderHeader: props => <ColumnHeader {...props} />
    },
    {
      field: 'companyOwner',
      headerName: 'Company Owner',
      width: 280,
      editable: false,
      sortable: false,
      renderCell: (props: GridCellParams) => {
        const ownerName = `${props.row.owner.first_name} ${
          props.row.owner.last_name || ''
        } `
        return (
          <Stack>
            <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
              {ownerName}
            </Typography>
            <LinkButton style={{ marginLeft: 0 }} to="#">
              {props.row.owner.email}
            </LinkButton>
          </Stack>
        )
      },
      renderHeader: props => <ColumnHeader {...props} />
    },
    {
      field: 'employes',
      headerName: 'Employees',
      editable: false,
      width: 100,
      sortable: false,
      renderHeader: props => <ColumnHeader {...props} />
    },
    {
      field: 'referrals',
      headerName: 'Referrals',
      width: 80,
      editable: false,
      sortable: false,
      renderHeader: props => <ColumnHeader {...props} />
    },
    {
      field: 'inserted_at',
      headerName: 'Joined date',
      width: 180,
      editable: false,
      sortable: false,
      renderCell: (props: GridCellParams) => (
        <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
          {formatDate(props.row.inserted_at, 'MMM dd, yyy')}
        </Typography>
      )
      // renderHeader: props => (
      //   <ColumnHeader {...props}>
      //     <div>test</div>
      //   </ColumnHeader>
      // )
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      editable: false,
      sortable: false,
      renderCell: props => (
        <TableChip {...props} chipProps={{ variant: 'outlined' }} />
      )
      // renderHeader: props => (
      //   <ColumnHeader {...props}>
      //     <div>test</div>
      //   </ColumnHeader>
      // )
    },
    {
      field: 'loginAs',
      headerName: 'Login as',
      width: 140,
      editable: false,
      sortable: false,
      renderCell: (props: GridCellParams) => {
        const ownerId = props.row?.owner?.id
        const isCompanyOwner = selectedCompany?.owner?.id === ownerId
        const isPending = props.row.status === 'pending'
        return (
          <LoadingButton
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            loading={isCompanyOwner && isLoading}
            disabled={isLoading || isLoginAs || isPending}
          >
            Login as
          </LoadingButton>
        )
      },
      renderHeader: props => <ColumnHeader {...props} />
    }
  ]
}

export const COMPANY_STATUS = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' }
]
