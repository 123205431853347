import { Line } from '@nivo/line'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import useReferralGraph from '../hooks/useReferralGraph'
import useReferralGraphE from '../hooks/useReferralGraphE'

export const Lineal = () => {
  const { userRole } = useAuth()
  const { dataReferral } =
    userRole == 'super_admin' ? useReferralGraph() : useReferralGraphE()

  const referalData =
    dataReferral.data != undefined ? dataReferral.data : [{ x: 0, y: 0 }]

  const data = [
    {
      id: 'refferals',
      color: COLORS.honey1,
      data: referalData
    }
  ]

  return (
    <Line
      width={564}
      height={214}
      data={data}
      margin={{ top: 7, right: 110, bottom: 50, left: 60 }}
    />
  )
}
