import { config } from 'config'
import { initializeApp } from 'firebase/app'
import { Messaging, getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId
}

let messaging: Messaging

isSupported()
  .then(() => {
    const app = initializeApp(firebaseConfig)
    const initMessaging = getMessaging(app)
    messaging = initMessaging
  })
  .catch(error => {
    console.error(error)
  })

export { messaging }
