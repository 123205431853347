import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useNavigation from 'hooks/useNavigation'
import type { FilterName } from 'types/jobs'
import type { UseFilter } from 'types/table'
import { PAGE, SIZE } from 'utils/catalogs'

const initialState = hookstate({
  filters: {
    company: [] as Array<UseFilter>,
    jobStatus: [] as Array<UseFilter>,
    bonusStatus: [] as Array<UseFilter>
  }
})

export default function useFilterReferralByEmpStore() {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const { filters } = useHookstate(initialState)

  useEffect(() => {
    addUrlFilters()
  }, [JSON.stringify(filters.value)])

  const onAddFilter = (filter: UseFilter, name: FilterName) => {
    filters.set(prevState => {
      if (!filter.checked) {
        return {
          ...prevState,
          [name]: [...prevState[name].filter(item => item.id !== filter.id)]
        }
      }
      return {
        ...prevState,
        [name]: [
          ...prevState[name].filter(item => item.id !== filter.id),
          filter
        ]
      }
    })
  }

  const addUrlFilters = () => {
    onNavigate(pathname, {
      search: {
        ...searchParams,
        page: searchParams.page || PAGE,
        size: searchParams.size || SIZE
      }
    })
  }

  const onDeleteFilter = (filter: UseFilter, name: FilterName) => {
    filters.set(prevState => ({
      ...prevState,
      [name]: [...prevState[name].filter(item => item.id !== filter.id)]
    }))
  }

  const onCleanFilters = () => filters.set({} as any)

  const paginationParams = {
    page: searchParams.page,
    size: searchParams.size
  }
  return {
    onAddFilter,
    onDeleteFilter,
    onCleanFilters,
    filters: { ...filters.value, ...paginationParams }
  }
}
