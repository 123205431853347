import { useEffect, useState } from 'react'
import { GetTangoCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import { TangoResponse } from 'types/profile'
import { get } from 'utils'

export default function useTangoCompany() {
  const { onNotify } = useNotification()
  const [tangoCompany, setTangoCompany] = useState<TangoResponse>()

  const { isLoading, isFetching, refetch } = useQuery(
    'TANGO_COMPANY',
    () => GetTangoCompany(),
    {
      refetchOnWindowFocus: 'always',
      onSuccess: data => {
        setTangoCompany(data.entries)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  return {
    tangoCompany,
    isLoading: isLoading || isFetching
  }
}
