import { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import CreditCard from '../creditCard'
import WireTransfer from '../wiretransfer'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Box
      role="tabpanel"
      component="div"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      maxHeight={660}
      sx={{
        overflow: 'scroll',
        ...StylesScrollBar
      }}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export function RechargeTabs({ accountNumber }: { accountNumber: string }) {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue)

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="recharge-tabs"
        variant="scrollable"
        TabIndicatorProps={{
          sx: {
            background: 'transparent'
          }
        }}
        sx={{ marginBottom: 6 }}
      >
        <Tab
          {...a11yProps(0)}
          label={
            <Typography
              textTransform="capitalize"
              color={value === 0 ? COLORS.black : COLORS.gray1}
            >
              Credit card
            </Typography>
          }
          sx={{
            width: { xs: '50%', sm: '340px' },
            justifyContent: 'space-between',
            background: value === 0 ? '#FFDA8D' : ''
          }}
        />
        <Tab
          {...a11yProps(1)}
          label={
            <Typography
              textTransform="capitalize"
              color={value === 1 ? COLORS.black : COLORS.gray1}
            >
              ACH/Wire transfer
            </Typography>
          }
          sx={{
            width: { xs: '50%', sm: '340px' },
            justifyContent: 'space-between',
            background: value === 1 ? '#FFDA8D' : ''
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <CreditCard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WireTransfer accountNumber={accountNumber} />
      </TabPanel>
    </Box>
  )
}
