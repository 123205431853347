import { Link, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { COLORS } from 'styles/colors'
import { formatDate } from 'utils/index'
import ColumnHeader from '../../../components/table/ColumnHeader'
import TableChip from '../../../components/table/TableChip'
import { ReferralStatus, Referrals } from '../../../types/referrals'
import Status from '../filters/Status'
const STATUS: { [key in ReferralStatus]: string } = {
  hired: 'Hired',
  on_hold: 'On hold',
  in_progress: 'In progress',
  rejected: 'Rejected',
  quit: 'Quit',
  active: 'Active'
}
export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  {
    field: 'names',
    headerName: 'Name',
    headerAlign: 'left',
    width: 290,
    editable: true,
    renderHeader: props => <ColumnHeader {...props} />,
    renderCell: props => {
      return <div>{`${props.row.first_name} ${props.row.last_name || ''}`}</div>
    }
  },
  {
    field: 'email',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Email',
    width: 300,
    editable: true,
    renderHeader: props => <ColumnHeader {...props} />,
    renderCell: params => {
      return <div>{params.row.email.toLowerCase()}</div>
    }
  },

  {
    field: 'job_id',
    headerName: 'Job ID',
    headerAlign: 'left',
    align: 'left',
    width: 100,
    editable: true,
    sortable: false,
    renderCell: params => {
      return (
        <Link>
          <Typography
            variant="body2"
            color={COLORS.main}
            sx={{ textDecoration: 'underline' }}
          >
            {params.row.job_id}
          </Typography>
        </Link>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'employee',
    headerName: 'Referred By',
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    width: 220,
    editable: true,
    renderHeader: props => <ColumnHeader {...props} />,
    renderCell: params => {
      return (
        <div>
          {params.row.referred?.first_name}{' '}
          {params.row.referred?.last_name || ''}
        </div>
      )
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: props => {
      return (
        <TableChip
          {...props}
          label={STATUS[props.row.status]}
          chipProps={{ variant: 'filled' }}
          labelColor={STATUS[props.row.status] === 'Quit' ? 'black' : 'white'}
        />
      )
    },
    renderHeader: props => {
      return (
        <ColumnHeader {...props}>
          <Status />
        </ColumnHeader>
      )
    }
  },
  {
    field: 'inserted_at',
    headerName: 'Joined',
    width: 160,
    headerAlign: 'left',
    align: 'left',
    type: 'number',
    editable: true,
    sortable: false,
    renderCell: props => {
      return (
        <div>{formatDate(new Date(props.row.inserted_at), 'dd LLL yyyy')}</div>
      )
    },
    renderHeader: props => <ColumnHeader {...props} />
  }
]

export const getRows = (data: Referrals['entries']) => {
  const filteredJobs = data.map(item => {
    return {
      ...item
    }
  })
  return filteredJobs || []
}

export const REFERRAL_STATUS = [
  { id: 'hired', label: 'Hired' },
  { id: 'in_progress', label: 'In Progress' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'on_hold', label: 'On Hold' },
  { id: 'active', label: 'Active' }
]
export const REFERRAL_JOINED = [
  { id: 1, label: 'This Week' },
  { id: 2, label: 'This Month' },
  { id: 3, label: 'This Quarter' },
  { id: 4, label: 'This Year' },
  { id: 5, label: 'Last Week' },
  { id: 6, label: 'Last Month' },
  { id: 7, label: 'Last Year' },
  { id: 8, label: 'All Time' }
]

export const REFERRAL_RANGEDATES = [
  { id: 1, label: 'Start Date' },
  { id: 2, label: 'End Date' }
]

export const REFERRAL_DETAIL = {
  name: 'Catherine',
  lastName: 'Metzler',
  country: 'United States',
  email: 'personame@company.com',
  contact: '+1 591 294 448',
  CV: 'Cate_Metzler_CV.pdf'
}
