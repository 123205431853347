import { useState } from 'react'

export const useToggle = () => {
  const [active, setActive] = useState<boolean>(false)
  const [activeMessage, setActiveMessage] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const handleToggle = () => {
    setActive(!active)
  }
  const handleToggleMessage = () => {
    setActiveMessage(!activeMessage)
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  return {
    active,
    activeMessage,
    openDialog,
    handleToggle,
    handleToggleMessage,
    handleClickOpenDialog,
    handleCloseDialog
  }
}
