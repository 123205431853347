import { Link, Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { ColumnHeader } from 'components/table'
import TableChip from 'components/table/TableChip'
import { COLORS } from 'styles/colors'
import { formatDate } from 'utils'
import { BonusPayments } from '../types'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'referral_first_name',
    headerName: 'Referral',
    width: 250,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Link>
        <Typography
          variant="body2"
          color={COLORS.main}
          sx={{ textDecoration: 'underline' }}
        >
          {`${props.row.referral_first_name} ${props.row.referral_last_name}`}
        </Typography>
      </Link>
    ),

    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'job_role',
    headerName: 'Job',
    width: 220,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Link>
        <Typography
          variant="body2"
          overflow="hidden"
          color={COLORS.main}
          textOverflow="ellipsis"
          sx={{ textDecoration: 'underline' }}
        >
          {props.row.job_role}
        </Typography>
      </Link>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'payment_amount',
    headerName: 'Amount',
    width: 110,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {'$ ' + props.row.payment_amount + ' USD'}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'payment_date',
    headerName: 'Application date',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.payment_date, 'MMM dd, yyy')}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    width: 200,
    editable: false,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
        {formatDate(props.row.updated_at, 'MMM dd, yyy')}
      </Typography>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'referral_status',
    headerName: 'Referral Status',
    headerAlign: 'left',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip
        {...props}
        chipProps={{
          variant: 'filled'
        }}
      />
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'payment_status',
    headerName: 'Bonus Payment',
    headerAlign: 'left',
    width: 160,
    editable: false,
    sortable: false,
    renderCell: props => (
      <TableChip
        {...props}
        chipProps={{
          variant: 'filled'
        }}
      />
    ),
    renderHeader: props => <ColumnHeader {...props} />
  }
]
export const getRows = (data: BonusPayments['entries']) => {
  const filteredBonusPayments = data.map(item => {
    return {
      ...item
      //applicants: item. || 0,
      //bonusStatus: 'Active' // what's that?
    }
  })
  return filteredBonusPayments || []
}
