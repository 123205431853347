import { EmployeeDetailBody } from './EmployeeDetailBody'
import { EmployeeDetailHeader } from './EmployeeDetailHeader'

export const EmployeeDetail = () => {
  return (
    <>
      <EmployeeDetailHeader />
      <EmployeeDetailBody />
    </>
  )
}
