import { useEffect, useState } from 'react'
import { DropResult } from '@hello-pangea/dnd'
import { hookstate, useHookstate } from '@hookstate/core'
import type { Job, JobProps } from 'types/jobs'
import { TypeJob, emptyJobs, getInitialJobs, onDragAndDrop } from '../contants'

const initialSelected = hookstate({
  selectedJob: {} as Job,
  typeJob: { import: false, jobs: false },
  jobs: emptyJobs
})

export default function useSelected(data?: Array<Job>) {
  const { jobs, typeJob, selectedJob } = useHookstate(initialSelected)
  const [searchValue, setSearchValue] = useState('')
  const [prevImport, setPrevImport] = useState<Array<Job>>([])

  useEffect(() => {
    if (data) {
      const initialJobs = getInitialJobs(data as Array<Job>)
      jobs.set(initialJobs)
    }
  }, [JSON.stringify(data)])

  const onImportSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchValue(value)
    const parseJobs = JSON.parse(JSON.stringify(jobs.value)) as typeof emptyJobs
    const jobIndex = parseJobs.findIndex(item => item.groupName === 'Jobs')
    const importIndex = parseJobs.findIndex(item => item.groupName === 'Import')
    jobs.set([
      {
        groupName: 'Jobs',
        jobs: [...parseJobs[jobIndex].jobs]
      },
      {
        groupName: 'Import',
        jobs: value
          ? [
              ...parseJobs[importIndex].jobs.filter(item =>
                item.name.toLocaleLowerCase().includes(value)
              )
            ]
          : [...prevImport]
      }
    ])
  }

  const onCheckJob = ({
    idSource,
    isChecked,
    type
  }: {
    idSource: number
    isChecked: boolean
    type: TypeJob
  }) => {
    jobs.set(prevState => {
      return [
        ...prevState.filter(
          item => item.groupName.toLocaleLowerCase() !== type
        ),
        ...prevState
          .filter(item => item.groupName.toLocaleLowerCase() === type)
          .map(group => {
            return {
              ...group,
              jobs: [
                ...group.jobs.map(item => {
                  return {
                    ...item,
                    isChecked:
                      Number(item.id_source) === Number(idSource)
                        ? isChecked
                        : item.isChecked
                  }
                })
              ]
            }
          })
      ]
    })
  }

  const handleJobs = ({
    checked,
    type
  }: {
    checked: boolean
    type: TypeJob
  }) => {
    jobs.set(prevState => {
      return [
        ...prevState.filter(
          item => item.groupName.toLocaleLowerCase() !== type
        ),
        ...prevState
          .filter(item => item.groupName.toLocaleLowerCase() === type)
          .map(group => {
            return {
              ...group,
              jobs: [
                ...group.jobs.map((item: any) => ({
                  ...item,
                  isChecked: checked
                }))
              ]
            }
          })
      ]
    })
  }

  const onRemoveToImport = () => {
    const parseJobs = JSON.parse(JSON.stringify(jobs.value)) as typeof emptyJobs
    const jobIndex = parseJobs.findIndex(item => item.groupName === 'Jobs')
    const importIndex = parseJobs.findIndex(item => item.groupName === 'Import')

    jobs.set([
      {
        groupName: 'Jobs',
        jobs: [
          ...parseJobs[jobIndex].jobs,
          ...parseJobs[importIndex].jobs.filter(item => item.isChecked)
        ]
      },
      {
        groupName: 'Import',
        jobs: [...parseJobs[importIndex].jobs.filter(item => !item.isChecked)]
      }
    ])
  }

  const onAddToImport = () => {
    const parseJobs = JSON.parse(JSON.stringify(jobs.value)) as typeof emptyJobs
    const jobIndex = parseJobs.findIndex(item => item.groupName === 'Jobs')
    const importIndex = parseJobs.findIndex(item => item.groupName === 'Import')

    jobs.set([
      {
        groupName: 'Jobs',
        jobs: [...parseJobs[jobIndex].jobs.filter(item => !item.isChecked)]
      },
      {
        groupName: 'Import',
        jobs: [
          ...parseJobs[jobIndex].jobs.filter(item => item.isChecked),
          ...parseJobs[importIndex].jobs
        ]
      }
    ])
  }

  const onSelectedAllJobs = (type: TypeJob) => {
    typeJob.set(prevState => ({
      ...prevState,
      [type]: true
    }))
    handleJobs({ checked: true, type })
  }

  const onUnselectedAllJobs = (type: TypeJob) => {
    typeJob.set(prevState => ({
      ...prevState,
      [type]: false
    }))
    handleJobs({ checked: false, type })
  }

  const onSelectJob = (job: Job) => selectedJob.set(job)

  const onCleanSelectedJobs = () => selectedJob.set({} as Job)

  const onDragEnd = (result: DropResult) => {
    const parseJobs = JSON.parse(JSON.stringify(jobs.value)) as typeof emptyJobs
    const importIndex = parseJobs.findIndex(item => item.groupName === 'Import')
    const dragResult = onDragAndDrop({
      result,
      jobs: parseJobs
    })
    jobs.set(dragResult)
    setPrevImport(parseJobs[importIndex].jobs)
  }

  const onClearImport = () => {
    const parseJobs = JSON.parse(JSON.stringify(jobs.value)) as typeof emptyJobs
    const jobIndex = parseJobs.findIndex(item => item.groupName === 'Jobs')
    jobs.set([
      {
        groupName: 'Jobs',
        jobs: [...parseJobs[jobIndex].jobs.filter(item => !item.isChecked)]
      },
      {
        groupName: 'Import',
        jobs: []
      }
    ])
  }

  const jobsList = jobs.value.filter(item => item.groupName === 'Jobs')[0]
    .jobs as Array<JobProps>
  const importList = jobs.value.filter(item => item.groupName === 'Import')[0]
    .jobs as Array<JobProps>

  return {
    type: typeJob.value,
    selectedJob: selectedJob.value,
    jobsList,
    importList,
    onClearImport,
    searchValue,
    onImportSearch,
    onDragEnd,
    onCheckJob,
    onSelectJob,
    onAddToImport,
    onRemoveToImport,
    onSelectedAllJobs,
    onUnselectedAllJobs,
    onCleanSelectedJobs
  }
}
