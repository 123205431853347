import { useEffect, useState } from 'react'
import { GetPaymentMethods } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import { PaymentMethod } from 'types/profile'
import { get } from 'utils'

export default function usePaymentMethod(params?: {
  status?: 'ACTIVE' | 'DEACTIVATED'
  default?: boolean
}) {
  const { onNotify } = useNotification()

  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>(
    [] as Array<PaymentMethod>
  )

  const { isLoading, isFetching, refetch } = useQuery(
    'PAYMENT_METHODS',
    () => GetPaymentMethods(params),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setPaymentMethods(data.entries)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  const getPayments = () => refetch()

  return {
    isLoading: isLoading || isFetching,
    paymentMethods,
    getPayments
  }
}
