import { Stack } from '@mui/material'
import { COLORS } from 'styles/colors'

export default function EmptyList({
  title = 'Drag and drop to start a new import'
}: {
  title?: string
}) {
  return (
    <Stack
      width="98%"
      height="98%"
      justifyContent="center"
      alignItems="center"
      border={`1px dashed ${COLORS.gray2}`}
    >
      {title}
    </Stack>
  )
}
