import { Box, Button, Paper, Typography } from '@mui/material'
import useRefer from 'app/educationDetails/hooks/useRefer'
import ReferBy from 'app/educationDetails/sideDetail/byEmail/referBy'
import ToggleSidebar from 'components/toggleSidebar'

export default function ByEmail() {
  const { openReferBy, onToggleReferBy } = useRefer()
  return (
    <Box component={Paper} marginTop={3}>
      <Typography variant="h3" marginBottom={3}>
        Refer by SMS or Email
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => onToggleReferBy('referBy')}
      >
        Email or SMS
      </Button>
      <ToggleSidebar
        isOpen={openReferBy.referBy}
        headerTitle="Refer by SMS or Email"
        toggleSidebar={() => onToggleReferBy('referBy')}
      >
        <ReferBy />
      </ToggleSidebar>
    </Box>
  )
}
