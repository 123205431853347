import { useState, useRef, Fragment, useEffect } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Stack,
  Typography,
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'
import Spinner from 'components/spinner'
import { COLORS } from 'styles/colors'

type Option = { id: string | number; label: string }

export default function ButtonGroup({
  onChange,
  options,
  bgcolor,
  selectedValue,
  loadingOption,
  isDisabled
}: {
  onChange?: (option: Option) => void
  options: Array<Option>
  bgcolor?: string
  selectedValue?: Option
  loadingOption?: boolean
  isDisabled?: boolean
}) {
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option>(
    selectedValue as Option
  )

  useEffect(() => {
    if (selectedValue) {
      setSelectedOption(selectedValue)
    }
  }, [selectedValue?.id, loadingOption])

  const handleMenuItemClick = (option: Option) => {
    onChange?.(option)
    setSelectedOption(option)
    setOpen(false)
  }

  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  return (
    <Fragment>
      <Box
        paddingRight={1}
        paddingLeft={2}
        ref={anchorRef}
        direction="row"
        height="30px"
        borderRadius={5}
        component={Stack}
        alignItems="center"
        bgcolor={bgcolor || COLORS.gray8}
      >
        <Typography
          textTransform="capitalize"
          variant="body2"
          color={bgcolor ? COLORS.white : COLORS.gray4}
        >
          {selectedOption?.label || 'Choose one'}
        </Typography>
        <Stack
          height="100%"
          component="div"
          marginLeft={2}
          paddingLeft={1}
          paddingRight="4px"
          alignItems="center"
          aria-haspopup="menu"
          onClick={loadingOption || isDisabled ? undefined : handleToggle}
          justifyContent="center"
          borderLeft={`2px solid ${bgcolor ? COLORS.white : COLORS.gray2}`}
          sx={{
            cursor: loadingOption || isDisabled ? 'default' : 'pointer'
          }}
        >
          {loadingOption ? (
            <Spinner size="0.8rem" />
          ) : (
            <ArrowDropDownIcon
              htmlColor={bgcolor ? COLORS.white : COLORS.black1}
            />
          )}
        </Stack>
      </Box>
      <Popper
        transition
        disablePortal
        open={open}
        anchorEl={anchorRef.current}
        sx={{
          zIndex: 1
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(option => (
                    <MenuItem
                      key={option.id}
                      selected={selectedOption?.id === option.id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}
