import { Droppable as DNDDroppable } from '@hello-pangea/dnd'
import { Box } from '@mui/material'

export default function Droppable({
  children,
  droppableId
}: {
  children: React.ReactNode
  droppableId: string
}) {
  return (
    <DNDDroppable droppableId={droppableId}>
      {provided => (
        <Box width="100%" {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </Box>
      )}
    </DNDDroppable>
  )
}
