import { Stack, Typography, styled } from '@mui/material'
import { SigninLogo } from 'app/signin/styles'

export const Logo = styled('figure')`
  margin: 0;
  padding: 1em 2em;
  width: 146px;
  & > img {
    width: 146px;
  }
`

export default function Unauthorized() {
  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Logo>
        <SigninLogo src="/images/logo.png" alt="logo" />
      </Logo>
      <Typography
        variant="h2"
        paddingBottom={4}
        textAlign="center"
        width={{ xs: '100%', md: '70%' }}
      >
        You don't have permission to see this page!
      </Typography>
    </Stack>
  )
}
