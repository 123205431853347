import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Dialog, Stack, Typography, Box, Button } from '@mui/material'
import TextField from 'components/TextField'
import { Formik, Form } from 'formik'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { COLORS } from 'styles/colors'
import useInviteCompanies, {
  initialValues,
  validationSchema
} from './useInviteCompanies'

export default function DialogCompanies({
  handleClose,
  open
}: {
  handleClose: () => void
  open: boolean
}) {
  const [isFocused, seIsFocused] = useState(false)
  const { onHandleSumbit } = useInviteCompanies()
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <Stack paddingY="10px" paddingX="20px" margin="20px">
        <Box display="flex" width="100%" justifyContent="center">
          <Typography variant="h3">Invite Companies</Typography>
        </Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onHandleSumbit}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <Stack
                flexDirection="row"
                marginTop={5}
                marginBottom={3}
                width="100%"
              >
                <TextField
                  name="firstName"
                  label="First name"
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack flexDirection="row" marginY={3} width="100%">
                <TextField
                  name="lastName"
                  label="Last name"
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack flexDirection="row" marginY={3} width="100%">
                <TextField
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack
                flexDirection="row"
                marginTop={3}
                marginBottom={5}
                width="100%"
              >
                <TextField
                  name="company"
                  label="Company"
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack marginBottom={2}>
                <Stack
                  flexDirection="column"
                  paddingBottom={4}
                  position="relative"
                  sx={{
                    '.selected-flag': {
                      paddingLeft: 0
                    },
                    '.special-label': {
                      left: 0,
                      paddingX: 0,
                      color: COLORS.main,
                      fontSize: 14
                    }
                  }}
                >
                  <PhoneInput
                    value=""
                    country="us"
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      border: 'none',
                      borderBottom:
                        isFocused || values.phone
                          ? `2px solid ${COLORS.main}`
                          : `1px solid ${COLORS.gray4}`,
                      borderRadius: 0,
                      paddingLeft: 40,
                      boxShadow: 'none'
                    }}
                    onFocus={() => seIsFocused(true)}
                    onBlur={() => seIsFocused(false)}
                    onChange={(value: string, country: CountryData) => {
                      setFieldValue('phone_area_code', country.dialCode)
                      setFieldValue('phone', value)
                    }}
                  />
                </Stack>
                {touched.phone && errors.phone && (
                  <Typography
                    variant="h6"
                    color={COLORS.red1}
                    position="absolute"
                    margin="60px 0 0 0"
                  >
                    Required
                  </Typography>
                )}
              </Stack>
              <Stack flexDirection="row" width="100%">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleClose}
                  sx={{ marginRight: '3px' }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ marginLeft: '3px' }}
                >
                  Send
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Dialog>
  )
}
