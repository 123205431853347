import { useRef } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useNotification } from 'components/notification/useNotification'
import useFilterStore from '../../stores/useFilterStore'
import { COLORS } from '../../styles/colors'
type DropDownModel = {
  label: string
  actionUID: string
  children: React.ReactNode
  disabled?: boolean
}
const DropDown = ({
  actionUID,
  disabled,
  label = 'Change Job Status',
  children
}: DropDownModel) => {
  const { onNotify } = useNotification()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { onOpenAnchor } = useFilterStore()
  const handleOpenAchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return onNotify({
        message: 'Select one or more items to change the status.',
        severity: 'info'
      })
    }
    onOpenAnchor(event, actionUID)
  }
  return (
    <>
      <Stack
        border="none"
        direction="row"
        ref={anchorRef}
        component="button"
        alignItems="center"
        bgcolor="transparent"
        sx={{ cursor: 'pointer' }}
        onClick={handleOpenAchor}
      >
        <Typography variant="body2" fontWeight={500} color={COLORS.gray2}>
          {label}
        </Typography>
        <ArrowDropDownIcon htmlColor={COLORS.gray2} />
      </Stack>
      {children}
    </>
  )
}
export default DropDown
