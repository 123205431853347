import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { COLORS } from 'styles/colors'
import { formatDate } from 'utils'
import { CARDS } from 'utils/catalogs'
import useUnRegisterCard from '../hooks/useUnRegisterCard'

export default function UnregistrerMethod() {
  const {
    selectedMethod,
    openUnregister,
    isLoading,
    onSubmit,
    onCloseUnRegister
  } = useUnRegisterCard()

  const CardIcon = CARDS[selectedMethod.type || 'default']

  return (
    <Dialog open={openUnregister} maxWidth="lg">
      <DialogTitle
        fontSize={20}
        fontWeight={500}
        marginBottom={4}
        textAlign="center"
        color={COLORS.gray1}
      >
        Unregister method
      </DialogTitle>
      <DialogContent sx={{ xs: { width: '100%' }, md: 500 }}>
        <Stack
          paddingX={4}
          paddingY={2}
          height={120}
          elevation={1}
          borderRadius={4}
          component={Paper}
          justifyContent="space-around"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" marginBottom={1} alignItems="center">
              <Typography
                variant="h5"
                fontSize={18}
                marginRight={2}
                fontWeight={selectedMethod.default ? 500 : 300}
                color={selectedMethod.default ? COLORS.green1 : COLORS.gray2}
              >
                {selectedMethod.default ? 'Default' : selectedMethod.status}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <Typography variant="h5" color={COLORS.gray2} marginBottom={1}>
              {selectedMethod.label}
            </Typography>
            <Typography variant="body2" color={COLORS.gray2}>
              ************{selectedMethod.last_four_digits}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color={COLORS.gray2}>
              {formatDate(selectedMethod.expiration_date || '', 'MM / yy')}
            </Typography>
            <CardIcon />
          </Stack>
        </Stack>
      </DialogContent>
      <Typography
        paddingX={4}
        paddingTop={2}
        variant="body2"
        marginBottom={2}
        color={COLORS.red1}
      >
        This card will be removed from your payment methods, are you sure?
      </Typography>
      <DialogActions sx={{ paddingY: 3, paddingX: 4 }}>
        <Button
          children="Cancel"
          variant="text"
          size="large"
          onClick={onCloseUnRegister}
          sx={{ fontSize: 16, width: '100%', height: 42 }}
        />
        <LoadingButton
          size="large"
          children="Confirm"
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => onSubmit(selectedMethod.id)}
          sx={{ fontSize: 16, width: '100%', height: 42 }}
        />
      </DialogActions>
    </Dialog>
  )
}
