import WorkIcon from '@mui/icons-material/Work'
import { Typography, Stack } from '@mui/material'
import SearchBar from 'components/SearchBar'

export default function BoardHeader() {
  return (
    <Stack
      marginTop={3}
      justifyContent="flex-start"
      direction={{ xs: 'column', sm: 'row' }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        marginRight={{ xs: 2, md: 4 }}
        marginBottom={{ xs: 2, md: 0 }}
        justifyContent="flex-start"
      >
        <WorkIcon />
        <Typography marginLeft={2} variant="h2">
          Board configuration
        </Typography>
      </Stack>
      <SearchBar boxProps={{ width: { xs: '95%', sm: '250px' } }} />
    </Stack>
  )
}
