import { useState } from 'react'
import { GetReferralByJobId } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { Referrals } from 'types/referrals'
import { get, getPagination } from 'utils'

export default function useDetailReferral() {
  const { onNotify } = useNotification()
  const { params } = useNavigation()
  const [referrals, setReferrals] = useState<Referrals>({} as Referrals)

  const { isLoading } = useQuery(
    'REFERRALS_BY_JOB_ID',
    () => GetReferralByJobId(Number(params.id)),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setReferrals(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const pagination = getPagination(referrals)

  return {
    pagination,
    isLoading,
    referrals: referrals?.entries || []
  }
}
