import { useState } from 'react'
import { GetShowCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { CompanyDetail } from 'types/companies'
import { get } from 'utils'

export default function useCompanyDetail(companyId?: number) {
  const { searchParams } = useNavigation()
  const { onNotify } = useNotification()
  const [company, setCompany] = useState<CompanyDetail>({} as CompanyDetail)

  const { isLoading } = useQuery(
    'COMPANY_BY_ID',
    () => GetShowCompany(companyId || searchParams.companyId),
    {
      enabled: Boolean(companyId || searchParams.companyId),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCompany(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return {
    company,
    isLoading: isLoading
  }
}
