import { Stack } from '@mui/material'

import useAuth from 'hooks/useAuth'
import { CardsGraph } from './Cards/CardsGraph'
import { PlatformOverview } from './Cards/PlatformOverview'
import { BarJobs } from './Graphs/BarJobs'
import { FunnelReferrals } from './Graphs/FunnelReferrals'
import { Lineal } from './Graphs/Lineal'
import { PiePayments } from './Graphs/PiePayments'

export const DashboardBody = () => {
  const { userRole } = useAuth()
  return (
    <>
      {userRole !== 'employee' ? (
        <Stack>
          <Stack
            gridRow={2}
            flexDirection={{ xs: 'column', lg: 'row', md: 'column' }}
          >
            <PlatformOverview />
            <CardsGraph title="Referrals">
              <Lineal />
            </CardsGraph>
          </Stack>

          <Stack gridRow={2} flexDirection={{ xs: 'column', lg: 'row' }}>
            <CardsGraph title="Jobs">
              <BarJobs />
            </CardsGraph>
            <CardsGraph title="Payments">
              <PiePayments />
            </CardsGraph>
          </Stack>
          <Stack gridRow={2} flexDirection={{ xs: 'column', lg: 'row' }}>
            <CardsGraph title="Referrals funnel">
              <FunnelReferrals />
            </CardsGraph>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Stack
            gridRow={2}
            flexDirection={{ xs: 'column', lg: 'row', md: 'column' }}
          >
            <PlatformOverview />
            <CardsGraph title="Referrals">
              <Lineal />
            </CardsGraph>
          </Stack>
          <Stack gridRow={2} flexDirection={{ xs: 'column', lg: 'row' }}>
            <CardsGraph title="Referrals funnel">
              <FunnelReferrals />
            </CardsGraph>
          </Stack>
        </Stack>
      )}
    </>
  )
}
