import { useState } from 'react'
import useNavigation from 'hooks/useNavigation'
import * as yup from 'yup'

export const initialValues = {
  email: ''
}

export const validationSchema = yup.object({
  email: yup.string().email('Email invalid').required('Email required')
})

export default function usePotencialCustomer() {
  const { onNavigate } = useNavigation()

  const [isLoading, setIsLoading] = useState(false)

  const onRedirect = () => onNavigate('/message')

  const onSubmit = (values: typeof initialValues) => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      console.log(values)
    }, 3000)
  }

  return {
    isLoading,
    onSubmit,
    onRedirect
  }
}
