import DiversityIcon from '@mui/icons-material/Diversity3'
import WorkIcon from '@mui/icons-material/Work'
import { Button, Stack, Typography } from '@mui/material'
import SearchBar from 'components/SearchBar'
import useNavigation from 'hooks/useNavigation'

export default function EmployeeReferralHeader() {
  const { onNavigate } = useNavigation()

  const startReferring = () => {
    onNavigate('/jobs/jobboard')
  }

  return (
    <Stack flexDirection="row" alignItems="center" marginTop={3} paddingX={2}>
      <Stack
        flexDirection="row"
        alignItems="center"
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <DiversityIcon />
        <Typography marginLeft={2} marginRight={4} variant="h2">
          Referrals
        </Typography>
      </Stack>
      <Stack
        gap={3}
        alignItems="flex-end"
        width={{ xs: '100%' }}
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <SearchBar boxProps={{ width: { xs: '95%', md: '250px' } }} />
        <Button
          onClick={() => startReferring()}
          fullWidth
          variant="contained"
          startIcon={<WorkIcon />}
          children="Start referring"
          sx={{ maxWidth: '180px' }}
        />
      </Stack>
    </Stack>
  )
}
