import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from 'styles/colors'
import { BonusPaymentDetails } from 'types/bonusPayments'
import { StatusPaymentTemplateBulkActions } from 'types/bonusTemplate'

type ModalInviteProps = {
  loading?: boolean
  open: boolean
  title: string
  subtitle?: string
  content?: string
  values: BonusPaymentDetails
  close: () => void
  onApply: (data: StatusPaymentTemplateBulkActions) => void
}

export const DialogEditChanges = ({
  open,
  title,
  subtitle,
  content,
  values,
  onApply,
  close
}: ModalInviteProps) => {
  const handleSubmmit = () => {
    const data: StatusPaymentTemplateBulkActions = {
      ids: [values.entries.id],
      action: values.entries.status
    }
    onApply(data)
  }

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle
          variant="h3"
          align="center"
          marginBottom={5}
          color={COLORS.black}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            marginBottom={2}
            align={'justify'}
            variant="h5"
            color={COLORS.black}
          >
            {subtitle}
          </DialogContentText>
          <DialogContentText
            align={'justify'}
            variant="h5"
            color={COLORS.black}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <Divider
          sx={{
            backgroundColor: COLORS.gray10,
            marginBottom: 2
          }}
        />
        <DialogActions
          sx={{ display: 'flex', gap: 2, justifyContent: 'center', margin: 2 }}
        >
          <Button onClick={close} sx={{ fontSize: 16, width: 237, height: 42 }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmmit}
            variant="contained"
            sx={{ fontSize: 16, width: 237, height: 42 }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
