import { useEffect, useState } from 'react'
import { GetCompaniesByFilterJobs, GetJobsTypes } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { get } from 'utils'
import { pickBy } from 'utils'
import { REGEX_NUMBERS } from 'utils/catalogs'
import * as yup from 'yup'

export const schema = yup.object().shape({
  bonusMin: yup.string().matches(REGEX_NUMBERS, 'Only numbers'),
  bonusMax: yup
    .string()
    .matches(REGEX_NUMBERS, 'Only numbers')
    .when('bonusMin', {
      is: (val: string) => val?.length > 0,
      then: schema => schema.required('bonus max is required')
    })
})

export const values = {
  type: '',
  bonusMin: '',
  bonusMax: '',
  company: ''
}
export default function useJobBoardFilter() {
  const { onNotify } = useNotification()
  const { onNavigate, pathname, searchParams } = useNavigation()
  const [initialValues, setInitialValues] = useState(values)

  useEffect(() => {
    if (searchParams) {
      const [bonusMin, bonusMax] = searchParams.bonus
        ? searchParams.bonus.split('-')
        : ''
      const validParams = {
        bonusMin,
        bonusMax,
        company: searchParams.company,
        type: searchParams.type
      }
      setInitialValues(validParams)
    }
  }, [])

  const { isLoading, data } = useQuery(
    'ALL_COMPANIES',
    () => GetCompaniesByFilterJobs({ status: ['published'] }),
    {
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const { isLoading: loadingJobs, data: jobsTypes } = useQuery(
    'JOB_TYPES',
    () => GetJobsTypes(),
    {
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onChangeFilter = (values: typeof initialValues) => {
    const params = {
      bonus: values.bonusMin ? `${values.bonusMin}-${values.bonusMax}` : '',
      company: values.company,
      type: values.type
    }
    setInitialValues(values)
    onNavigate(pathname, {
      search: { ...searchParams, ...pickBy(params) }
    })
  }

  const onClearFilters = () => {
    setInitialValues(values)
    onNavigate(pathname)
  }

  const companyOptions =
    data?.map(item => ({
      label: item,
      value: item
    })) || ([] as Array<{ label: string; value: string }>)

  const jobsTypesOptions =
    jobsTypes?.map(item => ({
      label: item,
      value: item
    })) || ([] as Array<{ label: string; value: string }>)

  return {
    onClearFilters,
    onChangeFilter,
    initialValues,
    isLoading,
    loadingJobs,
    companyOptions,
    jobsTypesOptions,
    companies: data || ([] as Array<string>)
  }
}
