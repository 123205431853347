import { useEffect } from 'react'
import { Box, Typography, Paper, Stack } from '@mui/material'
import { COLORS } from 'styles/colors'
import useReferralDetail from '../hooks/useReferralDetail'

export default function index() {
  const { referral, getReferral, isLoading } = useReferralDetail()
  useEffect(() => {
    getReferral()
  }, [])

  if (isLoading) {
    return <div>loading</div>
  }

  return (
    <Stack height="100%" margin={2}>
      <Box component={Paper} elevation={4} paddingY={2} marginBottom={4}>
        <Typography variant="h2" marginBottom={2}>
          General Information
        </Typography>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack width="20%">
            <Typography variant="body2" color={COLORS.gray2} marginBottom={2}>
              Name
            </Typography>
            <Typography variant="body2" color={COLORS.gray2} marginBottom={2}>
              Last Name
            </Typography>
            <Typography variant="body2" color={COLORS.gray2} marginBottom={2}>
              Country
            </Typography>
            <Typography variant="body2" color={COLORS.gray2} marginBottom={2}>
              Email
            </Typography>
            <Typography variant="body2" color={COLORS.gray2} marginBottom={2}>
              Phone
            </Typography>
          </Stack>
          <Stack width="80%">
            <Typography variant="body2" color={COLORS.black} marginBottom={2}>
              {referral.first_name || '-'}
            </Typography>
            <Typography variant="body2" color={COLORS.black} marginBottom={2}>
              {referral.last_name || '-'}
            </Typography>
            <Typography variant="body2" color={COLORS.black} marginBottom={2}>
              {referral.country || '-'}
            </Typography>
            <Typography variant="body2" color={COLORS.black} marginBottom={2}>
              {referral.email || '-'}
            </Typography>
            <Typography variant="body2" color={COLORS.black} marginBottom={2}>
              {`${referral.phone_area_code} ${referral.phone}`}
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Box component={Paper} elevation={4} paddingY={2}>
        <Typography variant="h2" marginBottom={2}>
          Job(s) applied to
        </Typography>
        <Stack flexDirection="column">
          {referral.applied_to?.map((item, index) => {
            return (
              <Box key={index} width="100%">
                <Stack direction="row" width="100%" alignItems="center">
                  <Typography
                    variant="body2"
                    color={COLORS.black}
                    marginBottom={2}
                    sx={{ width: '20%' }}
                  >
                    Job name
                  </Typography>
                  <Typography
                    variant="body2"
                    color={COLORS.gray2}
                    marginBottom={2}
                    sx={{ width: '80%' }}
                  >
                    {item.job_name}
                  </Typography>
                </Stack>
                <Stack direction="row" width="100%" alignItems="center">
                  <Typography
                    variant="body2"
                    color={COLORS.black}
                    marginBottom={2}
                    sx={{ width: '20%' }}
                  >
                    Referred by
                  </Typography>
                  <Typography
                    variant="body2"
                    color={COLORS.gray2}
                    marginBottom={2}
                    sx={{ width: '80%' }}
                  >
                    {item.referred?.first_name}
                  </Typography>
                </Stack>
                <Stack direction="row" width="100%" alignItems="center">
                  <Typography
                    variant="body2"
                    color={COLORS.black}
                    marginBottom={2}
                    sx={{ width: '20%' }}
                  >
                    Resume
                  </Typography>
                  {item.cv ? (
                    <Typography
                      component="a"
                      variant="body2"
                      color={COLORS.main}
                      target="_blank"
                      href={item.cv || ''}
                      marginBottom={2}
                      sx={{
                        textOverflow: 'ellipsis',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      Resume
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color={COLORS.black}
                      marginBottom={2}
                    >
                      Not upload
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" width="100%" alignItems="center">
                  <Typography
                    variant="body2"
                    color={COLORS.black}
                    marginBottom={2}
                    sx={{ width: '20%' }}
                  >
                    Job status
                  </Typography>
                  <Stack
                    paddingY={1}
                    paddingX={3}
                    fontSize={14}
                    borderRadius={6}
                    textAlign="center"
                    marginBottom={1.3}
                    color={COLORS.white}
                    textTransform="capitalize"
                    bgcolor={referralsStatusColor[item.status as 'hired']}
                  >
                    {item?.status || ''}
                  </Stack>
                </Stack>
              </Box>
            )
          })}
        </Stack>
      </Box>
    </Stack>
  )
}

const referralsStatusColor = {
  hired: COLORS.green2,
  in_progress: COLORS.honey3,
  rejected: COLORS.red1,
  on_hold: COLORS.honey3,
  active: COLORS.main
}
