import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { GetCountsByEmployeeId } from 'api/index'
import { CountsByEmployeeBoard } from 'app/employees/EmployeeDetail/Types/ReferralByEmployee'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get } from 'utils'

const initialState = hookstate({
  edit: false
})

export default function useCountsByUser() {
  const { user } = useAuth()
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()

  const [countsInformation, setCountsInformation] =
    useState<CountsByEmployeeBoard>({} as CountsByEmployeeBoard)

  const { isLoading } = useQuery(
    'COUNTS_BY_EMPLOYEE_ID',
    () => GetCountsByEmployeeId(user.id),
    {
      refetchOnMount: 'always',
      onSuccess: data => {
        setCountsInformation(data.data)
      },
      enabled: Boolean(user.company_id),
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onHandleOpenEdit = () => {
    edit.set(true)
  }

  const onHandleCloseEdit = () => {
    edit.set(false)
  }

  return {
    isLoading: isLoading,
    countsInformation,
    open,
    edit: edit.value,
    onHandleOpenEdit,
    onHandleCloseEdit
  }
}
