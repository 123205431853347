import { Typography, Box, Stack } from '@mui/material'
import { COLORS } from '../../../styles/colors'
export const GeneralInformation = () => {
  return (
    <Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Job
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px" color={COLORS.main}>
          Bit.me/...
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="8px"
      >
        <Typography
          variant="body2"
          width="40%"
          paddingY="8px"
          color={COLORS.gray2}
        >
          Company
        </Typography>
        <Typography variant="h5" width="60%" paddingY="8px">
          ModisMacneal
        </Typography>
      </Stack>
    </Box>
  )
}
