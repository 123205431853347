import { hookstate, useHookstate } from '@hookstate/core'
import { UnRegisterCard } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { PaymentMethod } from 'types/profile'
import { get } from 'utils'

const initialState = hookstate({
  openUnregister: false,
  selectedMethod: {} as PaymentMethod
})

export default function useUnRegisterCard() {
  const { onNotify } = useNotification()
  const { openUnregister, selectedMethod } = useHookstate(initialState)

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { cardId: number }) => UnRegisterCard(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Unregistered method successfully'
        })
        query.invalidateQueries('PAYMENT_METHODS')
        onCloseUnRegister()
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail.errors[0].message'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onSelectedMethod = (method: PaymentMethod) => {
    selectedMethod.set(method)
    onOpenUnRegister()
  }

  const onOpenUnRegister = () => openUnregister.set(true)
  const onCloseUnRegister = () => openUnregister.set(false)

  const onSubmit = (cardId: number) => mutate({ cardId })

  return {
    openUnregister: openUnregister.get(),
    selectedMethod: selectedMethod.get(),
    isLoading,
    onSubmit,
    onSelectedMethod,
    onOpenUnRegister,
    onCloseUnRegister
  }
}
