import { Box } from '@mui/material'

import useWithdraw from '../hooks/useWithdraw'
import StepperWithdraw from './SteperWithdraw'
import WithdrawStepMessage from './WithdrawStepMessage'
import WithdrawStepOne from './WithdrawStepOne'
import WithdrawStepTwo from './WithdrawStepTwo'

const steps = ['One', 'Two', 'Tree']

export const Withdraw = () => {
  const { isSent, message, content } = useWithdraw()
  return (
    <Box height="calc(100% - 96px)">
      {isSent ? (
        <WithdrawStepMessage title={message} subtitle={content} />
      ) : (
        <StepperWithdraw steps={steps}>
          <WithdrawStepOne />
          <WithdrawStepTwo />
        </StepperWithdraw>
      )}
    </Box>
  )
}
