import React, { useEffect, useState } from 'react'
import {
  Snackbar,
  Alert as MUIAlert,
  SnackbarProps,
  AlertProps
} from '@mui/material'

export type Severity = AlertProps['severity']

interface NotificationProps extends SnackbarProps {
  severity: AlertProps['severity']
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MUIAlert elevation={6} ref={ref} {...props} />
})

export default function Notification({ ...props }: NotificationProps) {
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    setOpen(props.open as boolean)
  }, [props.open])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
      open={open}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}
