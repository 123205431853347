import styled from 'styled-components'

export const TopbarContainer = styled.div<{ isLoginAs?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  padding: 0 1em;
  background: #fff;
  max-width: 1536px;
  border-bottom: 1px solid #efeff4;
`

export const TopbarUserName = styled.div<{ isLoginAs?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.isLoginAs ? 'space-between' : 'flex-end')};
`
