import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useNavigation from 'hooks/useNavigation'
import { PAGE, SIZE } from 'utils/catalogs'

const initialState = hookstate({
  filters: {
    company: [],
    companyOwner: [],
    date: [],
    status: []
  }
})

export default function useFilterCompanies() {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const { filters } = useHookstate(initialState)

  useEffect(() => {
    Filters()
  }, [JSON.stringify(filters.value), searchParams.page])

  const Filters = () => {
    onNavigate(pathname, {
      search: {
        ...searchParams,
        page: searchParams.page || PAGE,
        size: searchParams.size || SIZE
      }
    })
  }

  const paginationParams = {
    page: searchParams.page || PAGE,
    size: searchParams.size || SIZE
  }

  return { filters: { ...filters.value, ...paginationParams } }
}
