import type { Status, Color } from 'types/table'

export const STATUS_COLOR: { [key in Status]: Color } | any = {
  new: 'primary',
  published: 'success',
  on_hold: 'bonus',
  closed: 'error',

  inactive: 'empty',
  empty: 'other',
  default: 'other',
  'not found': 'primary',
  hired: 'success',
  on_hold_bullhord: 'success',
  closed_ats: 'error',
  closed_beegopp: 'error',
  in_progress: 'inProgress',

  pending: 'bonus',
  active: 'primary',
  rejected: 'error',
  approved: 'approved',
  review: 'inProgress',
  deleted: 'canceled',
  paid: 'paid',
  not_set: 'notSet',
  canceled: 'error',
  success: 'success'
}
