import { useState } from 'react'
import { UpdateCompanyImage } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { ImageUpdate } from 'types/profile'
import { get, removeEmptyKeys, toBase64 } from 'utils'

export default function useCompanyJobsite(callback: () => void) {
  const { user } = useAuth()
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const [image, setImage] = useState({
    imageLogo: '',
    imageHeader: ''
  })

  const { isLoading, mutate } = useMutation(
    (data: { company: ImageUpdate }) =>
      UpdateCompanyImage(user.company_id, data),
    {
      onSuccess: () => {
        onNotify({
          message: 'Images updated successfully',
          severity: 'success'
        })
        query.invalidateQueries('COMPANY_BY_ID')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      },
      onSettled: () => {
        callback()
      }
    }
  )

  const onHandleDrop = async ({
    file,
    imageType
  }: {
    file: File[]
    imageType: 'imageLogo' | 'imageHeader'
  }) => {
    const imageResult = await toBase64(file[0])
    setImage(prevState => ({
      ...prevState,
      [imageType]: imageResult
    }))
  }

  const onHandleRemoveImage = (imageType: 'imageLogo' | 'imageHeader') => {
    setImage(prevState => ({
      ...prevState,
      [imageType]: ''
    }))
  }

  const onSubmit = (values: { description: string }) => {
    const dataTosend = removeEmptyKeys({
      logo: image.imageLogo,
      header: image.imageHeader,
      description: values.description
    }) as ImageUpdate
    mutate({ company: dataTosend })
  }

  return {
    onHandleRemoveImage,
    onHandleDrop,
    onSubmit,
    isLoading,
    image
  }
}
