import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import ColumnHeader from 'components/table/ColumnHeader'
import TableChip from 'components/table/TableChip'
import { formatEmployeeRoles } from 'utils'
import { COLORS } from '../../../styles/colors'
import { EmployeeBoard } from '../Types/Employees'
import Role from '../filters/role'
import EmployeeStatus from '../filters/status'

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80, headerAlign: 'left' },
  {
    field: 'email',
    headerName: 'Email',
    headerAlign: 'left',
    width: 300,
    editable: true,
    sortable: false,
    renderCell: (props: GridCellParams) => (
      <>
        <Stack>
          <Typography variant="body2" color={COLORS.gray1} fontWeight="500">
            {props.row.first_name} {props.row.last_name}
          </Typography>
          <Typography
            variant="body2"
            color={COLORS.gray1}
            fontWeight="500"
            style={{ textTransform: 'lowercase' }}
          >
            {props.row.email}
          </Typography>
        </Stack>
      </>
    ),
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'role',
    headerName: 'Role',
    headerAlign: 'left',
    width: 160,
    editable: true,
    sortable: false,
    renderCell: props => (
      <div>{formatEmployeeRoles(props.row?.role || 'Employee')}</div>
    ),
    renderHeader: props => (
      <ColumnHeader {...props}>
        <Role />
      </ColumnHeader>
    )
  },
  {
    field: 'staff',
    headerName: 'Staff',
    headerAlign: 'left',
    width: 140,
    editable: true,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'company',
    headerName: 'Company',
    headerAlign: 'left',
    width: 280,
    editable: true,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'referred_jobs',
    headerName: 'Refered Jobs',
    headerAlign: 'left',
    width: 170,
    editable: true,
    sortable: false,
    renderHeader: props => <ColumnHeader {...props} />
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'left',
    width: 162,
    editable: true,
    sortable: false,
    renderCell: props => (
      <TableChip
        {...props}
        chipProps={{
          variant: 'outlined',
          sx: { background: 'transparent' }
        }}
      />
    ),
    renderHeader: props => (
      <ColumnHeader {...props}>
        <EmployeeStatus />
      </ColumnHeader>
    )
  }
]
export const getRows = (data: EmployeeBoard['entries']) => {
  const filteredEployees = data.map(item => {
    return {
      ...item
      //applicants: item. || 0,
      //bonusStatus: 'Active' // what's that?
    }
  })
  return filteredEployees || []
}

export const ROLE_ACTIONS = [
  { id: 1, label: 'Rewards manager', name: 'rewards_manager' },
  { id: 2, label: 'Market manager', name: 'market_manager' },
  { id: 3, label: 'Employee', name: 'employee' }
]

export const ROLES = [
  { id: 'rewards_manager', label: 'Rewards manager' },
  { id: 'market_manager', label: 'Market manager' },
  { id: 'employee', label: 'Employee' },
  { id: 'company_owner', label: 'Company owner' }
]

export const EMPLOYEE_STATUS = [
  { id: 'new', label: 'New' },
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' },
  { id: 'pending', label: 'Pending' }
]

export const STATUS_ACTIONS = [
  { id: 'active', label: 'Active' },
  { id: 'inactive', label: 'Inactive' }
]
