import { Stack, Typography, Divider, Button, Box } from '@mui/material'
import ImportSearch from 'app/jobs/components/importSearch'
import { Draggable, Droppable } from 'components/drag'
import { useNotification } from 'components/notification/useNotification'
import Paper from 'components/paper'
import { JobsImportSkeleton } from 'components/skeleton'
import { COLORS } from 'styles/colors'
import { hexToRGB, StylesScrollBar } from 'styles/utils'
import type { JobProps } from 'types/jobs'
import Job from '../Job'
import EmptyList from '../emptyList'
import useSelected from '../hooks/useSelected'

export default function DragJobs({
  jobsList = [],
  isLoading,
  isDisabled,
  hasImportJobs,
  fetchNextPage
}: {
  isLoading: boolean
  hasImportJobs: boolean
  jobsList: Array<JobProps>
  isDisabled: boolean
  fetchNextPage: () => void
}) {
  const { onNotify } = useNotification()
  const {
    onSelectedAllJobs,
    onUnselectedAllJobs,
    onAddToImport,
    selectedJob,
    type
  } = useSelected()

  const handleJobs = (typeJob: 'jobs') => {
    if (type[typeJob]) {
      return onUnselectedAllJobs(typeJob)
    }
    onSelectedAllJobs(typeJob)
  }

  const handleMoreJobs = () => {
    if (hasImportJobs) {
      return onNotify({
        message: 'You have jobs regardless yet',
        severity: 'info',
        options: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        }
      })
    }
    fetchNextPage()
  }

  const hasJob = selectedJob.id_source

  const selected =
    jobsList.length > 0 ? jobsList.filter(item => item.isChecked) : []

  return (
    <Droppable droppableId="Jobs">
      <Box
        elevation={3}
        component={Paper}
        position="relative"
        bgcolor={hexToRGB(COLORS.gray8, '0.2')}
      >
        <Typography
          padding={2}
          variant="h3"
          bgcolor={COLORS.white}
          fontSize="20px"
        >
          Jobs ({jobsList.length})
        </Typography>
        <Divider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor={COLORS.white}
          paddingX={1}
        >
          <Stack flexDirection="row" alignItems="center">
            <Button variant="text" onClick={() => handleJobs('jobs')}>
              {type.jobs ? 'Remove all' : 'Select all'}
            </Button>
            <Typography padding={2} variant="h5" color={COLORS.gray2}>
              {selected.length || 0} Selected Jobs
            </Typography>
          </Stack>
          <Button
            disabled={selected.length === 0}
            variant="outlined"
            onClick={onAddToImport}
          >
            Add to import list
          </Button>
        </Stack>
        <Divider />
        <ImportSearch />
        {isLoading ? (
          <JobsImportSkeleton />
        ) : (
          <Box
            paddingX={1}
            paddingBottom={1}
            height={`calc(100vh - ${hasJob ? '26em' : '23em'})`}
            sx={{
              overflow: 'auto',
              ...StylesScrollBar
            }}
          >
            {jobsList.length > 0 ? (
              <>
                {jobsList.map((item, index) => {
                  const checkedBgcolor = item.isChecked
                    ? COLORS.honey2
                    : COLORS.white
                  return (
                    <Draggable
                      key={item.id_source}
                      id={String(item.id_source)}
                      index={index}
                      paperProps={{
                        sx: {
                          bgcolor: checkedBgcolor
                        }
                      }}
                    >
                      <Job {...item} importType="jobs" />
                    </Draggable>
                  )
                })}
              </>
            ) : (
              <EmptyList title="No jobs were found to display." />
            )}
          </Box>
        )}
        {isDisabled ? (
          <Typography
            left="40%"
            bottom={12}
            variant="body2"
            position="absolute"
          >
            No more results.
          </Typography>
        ) : (
          <Box
            left="40%"
            bottom={12}
            component="div"
            fontSize="14px"
            position="absolute"
            color={isLoading ? COLORS.gray3 : COLORS.main}
            onClick={handleMoreJobs}
            sx={{
              cursor: 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
              ':hover': { textDecoration: 'underline' }
            }}
          >
            Load more
          </Box>
        )}
      </Box>
    </Droppable>
  )
}
