import { Box, Button, Stack, Typography } from '@mui/material'
import TextField from 'components/TextField'
import { Form, Formik } from 'formik'
import { JobDetail } from 'types/jobs'
import useBuyBonus, { validationSchema } from '../useBuyBonus'

export default function BuyForm({ details }: { details: JobDetail }) {
  const { positions, onNextSubmit, onChangePositions } = useBuyBonus(details)

  return (
    <Box padding="16px" minHeight="calc(100vh - 245px)">
      <Typography variant="body2" marginBottom={4}>
        ATS positions: {details.open_positions}
      </Typography>
      <Formik
        initialValues={{ positions: positions || 0 }}
        validationSchema={validationSchema}
        onSubmit={onNextSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Stack
              height="68vh"
              component={Form}
              justifyContent="space-between"
            >
              <TextField
                variant="standard"
                label="Positions"
                type="number"
                value={values.positions}
                onChange={event => {
                  setFieldValue('positions', event.target.value)
                  onChangePositions(Number(event.target.value))
                }}
                name="positions"
                sx={{
                  width: '100%'
                }}
              />
              <Button fullWidth variant="contained" type="submit">
                Continue
              </Button>
            </Stack>
          )
        }}
      </Formik>
    </Box>
  )
}
