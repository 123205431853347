import { ResetPassword } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'

export default function useDialogPassword(callback: () => void) {
  const { onNotify } = useNotification()
  const { mutate } = useMutation(
    (data: { email: string }) => ResetPassword({ email: data.email }),
    {
      onSuccess: () => {
        callback()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onSumbit = (email: string) => {
    mutate({ email: email.toLocaleLowerCase() })
  }

  return { onSumbit }
}
