import { LoadingButton } from '@mui/lab'
import {
  Box,
  Stack,
  Typography,
  TextField as MUITextField,
  Button,
  Tooltip
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TextField from 'components/TextField'
import DragAndDroFile from 'components/dragAndDropFile'
import { Field, Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { UserInformation } from 'types/profile'
import { formatDate } from 'utils'
import * as yup from 'yup'
import useShowUser from '../useShowUser'
import useUpdateUser from './useUpdateUser'

const validationSchema = yup.object({
  first_name: yup.string().required('Last name required'),
  last_name: yup.string().required('Last name required'),
  birthday: yup.string().required('Birthday required')
})

export default function EditInformation({ user }: { user: UserInformation }) {
  const initialValues = {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    birthday: user.birthday || '',
    email: user.email || '',
    phone: user.phone || ''
  }

  const { onHandleCloseEdit } = useShowUser()
  const { isLoading, image, onHandleDrop, onHandleRemoveImage, onSumbit } =
    useUpdateUser(user.id)

  return (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSumbit}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Stack
              paddingBottom={2}
              justifyContent="space-between"
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              borderBottom={`1px solid ${COLORS.gray8}`}
            >
              <Typography
                fontWeight="500"
                fontSize={{ xs: '18px', sm: '20px' }}
                paddingLeft={{ xs: 0, sm: '12px' }}
              >
                General Information
              </Typography>
              <Stack
                direction="row"
                alignItems="baseline"
                marginTop={{ xs: 2, sm: 0 }}
                width={{ xs: '96%', sm: 'auto' }}
              >
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  onClick={onHandleCloseEdit}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  variant="contained"
                  sx={{ marginLeft: 2 }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              paddingRight={{ xs: '0', sm: 6 }}
              marginBottom="18px"
              marginTop={2}
            >
              <Typography
                variant="h5"
                paddingY="8px"
                width={{ xs: '100%', lg: '30%' }}
              >
                Avatar
              </Typography>
              <Stack
                width={{ xs: '100%', lg: '70%' }}
                flexDirection="row"
                alignItems="center"
              >
                {image ? (
                  <Box width="100%">
                    <Tooltip title="change image" placement="top">
                      <Box
                        width="100%"
                        component="div"
                        onClick={() => onHandleRemoveImage()}
                      >
                        <img
                          alt="Logo"
                          src={image}
                          style={{
                            maxHeight: '80px',
                            maxWidth: '80px',
                            borderRadius: '50%'
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box width="100%">
                    <Field
                      name="imageLogo"
                      as={DragAndDroFile}
                      accept={{ 'image/*': [] }}
                      onDrop={(file: any) => {
                        onHandleDrop({ file })
                      }}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              paddingRight={{ xs: '0', sm: 6 }}
              marginBottom="18px"
              marginTop={2}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                First name
              </Typography>
              <TextField
                sx={{
                  width: { xs: '100%', lg: '70%' }
                }}
                variant="standard"
                onChange={handleChange('first_name')}
                name="first_name"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              paddingRight={{ xs: '0', sm: 6 }}
              marginBottom="18px"
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Last name
              </Typography>
              <TextField
                sx={{
                  width: { xs: '100%', lg: '70%' }
                }}
                variant="standard"
                onChange={handleChange('last_name')}
                name="last_name"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              marginBottom="22px"
              paddingRight={{ xs: '0', sm: 6 }}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Birthday
              </Typography>
              <Stack
                spacing={3}
                sx={{
                  width: { xs: '100%', lg: '70%' }
                }}
              >
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={values.birthday}
                  onChange={newValue => setFieldValue('birthday', newValue)}
                  renderInput={params => (
                    <MUITextField variant="standard" {...params} />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              paddingRight={{ xs: '0', sm: 6 }}
              marginBottom="18px"
              marginTop={2}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Email
              </Typography>
              <TextField
                sx={{
                  width: { xs: '100%', lg: '70%' }
                }}
                variant="standard"
                onChange={handleChange('email')}
                name="email"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              marginBottom="22px"
              paddingRight={{ xs: '0', sm: 6 }}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Type of user
              </Typography>
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '70%' }}
                color={COLORS.gray2}
              >
                {user.type}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              paddingRight={{ xs: '0', sm: 6 }}
              marginBottom="18px"
              marginTop={2}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Phone number
              </Typography>
              <TextField
                sx={{
                  width: { xs: '100%', lg: '70%' }
                }}
                variant="standard"
                onChange={handleChange('phone')}
                name="phone"
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="start"
              marginBottom="22px"
              paddingRight={{ xs: '0', sm: 6 }}
            >
              <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                Last login
              </Typography>
              <Typography
                variant="h5"
                width={{ xs: '100%', lg: '70%' }}
                color={COLORS.gray2}
              >
                {formatDate(user.last_login, 'MMMM dd, yyyy HH:mm:ss')}
              </Typography>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
