import useAddBonusTemplate from 'app/bonus/templates/hooks/useAddBonusTemplate'
import useBonusTemplateDetails from 'app/bonus/templates/hooks/useBonusTemplateDetails'
import { FieldArrayRenderProps } from 'formik'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import {
  BonusConfig,
  BonusTemplateDetails,
  NewBonusTemplate
} from 'types/bonusTemplate'
import { removeEmptyKeys } from 'utils'
import * as yup from 'yup'

export const validationSchema = yup.object({
  entries: yup.object({
    description: yup.string().required('Description is required'),
    bonus_config: yup.array().of(
      yup.object().shape({
        amount: yup
          .number()
          .max(2000, 'Max amount is 2000')
          .typeError('Amount must be a number')
          .required('Amount is required'),
        days: yup.number().min(0).required('Days is required')
      })
    )
  })
})

const getInitialValues = (bonusTemplate: BonusTemplateDetails) => {
  return {
    entries: {
      is_template: false,
      description: bonusTemplate?.entries?.description || '',
      is_for_referrer: bonusTemplate?.entries?.is_for_referrer || false,
      is_for_referral: bonusTemplate?.entries?.is_for_referral || false,
      company_id: bonusTemplate?.entries?.company_id,
      status: bonusTemplate?.entries?.status,
      type: bonusTemplate?.entries?.type,
      bonus_config:
        bonusTemplate?.entries?.bonus_config || ([] as BonusConfig[])
    }
  }
}

export default function useAddFormBonus() {
  const { searchParams, params } = useNavigation()
  const { user } = useAuth()

  const { isLoading: sending, onAddBonusTemplate } = useAddBonusTemplate()

  const { bonusTemplate, isLoading, isFetching } = useBonusTemplateDetails(
    Number(searchParams.bonusId)
  )

  const onAddBonusConfigToArray = (
    arrayHelpers: FieldArrayRenderProps,
    type: 'referral' | 'referrer',
    isNew?: boolean
  ) => {
    const newBonusTemplate: BonusConfig = {
      amount: '',
      days: '',
      type: type,
      new: isNew
    }
    arrayHelpers.push(newBonusTemplate)
  }
  const onRemoveBonusConfigFromArray = (
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index)
  }

  const initialValues = getInitialValues(bonusTemplate)

  const onSubmit = (values: typeof initialValues) => {
    const parseConfig = values.entries.bonus_config.map(config => {
      return removeEmptyKeys({
        ...config,
        amount: Number(config.amount),
        days: Number(config.days),
        edited: '',
        new: '',
        id: ''
      }) as BonusConfig
    })
    const data: NewBonusTemplate = {
      bonus_config: parseConfig,
      job_id: Number(params.id),
      is_template: values.entries.is_template,
      status: values.entries.status,
      description: values.entries.description,
      is_for_referral: values.entries.is_for_referral,
      is_for_referrer: values.entries.is_for_referrer,
      user_id: user?.id,
      total_amount_referral: bonusTemplate?.entries?.total_amount_referral,
      total_amount_referrer: bonusTemplate?.entries?.total_amount_referrer,
      type: bonusTemplate?.entries?.type
    }
    onAddBonusTemplate(data)
  }

  return {
    initialValues,
    isFetching,
    isLoading,
    sending,
    onSubmit,
    onAddBonusConfigToArray,
    onRemoveBonusConfigFromArray
  }
}
