import { Box } from '@mui/material'
import Stepper from 'components/stepper'
import ListReferrals from './listReferrals'
import Referrals from './referrals'
import Subject from './subject'

const steps = ['Referrals', 'List', 'Subject']

export default function ReferBy() {
  return (
    <Box height="calc(100% - 96px)">
      <Stepper steps={steps}>
        <Referrals />
        <ListReferrals />
        <Subject />
      </Stepper>
    </Box>
  )
}
