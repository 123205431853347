import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { BulkUpdateStatusPaymentTemplates } from 'api/index'
import { useNotification } from 'components/notification/useNotification'
import { SelectedOption } from 'components/selectActions/Actions'
import { useMutation } from 'react-query'
import { StatusPaymentTemplateBulkActions } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from '../../templates/hooks/useBonusTable'

interface UpdatePaymentTemplateParameters {
  data: StatusPaymentTemplateBulkActions
}

const initialState = hookstate({
  isLoading: false
})

export default function useUpdateStatusPayment(callback?: () => void) {
  const { getBonusTemplates } = useBonusTable()
  const [selectedBonusTemplates, setSelectedBonusTemplates] = useState<any>([])
  const { isLoading } = useHookstate(initialState)
  const { onNotify } = useNotification()
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(
    {} as SelectedOption
  )

  const onApply = async (params: StatusPaymentTemplateBulkActions) => {
    isLoading.set(true)
    mutate({ data: params })
  }

  const { mutate } = useMutation(
    (data: UpdatePaymentTemplateParameters) =>
      BulkUpdateStatusPaymentTemplates(data.data),
    {
      onSuccess: data => {
        data.data.entries.errors.length != 0
          ? onNotify({
              severity: 'error',
              message: data.data.entries.errors[0]
            })
          : onNotify({
              severity: 'success',
              message: 'Payment updated successfully'
            })
        callback?.()
        getBonusTemplates()
        onToggleDialog()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  const onToggleDialog = () => setOpenDialog(!openDialog)
  const onSelectedOption = (args: SelectedOption) => setSelectedOption(args)

  const onConfirmBulkActions = () => {
    const data: StatusPaymentTemplateBulkActions = {
      ids: selectedBonusTemplates,
      action: selectedOption.id as StatusPaymentTemplateBulkActions['action']
    }
    onApply(data)
  }

  return {
    onApply,
    openDialog,
    onToggleDialog,
    onSelectedOption,
    onConfirmBulkActions,
    selectedBonusTemplates,
    setSelectedBonusTemplates,
    isLoading: isLoading.value
  }
}
