import { format, fromUnixTime, isAfter, parseISO } from 'date-fns'
import queryString from 'query-string'
import store from 'storejs'
import type { Role, UserType } from 'types/auth'
import { Pagination, PaginationParse } from 'types/general'
import {
  DEFAULT_MESSAGE,
  ENTITIES_TO_REMOVE,
  MIME_TYPES,
  PREVIEW_TYPES
} from 'utils/catalogs'

export const getPagination = (pagination: Pagination) =>
  ({
    totalPages: pagination.total_pages,
    totalEntries: pagination.total_entries,
    pageNumber: pagination.page_number,
    pageSize: pagination.page_size
  } as PaginationParse)

export function getBonusFor(
  isForReferral: boolean | undefined,
  isForReferrer: boolean | undefined
) {
  return isForReferral && isForReferrer
    ? 'Both'
    : isForReferral
    ? 'Referral'
    : 'Employee'
}

export const getCompanyId = () => {
  const sessionStore = store.get('SESSION') || ''
  const companyStore = store.get('SESSION_BY_COMPANY') || ''
  return {
    companyId: companyStore?.user?.company_id || sessionStore?.user?.company_id,
    token: companyStore?.token || sessionStore?.token,
    user: companyStore?.user || sessionStore?.user
  }
}
export function formatEmployeeRoles(texto: string) {
  const role = texto.split('_')
  for (let i = 0; i < role.length; i++) {
    role[i] = role[i].charAt(0).toUpperCase() + role[i].slice(1)
  }
  return role.join(' ')
}
export const getLetters = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const LETTERS = getLetters('A'.charCodeAt(0), 'Z'.charCodeAt(0), 1).map(
  x => String.fromCharCode(x)
)

export const parseQuery = (
  params: string,
  options?: { arrayFormat: 'bracket' | 'bracket' | 'comma' }
) => {
  const parsed = queryString.parse(params, options)
  return parsed
}

export const toBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const stringifyQuery = (
  params: {
    [K: string]: string | number | unknown
  },
  arrayFormat?: 'bracket' | 'index' | 'comma' | 'none'
): string => {
  const _filters = pickBy(params, (filter: string | any[]) => {
    if (typeof filter === 'string') return filter.length > 0
    return true
  })

  return !isEmpty(_filters)
    ? `?${queryString.stringify(_filters, {
        arrayFormat: arrayFormat || 'bracket'
      })}`
    : ''
}

export const isTruthyValue = (value: null | undefined) => {
  return value !== null && value !== undefined
}

export const pickBy = (
  object: { [K: string]: string | number | any },
  condition: any = isTruthyValue,
  recursive?: boolean
) => {
  const obj = Array.isArray(object) ? [] : ({} as any)

  for (const key in object) {
    if (condition(object[key], key)) {
      if (recursive && object[key] instanceof Object) {
        obj[key] = pickBy(object[key], condition, recursive)
      } else {
        obj[key] = object[key]
      }
    }
  }
  return obj
}

export const formatDate = (value: Date | string, dateFormat?: string) => {
  if (!value) {
    return 'No Date'
  }
  const cleanValue = typeof value === 'object' ? value : parseISO(value)
  const currentFormat = dateFormat || 'dd/MM/yyyy'
  return format(cleanValue, currentFormat)
}

export const isEmpty = (obj: { [key: string]: string | number | any }) => {
  return (
    obj &&
    typeof obj === 'object' &&
    obj.constructor === Object &&
    Object.keys(obj).length === 0
  )
}

export const get = (obj: any, path: any, defaultValue = DEFAULT_MESSAGE) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export const formatCurrency = (value: number) => {
  return `$${value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}
export const formatAmount = (value: number) => {
  if (!value) return 0
  return `$${value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export const sortBy = (
  items: Array<{ [key in number | string]: unknown }>
): Array<any> => {
  return items.sort((a, b) => {
    return Number(b.id) - Number(a.id)
  })
}

const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1
}

export const timeAgo = (date: string | Date | number) => {
  const timestamp = typeof date === 'number' ? date : new Date(date).getTime()
  const parseTime = (Date.now() - timestamp) / 1000

  const days = Math.floor(parseTime / DATE_UNITS.day)

  const diffHourPerDay = Math.floor(
    Math.floor(parseTime % DATE_UNITS.day) / DATE_UNITS.hour
  )

  const hours = Math.floor(parseTime / DATE_UNITS.hour)

  const minutes =
    Math.floor(parseTime / DATE_UNITS.minute) - hours * DATE_UNITS.minute

  const seconds = parseTime % DATE_UNITS.minute

  if (Number.isNaN(parseTime)) return `${date}`
  if (days > 30) return formatDate(new Date(date))
  if (days > 0)
    return `${days} Day(s) ${diffHourPerDay}hour(s) ${minutes} minute(s) ago`
  if (hours > 0) return `${hours} hour(s) ${minutes} minute(s) ago`
  if (minutes > 0) return `${minutes} minute(s) ago`
  return `${seconds} seconds ago`
}

export const trimObjectValues = (obj: Record<string, string | any>) => {
  const newObj = {} as Record<string, string>
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      newObj[key] = obj[key].trim()
    } else {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const clearEntities = (entities: Array<string>) => {
  const filteredEntities = entities.filter(
    entity => !ENTITIES_TO_REMOVE.includes(entity)
  )
  return filteredEntities.map(item => {
    const validateName = item.replace(/_/g, ' ')
    return { value: item, label: validateName }
  })
}

export const removeEmptyKeys = (obj: {
  [K: string]: string | number | any
}) => {
  for (const prop in obj) {
    if (typeof obj[prop] === 'object') {
      removeEmptyKeys(obj[prop])
    } else if (obj[prop] === '') {
      delete obj[prop]
    }
  }
  return obj
}

export const canDownload = (url: string) => {
  const expirationDate = getQueryParam(url, 'Expires')
  return isAfter(fromUnixTime(Number(expirationDate)), new Date())
}
export const getQueryParam = (url: string, name: string) => {
  const param = url.match(new RegExp('[?&]' + name + '=([^&#]*)'))
  return param && param[1]
}

export const getFileName = (url: string) => {
  if (!url) return
  const expirationDate = getQueryParam(url, 'Expires')
  // eslint-disable-next-line no-useless-escape
  const name = expirationDate ? url.replace(/^.*[\\\/]/, '').split('?') : []
  return name && name[0]
}

export const getMimeType = (url: string) => {
  const extension = url.match(/\.([a-zA-Z\d]+)\?/)
  const extensionType = extension ? extension[1] : 'default'

  return PREVIEW_TYPES[extensionType as keyof typeof PREVIEW_TYPES]
}

export const downloadFile = async (url: string, name?: string) => {
  if (!canDownload(url)) return
  const fileName = name || getFileName(url) || 'archivo'
  const mimeType = getMimeType(url)
  const response = await fetch(url)
  const data = await response.blob()
  const newBlob = new Blob([data], {
    type: MIME_TYPES[mimeType as keyof typeof MIME_TYPES]
  })
  const blobURL = window.URL.createObjectURL(newBlob)
  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = blobURL
  anchor.click()

  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => {
    URL.revokeObjectURL(blobURL)
  }, 100)
}

export const getUserRole = ({ type, role }: { type: UserType; role: Role }) => {
  if (type === 'superuser' && role === 'super_admin') {
    return 'super_admin'
  }

  if (type === 'employee' && role === 'company_owner') {
    return 'company_owner'
  }

  if (type === 'employee' && role === 'market_manager') {
    return 'market_manager'
  }

  if (type === 'employee' && role === 'rewards_manager') {
    return 'rewards_manager'
  }

  if (type === 'employee' && role === null) {
    return 'employee'
  }

  return 'employee'
}

export const downloadCSV = async ({
  url,
  name
}: {
  url: string
  name: string
}) => {
  const response = await fetch(url)
  const data = await response.text()
  const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' })
  const blobURL = window.URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.download = name
  anchor.href = blobURL
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  )
  anchor.click()
  setTimeout(() => {
    URL.revokeObjectURL(blobURL)
  }, 100)
}
