import styled from 'styled-components'
import { device } from 'styles/utils'

export const Button = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  display: block;
  ${device('md')} {
    display: none;
  }
`
