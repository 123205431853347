import { UserRegisters } from 'api'
import { AxiosError } from 'axios'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import * as yup from 'yup'

export const initialValues = {
  phone_area_code: '',
  privacy: false,
  first_name: '',
  last_name: '',
  company: '',
  phone: '',
  email: ''
}

export const validationSchema = yup.object({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  company: yup.string().required('Required'),
  phone: yup.string().required('Required'),
  privacy: yup.bool().oneOf([true], 'Required'),
  email: yup.string().email('Email invalid').required('Required')
})

export default function useRegisters() {
  const { onNotify } = useNotification()

  const { isLoading, mutate } = useMutation(
    (data: any) => UserRegisters(data),
    {
      onSuccess: () => {
        onNotify({
          message: 'Users register successfully',
          severity: 'success'
        })
      },
      onError: (error: AxiosError<any>) => {
        const errResponse = error.response?.data.errors
        const errorMessage = Object.entries(errResponse?.detail).map(item => {
          const [key, value] = item as [string, string]
          return `${key}: ${value[0]}`
        })

        const message =
          errorMessage.length > 0 ? errorMessage[0] : 'Something went wrong'

        onNotify({ message: message, severity: 'error' })
      }
    }
  )

  const onSubmit = (values: typeof initialValues) => {
    mutate(values)
  }

  return { onSubmit, isLoading }
}
