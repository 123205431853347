import { useEffect, useState } from 'react'
import { SendNotificationToken } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { config } from 'config'
import { getToken, onMessage } from 'firebase/messaging'
import { messaging } from 'firebaseConfig'

export default function useMessage() {
  const { onNotify } = useNotification()
  const [sent, setSent] = useState('')

  useEffect(() => {
    getMessage()
  }, [])

  const getFirebaseToken = (userId: number) => {
    getToken(messaging, {
      vapidKey: config.firebase.vapidKey
    })
      .then(currentToken => {
        onSenNotificationToken(userId, currentToken)
      })
      .catch(err => {
        console.error('An error occurred while retrieving token. ', err)
      })
  }

  const onSenNotificationToken = (userId: number, token: string) => {
    SendNotificationToken(userId, token)
      .then(response => {
        setSent(response.data)
      })
      .catch(error => {
        console.error('An error occurred while sending token. ', error)
      })
  }

  const getMessage = async () => {
    onMessage(messaging, payload => {
      const notificationTitle = payload?.notification?.title as string
      onNotify({ message: notificationTitle, severity: 'success' })
    })
  }

  return { getFirebaseToken, sent }
}
