import { Button, Stack } from '@mui/material'

export const TransferDetailHeader = () => {
  return (
    <Stack alignItems="center" flexDirection={'row'} gap={2}>
      <Button variant="contained">Bonus Arch</Button>
      <Button variant="outlined">Bonus Bitso</Button>
      <Button variant="outlined">Bonus Clip</Button>
    </Stack>
  )
}
