import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { CompanyDetail } from 'types/companies'
import * as yup from 'yup'
import useBegoppInformation from '../../beegoppInformation/useBegoppInformation'

const validationSchema = yup.object({
  companyName: yup.string().required('Required'),
  phoneNumber: yup.string().required('Required'),
  street: yup.string().required('Required'),
  state: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  country: yup.string().required('Required'),
  billingStreet: yup.string().required('Required'),
  billingState: yup.string().required('Required'),
  billingZipcode: yup.string().required('Required'),
  billingCountry: yup.string().required('Required'),
  billingCity: yup.string().required('Required')
})

export type InitialValues = {
  id: number
  companyName: string
  phoneNumber: string
  url: string
  addressId: string
  street: string
  state: string
  zipcode: string
  country: string
  city: string
  billingAddressId: string
  billingStreet: string
  billingState: string
  billingZipcode: string
  billingCountry: string
  billingCity: string
}

const HEADQUATER = 'headquarter'
const BILLING = 'billing'

export default function EditCompany({ company }: { company: CompanyDetail }) {
  const headquarterAddress =
    company?.addresses?.find(address => address.type === HEADQUATER) ||
    ({} as CompanyDetail['addresses'][0])

  const billingAddress =
    company?.addresses?.find(address => address.type === BILLING) ||
    ({} as CompanyDetail['addresses'][0])

  const { onHandleCloseEdit, isLoading, onSubmit } = useBegoppInformation(
    company.addresses
  )

  const initialValues = {
    id: company.id,
    companyName: company.name || '',
    phoneNumber: company.phone || '',
    url: company.url || '',
    billingAddressId: String(billingAddress?.id) || '',
    billingStreet: billingAddress?.street || '',
    billingState: billingAddress?.state || '',
    billingZipcode: billingAddress?.zipcode || '',
    billingCountry: billingAddress?.country || '',
    billingCity: billingAddress?.city || '',

    addressId: String(headquarterAddress?.id) || '',
    street: headquarterAddress?.street || '',
    state: headquarterAddress?.state || '',
    zipcode: headquarterAddress?.zipcode || '',
    country: headquarterAddress?.country || '',
    city: headquarterAddress?.city || ''
  }

  const OwnerName = `${company.owner.first_name} ${company.owner.last_name}`

  return (
    <Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleChange, setFieldValue }) => (
          <Form>
            <Stack
              paddingBottom={2}
              justifyContent="space-between"
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              borderBottom={`1px solid ${COLORS.gray8}`}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontWeight="500"
                  fontSize={{ xs: '18px', md: '20px' }}
                  paddingLeft={{ xs: 0, sm: '12px' }}
                >
                  General Information
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="baseline"
                marginTop={{ xs: 2, sm: 0 }}
                width={{ xs: '96%', sm: 'auto' }}
              >
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  onClick={onHandleCloseEdit}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  variant="contained"
                  sx={{ marginLeft: 2 }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
            <Divider />
            <Box padding={2}>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                direction={{ xs: 'column', lg: 'row' }}
                paddingBottom="8px"
                paddingRight={{ xs: 0, sm: 6 }}
              >
                <Typography width={{ xs: '100%', lg: '30%' }} paddingY="8px">
                  ID
                </Typography>
                <Typography width={{ xs: '100%', lg: '70%' }} paddingY="8px">
                  {company.id}
                </Typography>
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Company name
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('companyName')}
                  name="companyName"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Company url
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('url')}
                  name="url"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Owner
                </Typography>
                <Typography width={{ xs: '100%', lg: '70%' }}>
                  {OwnerName}
                </Typography>
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Phone number
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('phoneNumber')}
                  name="phoneNumber"
                />
              </Stack>
            </Box>
            <Divider
              sx={{
                background: COLORS.honey2
              }}
            />
            <Box padding={2}>
              <Box paddingBottom="8px">
                <Typography fontSize="14px" fontWeight="500" paddingY="8px">
                  Company address
                </Typography>
              </Box>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                direction={{ xs: 'column', lg: 'row' }}
                paddingBottom="8px"
              >
                <Typography
                  variant="h5"
                  width={{ xs: '100%', lg: '30%' }}
                  paddingY="8px"
                >
                  Street
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('street')}
                  name="street"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                direction={{ xs: 'column', lg: 'row' }}
                paddingBottom="8px"
              >
                <Typography
                  variant="h5"
                  width={{ xs: '100%', lg: '30%' }}
                  paddingY="8px"
                >
                  State
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('state')}
                  name="state"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                direction={{ xs: 'column', lg: 'row' }}
                paddingBottom="8px"
              >
                <Typography
                  variant="h5"
                  width={{ xs: '100%', lg: '30%' }}
                  paddingY="8px"
                >
                  Zipcode
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('zipcode')}
                  name="zipcode"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                direction={{ xs: 'column', lg: 'row' }}
                marginBottom={2}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '43%' }}>
                  Country
                </Typography>
                <Select
                  name="country"
                  variant="standard"
                  onChange={e => {
                    setFieldValue('country', e.target.value)
                  }}
                  options={[{ value: 'US', label: 'United States' }]}
                  sx={{
                    width: { xs: '100%' }
                  }}
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography
                  variant="h5"
                  width={{ xs: '100%', lg: '30%' }}
                  paddingY="8px"
                >
                  City
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('city')}
                  name="city"
                />
              </Stack>
            </Box>
            <Divider
              sx={{
                marginTop: 2,
                background: COLORS.honey2
              }}
            />
            <Box padding={2}>
              <Box paddingBottom="8px">
                <Typography fontSize="14px" fontWeight="500" paddingY="8px">
                  Billing address
                </Typography>
              </Box>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Street
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('billingStreet')}
                  name="billingStreet"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  State
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('billingState')}
                  name="billingState"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="20px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  Zipcode
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('billingZipcode')}
                  name="billingZipcode"
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="8px"
                marginBottom={2}
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '43%' }}>
                  Country
                </Typography>
                <Select
                  name="billingCountry"
                  variant="standard"
                  onChange={e => {
                    setFieldValue('billingCountry', e.target.value)
                  }}
                  options={[{ value: 'US', label: 'United States' }]}
                  sx={{
                    width: { xs: '100%' }
                  }}
                />
              </Stack>
              <Stack
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                justifyContent="start"
                paddingRight={{ xs: 0, sm: 6 }}
                paddingBottom="8px"
                direction={{ xs: 'column', lg: 'row' }}
              >
                <Typography variant="h5" width={{ xs: '100%', lg: '30%' }}>
                  City
                </Typography>
                <TextField
                  sx={{
                    width: { xs: '100%', lg: '70%' }
                  }}
                  variant="standard"
                  onChange={handleChange('billingCity')}
                  name="billingCity"
                />
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
