import { hookstate, useHookstate } from '@hookstate/core'
import { GridSelectionModel } from '@mui/x-data-grid'
import { DeleteJobs } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { UpateJobStatusData } from 'types/jobs'
import { get } from 'utils'

const initialState = hookstate({
  isOpenModal: false,
  isChecked: false
})
export default function useDeleteJob() {
  const { onNotify } = useNotification()
  const { isOpenModal, isChecked } = useHookstate(initialState)

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: UpateJobStatusData) => DeleteJobs(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Job deleted successfully'
        })
        onCloseModal()
        query.invalidateQueries('JOBS')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({
          severity: 'error',
          message: errorMessage
        })
      }
    }
  )

  const onChecked = (_: any, checked: boolean) => isChecked.set(checked)

  const onOpenModal = () => isOpenModal.set(true)
  const onCloseModal = () => isOpenModal.set(false)

  const onDeleteJob = (hasJob: boolean) => {
    if (!hasJob) {
      return onNotify({
        message: 'Select one or more items to delete',
        severity: 'info'
      })
    }
    onOpenModal()
  }

  const onSubmit = (jobs: GridSelectionModel) =>
    mutate({ jobs, status: 'deleted' })

  return {
    isOpenModal: isOpenModal.get(),
    isChecked: isChecked.get(),
    isLoading,
    onDeleteJob,
    onOpenModal,
    onCloseModal,
    onSubmit,
    onChecked
  }
}
