import { hookstate, useHookstate } from '@hookstate/core'
import { UpdateBonusTemplate } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateBonusTemplateParams } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from './useBonusTable'

interface UpdateBonusTemplateParameters {
  data: UpdateBonusTemplateParams
  id: number
}

const initialState = hookstate({
  isLoading: false
})

export default function useUpdateBonusTemplate() {
  const { isLoading } = useHookstate(initialState)
  const { onToggleTemplate } = useBonusTable()
  const query = useQueryClient()
  const { onNotify } = useNotification()
  const onUpdateBonusTemplate = async (
    bonusTemplate: UpdateBonusTemplateParams,
    id: number
  ) => {
    isLoading.set(true)
    mutate({ data: bonusTemplate, id })
  }
  const { mutate } = useMutation(
    (data: UpdateBonusTemplateParameters) =>
      UpdateBonusTemplate(data.data, data.id),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Bonus template updated successfully'
        })
        query.invalidateQueries('JOB_DETAIL')
        query.invalidateQueries('BONUSTEMPLATES')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        isLoading.set(!isLoading.value)
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  return {
    onUpdateBonusTemplate,
    isLoading: isLoading.value,
    onToggleTemplate
  }
}
