import { Box } from '@mui/material'
import { JobDetailSkeleton } from 'components/skeleton'
import Body from './body'
import Header from './header'
import useFetchDetail from './hooks/useFetchDetail'
import Referrals from './referrals'

export default function Details() {
  const { isLoading } = useFetchDetail()
  return (
    <Box>
      {isLoading ? (
        <JobDetailSkeleton />
      ) : (
        <>
          <Header />
          <Body />
          <Referrals />
        </>
      )}
    </Box>
  )
}
