import { Cancel } from '@mui/icons-material'
import AttachMoney from '@mui/icons-material/AttachMoney'
import Done from '@mui/icons-material/Done'
import RotateRight from '@mui/icons-material/RotateRight'
import SendIcon from '@mui/icons-material/Send'
import WalletIcon from '@mui/icons-material/Wallet'
import WorkIcon from '@mui/icons-material/Work'
import { Typography, Box, Stack, Button } from '@mui/material'
import Paper from 'components/paper'
import PaymentCard from 'components/paymentCard'
import StatusCard from 'components/statusCard'
import ToggleSidebar from 'components/toggleSidebar'
import WithdrawCard from 'components/withdrawCard'

import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { formatCurrency } from 'utils'

import useTemplate from '../bonus/templates/hooks/useTemplate'
import { BonusReferalTable } from './bonus/index'
import useCountsByUser from './hooks/useCountsByUser'
import useWithdraw from './hooks/useWithdraw'
import { TransferDetail } from './transferDetail'
import { Withdraw } from './withdraw/index'

export default function Wallet() {
  const { openTemplate, onToggleTemplate } = useTemplate()
  const { isSent } = useWithdraw()
  const { countsInformation } = useCountsByUser()
  const { onNavigate } = useNavigation()

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        marginTop={3}
        width={{ xs: '100%', sm: '50%', md: '300px' }}
      >
        <WalletIcon />
        <Typography marginLeft={2} variant="h2">
          Wallet
        </Typography>
      </Stack>
      <Stack
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop={3}
      >
        <Box
          width={{ xs: '100%', lg: '30%' }}
          height={{ xs: '100%', lg: '500px' }}
          marginRight={{ xs: 0, lg: 8 }}
        >
          <WithdrawCard marginBottom={4}>
            <Stack height="100%" justifyContent="center">
              <Typography variant="h6" color={COLORS.white} letterSpacing="1px">
                YOUR AVAILABLE BALANCE
              </Typography>
              <Typography
                fontSize={32}
                variant="h6"
                color={COLORS.white}
                marginBottom={2}
                fontWeight={500}
              >
                {formatCurrency(
                  countsInformation.total_available_balance
                    ? countsInformation.total_available_balance
                    : 0
                ) + ' USD'}
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: COLORS.white,
                  color: COLORS.white,
                  width: '80%'
                }}
                onClick={() => onToggleTemplate('wallet')}
              >
                Withdraw
              </Button>
            </Stack>
          </WithdrawCard>

          <PaymentCard
            showInfo
            variant="warning"
            icon={SendIcon}
            title={`Your ${
              countsInformation.active_referrals_count
                ? countsInformation.active_referrals_count
                : 0
            } 
              referrals can potentially give you`}
            subtitle={
              formatCurrency(
                countsInformation.pending_bonus
                  ? countsInformation.pending_bonus
                  : 0
              ) + ' USD'
            }
            tooltip="Total amount of money you can earn if all your referrals are hired."
            paperProps={{
              sx: { marginTop: 3, marginBottom: 3, height: '76px' }
            }}
            typography={{
              fontSize: '28px',
              fontWeight: 300
            }}
            iconProps={{
              sx: {
                transform: 'rotate(-45deg)',
                width: 20,
                marginBottom: '5px',
                marginLeft: '5px'
              }
            }}
          />
        </Box>
        <Paper
          elevation={4}
          sx={{
            width: { xs: '90%', lg: '65%' },
            padding: 3,
            height: '100%'
          }}
        >
          <Stack
            flexDirection={{ xs: 'column', lg: 'row' }}
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              onClick={() => onNavigate('/jobs/jobboard')}
            >
              Keep Referring
            </Button>
          </Stack>
          <Stack
            flexDirection={{
              xs: 'column-reverse',
              lg: 'row'
            }}
            justifyContent={{
              xs: 'center',
              lg: 'between'
            }}
            alignItems={{
              xs: 'center',
              lg: 'normal'
            }}
            gap={4}
          >
            <Stack width={{ xs: '100%', lg: '50%' }}>
              <StatusCard
                subtitle={
                  countsInformation.hired_referrals_count
                    ? countsInformation.hired_referrals_count
                    : 0
                }
                variant="success"
                icon={Done}
                title="Hired referrals"
                other="Well Done!"
                paperProps={{
                  sx: {
                    marginTop: 3,
                    position: 'relative',
                    display: { xs: 'block', md: 'none' }
                  }
                }}
                typography={{ fontSize: '50px', fontWeight: 300 }}
              />
              <StatusCard
                subtitle={
                  countsInformation.active_referrals_count
                    ? countsInformation.active_referrals_count
                    : 0
                }
                variant="warning2"
                paperColor="warning"
                icon={RotateRight}
                title="In progress"
                typography={{ fontSize: '50px', fontWeight: 300 }}
                paperProps={{ sx: { marginTop: 3, position: 'relative' } }}
              />
              <StatusCard
                subtitle={
                  countsInformation.rejected_referrals_count
                    ? countsInformation.rejected_referrals_count
                    : 0
                }
                variant="error2"
                paperColor="error"
                icon={Cancel}
                title="Rejected"
                typography={{ fontSize: '50px', fontWeight: 300 }}
                paperProps={{ sx: { marginTop: 3, position: 'relative' } }}
              />
              <StatusCard
                subtitle={
                  countsInformation.on_hold_referrals_count
                    ? countsInformation.on_hold_referrals_count
                    : 0
                }
                variant="other"
                icon={WorkIcon}
                title="Unanswered"
                paperProps={{ sx: { marginTop: 3, position: 'relative' } }}
                typography={{ fontSize: '50px', fontWeight: 300 }}
              />
            </Stack>

            <Stack width={{ xs: '100%', lg: '50%' }}>
              <PaymentCard
                subtitle=""
                icon={Done}
                variant="success"
                title="Hired referrals"
                paperProps={{
                  sx: {
                    marginTop: 3,
                    display: { xs: 'none', md: 'block' }
                  }
                }}
              >
                <Stack justifyContent="center" alignItems="center">
                  <Typography fontSize="96px" color={COLORS.green2}>
                    {countsInformation.hired_referrals_count
                      ? countsInformation.hired_referrals_count
                      : 0}
                  </Typography>
                  <Typography fontSize="16px">Well done</Typography>
                </Stack>
              </PaymentCard>
              <PaymentCard
                showInfo
                variant="success"
                icon={AttachMoney}
                title="TOTAL MONEY EARNED"
                subtitle={
                  formatCurrency(
                    countsInformation.total_paid
                      ? countsInformation.total_paid
                      : 0
                  ) + ' USD'
                }
                tooltip="The total amount of money you’ve earned"
                typography={{
                  fontSize: '28px',
                  fontWeight: 300
                }}
                paperProps={{
                  sx: { marginTop: 3, height: '76px' }
                }}
              />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <ToggleSidebar
        isOpen={openTemplate.transferDetail}
        headerTitle="Money to be transferred"
        toggleSidebar={() => onToggleTemplate('transferDetail')}
        bgheader={isSent ? 'success' : 'info'}
      >
        <TransferDetail
          onToggleTemplate={() => onToggleTemplate('transferDetail')}
          EmployeeDetail={null}
        />
      </ToggleSidebar>
      <ToggleSidebar
        isOpen={openTemplate.wallet}
        headerTitle="Withdrawal"
        bgheader={isSent ? 'success' : 'info'}
        toggleSidebar={() => onToggleTemplate('wallet')}
      >
        <Withdraw />
      </ToggleSidebar>

      <BonusReferalTable />
    </>
  )
}
