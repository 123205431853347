import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Stack
} from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Form, Formik, Field } from 'formik'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { listImages } from 'utils/catalogs'
import useNewPassword, {
  getIconColor,
  checkList,
  validationSchema,
  initialValues
} from './useNewPassword'

export default function NewPassword() {
  const { onClickShowPassword, showPassword, onSubmit, isLoading } =
    useNewPassword()
  const { searchParams } = useNavigation()

  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h1" paddingX="8px" paddingBottom={4}>
              {searchParams.screen === 'signup'
                ? 'Welcome to BeeGopp!'
                : 'New password'}
            </Typography>
            <Typography variant="body1" paddingX="8px" paddingBottom={4}>
              {searchParams.screen === 'signup'
                ? 'Please create your password'
                : 'Enter a new password'}
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({ values }) => {
                const types: any = getIconColor(values)
                return (
                  <Form>
                    <Field
                      as={TextField}
                      label={
                        searchParams.screen === 'signup'
                          ? 'Password'
                          : 'New password'
                      }
                      variant="standard"
                      name="newPassword"
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={onClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        )
                      }}
                      fullWidth
                    />
                    <Stack paddingTop={3}>
                      {checkList.map(
                        ({ id, icon: IconComponent, name, type }) => (
                          <Box
                            key={id}
                            paddingBottom={2}
                            display="flex"
                            alignItems="center"
                          >
                            <IconComponent
                              htmlColor={types[type] ? 'green' : COLORS.main}
                            />
                            <Typography variant="body2" paddingX="10px">
                              {name}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Stack>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      loading={isLoading}
                      type="submit"
                    >
                      {searchParams.screen === 'signup' ? 'Sign Up' : 'Submit'}
                    </LoadingButton>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
