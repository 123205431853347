import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'

export default function EmptyCard({
  onCloseDialog
}: {
  onCloseDialog: () => void
}) {
  const { onNavigate, pathname } = useNavigation()

  const onAddCard = () => {
    onNavigate(pathname, { search: { tab: 4 } })
    onCloseDialog()
  }

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <img src="/images/cardblank.png" alt="logo" />
      <Typography
        variant="h5"
        width="100%"
        maxWidth={350}
        marginTop={6}
        fontSize={24}
        fontWeight={500}
        textAlign="center"
        color={COLORS.gray1}
      >
        It looks like there are no card to made fund
      </Typography>
      <Stack gap={2} direction="row" marginTop={10} width="100%">
        <Button fullWidth onClick={onCloseDialog}>
          Cancel
        </Button>
        <LoadingButton fullWidth variant="contained" onClick={onAddCard}>
          Add card
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
