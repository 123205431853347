import { useEffect, useState } from 'react'
import { GetTransactions } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import { Transaction } from 'types/profile'
import { get } from 'utils'

export default function useTransactions() {
  const { onNotify } = useNotification()
  const [transactions, setTransactions] = useState<Transaction>(
    {} as Transaction
  )

  const { isLoading, isFetching, refetch } = useQuery(
    'TRANSACTIONS',
    () => GetTransactions(),
    {
      onSuccess: data => {
        setTransactions(data.entries)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  return { isLoading: isLoading || isFetching, transactions }
}
