import { useState } from 'react'
import { getBonusTemplateById } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { BonusTemplateDetails } from 'types/bonusTemplate'
import { get } from 'utils'

export default function useBonusTemplateDetails(id?: number) {
  const [bonusTemplate, setBonusTemplate] = useState<BonusTemplateDetails>(
    {} as BonusTemplateDetails
  )
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()
  const { isLoading, refetch, isFetching } = useQuery(
    `BONUSTEMPLATE`,
    () => getBonusTemplateById(id || searchParams.id),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const filteredConfig = data.entries.bonus_config.map(item => ({
          ...item,
          edited: false
        }))
        setBonusTemplate({
          entries: { ...data.entries, bonus_config: filteredConfig }
        })
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const getBonusTemplate = () => refetch()

  return {
    bonusTemplate,
    refetch,
    isFetching,
    getBonusTemplate,
    isLoading
  }
}
