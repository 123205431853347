export const FAQS = [
  {
    id: 1,
    question: 'How to connect BeeGopp to your ATS?',
    response:
      'At the bottom of the navigation menu, click on your name, then Integrations and fill up the required fields for the ATS credentials.'
  },
  {
    id: 2,
    question: 'What is field mapping for?',
    response:
      'This configuration is needed to match fields between ATS and Beegopp  in order to standardize data.'
  },
  {
    id: 3,
    question: 'How to import jobs?',
    response:
      'In your navigation menu go to Jobs and select imports. If the ATS is connected a list of jobs will appear on the left side of the table content. Select the jobs you want to import, move them to the right side of the table and click on Import. The jobs will be listed in the Configuration board where bonuses and characteristics can be set on.'
  },
  {
    id: 4,
    question: 'What is a Bonus Template?',
    response:
      'A Bonus Template will help recruiters to configure bonuses characteristics and have them in a list ready to be used.'
  },
  {
    id: 5,
    question: 'What is the process for withdrawing a payment?',
    response:
      'Each referral has their own payment conditions depending on hours worked. Once the hour milestone is reached, the payment will be available for withdrawal. The next step is just to select the desired method of payment.'
  },
  {
    id: 6,
    question: 'Can you refer the same person several times?',
    response: 'Yes, to different job openings.'
  },
  {
    id: 7,
    question: 'What is a Bonus in “not set” status?',
    response:
      'It is when the user has not yet selected the payment method. To do this, go to the wallet and click on withdraw to select one of the available options'
  },
  {
    id: 8,
    question: 'What is the difference between Job Board and Job Configuration?',
    response:
      'The Job Board will show all the jobs available to share with your referrals. Job Configuration is where all the job characteristics are specified.'
  },
  {
    id: 9,
    question: 'How do you publish a job on the job board? ',
    response:
      'Select a Job in board configuration and click on the Job Status button and change the status to Published. Remember that before publishing a Job you must establish the bonus that will be given to the employee or referral and you must establish the visibility of the Job internal employees or outsourced employees'
  },
  {
    id: 10,
    question: 'What is Job visibility?',
    response:
      'It is the configuration to know which people will see the job. These can be internal employees, outsourcing employees or both.'
  },
  {
    id: 11,
    question: 'How can I know if my referral applied for the job?',
    response:
      'Click on referrals and you will see the list of people who have applied and their status.'
  },
  {
    id: 12,
    question: 'Is there a limit of referrals?',
    response:
      'No. You can refer the same person to different Jobs. You can also refer one Job to several candidates.'
  },
  {
    id: 13,
    question: 'How do I change my password?',
    response:
      'Scroll down to the bottom of the navigation menu and click on your name, then choose profile and follow instructions.'
  },
  {
    id: 14,
    question: 'How can I see who have applied for a job?',
    response:
      "Go to Referrals and you will find all the applicant's information and status."
  },
  {
    id: 15,
    question: 'What happens if I close a job from my ATS?',
    response: 'Begopp will close it automatically with a “Closed by ATS” tag.'
  },
  {
    id: 16,
    question: 'How do I know if I integrated my ATS correctly?',
    response:
      'In the upper corner of your card, you will find a chip that says connected. If it could not be connected, then you will see a blue button that says connect.'
  },
  {
    id: 17,
    question: 'What are the conditions of a bonus?',
    response:
      'When a job has a bonus amount, it can come in deferred payments, in different amounts that will add up to the total bonus, and its conditions refer to the period of time in which that bonus will be released.'
  },
  {
    id: 18,
    question: 'What does the Bonus Pending label in Wallet refer to?',
    response:
      'When we share a job, we can see if it has a bonus and its conditions, which refer to a specific time to be able to release the amount of that bonus. So the bonus pending status in our wallet shows us the accumulated bonuses that we have in this process. (Earned but still do not complete the days of bonus condition).'
  },
  {
    id: 19,
    question: 'What does the Total Paid label in Wallet refer to?',
    response:
      'It refers to the sum of earned and collected amounts. (Amounts already collected, that is, those with a paid status in bonus payments).'
  },
  {
    id: 20,
    question: 'What is your available balance in the Wallet?',
    response:
      'When a bonus is released in Beegopp depending on its conditions, it becomes visible in the accumulated of your available balance. Once entering this section, it will allow us to select separately the bonuses we have and select the method with which we want to receive the bonus. (That comply the condition of  “after _days”).'
  },
  {
    id: 21,
    question: 'What does the in-process label in my Wallet refer to?',
    response:
      'It refers to the sum of bonuses that we have already put a payment method on, and are in the process of being delivered. In process, when a method has already been set, it only remains for it to be released from the tremendous side.'
  }
]
