import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import PreviewIcon from '@mui/icons-material/Preview'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'

import { COLORS } from 'styles/colors'
import { CompanyDetail } from 'types/companies'
import EditBegopp from './editBegopp'

export default function BeegoppInformation({
  company
}: {
  company: CompanyDetail
}) {
  const { user } = useAuth()

  const [edit, setEdit] = useState(false)

  const handleEdit = () => setEdit(!edit)

  return (
    <Box>
      {edit ? (
        <EditBegopp onChangeEdit={handleEdit} company={company} />
      ) : (
        <>
          <Stack
            paddingLeft={2}
            paddingBottom="5px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              fontWeight="500"
              fontSize={{ xs: '18px', sm: '20px' }}
            >
              BeeGopp jobsite information
            </Typography>
            <Button
              variant="text"
              onClick={handleEdit}
              startIcon={<EditIcon htmlColor={COLORS.main} />}
              sx={{
                marginRight: '10px',
                alignSelf: 'flex-end'
              }}
            >
              Edit
            </Button>
          </Stack>
          <Divider />
          <Box padding={2}>
            <Stack
              marginY="8px"
              alignItems="flex-start"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Typography width="30%" variant="h5" paddingY="8px">
                Logo
              </Typography>

              <Box
                component="div"
                onClick={handleEdit}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  alt="Logo"
                  src={company.logo || '/images/logo.png'}
                  style={{ maxHeight: '180px', maxWidth: '100%' }}
                />
              </Box>
            </Stack>
            <Stack
              marginY="8px"
              alignItems="flex-start"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Typography width="30%" variant="h5" paddingY="8px">
                Header
              </Typography>
              <Box
                component="div"
                onClick={handleEdit}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  alt="header"
                  src={company.header || '/images/company-header.png'}
                  style={{ maxHeight: '180px', maxWidth: '100%' }}
                />
              </Box>
            </Stack>
            <Stack
              marginY="8px"
              justifyContent="flex-start"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Typography
                variant="h5"
                paddingY="8px"
                alignSelf="flex-start"
                width={{ xs: '100%', sm: '30%' }}
              >
                Company description
              </Typography>
              <Typography
                variant="h5"
                paddingTop="8px"
                color={COLORS.gray2}
                width={{ xs: '100%', sm: '50%' }}
              >
                {company.description}
              </Typography>
            </Stack>
          </Box>
          <Divider />
          <Stack flexDirection="row" alignItems="center" justifyContent="end">
            <Button
              component="a"
              target="_blank"
              href={`/company/${user.company_id}/jobs/${user.id}`}
              startIcon={<PreviewIcon />}
              variant="text"
              sx={{
                marginTop: '10px',
                marginRight: '25px'
              }}
            >
              Preview
            </Button>
          </Stack>
        </>
      )}
    </Box>
  )
}
