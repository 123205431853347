import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Formik, Form } from 'formik'
import { COLORS } from 'styles/colors'
import { LinkButton } from 'styles/utils'
import { listImages } from 'utils/catalogs'
import useSignin, { initialValues, validationSchema } from './useSignin'

export default function Signin() {
  const { onSubmit, onShowPassword, showPassword, isLoading } = useSignin()

  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={{ xs: 0, md: 5 }}>
            <Typography
              variant="h1"
              fontSize={{ sx: '44px', md: '48px' }}
              paddingX="8px"
              paddingBottom={4}
            >
              Welcome to BeeGopp!
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              Please enter your credentials
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <TextField
                    sx={{
                      paddingBottom: '30px'
                    }}
                    label="Email"
                    variant="standard"
                    name="email"
                    fullWidth
                  />
                  <TextField
                    label="Password"
                    variant="standard"
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      )
                    }}
                    fullWidth
                  />
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop={3}
                    paddingBottom={4}
                  >
                    <FormControlLabel
                      label="Remember password"
                      componentsProps={{
                        typography: { variant: 'h5' }
                      }}
                      control={
                        <Checkbox
                          sx={{
                            color: COLORS.main
                          }}
                          defaultChecked
                        />
                      }
                    />
                    <LinkButton to="/reset-password">
                      Forgot password
                    </LinkButton>
                  </Stack>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isLoading}
                  >
                    Log in
                  </LoadingButton>
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={3}
                  >
                    <Typography variant="h5" paddingRight="10px">
                      Don’t have an account?
                    </Typography>
                    <LinkButton to="/register">Register</LinkButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
