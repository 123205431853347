import { Box } from '@mui/material'
import CompanyHeader from './companiesBody/companyHeader'
import CompanyTable from './companiesBody/companyTable'

export default function Companies() {
  return (
    <Box>
      <CompanyHeader />
      <CompanyTable />
    </Box>
  )
}
