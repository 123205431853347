import { useEffect, useState } from 'react'
import useFilterStore from 'stores/useFilterStore'
import { FilterEmployeeName } from 'types/referrals'
import type { Filter, UseFilter } from 'types/table'
import useFilterStatusStore from './useFilterStatusStore'

export default function useFilter() {
  const { filters, onAddFilter, onCleanFilters } = useFilterStatusStore()
  const { onCloseAnchor } = useFilterStore()

  const [filterReferral, setFilterReferral] = useState({
    status: [] as Array<UseFilter>,
    joined: [] as Array<UseFilter>
  })

  useEffect(() => {
    const filterEntries = Object.entries(filters)
      .map(([key, value]) => {
        return {
          key: Array.isArray(value) && value.length > 0 ? key : null,
          value: JSON.parse(JSON.stringify(value)) as Array<string>
        }
      })
      .filter(item => item.key)

    filterEntries.map(item => {
      setFilterReferral(prevState => {
        return {
          ...prevState,
          [item.key as FilterEmployeeName]: item.value.map(item => {
            return {
              id: item,
              label: item,
              checked: true
            }
          })
        }
      })
    })
  }, [])

  const onClearFilters = (name: FilterEmployeeName) => {
    onCleanFilters(name)
    onCloseAnchor()
  }

  const onAddUrlFilters = (name: FilterEmployeeName) => {
    onAddFilter(filterReferral[name], name)
    onCloseAnchor()
  }

  const onChangeValue = ({
    filter,
    name
  }: {
    filter: Filter
    name: 'status' | 'joined'
  }) => {
    setFilterReferral(prevState => {
      if (!filter.checked) {
        return {
          ...prevState,
          [name]: [...prevState[name].filter(item => item.id !== filter.id)]
        }
      }
      return {
        ...prevState,
        [name]: [
          ...prevState[name].filter(item => item.id !== filter.id),
          filter
        ]
      }
    })
  }

  return {
    onChangeValue,
    onClearFilters,
    onAddUrlFilters,
    filterReferral
  }
}
