import { UpdateCompanyConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { ConfigurationTosend } from 'types/profile'
import { get } from 'utils'
import { atsDefaultValues } from 'utils/catalogs'

export default function useResetSettings(callBack: () => void) {
  const { user } = useAuth()
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: ConfigurationTosend) =>
      UpdateCompanyConfig({
        configId: user.config_id,
        companyId: user.company_id,
        dataTosend: data
      }),
    {
      onSuccess: () => {
        onNotify({
          message: 'Successfully reset settings by default',
          severity: 'success'
        })
        callBack()
        query.invalidateQueries('COMPANY_DETAIL_CONFIG')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  const onResetSettings = (atsEnable: string) => {
    const dataTosend = {
      mapped_fields: {
        job: atsDefaultValues[atsEnable.toLocaleLowerCase() as string].job,
        employee: {},
        candidate: {}
      }
    }
    mutate({ config: dataTosend })
  }
  return { onResetSettings, isLoading }
}
