import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from '@mui/material'
import { useField } from 'formik'

export default function TextField({ ...props }: MuiTextFieldProps) {
  const [field, meta] = useField<any>(props.name || '')
  return (
    <MuiTextField
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  )
}
