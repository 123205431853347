import LoadingButton from '@mui/lab/LoadingButton'
import { Container, Grid, Box, Typography } from '@mui/material'
import { SigninImg, SigninLogo } from 'app/signin/styles'
import TextField from 'components/TextField'
import Carousel from 'components/carousel'
import { Form, Formik } from 'formik'
import { listImages } from 'utils/catalogs'
import usePotencialCustomer, {
  initialValues,
  validationSchema
} from './usePotencialCustomer'

export default function PotencialCustomer() {
  const { isLoading, onRedirect, onSubmit } = usePotencialCustomer()
  return (
    <Container>
      <Grid
        container
        paddingX={8}
        spacing={5}
        padding={0}
        justifyContent="center"
      >
        <Grid item md={5} paddingY={5}>
          <SigninLogo src="/images/logo.png" alt="logo" />
          <Box paddingTop={5} paddingRight={5}>
            <Typography variant="h4" paddingX="8px" paddingBottom={4}>
              Are you interested in joining BeeGopp?
            </Typography>
            <Typography variant="h5" paddingBottom={3}>
              We noticed you’ve tried to create an account in our website.
              Please enter your email and we’ll send you information on how to
              apply to our program.
            </Typography>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <TextField
                    sx={{
                      paddingBottom: '30px'
                    }}
                    label="Email"
                    variant="standard"
                    name="email"
                    fullWidth
                  />

                  <LoadingButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isLoading}
                    onClick={onRedirect}
                  >
                    Submit
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Carousel loop autoPlay interval={5000}>
            {listImages.map(item => (
              <SigninImg key={item.id} src={item.img} alt="logo" />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  )
}
