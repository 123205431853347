import { Box, Typography } from '@mui/material'
import { EmptyPage } from 'components/emtpyPage/'
import { DataGrid, Pagination } from 'components/table'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { commonTableStyles } from 'styles/utils'
import useReferralDetail from '../hooks/useDetailReferrals'
import { columns, getRows } from './constants'

export default function ReferralTable() {
  const { onNavigate } = useNavigation()
  const { referrals, isLoading, pagination } = useReferralDetail()
  const { page, rowsPerPage, handleChangePage } = usePage()

  const rows = getRows(referrals)

  const handleDetail = (id: number) => {
    onNavigate('/referrals', {
      search: {
        id,
        referrals: 'open'
      }
    })
  }

  return (
    <Box
      width="100%"
      marginTop={4}
      paddingBottom={3}
      height={commonTableStyles.minHeightSubtable}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        checkboxSelection={false}
        rowsPerPageOptions={[rowsPerPage]}
        rowCount={pagination.totalPages || 0}
        onCellClick={props => {
          if (!props.row.cv) return
          if (props.field === 'cv') {
            return window.open(props.row.cv, '_blank', 'noopener, noreferrer')
          }
          handleDetail(props.row.user_referral.id)
        }}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          NoResultsOverlay: () => (
            <EmptyPage
              message="This job doesn’t have referrals yet"
              logo="/images/bag.png"
            >
              <Typography variant="caption">
                Share the link to have a list of referrals
              </Typography>
            </EmptyPage>
          )
        }}
      />
    </Box>
  )
}
