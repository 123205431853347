import { Box } from '@mui/material'
import ReferralDetail from 'app/referrals/referralDetail'
import { EmptyPage } from 'components/emtpyPage'
import { DataGrid, Pagination } from 'components/table'
import { TableHeader } from 'components/table'
import ToggleSidebar from 'components/toggleSidebar'
import useAuth from 'hooks/useAuth'
import { commonTableStyles } from 'styles/utils'
import { usePage } from '../../../../hooks/usePage'
import { columns } from '../constants'
import useReferralByEmployees from '../hooks/useReferralByEmployees'
import EmployeeReferralHeader from './EmployeeReferralHeader'

const users = ['company_owner', 'rewards_manager', 'market_manager']

export const EmployeeReferralTable = () => {
  const { userRole } = useAuth()

  const { referrals, isLoading, pagination, openToggle, onToggleTemplate } =
    useReferralByEmployees()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()

  const tableHeight =
    userRole == 'employee'
      ? commonTableStyles.minHeight
      : commonTableStyles.minHeightSubtable

  return (
    <>
      {!users.includes(userRole) && <EmployeeReferralHeader />}
      <Box width="100%" marginTop={4} paddingBottom={3} height={tableHeight}>
        <DataGrid
          page={page}
          rows={referrals}
          columns={columns}
          checkboxSelection
          loading={isLoading}
          disableSelectionOnClick
          pageSize={rowsPerPage}
          rowsPerPageOptions={[10]}
          rowCount={pagination.totalPages || 0}
          onRowClick={props => {
            if (userRole === 'employee') {
              return onToggleTemplate('referrals', {
                id: props.row.referral_id
              })
            }
          }}
          components={{
            Header: () => (
              <>
                {users.includes(userRole) && <EmployeeReferralHeader />}
                <TableHeader
                  hideBulk
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  totalPages={referrals.length}
                >
                  &nbsp;
                </TableHeader>
              </>
            ),
            Pagination: () => {
              return userRole === 'employee' ? (
                <Pagination
                  page={page}
                  handleChangePage={handleChangePage}
                  totalPages={pagination.totalPages}
                />
              ) : null
            },
            NoResultsOverlay: () => (
              <EmptyPage
                message="No referrals yet"
                logo="/images/referralIcon.svg"
                children={null}
              />
            )
          }}
        />
        <ToggleSidebar
          headerTitle="Referral detail"
          isOpen={openToggle.referrals}
          toggleSidebar={() => onToggleTemplate('referrals')}
        >
          <ReferralDetail />
        </ToggleSidebar>
      </Box>
    </>
  )
}
