import { Stack, Typography } from '@mui/material'
import Breadcrumbs from 'components/breadcrums'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import useJobBoardDetail from '../hooks/useJobBoardDetail'

export default function Header() {
  const { params } = useNavigation()
  const { details } = useJobBoardDetail()
  return (
    <>
      <Stack
        width="100%"
        marginY={3}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'initial', md: 'space-between' }}
      >
        <Stack
          width={{ xs: '100%', md: '70%' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'baseline' }}
        >
          <Typography
            variant="h3"
            height="40px"
            paddingX={3}
            lineHeight="40px"
            borderRadius="6px"
            bgcolor={COLORS.honey4}
            fontSize={{ xs: '18px', md: '24px' }}
            display={{ xs: 'none', md: 'block' }}
          >
            {params.id}
          </Typography>
          <Typography variant="h2" marginLeft={{ xs: 0, md: 2 }}>
            {details.name}
          </Typography>
        </Stack>
      </Stack>
      <Breadcrumbs routes={[{ route: '/jobs/jobboard', label: 'Job board' }]}>
        {details.name} ID {params.id}
      </Breadcrumbs>
    </>
  )
}
