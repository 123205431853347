import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { Formik, Form } from 'formik'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { COLORS } from 'styles/colors'
import useCreateEmployees, {
  validationSchema,
  initialValues
} from '../hooks/useCreateEmployees'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Invitation({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const [isFocused, seIsFocused] = useState(false)
  const { onCreateEmployeeRequest, isLoading } = useCreateEmployees()
  const onSubmit = (res: any) => {
    onCreateEmployeeRequest(res)
    onClose()
  }
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      TransitionComponent={Transition}
      aria-describedby="dialog-slide-description"
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleChange, setFieldValue, values, touched, errors }) => (
          <Box component={Form} margin="20px">
            <DialogContent>
              <Typography variant="h3" textAlign="center" marginBottom={8}>
                Invite your employees
              </Typography>
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  name="first_name"
                  label="First name"
                  variant="standard"
                  onChange={handleChange('first_name')}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  fullWidth
                  name="last_name"
                  variant="standard"
                  label="Last name"
                  onChange={handleChange('last_name')}
                  sx={{ marginBottom: 2 }}
                />
              </Stack>
              <TextField
                fullWidth
                name="email"
                variant="standard"
                label="Email"
                style={{ textTransform: 'lowercase' }}
                onChange={handleChange('email')}
              />
              <Stack marginTop={3}>
                <Stack
                  flexDirection="column"
                  position="relative"
                  sx={{
                    '.selected-flag': {
                      paddingLeft: 0
                    },
                    '.special-label': {
                      left: 0,
                      paddingX: 0,
                      color: COLORS.main,
                      fontSize: 14
                    }
                  }}
                >
                  <PhoneInput
                    value=""
                    country="us"
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      border: 'none',
                      borderBottom:
                        isFocused || values.phone
                          ? `2px solid ${COLORS.main}`
                          : `1px solid ${COLORS.gray4}`,
                      borderRadius: 0,
                      paddingLeft: 40,
                      boxShadow: 'none'
                    }}
                    onFocus={() => seIsFocused(true)}
                    onBlur={() => seIsFocused(false)}
                    onChange={(value: string, country: CountryData) => {
                      setFieldValue('phone_area_code', country.dialCode)
                      setFieldValue('phone', value)
                    }}
                  />
                  <Stack marginTop={3}>
                    <Select
                      name="role"
                      defaultValue="role"
                      variant="standard"
                      width="100%"
                      label="Role"
                      onChange={event =>
                        setFieldValue('role', event.target.value)
                      }
                      options={[
                        { value: 'contractor', label: 'Contractor' },
                        { value: 'employee', label: 'Employee' },
                        { value: 'market_manager', label: 'Market Manager' },
                        { value: 'rewards_manager', label: 'Rewards Manager' }
                      ]}
                    />
                  </Stack>
                  <Stack marginTop={3}>
                    <Select
                      name="staff"
                      defaultValue="staff"
                      variant="standard"
                      width="100%"
                      label="Staff"
                      onChange={event =>
                        setFieldValue('staff', event.target.value)
                      }
                      options={[
                        { value: 'internal', label: 'Internal' },
                        { value: 'outsourced', label: 'Outsourced' }
                      ]}
                    />
                  </Stack>
                </Stack>

                {touched.phone && errors.phone && (
                  <Typography
                    variant="h6"
                    color={COLORS.red1}
                    position="absolute"
                    margin="60px 0 0 0"
                  >
                    Required
                  </Typography>
                )}
              </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
              <Button
                children="Cancel"
                variant="text"
                size="large"
                onClick={onClose}
                sx={{ fontSize: 16, width: 237, height: 42 }}
              />
              <LoadingButton
                size="large"
                type="submit"
                children="Send"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
                sx={{ fontSize: 16, width: 237, height: 42 }}
              />
            </DialogActions>
          </Box>
        )}
      </Formik>
    </Dialog>
  )
}
