import FilterList from '@mui/icons-material/FilterList'
import { Stack, Typography, IconButton } from '@mui/material'
import { GridColumnHeaderParams } from '@mui/x-data-grid'
import useFilterStore from 'stores/useFilterStore'
import { COLORS } from 'styles/colors'
import MenuColumn from './MenuColumn'

interface ColumnHeaderProps extends GridColumnHeaderParams {
  children?: React.ReactNode
}

export default function ColumnHeader({
  children,
  ...props
}: ColumnHeaderProps) {
  const { filterId, popoverUid, anchorEl, open, onOpenAnchor, onCloseAnchor } =
    useFilterStore()
  return (
    <Stack
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle2" width="70%" color={COLORS.blue1}>
        {props.colDef.headerName}
      </Typography>
      {children && (
        <>
          <IconButton
            onClick={event => onOpenAnchor(event, props.colDef.field)}
          >
            <FilterList />
          </IconButton>
          {props.colDef.field === popoverUid && (
            <MenuColumn
              onClose={onCloseAnchor}
              anchorEl={anchorEl}
              id={filterId}
              open={open}
            >
              {children}
            </MenuColumn>
          )}
        </>
      )}
    </Stack>
  )
}
