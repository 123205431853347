import EmployeeHeader from './EmployeeHeader'
import EmployeeTable from './EmployeeTable'

const Employee = () => {
  return (
    <>
      <EmployeeHeader />
      <EmployeeTable />
    </>
  )
}

export default Employee
