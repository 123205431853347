import React from 'react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Spinner from 'components/spinner'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ImportDialog({
  isOpen,
  title = 'Sending information',
  onClose
}: {
  isOpen: boolean
  title?: string
  onClose?: () => void
}) {
  return (
    <Dialog
      keepMounted
      maxWidth="md"
      open={isOpen}
      TransitionComponent={Transition}
      aria-describedby="dialog-slide-description"
    >
      <DialogContent
        sx={{
          width: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Spinner />
        <Typography variant="body2" marginTop={3}>
          {title}
        </Typography>
      </DialogContent>
      {onClose && (
        <DialogActions sx={{ paddingRight: 3 }}>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
