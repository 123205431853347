import BusinessIcon from '@mui/icons-material/Business'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { Button, Stack, Typography } from '@mui/material'
import SearchBar from 'components/SearchBar'
import { COLORS } from 'styles/colors'
import DialogCompanies from '../dialogCompanies'
import useInviteCompanies from '../dialogCompanies/useInviteCompanies'

export default function CompanyHeader() {
  const { onHandleClose, onHandleOpen, open } = useInviteCompanies()

  return (
    <>
      <Stack
        marginTop={3}
        marginBottom={3}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent={{ xs: 'flex-start' }}
      >
        <Stack
          marginBottom={{ xs: 2, sm: 0 }}
          width={{ xs: '95%', sm: '72%', md: 'auto' }}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'baseline' }}
        >
          <Stack direction="row" alignItems="center">
            <BusinessIcon fontSize="medium" htmlColor={COLORS.gray1} />
            <Typography variant="h2" color={COLORS.gray1} paddingX={2}>
              Companies
            </Typography>
          </Stack>
        </Stack>
        <Stack
          gap={3}
          alignItems="flex-end"
          width={{ xs: '100%' }}
          justifyContent="space-between"
          direction={{ xs: 'column', sm: 'row' }}
        >
          <SearchBar boxProps={{ width: { xs: '95%', md: '250px' } }} />
          <Button
            fullWidth
            variant="contained"
            startIcon={<DomainAddIcon />}
            children="Add Company"
            sx={{ maxWidth: '180px', bgcolor: COLORS.main }}
            onClick={onHandleOpen}
          />
        </Stack>
      </Stack>
      <DialogCompanies open={open} handleClose={onHandleClose} />
    </>
  )
}
