import AnalyticsIcon from '@mui/icons-material/Analytics'
import {
  Stack,
  Typography,
  TextField as MUITextField,
  Button
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import useCountsByUser from 'app/wallet/hooks/useCountsByUser'
import Paper from 'components/paper'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { formatCurrency, formatDate } from 'utils'
import { FIRST_DAY } from 'utils/catalogs'
import useJobsGraph from './hooks/useJobsGraph'
import useJobsGraphE from './hooks/useJobsGraphE'
import useOverviewData from './hooks/useOverviewData'
import useOverviewDataE from './hooks/useOverviewDataE'
import usePaymentsGraph from './hooks/usePaymentsGraph'
import usePaymentsGraphE from './hooks/usePaymentsGraphE'
import useReferralFunnelGraph from './hooks/useReferralFunnelGraph'
import useReferralFunnelGraphE from './hooks/useReferralFunnelGraphE'
import useReferralGraph from './hooks/useReferralGraph'
import useReferralGraphE from './hooks/useReferralGraphE'
export const DashboardHeader = () => {
  const { userRole } = useAuth()
  const { onNavigate } = useNavigation()
  const { countsInformation } = useCountsByUser()

  const date = Date.now()

  const { initialDate, endDate, setInitialDate, setEndDate, onGetData } =
    userRole == 'super_admin' ? useReferralGraph() : useReferralGraphE()

  const { setInitialDateOverview, setEndDateOverview, onGetDataOverview } =
    userRole == 'super_admin' ? useOverviewData() : useOverviewDataE()

  const { setInitialDatePayment, setEndDatePayment, onGetDataPayment } =
    userRole == 'super_admin' ? usePaymentsGraph() : usePaymentsGraphE()

  const { setInitialDateJobs, setEndDateJobs, onGetDataJobs } =
    userRole == 'super_admin' ? useJobsGraph() : useJobsGraphE()

  const { setInitialDateFunnel, setEndDateFunnel, onGetDataFunnel } =
    userRole == 'super_admin'
      ? useReferralFunnelGraph()
      : useReferralFunnelGraphE()

  const handleChangeInitialDates = (event: any) => {
    setInitialDate(event)
    setInitialDateOverview(event)
    setInitialDatePayment(event)
    setInitialDateJobs(event)
    setInitialDateFunnel(event)
  }

  const handleChangeEndDates = (event: any) => {
    setEndDate(event)
    setEndDateOverview(event)
    setEndDatePayment(event)
    setEndDateJobs(event)
    setEndDateFunnel(event)
  }

  const handleInitialDate = (event: any) => handleChangeInitialDates(event)
  const handleEndDate = (event: any) => handleChangeEndDates(event)

  const handleApply = () => {
    onGetData()
    onGetDataOverview()
    onGetDataPayment()
    onGetDataJobs()
    onGetDataFunnel()
  }

  const handleRestartDates = () => {
    setInitialDate(formatDate(FIRST_DAY, 'MM/dd/yyyy'))
    setEndDate(formatDate(new Date(date), 'MM/dd/yyyy'))
    setInitialDateOverview(formatDate(FIRST_DAY, 'MM/dd/yyyy'))
    setEndDateOverview(formatDate(new Date(date), 'MM/dd/yyyy'))
    setInitialDatePayment(formatDate(FIRST_DAY, 'MM/dd/yyyy'))
    setEndDatePayment(formatDate(new Date(date), 'MM/dd/yyyy'))
    setInitialDateJobs(formatDate(FIRST_DAY, 'MM/dd/yyyy'))
    setEndDateJobs(formatDate(new Date(date), 'MM/dd/yyyy'))
    setInitialDateFunnel(formatDate(FIRST_DAY, 'MM/dd/yyyy'))
    setEndDateFunnel(formatDate(new Date(date), 'MM/dd/yyyy'))
    setTimeout(() => handleApply(), 0)
  }

  return (
    <>
      {userRole !== 'employee' ? (
        <Stack
          width="100%"
          marginTop={3}
          marginBottom={3}
          justifyContent="space-between"
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <Stack direction="row" marginBottom={{ xs: 4, md: 0 }}>
            <AnalyticsIcon />
            <Typography marginLeft={2} marginRight={4} variant="h2">
              Dashboard
            </Typography>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }}>
            <Stack marginRight={4} direction="row">
              <DesktopDatePicker
                label="Start:"
                value={initialDate}
                onChange={event => handleInitialDate(event)}
                renderInput={params => (
                  <MUITextField variant="standard" {...params} />
                )}
                InputProps={{
                  sx: {
                    marginRight: 4
                  }
                }}
              />
              <DesktopDatePicker
                label="End:"
                value={endDate}
                onChange={event => handleEndDate(event)}
                renderInput={params => (
                  <MUITextField variant="standard" {...params} />
                )}
              />
            </Stack>
            <Stack direction="row" marginRight={3} marginTop={{ xs: 4, md: 0 }}>
              <Button
                size="large"
                variant="outlined"
                onClick={handleRestartDates}
                sx={{
                  marginRight: 2,
                  width: { xs: '100%', md: '50%' }
                }}
              >
                Clear
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={handleApply}
                sx={{
                  width: { xs: '100%', md: '50%' }
                }}
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <>
          <Stack
            width="100%"
            marginTop={3}
            marginBottom={3}
            justifyContent="space-between"
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Stack direction="row" marginBottom={{ xs: 4, md: 0 }}>
              <AnalyticsIcon />
              <Typography marginLeft={2} marginRight={4} variant="h2">
                Dashboard
              </Typography>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <Stack marginRight={4} direction="row">
                <DesktopDatePicker
                  label="Start:"
                  value={initialDate}
                  onChange={event => handleInitialDate(event)}
                  renderInput={params => (
                    <MUITextField variant="standard" {...params} />
                  )}
                  InputProps={{
                    sx: {
                      marginRight: 4
                    }
                  }}
                />
                <DesktopDatePicker
                  label="End:"
                  value={endDate}
                  onChange={event => handleEndDate(event)}
                  renderInput={params => (
                    <MUITextField variant="standard" {...params} />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                marginRight={3}
                marginTop={{ xs: 4, md: 0 }}
              >
                <Button
                  size="large"
                  variant="outlined"
                  onClick={handleRestartDates}
                  sx={{
                    marginRight: 2,
                    width: { xs: '100%', md: '50%' }
                  }}
                >
                  Clear
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleApply}
                  sx={{
                    width: { xs: '100%', md: '50%' }
                  }}
                >
                  Apply
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={'center'}>
            <Paper
              bgcolor="rgb(137, 169, 253,0.2)"
              sx={{ width: '95%', marginTop: 2, marginBottom: 3 }}
            >
              <Typography
                marginLeft={2}
                marginRight={4}
                marginBottom={1}
                variant="h5"
                align="center"
              >
                Your{' '}
                {countsInformation.active_referrals_count
                  ? countsInformation.active_referrals_count
                  : 0}{' '}
                referrals potencial can potentially give you
              </Typography>
              <Typography
                marginLeft={2}
                marginRight={4}
                marginBottom={1}
                variant="h2"
                align="center"
              >
                {formatCurrency(
                  countsInformation.pending_bonus
                    ? countsInformation.pending_bonus
                    : 0
                )}{' '}
                USD!
              </Typography>
              <Button
                variant="contained"
                fullWidth
                onClick={() => onNavigate('/jobs/jobboard')}
              >
                Keep Referring
              </Button>
            </Paper>
          </Stack>
        </>
      )}
    </>
  )
}
