import { useState, useEffect } from 'react'
import { GetBonusPayments } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useToggle from 'components/toggleSidebar/useToggle'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { ListPayments } from 'types/bonusPayments'
import { get } from 'utils'
import { getPagination } from 'utils/index'
import { getRows } from '../constants/'
import usePaymentsFilter from './usePaymentsFilter'

type Params = { [key in string]: string | unknown }
export default function usePaymentsTable() {
  const { filters } = usePaymentsFilter()
  const { searchParams } = useNavigation()

  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const [bonusPayments, setBonusPayments] = useState<ListPayments>(
    {} as ListPayments
  )
  const { onNotify } = useNotification()
  const { isLoading, isFetching, refetch } = useQuery(
    'BONUSPAYMENTS',
    () =>
      GetBonusPayments({
        page: filters.page,
        size: filters.size,
        ...searchParams
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setBonusPayments(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  useEffect(() => {
    refetch()
  }, [JSON.stringify(searchParams)])

  const onToggleTemplate = (toggle: 'payment', id?: Params) => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle, id)
    } else {
      onOpenToggle(toggle, id)
    }
  }

  const refreshTable = () => refetch()

  const pagination = getPagination(bonusPayments)
  const validateRows = getRows(bonusPayments.entries || [])

  return {
    openToggle,
    onToggleTemplate,
    refreshTable,
    bonusPayments: validateRows,
    isLoading: isLoading || isFetching,
    pagination
  }
}
