import { useEffect } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useNavigation from 'hooks/useNavigation'
import type { EmployeeFilterName } from 'types/jobs'
import type { UseFilter } from 'types/table'
import { removeEmptyKeys } from 'utils'
import { PAGE, SIZE } from 'utils/catalogs'

const initialState = hookstate({
  company_name: '',
  status: [] as Array<UseFilter>,
  role: [] as Array<UseFilter>,
  paginationParams: {
    page: PAGE,
    size: SIZE
  }
})

export default function useFilterEmployeeStore() {
  const { pathname, searchParams, onNavigate } = useNavigation()
  const state = useHookstate(initialState)

  useEffect(() => {
    state.status.set(searchParams.status || [])
    state.role.set(searchParams.role || [])
    state.company_name.set(searchParams.company_name || '')
    state.paginationParams.set({
      page: searchParams.page || PAGE,
      size: searchParams.size || SIZE
    })
  }, [JSON.stringify(searchParams)])

  const onAddFilter = (
    filter: Array<UseFilter> | string,
    name: EmployeeFilterName
  ) => {
    const ids = Array.isArray(filter) ? filter.map(item => item.id) : filter
    onNavigate(pathname, {
      search: {
        ...searchParams,
        [name]: ids
      }
    })
  }

  const onCleanFilters = (name: EmployeeFilterName) => {
    onNavigate(pathname, {
      search: removeEmptyKeys({
        ...searchParams,
        [name]: ''
      })
    })
  }

  return {
    onAddFilter,
    onCleanFilters,
    filters: {
      status: state.status.value,
      company_name: state.company_name.value,
      role: state.role.value,
      ...state.paginationParams.value
    }
  }
}
