import { useState } from 'react'
import { AddPaymentMethod } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { CompanyDetail } from 'types/companies'
import { formatDate, get } from 'utils'
import { KeyTypeCard, cardType } from 'utils/validationCard'
import * as yup from 'yup'

const BILLING = 'billing'
const CARD_NUMBER = /^[0-9]{12,16}$/

export type DatatoSend = {
  label: string
  type: KeyTypeCard
  creditCard: { number: string; expiration: string; verificationNumber: string }
  billingAddress: {
    firstName: string
    lastName: string
    addressLine1: string
    city: string
    state: string
    postalCode: string
    country: string
    emailAddress: string
  }
}

export const initialValues = {
  expires: '',
  cvv: '',
  number: '',
  firstName: '',
  lastName: ''
}

export const validationSchema = yup.object().shape({
  number: yup
    .string()
    .required('Card number is required.')
    .matches(CARD_NUMBER, 'Invalid card number.')
    .min(12, 'Card number must be 16 digits long.')
    .max(16, 'Card number must be 16 digits long.'),
  expires: yup.string().required('Expires date is required.').nullable(),
  cvv: yup
    .string()
    .required('CVV is required.')
    .matches(/^\d{3,4}$/, 'Invalid CVV.'),
  firstName: yup.string().required('Name is required'),
  lastName: yup.string().required('Last name is required')
})

export default function useAddCard(company: CompanyDetail) {
  const [isOpen, setIsOpen] = useState(false)
  const { onNotify } = useNotification()
  const query = useQueryClient()

  const onOpenDialog = () => setIsOpen(true)
  const onCloseDialog = () => setIsOpen(false)

  const { isLoading, mutate } = useMutation(
    (data: DatatoSend) => AddPaymentMethod(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Payment method added successfully'
        })
        onCloseDialog()
        query.invalidateQueries('PAYMENT_METHODS')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onSubmit = (values: typeof initialValues) => {
    const billingAddress = company.addresses?.filter(
      address => address.type === BILLING
    )[0]

    const cardData = cardType(Number(values.number))

    mutate({
      label: cardData.name,
      type: cardData.type,
      creditCard: {
        number: String(values.number),
        expiration: formatDate(values.expires, 'yyyy-MM'),
        verificationNumber: String(values.cvv)
      },
      billingAddress: {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLine1: billingAddress.street,
        city: billingAddress.city || 'New York',
        state: billingAddress.state,
        postalCode: billingAddress.zipcode,
        country: billingAddress.country,
        emailAddress: company.owner.email
      }
    })
  }

  return { onSubmit, onOpenDialog, onCloseDialog, isOpen, isLoading }
}
