import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useToggle from 'components/toggleSidebar/useToggle'
import { EntityName, Step, Field } from 'types/profile'
import useCompanyEntities from './useCompanyEntities'
import useEntities from './useEntities'

type DataToSave = {
  job?: Record<string, string>
  employee?: Record<string, string>
  candidate?: Record<string, string>
}

const initialState = hookstate({
  dataBySteps: { employee: {}, job: {}, candidate: {} },
  selectedField: [] as Field
})

export default function useSettings(entityName?: EntityName) {
  const [saving, setSaving] = useState(false)
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()

  const { onNextStep, onBackStep, onClearSteps } = useStepper()
  const { dataBySteps, selectedField } = useHookstate(initialState)

  const { entity, isLoading: loadinEntity } = useEntities(
    entityName as EntityName
  )

  const { companyEntity, isLoading: loadingCompany } = useCompanyEntities(
    entityName as EntityName
  )

  const onToggleConfig = (toggle: 'addConfig' | 'editConfig') => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
      onClearSteps()
    } else {
      onOpenToggle(toggle)
    }
  }

  const onSaveDataById = ({
    step,
    data
  }: {
    step: Step
    data: Record<string, string>
  }) => {
    dataBySteps.set(prevState => ({
      ...prevState,
      [step]: data
    }))
  }

  const onContinue = ({
    step,
    data
  }: {
    step: Step
    data: Record<string, string>
  }) => {
    setSaving(true)
    setTimeout(() => {
      onSaveDataById({ step, data })
      onNextStep()
      setSaving(false)
    }, 1000)
  }

  const onSaveDataUpdate = (data: DataToSave) =>
    dataBySteps.set(prevState => ({
      ...prevState,
      ...data
    }))

  const onSelectedField = (field: Field) => selectedField.set(field)

  return {
    onToggleConfig,
    onContinue,
    onBackStep,
    onSaveDataUpdate,
    onSelectedField,
    saving,
    companyEntity,
    entity,
    openConfig: openToggle,
    dataBySteps: dataBySteps.value,
    selectedField: selectedField.value,
    isLoading: loadingCompany || loadinEntity
  }
}
