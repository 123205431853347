import { hookstate, useHookstate } from '@hookstate/core'
import { GridSelectionModel } from '@mui/x-data-grid'
import { DeleteBonusTemplates } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'

const initialState = hookstate({
  isOpenDialog: false,
  selectedTemplateCheck: [] as GridSelectionModel
})
export default function useDeleteTemplates() {
  const { onNotify } = useNotification()
  const { isOpenDialog, selectedTemplateCheck } = useHookstate(initialState)

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { ids: GridSelectionModel }) => DeleteBonusTemplates(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Template deleted successfully'
        })
        query.invalidateQueries('BONUSTEMPLATES')
        onCloseDialog()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        const errorMessageId = get(error, 'response.data.errors.detail[0].id')
        onNotify({
          severity: 'error',
          message: JSON.stringify(`template-${errorMessageId}: ${errorMessage}`)
        })
      }
    }
  )

  const onCloseDialog = () => isOpenDialog.set(false)

  const onOpenDialog = () => {
    if (selectedTemplateCheck.length === 0) {
      return onNotify({
        message: 'Select one or more items to delete.',
        severity: 'info'
      })
    }
    isOpenDialog.set(true)
  }

  const onSelectedTemplates = (templates: GridSelectionModel) =>
    selectedTemplateCheck.set(templates)

  const onSubmit = () =>
    mutate({
      ids: selectedTemplateCheck.get() as GridSelectionModel
    })

  return {
    selectedTemplateCheck: selectedTemplateCheck.get() as GridSelectionModel,
    isOpenDialog: isOpenDialog.get(),
    isLoading,
    onSubmit,
    onOpenDialog,
    onCloseDialog,
    onSelectedTemplates
  }
}
