import { Button, Container, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import useNavigation from 'hooks/useNavigation'

const BackgroundImage = styled('img')`
  width: 80%;
  overflow: hidden;
`

export default function ErrorMessage() {
  const { onNavigate } = useNavigation()
  const handleRedirect = () => {
    onNavigate('/')
  }

  return (
    <Container
      sx={{
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <BackgroundImage src="images/backgroundImage.png" alt="" />
      <Stack
        position="absolute"
        top="25%"
        left="0"
        right="0"
        bottom="0"
        margin="0 auto"
      >
        <Typography variant="h2" paddingBottom={4}>
          This page doesn’t exist
        </Typography>
        <Button
          variant="contained"
          onClick={handleRedirect}
          sx={{
            width: '30%',
            margin: '0 auto'
          }}
        >
          Go Home
        </Button>
      </Stack>
    </Container>
  )
}
