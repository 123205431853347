import { useState } from 'react'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { get } from 'utils'
import { FIRST_DAY } from 'utils/catalogs'
import { formatDate } from 'utils/index'
import { GetOverviewDataE } from '../../../api/index'
import { Overview } from '../Types/DashboardTypes'

export default function useOverviewDataE() {
  const { user } = useAuth()

  const date = Date.now()

  const { onNotify } = useNotification()
  const [dataOverview, setDataOverview] = useState<Overview>({} as Overview)
  const [initialDate, setInitialDateOverview] = useState<any>(
    formatDate(FIRST_DAY, 'yyyy-MM-dd')
  )
  const [endDate, setEndDateOverview] = useState<any>(
    formatDate(new Date(date), 'yyyy-MM-dd')
  )

  const { isLoading, refetch } = useQuery(
    'GET_OVERVIEW_DATA',
    () =>
      GetOverviewDataE(
        user.id,
        formatDate(new Date(initialDate), 'yyyy-MM-dd'),
        formatDate(new Date(endDate), 'yyyy-MM-dd')
      ),
    {
      onSuccess: data => {
        setDataOverview(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onGetDataOverview = () => refetch()
  return {
    isLoading: isLoading,
    dataOverview,
    initialDate,
    endDate,
    open,
    setInitialDateOverview,
    setEndDateOverview,
    onGetDataOverview
  }
}
