import { Breadcrumbs as MUIBreadcrumbs, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'

export default function Breadcrumbs({
  children,
  routes
}: {
  children: React.ReactNode
  routes: { route: string; label: string; onClick?: () => void }[]
}) {
  const navigate = useNavigate()

  const handleClick = (event: any) => {
    event.preventDefault()
    navigate(-1)
  }

  const breadcrumbs = [
    ...routes.map(({ label, onClick }, index) => {
      return (
        <Typography
          component="div"
          variant="body2"
          color={COLORS.gray2}
          key={index + 1}
          onClick={event => (onClick ? onClick() : handleClick(event))}
          sx={{ cursor: 'pointer' }}
        >
          {label}
        </Typography>
      )
    }),
    <Typography key={routes.length} color="text.primary">
      {children}
    </Typography>
  ]

  return (
    <Stack spacing={2} marginBottom={4}>
      <MUIBreadcrumbs
        separator={<Typography color={COLORS.honey3}>/</Typography>}
        aria-label="breadcrumb"
        sx={{ color: COLORS.gray1, fontSize: '14px' }}
      >
        {breadcrumbs}
      </MUIBreadcrumbs>
    </Stack>
  )
}
