import { useState, useEffect } from 'react'
import { GetPublicJobsByCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { JobBoard } from 'types/jobs'
import { get, getPagination } from 'utils'
import useFilterBonusTemplatesStore from '../../bonus/templates/hooks/useFilterBonusTemplatesStore'
export default function useGetPublicJobsByCompany() {
  const { params } = useNavigation()
  const { onNotify } = useNotification()
  const { filters } = useFilterBonusTemplatesStore()
  const [jobs, setJobs] = useState({} as JobBoard)
  const { isLoading, isFetching, refetch } = useQuery(
    'JOBS_BY_COMPANY',
    () =>
      GetPublicJobsByCompany(Number(params.id), {
        status: 'published',
        page: filters.page,
        size: filters.size
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setJobs(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )
  useEffect(() => {
    refetch()
  }, [JSON.stringify(filters)])
  const pagination = getPagination(jobs)

  return {
    isLoading: isFetching || isLoading,
    jobs,
    pagination
  }
}
