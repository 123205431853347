import { useState } from 'react'
import { GridSelectionModel } from '@mui/x-data-grid'
import { DeleteEmployees, ResendEmployeeInvite } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'

type ActionType = 'resend' | 'delete'
export default function useResendDeleteEmployee(callback: () => void) {
  const [isChecked, setIsChecked] = useState(false)

  const { isSending, onResendInvite } = useResendInvite(callback)
  const { isDeleting, onDeleteEmployee } = useDeleteEmployee(callback)

  const onChecked = (_: any, checked: boolean) => setIsChecked(checked)

  const onSubmit = ({
    employees,
    actionType
  }: {
    employees: GridSelectionModel
    actionType: ActionType
  }) => {
    if (actionType === 'resend') {
      return onResendInvite(employees)
    }
    onDeleteEmployee(employees)
  }

  return { isChecked, isLoading: isDeleting || isSending, onChecked, onSubmit }
}

const useResendInvite = (callback: () => void) => {
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { employees: GridSelectionModel }) => ResendEmployeeInvite(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Invitation sent successfully'
        })
        callback()
        query.invalidateQueries('EMPLOYEE')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        const errorMessageId = get(error, 'response.data.errors.detail[0].id')
        onNotify({
          severity: 'error',
          message: JSON.stringify(`employee-${errorMessageId}: ${errorMessage}`)
        })
      }
    }
  )

  const onResendInvite = (employees: GridSelectionModel) => {
    mutate({ employees })
  }

  return { isSending: isLoading, onResendInvite }
}

const useDeleteEmployee = (callback: () => void) => {
  const { onNotify } = useNotification()

  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: { employees: GridSelectionModel }) => DeleteEmployees(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Employee deleted successfully'
        })
        callback()
        query.invalidateQueries('EMPLOYEE')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({
          severity: 'error',
          message: errorMessage
        })
      }
    }
  )

  const onDeleteEmployee = (employees: GridSelectionModel) => {
    mutate({ employees })
  }

  return { isDeleting: isLoading, onDeleteEmployee }
}
