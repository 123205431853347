import { IconButtonProps, IconButton } from '@mui/material'
import { COLORS } from 'styles/colors'
export default function FaceBook({ ...props }: IconButtonProps) {
  const defaultColor = props.disabled ? COLORS.gray8 : COLORS.main
  return (
    <IconButton {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 3C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H11.6211H14.4141H19C20.103 21 21 20.103 21 19V5C21 3.897 20.103 3 19 3H5ZM5 5H19L19.002 19H14.4141V15.0352H16.7793L17.1309 12.3105H14.4297V10.5742C14.4297 9.78622 14.6493 9.25391 15.7793 9.25391H17.207V6.82227C16.512 6.75127 15.8142 6.71675 15.1152 6.71875C13.0412 6.71875 11.6211 7.98459 11.6211 10.3086V12.3145H9.27734V15.0391H11.6211V19H5V5Z"
          fill={props.color || defaultColor}
        />
      </svg>
    </IconButton>
  )
}
