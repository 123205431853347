import { useState } from 'react'
import { getBonusPaymentById } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import { BonusPaymentDetails } from 'types/bonusPayments'
import { get } from 'utils'

export default function useBonusPaymentsDetail() {
  const [bonusPayment, setBonusPayment] = useState<BonusPaymentDetails>(
    {} as BonusPaymentDetails
  )
  const { onNotify } = useNotification()
  const { searchParams } = useNavigation()
  const { isLoading, refetch, isFetching } = useQuery(
    `BONUSPAYMENT`,
    () => getBonusPaymentById(searchParams.id),
    {
      enabled: Boolean(searchParams.id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setBonusPayment(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const getBonusPayment = () => refetch()

  return {
    bonusPayment,
    refetch,
    getBonusPayment,
    isFetching,
    isLoading: isLoading
  }
}
