import { Stack, Pagination as MUIPagination } from '@mui/material'
type CustomPaginationModel = {
  handleChangePage: (newPage: number) => void
  page: number
  totalPages: number
}
export const Pagination = ({
  handleChangePage,
  page,
  totalPages
}: CustomPaginationModel) => {
  return (
    <Stack alignItems="center" sx={{ width: '100%', margin: 'auto' }}>
      <MUIPagination
        color="primary"
        count={totalPages}
        page={page + 1}
        onChange={(_, value) => {
          handleChangePage(value - 1)
        }}
      />
    </Stack>
  )
}
