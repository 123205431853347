import { hookstate, useHookstate } from '@hookstate/core'

const initialState = hookstate({
  activeStep: 0,
  stepComplete: new Set()
})

export default function useStepper() {
  const { activeStep, stepComplete } = useHookstate(initialState)

  const handleStep = (type: 'next' | 'back') => {
    const action = type === 'next' ? 'add' : 'delete'
    const currentComplete = new Set(stepComplete.value)
    const currentStep =
      type === 'next' ? activeStep.value : activeStep.value - 1

    if (type === 'next') {
      activeStep.set(prevActiveStep => prevActiveStep + 1)
    } else {
      activeStep.set(prevActiveStep => prevActiveStep - 1)
    }

    currentComplete[action](currentStep)
    stepComplete.set(currentComplete)
  }

  const onNextStep = () => handleStep('next')
  const onBackStep = () => handleStep('back')

  const onClearSteps = () => {
    activeStep.set(0)
    stepComplete.set(new Set())
  }

  const onStep = (step: number) => () => {
    activeStep.set(step)
  }

  return {
    onStep,
    onNextStep,
    onBackStep,
    handleStep,
    onClearSteps,
    activeStep: activeStep.value,
    stepComplete: Array.from(stepComplete.value)
  }
}
