import * as React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Stack,
  Typography,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@mui/material'
import DetailInformation from 'app/profile/personalInfo/detail'
import { COLORS } from 'styles/colors'
import ChangePassword from '../changePassword'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      width={{ xs: '100%', sm: 'calc(100% - 240px)' }}
      padding={{ xs: 0, sm: 2 }}
      marginTop={{ xs: 2, sm: 0 }}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export default function PersonalInformation() {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box paddingBottom={4}>
      <Stack flexDirection="row" alignItems="center" marginY="30px">
        <AccountCircleIcon
          htmlColor="black"
          sx={{ width: '20px', paddingRight: '18px' }}
        />
        <Typography variant="h2">My Profile</Typography>
      </Stack>
      <Stack
        flexGrow={1}
        bgcolor="background.paper"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Tabs
          orientation={isDesktop ? 'vertical' : 'horizontal'}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              background: 'transparent'
            }
          }}
          aria-label="Vertical tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            {...a11yProps(0)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                Personal Information
              </Typography>
            }
            icon={
              isDesktop ? (
                <KeyboardArrowRightIcon htmlColor={COLORS.black} />
              ) : (
                ''
              )
            }
            iconPosition="end"
            sx={{
              width: { xs: '50%', sm: '240px' },
              justifyContent: 'space-between',
              background: value === 0 ? '#FFDA8D' : ''
            }}
          />
          <Tab
            {...a11yProps(1)}
            label={
              <Typography
                textTransform="capitalize"
                color={value === 0 ? COLORS.black : COLORS.gray1}
              >
                Change Password
              </Typography>
            }
            icon={
              isDesktop ? (
                <KeyboardArrowRightIcon htmlColor={COLORS.black} />
              ) : (
                ''
              )
            }
            iconPosition="end"
            sx={{
              width: { xs: '50%', sm: '240px' },
              justifyContent: 'space-between',
              background: value === 1 ? '#FFDA8D' : ''
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <DetailInformation />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangePassword />
        </TabPanel>
      </Stack>
    </Box>
  )
}
