import { hookstate, useHookstate } from '@hookstate/core'
import { GridSelectionModel } from '@mui/x-data-grid'
import { ResendCompanyInvite } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'

const initialState = hookstate({
  isOpenDialog: false,
  selectedReferralCheck: [] as GridSelectionModel
})
export default function useDeleteReferral() {
  const { onNotify } = useNotification()
  const { isOpenDialog, selectedReferralCheck } = useHookstate(initialState)

  // Add real implementation
  const { isLoading, mutate } = useMutation(
    (data: { companies: GridSelectionModel }) => ResendCompanyInvite(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Referral deleted successfully'
        })
        onCloseDialog()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        const errorMessageId = get(error, 'response.data.errors.detail[0].id')
        onNotify({
          severity: 'error',
          message: JSON.stringify(`company-${errorMessageId}: ${errorMessage}`)
        })
      }
    }
  )

  const onCloseDialog = () => isOpenDialog.set(false)

  const onOpenDialog = () => {
    if (selectedReferralCheck.length === 0) {
      return onNotify({
        message: 'Select one or more items to delete.',
        severity: 'info'
      })
    }
    isOpenDialog.set(true)
  }

  const onSelectedReferral = (referral: GridSelectionModel) =>
    selectedReferralCheck.set(referral)

  const onSubmit = () => {
    console.log('>>>>>>>>>>>', mutate)
    // mutate({ referrals: selectedReferralCheck.get() })
  }

  return {
    selectedReferralCheck: selectedReferralCheck.get() as GridSelectionModel,
    isOpenDialog: isOpenDialog.get(),
    isLoading,
    onSubmit,
    onOpenDialog,
    onCloseDialog,
    onSelectedReferral
  }
}
