import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import Breadcrumbs from 'components/breadcrums'
import ButtonGroup from 'components/buttonGroup/index'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from '../../../styles/colors'
import { STATUS_COLOR } from '../../educationDetails/constants/index'
import { StatusEmployeesTemplateBulkActions } from '../Types/Employees'
import useUpdateStatus from '../hooks/useUpdateStatus'
import useShowEmployee from './hooks/useShowEmployee'

const users = ['company_owner', 'rewards_manager']

export const EmployeeDetailHeader = () => {
  const { userRole } = useAuth()
  const { onNavigate } = useNavigation()
  const { onApply } = useUpdateStatus()
  const [statusColor, setStatusColor] = useState()
  const { employeeInformation } = useShowEmployee()

  const handleChangeStatus = (e: any) => {
    const data: StatusEmployeesTemplateBulkActions = {
      employees: [employeeInformation.id],
      status: e.label
    }
    setStatusColor(e.label)
    onApply(data)
  }

  const handleBack = () => {
    onNavigate('/employees')
  }

  return (
    <>
      <Stack
        width="100%"
        justifyContent={{ xs: 'initial', md: 'space-between' }}
        marginY={3}
        direction={{ xs: 'column', md: 'row' }}
      >
        <Stack
          alignItems="center"
          width={{ xs: '100%', md: '70%' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Typography
            variant="h3"
            fontSize={{ xs: '18px', md: '24px' }}
            bgcolor={COLORS.honey3}
            height="40px"
            paddingX={3}
            lineHeight="40px"
            borderRadius="6px"
            display={{ xs: 'none', md: 'block' }}
          >
            {employeeInformation.id}
          </Typography>
          <Stack
            width={{ xs: '100%' }}
            marginLeft={{ xs: 0, md: 2 }}
            marginBottom={{ xs: 2, md: 0 }}
          >
            <Typography variant="h2">
              {employeeInformation.first_name}
            </Typography>
          </Stack>
        </Stack>
        {users.includes(userRole) && (
          <Stack
            direction="row"
            alignItems="center"
            marginBottom={2}
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            width={{ xs: '100%', md: '30%' }}
          >
            <Typography variant="h6" marginRight={1}>
              Employee Status
            </Typography>
            <ButtonGroup
              isDisabled={employeeInformation.status === 'pending'}
              onChange={handleChangeStatus}
              selectedValue={{
                id: employeeInformation.status,
                label: employeeInformation.status
              }}
              bgcolor={
                STATUS_COLOR[
                  statusColor || (employeeInformation.status as 'active')
                ]
              }
              options={[
                { id: 1, label: 'active' },
                { id: 2, label: 'inactive' }
              ]}
            />
          </Stack>
        )}
      </Stack>
      {users.includes(userRole) && (
        <Breadcrumbs
          routes={[
            { route: '/employees', label: 'Employees', onClick: handleBack }
          ]}
        >
          {employeeInformation.first_name} ID:
          {employeeInformation.id}
        </Breadcrumbs>
      )}
    </>
  )
}
