import {
  Typography,
  Box,
  Stack,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import * as yup from 'yup'
import useUpdateVisibility from './hooks/useUpdateVisibility'
import useVisibilityDetails from './hooks/useVisibilityDetails'
export default function Visibility() {
  const { onUpdateVisibility } = useUpdateVisibility()
  const { visibility, isFetching, isLoading } = useVisibilityDetails()
  const schema = yup
    .object({
      external_employees: yup.boolean(),
      internal_employees: yup.boolean()
    })
    .test('myCustomTest', {}, obj => {
      if (obj.external_employees || obj.internal_employees) {
        return true
      }
      return new yup.ValidationError(
        'Please select at least one option',
        null,
        'internal_employees'
      )
    })
  const initialValues = {
    external_employees: visibility === 'external' || visibility === 'all',
    internal_employees: visibility === 'internal' || visibility === 'all'
  }
  if (!isLoading || isFetching) {
    return (
      <>
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={values => {
            const { internal_employees, external_employees } = values
            onUpdateVisibility({ internal_employees, external_employees })
          }}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Stack
                component={Form}
                justifyContent="space-between"
                height="calc(100vh - 168px)"
                padding="32px 16px 0 16px"
              >
                <Box
                  component={Paper}
                  elevation={4}
                  sx={{
                    padding: '16px',
                    height: '50%'
                  }}
                >
                  <Stack padding="6px" flexDirection="column">
                    <Typography variant="h3">Job Visibility</Typography>
                    <Box>
                      <Typography
                        variant="h5"
                        marginBottom={2}
                        marginTop={2}
                        color={COLORS.gray2}
                      >
                        Choose which type of employee will be able to see this
                        job on their job board
                      </Typography>
                      <FormControlLabel
                        name="internal_employees"
                        control={<Checkbox />}
                        label="Internal employees"
                        checked={values.internal_employees}
                        onChange={(e, checked) => {
                          setFieldValue('internal_employees', checked)
                        }}
                        sx={{ width: '100%' }}
                        componentsProps={{
                          typography: { color: COLORS.gray1 }
                        }}
                      />
                      <Typography variant="caption" color={COLORS.red1}>
                        {errors.internal_employees}
                      </Typography>
                      <FormControlLabel
                        name="external_employees"
                        checked={values.external_employees}
                        control={<Checkbox />}
                        label="Outsourced employees"
                        onChange={(e, checked) => {
                          setFieldValue('external_employees', checked)
                        }}
                        sx={{ width: '100%' }}
                        componentsProps={{
                          typography: { color: COLORS.gray1 }
                        }}
                      />
                      <Typography variant="caption" color={COLORS.red1}>
                        {errors.external_employees}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>

                <Stack>
                  <Button fullWidth variant="contained" type="submit">
                    Save
                  </Button>
                </Stack>
              </Stack>
            )
          }}
        </Formik>
      </>
    )
  }
  return <h1>1</h1>
}
