import { IconButtonProps, IconButton } from '@mui/material'
import { COLORS } from 'styles/colors'

export default function Linkedin({ ...props }: IconButtonProps) {
  const defaultColor = props.disabled ? COLORS.gray8 : COLORS.main
  return (
    <IconButton {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 3C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V5C21 3.895 20.105 3 19 3H5ZM5 5H19V19H5V5ZM7.7793 6.31641C6.9223 6.31641 6.4082 6.83158 6.4082 7.51758C6.4082 8.20358 6.92236 8.7168 7.69336 8.7168C8.55036 8.7168 9.06445 8.20358 9.06445 7.51758C9.06445 6.83158 8.5503 6.31641 7.7793 6.31641ZM6.47656 10V17H9V10H6.47656ZM11.082 10V17H13.6055V13.1738C13.6055 12.0348 14.4181 11.8711 14.6621 11.8711C14.9061 11.8711 15.5586 12.1158 15.5586 13.1738V17H18V13.1738C18 10.9768 17.0237 10 15.8027 10C14.5817 10 13.9305 10.4066 13.6055 10.9766V10H11.082Z"
          fill={props.color || defaultColor}
        />
      </svg>
    </IconButton>
  )
}
