import EmailIcon from '@mui/icons-material/Email'
import {
  Box,
  Stack,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Divider
} from '@mui/material'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import { FOOTER_ITEMS } from 'utils/catalogs'

export default function Footer() {
  const { onNavigate } = useNavigation()
  const handleRedirect = (route: string) => onNavigate(route)
  return (
    <Box
      width="100%"
      position="absolute"
      height="64px"
      bottom={0}
      bgcolor={COLORS.white}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexDirection: { md: 'row' }
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{ padding: { xs: 0, md: 1 } }}
        >
          <Typography fontSize="12px">Help Center: </Typography>
          <EmailIcon color="primary" sx={{ marginX: 1 }} />
          <Link
            href="mailto:help@beegopp.com"
            underline="hover"
            sx={{ fontSize: 12 }}
          >
            help@beegopp.com
          </Link>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ paddingLeft: 1, height: 32, color: COLORS.blue1 }}
          />
        </Stack>
        {FOOTER_ITEMS.map(({ id, label, showDivider, route }) => (
          <ListItem key={id} sx={{ width: 'auto', padding: { xs: 0, md: 1 } }}>
            <ListItemText
              disableTypography
              onClick={() => handleRedirect(route)}
              sx={{ cursor: 'pointer' }}
            >
              <Typography fontSize="12px">{label}</Typography>
            </ListItemText>
            {showDivider && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ paddingLeft: 1, color: COLORS.blue1 }}
              />
            )}
          </ListItem>
        ))}
      </Stack>
    </Box>
  )
}
