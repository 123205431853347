import { UpdateBonusTemplate } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateBonusTemplateParams } from 'types/bonusTemplate'
import { get } from 'utils'
import useTemplate from '../useTemplate'

interface UpdateBonusTemplateParameters {
  data: UpdateBonusTemplateParams
  id: number
}

export default function useAddUpdateConfig() {
  const { onToggleTemplate } = useTemplate()

  const query = useQueryClient()
  const { onNotify } = useNotification()

  const onUpdateBonusTemplate = async (
    bonusTemplate: UpdateBonusTemplateParams,
    id: number
  ) => {
    mutate({ data: bonusTemplate, id })
  }
  const { mutate, isLoading } = useMutation(
    (data: UpdateBonusTemplateParameters) =>
      UpdateBonusTemplate(data.data, data.id),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Bonus template updated successfully'
        })
        query.invalidateQueries('JOB_DETAIL')
        query.invalidateQueries('BONUSTEMPLATES')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onToggleTemplate('template')
      }
    }
  )

  return {
    onUpdateBonusTemplate,
    isLoading
  }
}
