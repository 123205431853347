import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'

import { get } from 'utils'
import { GetPaymentsReady } from '../../../api/index'
import { ReadyToPay } from '../../employees/EmployeeDetail/Types/ReferralByEmployee'

const initialState = hookstate({
  edit: false
})

export default function useReadyToPay() {
  const { user } = useAuth()
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()

  const [readyToPayment, setReadyToPayment] = useState<ReadyToPay>({
    entries: [] as ReadyToPay['entries']
  } as ReadyToPay)

  const { isLoading, isFetching } = useQuery(
    'READY_PAYMENTS_BY_USER',
    () => GetPaymentsReady(user.id),
    {
      onSuccess: data => {
        setReadyToPayment(data.data)
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail',
          'An error occurred'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onHandleOpenEdit = () => {
    edit.set(true)
  }

  const onHandleCloseEdit = () => {
    edit.set(false)
  }

  return {
    isLoading: isLoading || isFetching,
    readyToPayment,
    open,
    edit: edit.value,
    onHandleOpenEdit,
    onHandleCloseEdit
  }
}
