import { UpdateJobsVisibility } from 'api'
import useTemplate from 'app/educationDetails/sideDetail/bonus/useTemplate'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useMutation, useQueryClient } from 'react-query'

import { get } from 'utils'

export default function useUpdateVisibility() {
  const query = useQueryClient()
  const { onToggleTemplate } = useTemplate()
  const { onNotify } = useNotification()
  const { params } = useNavigation()
  const { mutate } = useMutation(
    (data: any) => UpdateJobsVisibility(data.data, Number(params.id)),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Visibility updated successfully'
        })
        query.invalidateQueries('JOB_DETAIL')
        onToggleTemplate('visibility')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )
  const onUpdateVisibility = async ({
    external_employees,
    internal_employees
  }: {
    external_employees: boolean
    internal_employees: boolean
  }) => {
    const visibility =
      external_employees && internal_employees
        ? 'all'
        : internal_employees
        ? 'internal'
        : 'external'
    mutate({ data: { employee_visibility: visibility } })
  }

  return {
    onUpdateVisibility,
    onToggleTemplate
  }
}
