import useAddBonusConfig from 'app/bonus/templates/hooks/useAddBonusConfig'
import useBonusTemplateDetails from 'app/bonus/templates/hooks/useBonusTemplateDetails'
import useDeleteBonusConfig from 'app/bonus/templates/hooks/useDeleteBonusConfig'
import useUpdateBonusConfig from 'app/bonus/templates/hooks/useUpdateBonusConfig'
import { FieldArrayRenderProps } from 'formik'
import useNavigation from 'hooks/useNavigation'
import {
  BonusConfig,
  BonusTemplateDetails,
  UpdateBonusTemplateParams
} from 'types/bonusTemplate'
import * as yup from 'yup'
import useAddUpdateConfig from '../hooks/useAddUpdateConfig'

export const validationSchema = yup.object({
  entries: yup.object({
    description: yup.string().required('Description is required'),
    bonus_config: yup.array().of(
      yup.object().shape({
        amount: yup
          .number()
          .max(2000, 'Max amount is 2000')
          .typeError('Amount must be a number')
          .required('Amount is required'),
        days: yup.number().min(0).required('Days is required')
      })
    )
  })
})

const getInitialValues = (bonusTemplate: BonusTemplateDetails) => {
  return {
    entries: {
      description: bonusTemplate?.entries?.description || '',
      is_for_referrer: bonusTemplate?.entries?.is_for_referrer || false,
      is_for_referral: bonusTemplate?.entries?.is_for_referral || false,
      bonus_config:
        bonusTemplate?.entries?.bonus_config || ([] as BonusConfig[])
    }
  }
}

export default function useAddUpdateBonus() {
  const { searchParams } = useNavigation()

  const { onAddBonusConfig } = useAddBonusConfig()
  const { onUpdateBonusConfig } = useUpdateBonusConfig()
  const { onDeleteBonusConfig } = useDeleteBonusConfig()
  const { isLoading, onUpdateBonusTemplate } = useAddUpdateConfig()

  const { bonusTemplate } = useBonusTemplateDetails(
    Number(searchParams.bonusId)
  )

  const addBonusConfigToArray = (
    arrayHelpers: FieldArrayRenderProps,
    type: 'referral' | 'referrer',
    isNew?: boolean
  ) => {
    const newBonusTemplate: BonusConfig = {
      amount: '',
      days: '',
      type: type,
      new: isNew
    }
    arrayHelpers.push(newBonusTemplate)
  }

  const onDeleteBonusConfigAction = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    index: number
  ) => {
    setFieldValue(`entries.bonus_config[${index}].deleted`, true)
  }

  const initialValues = getInitialValues(bonusTemplate)

  const onSubmit = (values: typeof initialValues) => {
    const updatedBonusTemplate: UpdateBonusTemplateParams = {
      is_template: false,
      is_for_referrer: values.entries.is_for_referrer,
      is_for_referral: values.entries.is_for_referral,
      description: values.entries.description,
      total_amount_referral: Number(
        bonusTemplate.entries.total_amount_referral
      ),
      total_amount_referrer: Number(
        bonusTemplate.entries.total_amount_referrer
      ),
      type: 'flat rate'
    }
    updateBonusConfig(values)
    addBonusConfig(values)
    deleteBonusConfig(values)
    onUpdateBonusTemplate(
      updatedBonusTemplate,
      Number(bonusTemplate.entries.id)
    )
  }

  const addBonusConfig = async (values: typeof initialValues) => {
    for (let i = 0; i < values.entries.bonus_config.length; i++) {
      if (
        values.entries.bonus_config[i].new &&
        !values.entries.bonus_config[i].deleted
      ) {
        onAddBonusConfig(
          values.entries.bonus_config[i],
          Number(searchParams.bonusId)
        )
      }
    }
  }

  const updateBonusConfig = async (values: typeof initialValues) => {
    for (let i = 0; i < values.entries.bonus_config.length; i++) {
      if (
        values.entries.bonus_config[i].edited &&
        !values.entries.bonus_config[i].deleted
      ) {
        onUpdateBonusConfig(
          values.entries.bonus_config[i],
          Number(searchParams.bonusId),
          Number(values.entries.bonus_config[i].id)
        )
      }
    }
  }

  const deleteBonusConfig = async (values: typeof initialValues) => {
    for (let i = 0; i < values.entries.bonus_config.length; i++) {
      if (
        values.entries.bonus_config[i].deleted &&
        !values.entries.bonus_config[i].new
      ) {
        onDeleteBonusConfig(
          Number(searchParams.bonusId),
          Number(values.entries.bonus_config[i].id)
        )
      }
    }
  }

  return {
    initialValues,
    isLoading,
    onSubmit,
    addBonusConfigToArray,
    onDeleteBonusConfigAction
  }
}
