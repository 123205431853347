import { hookstate, useHookstate } from '@hookstate/core'
import useStepper from 'components/stepper/useStepper'
import useToggle from 'components/toggleSidebar/useToggle'
import * as yup from 'yup'
import useInvitation from './useInvitation'

type Values = Referral & { via: string }

type Referral = {
  name: string
  lastName: string
  phone: string
  email: string
}

export type Referrals = {
  message: string
  subject: string
  via: string
  referred: Array<Referral>
}

export const validationSchema = yup.object({
  via: yup.string().required('Required'),
  name: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().when('via', {
    is: (via: string) => via === 'email' || via === 'both',
    then: yup.string().required('Email is required')
  }),
  phone: yup.string().when('via', {
    is: (via: string) => via === 'sms' || via === 'both',
    then: yup.string().required('Phone is required')
  })
})

const initialState = hookstate({
  referrals: {} as Referrals
})

export default function useRefer() {
  const { referrals } = useHookstate(initialState)
  const { openToggle, onOpenToggle, onCloseToggle } = useToggle()
  const { onNextStep, onBackStep } = useStepper()

  const onToggleReferBy = (toggle: 'referBy') => {
    if (openToggle[toggle]) {
      onCloseToggle(toggle)
    } else {
      onOpenToggle(toggle)
    }
  }

  const { onMutation, isLoading } = useInvitation(onToggleReferBy)

  const onRefferals = (values: Partial<Referrals>) => {
    referrals.set(prevState => ({
      ...prevState,
      ...values
    }))
  }

  const onSubmit = (values: { message: string; subject: string }) => {
    const referred = JSON.parse(JSON.stringify(referrals.value.referred))
    onMutation({ ...referrals.value, referred, ...values })
  }

  const onValidateReferrals = ({
    via,
    name,
    lastName,
    phone,
    email
  }: Values) => {
    onRefferals({
      via,
      referred: [
        {
          name,
          lastName,
          phone: `+${phone}`,
          email: email.toLocaleLowerCase()
        }
      ]
    })
    onNextStep()
  }

  return {
    referrals: referrals.value,
    openReferBy: openToggle,
    isLoading,
    onValidateReferrals,
    onToggleReferBy,
    onNextStep,
    onBackStep,
    onRefferals,
    onSubmit
  }
}
