import { Stack, Typography, BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { EmptyLogo } from 'app/layout/sidebar/styles'

type EmptyPageProps = {
  logo: string
  message: string | React.ReactNode
  children?: React.ReactNode
  boxProps?: BoxProps
}

export const EmptyPage = ({
  logo,
  message,
  children,
  boxProps
}: EmptyPageProps) => {
  const renderMessage =
    typeof message === 'string' ? (
      <Typography
        variant="h3"
        alignItems={'center'}
        textAlign={'center'}
        display={'flex'}
        position={'relative'}
      >
        {message}
      </Typography>
    ) : (
      message
    )

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <EmptyLogo>
        <img src={logo} alt="logo" />
      </EmptyLogo>
      {renderMessage}
      <Box {...boxProps}>{children}</Box>
    </Stack>
  )
}
