import { Box, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Spinner from 'components/spinner'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import useNotifications from './hooks/useNotifications'
import useToggleNotification from './hooks/useToggleNotification'
import Notification from './notification'

export default function Notifications() {
  const { isOpen, onCloseNotification } = useToggleNotification()
  const { isLoading, notifications, onViewedNotification } =
    useNotifications(isOpen)

  return (
    <Drawer
      anchor="right"
      elevation={5}
      onClose={onCloseNotification}
      open={Boolean(isOpen)}
      sx={{ backdropFilter: 'blur(6px)' }}
      PaperProps={{ sx: { border: 0 } }}
    >
      <Box
        role="presentation"
        sx={{
          width: isOpen ? { xs: '80vw', md: '40vw' } : 0,
          height: '100%'
        }}
      >
        <Box marginTop={3}>
          <Box
            bgcolor={COLORS.white}
            height="calc(100vh - 220px)"
            marginTop={2}
          >
            <Box height="100%">
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingX={2}
                paddingY={2}
              >
                <Typography variant="h4" fontSize={20} color={COLORS.gray1}>
                  Notifications
                </Typography>
              </Stack>
              <Box
                overflow="auto"
                sx={{
                  ...StylesScrollBar
                }}
              >
                {isLoading ? (
                  <Stack
                    height="100%"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner />
                  </Stack>
                ) : (
                  <>
                    {notifications.length > 0 ? (
                      <Notification
                        onClick={onViewedNotification}
                        notifications={notifications}
                      />
                    ) : (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100vh - 8em)"
                      >
                        <img src="/images/bell.png" alt="notify" />
                        <Typography
                          variant="h2"
                          color={COLORS.blue9}
                          marginTop={8}
                        >
                          No notifications yet
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
