import BoardHeader from './BoardHeader'
import BoardTable from './BoardTable'

export default function BoardConfig() {
  return (
    <>
      <BoardHeader />
      <BoardTable />
    </>
  )
}
