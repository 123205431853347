import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Button,
  TextField as MUITextField,
  Typography
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TextField from 'components/TextField'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { CARDS } from 'utils/catalogs'
import { cardType } from 'utils/validationCard'
import { initialValues, validationSchema } from './useAddCard'

export default function AddmethodDialog({
  isLoading,
  open,
  onClose,
  onSubmit
}: {
  isLoading: boolean
  open: boolean
  onClose: () => void
  onSubmit: (values: typeof initialValues) => void
}) {
  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle
        fontSize={20}
        fontWeight={500}
        marginBottom={4}
        textAlign="center"
        color={COLORS.gray1}
      >
        Register a new Credit Card
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" paddingBottom={4}>
          Please enter your information
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => {
            const cardData = cardType(Number(values.number))
            const CardIcon = CARDS[cardData.type || 'default']
            return (
              <Form>
                <Stack direction="row" gap={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="firstName"
                    variant="standard"
                    onChange={handleChange('firstName')}
                    sx={{
                      marginBottom: 4
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    variant="standard"
                    onChange={handleChange('lastName')}
                    sx={{
                      marginBottom: 4
                    }}
                  />
                </Stack>
                <TextField
                  fullWidth
                  label="Number"
                  type="number"
                  name="number"
                  variant="standard"
                  onChange={handleChange('number')}
                  InputProps={{
                    endAdornment: <CardIcon />
                  }}
                  sx={{
                    marginBottom: 4
                  }}
                />
                <Stack direction="row" gap={6}>
                  <DesktopDatePicker
                    label="Expires"
                    inputFormat="MM/yy"
                    value={values.expires}
                    onChange={(newValue = '') => {
                      setFieldValue('expires', newValue)
                    }}
                    renderInput={params => (
                      <MUITextField
                        fullWidth
                        error={touched && Boolean(errors.expires)}
                        helperText={touched && errors.expires}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                  <TextField
                    fullWidth
                    name="cvv"
                    label="CVV"
                    type="password"
                    variant="standard"
                    inputProps={{
                      maxLength: 4
                    }}
                    onChange={handleChange('cvv')}
                    sx={{
                      marginBottom: 4
                    }}
                  />
                </Stack>
                <Stack gap={2} direction="row" marginTop={4}>
                  <Button fullWidth onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Confirm
                  </LoadingButton>
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
