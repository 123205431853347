import { useState } from 'react'
import { Popover, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { MenuProfile as MenuProfileProps } from 'types/general'
import { getProfileMenu } from 'utils/catalogs'
import { MenuProfileLink } from './styles'

export default function MenuProfile({
  anchorEl,
  onCloseProfile
}: {
  anchorEl: HTMLLIElement | null
  onCloseProfile: () => void
}) {
  const { onNavigate } = useNavigation()
  const { userRole } = useAuth()
  const [selectedRoute, setSelectedRoute] =
    useState<MenuProfileProps['route']>()

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  const handleNavigate = (route: MenuProfileProps['route']) => {
    if (route === 'logout') {
      return onNavigate(route)
    }
    setSelectedRoute(route)
    onNavigate(`/profile/${route}`)
    onCloseProfile()
  }

  return (
    <Popover
      id={id}
      open={open}
      elevation={4}
      anchorEl={anchorEl}
      onClose={onCloseProfile}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {getProfileMenu(userRole).map(
        ({ id, title, route, icon: IconComponent, show }) => {
          return (
            show && (
              <MenuProfileLink
                key={id}
                isSelected={route === selectedRoute}
                onClick={() => handleNavigate(route)}
              >
                <IconComponent />
                <Typography variant="h5" marginLeft={2}>
                  {title}
                </Typography>
              </MenuProfileLink>
            )
          )
        }
      )}
    </Popover>
  )
}
