import { Delete } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { Button, Typography, Box, Stack, Divider } from '@mui/material'
import Select from 'components/Select'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import useStepper from 'components/stepper/useStepper'
import { Form, FieldArray } from 'formik'
import { FieldArrayRenderProps } from 'formik'
import { COLORS } from 'styles/colors'
import { BonusConfig } from 'types/bonusTemplate'
import { NewBonusTemplateProps } from 'types/bonusTemplate'
export default function ConfigurationStep({
  handleChange,
  values,
  isValid
}: NewBonusTemplateProps) {
  const { onNextStep, onBackStep } = useStepper()

  const onAddBonus = (
    arrayHelpers: FieldArrayRenderProps,
    type: 'referral' | 'referrer'
  ) => {
    const newBonusTemplate: BonusConfig = {
      amount: '',
      days: '',
      type: type
    }
    arrayHelpers.push(newBonusTemplate)
  }
  const onRemoveBonus = (
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index)
  }

  return (
    <Stack
      component={Form}
      height="100%"
      justifyContent="space-between"
      margin="0 16px 16px 16px"
    >
      <Box component={Paper} elevation={4}>
        {values.is_for_referrer && (
          <Stack padding="6px">
            <Typography variant="h3">Fill the information</Typography>
            <Stack flexDirection="column" marginY={4}>
              <Typography variant="h5">Employee Bonus</Typography>
              <Select
                disabled
                name="Type"
                value="1"
                variant="standard"
                label="Select bonus type"
                options={[{ value: '1', label: 'Flat rate' }]}
              />
            </Stack>
            <FieldArray
              name="bonus_config"
              render={arrayHelpers => (
                <div>
                  {values.bonus_config.map(
                    (item: BonusConfig, index: number) => {
                      if (item.type === 'referrer') {
                        return (
                          <Box marginY={2} key={index}>
                            <Stack flexDirection="row" alignItems="center">
                              <TextField
                                sx={{
                                  width: '30%'
                                }}
                                variant="standard"
                                defaultValue={item.amount}
                                onChange={handleChange(
                                  `bonus_config[${index}].amount`
                                )}
                                name="amount"
                                placeholder="$ Amount"
                              />
                              <Typography variant="body2" marginBottom={0.2}>
                                USD
                              </Typography>
                              <Typography
                                variant="body2"
                                marginLeft={3}
                                marginRight={3}
                                marginBottom={0.2}
                              >
                                After
                              </Typography>
                              <TextField
                                sx={{
                                  width: '30%',
                                  marginRight: '10px'
                                }}
                                defaultValue={item.days}
                                placeholder="Number of days"
                                variant="standard"
                                onChange={handleChange(
                                  `bonus_config[${index}].days`
                                )}
                                name="days"
                              />
                              <Typography
                                variant="body2"
                                marginBottom={0.2}
                                marginRight={1}
                              >
                                Days
                              </Typography>
                              <Delete
                                color="error"
                                onClick={() =>
                                  onRemoveBonus(arrayHelpers, index)
                                }
                              />
                            </Stack>
                          </Box>
                        )
                      }
                    }
                  )}
                  <Button onClick={() => onAddBonus(arrayHelpers, 'referrer')}>
                    <Add />
                    <Typography
                      variant="body2"
                      color={COLORS.main}
                      marginLeft={2}
                      marginRight={2}
                    >
                      Add Bonus Tier
                    </Typography>
                  </Button>
                </div>
              )}
            />
          </Stack>
        )}
        {values.is_for_referral && (
          <Stack padding="6px">
            <Divider sx={{ marginTop: 4, backgroundColor: COLORS.honey2 }} />
            <Stack flexDirection="column" marginY={4}>
              <Typography variant="h5">Referral Bonus</Typography>
              <Select
                disabled
                name="Type"
                value="1"
                variant="standard"
                label="Select bonus type"
                options={[{ value: '1', label: 'Flat rate' }]}
              />
            </Stack>
            <FieldArray
              name="bonus_config"
              render={arrayHelpers => (
                <div>
                  {values.bonus_config.map(
                    (item: BonusConfig, index: number) => {
                      if (item.type === 'referral') {
                        return (
                          <Box marginY={2} key={index}>
                            <Stack flexDirection="row" alignItems="center">
                              <TextField
                                sx={{
                                  width: '30%'
                                }}
                                variant="standard"
                                defaultValue={item.amount}
                                onChange={handleChange(
                                  `bonus_config[${index}].amount`
                                )}
                                name="amount"
                                placeholder="$ Amount"
                              />
                              <Typography variant="body2" marginBottom={0.2}>
                                USD
                              </Typography>
                              <Typography
                                variant="body2"
                                marginLeft={3}
                                marginRight={3}
                                marginBottom={0.2}
                              >
                                After
                              </Typography>
                              <TextField
                                sx={{
                                  width: '30%',
                                  marginRight: '10px'
                                }}
                                defaultValue={item.days}
                                placeholder="Number of days"
                                variant="standard"
                                onChange={handleChange(
                                  `bonus_config[${index}].days`
                                )}
                                name="days"
                              />
                              <Typography
                                variant="body2"
                                marginBottom={0.2}
                                marginRight={1}
                              >
                                Days
                              </Typography>
                              <Delete
                                color="error"
                                onClick={() =>
                                  onRemoveBonus(arrayHelpers, index)
                                }
                              />
                            </Stack>
                          </Box>
                        )
                      }
                    }
                  )}

                  <Button onClick={() => onAddBonus(arrayHelpers, 'referral')}>
                    <Add />
                    <Typography
                      variant="body2"
                      marginLeft={2}
                      color={COLORS.main}
                    >
                      Add Bonus Tier
                    </Typography>
                  </Button>
                </div>
              )}
            />
          </Stack>
        )}
      </Box>
      <Stack marginTop={2} alignItems="center">
        <Button
          fullWidth
          type="submit"
          disabled={!isValid}
          variant="contained"
          onClick={() => onNextStep()}
        >
          Continue
        </Button>
        <Button onClick={onBackStep}>
          <Typography
            variant="body2"
            marginLeft={2}
            marginRight={2}
            color={COLORS.main}
          >
            Back
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
