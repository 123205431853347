import { Fragment } from 'react'
import UploadFile from '@mui/icons-material/UploadFile'
import { Box, Typography, BoxProps } from '@mui/material'
import { Stack } from '@mui/system'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { COLORS } from 'styles/colors'

interface DropFileProps extends DropzoneOptions {
  boxProps?: BoxProps
}

export default function DragAndDroFile({ boxProps, ...props }: DropFileProps) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...props
  })

  return (
    <Box
      {...getRootProps()}
      height="120px"
      bgcolor={COLORS.white}
      border={`1px dashed ${COLORS.blue6}`}
      paddingX={2}
      {...boxProps}
    >
      <input {...getInputProps()} />
      <Stack alignItems="center" justifyContent="center" height="100%">
        <UploadFile htmlColor={COLORS.gray1} />
        {acceptedFiles.length > 0 ? (
          acceptedFiles.map((file: any, index) => (
            <Fragment key={index}>
              <Typography color={COLORS.gray1}>{file.path}</Typography>
              <Typography variant="h6" color={COLORS.gray2} marginTop={1}>
                {file.size} - bytes
              </Typography>
            </Fragment>
          ))
        ) : (
          <>
            <Typography color={COLORS.gray1}>
              Drag and drop file or,
              <Typography color={COLORS.main} margin={0.5} component="span">
                Browse
              </Typography>
            </Typography>
            <Typography variant="h6" color={COLORS.gray2} marginTop={1}>
              Support .pdf, 100-200 KB{' '}
            </Typography>
          </>
        )}
      </Stack>
    </Box>
  )
}
