import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'

import {
  InputBase,
  InputBaseProps,
  BoxProps,
  Stack,
  IconButton,
  Popover,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material'
import useNavigation from 'hooks/useNavigation'
import { useFilterStore } from 'stores'
import { COLORS } from 'styles/colors'
import { Search } from './StylesSearch'

type SearchOption = { value: string; label: string }
interface SearchBarProps extends InputBaseProps {
  boxProps?: BoxProps
  isSearch?: boolean
  defaultselected?: string
  options?: Array<SearchOption>
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ENTER_KEY = 'Enter'

export default function SearchBar({ boxProps, ...props }: SearchBarProps) {
  const { onNavigate, pathname, searchParams } = useNavigation()
  const [searchValue, setSearchValue] = useState(searchParams.search || '')

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setSearchValue(value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      onNavigate(pathname, {
        search: { ...searchParams, search: searchValue }
      })
    }
  }

  const handleClear = () => {
    onNavigate(pathname, {
      search: { searchBy: searchParams.searchBy }
    })
    setSearchValue('')
  }

  return (
    <Search bgcolor={COLORS.white} paddingLeft={2} {...boxProps}>
      {props.isSearch && (
        <SearchBy
          options={props.options || []}
          defaultValue={props.defaultselected}
        />
      )}
      <SearchIcon />
      <Stack direction="row" alignItems="center" width="100%" paddingRight={1}>
        <InputBase
          fullWidth
          name="search"
          placeholder="Search…"
          value={searchValue}
          sx={{ height: '48px', borderRadius: 25 }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          {...props}
        />
        {searchValue && (
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </Search>
  )
}

const SearchBy = ({
  options,
  defaultValue
}: {
  defaultValue?: string
  options: Array<SearchOption>
}) => {
  const theme = useTheme()
  const { onNavigate, pathname, searchParams } = useNavigation()
  const [selected, setSelected] = useState(
    (searchParams.sortBy as string) || ''
  )

  const { filterId, anchorEl, open, onCloseAnchor, onOpenAnchor } =
    useFilterStore()

  useEffect(() => {
    onNavigate(pathname, {
      search: {
        ...searchParams,
        searchBy: searchParams.searchBy || defaultValue
      }
    })
  }, [])

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const onSelectedValue = (searchBy: string) => {
    onNavigate(pathname, {
      search: { searchBy }
    })
    onCloseAnchor()
    setSelected(searchBy)
  }

  const selectedLabel = options.find(item => item.value === selected)
  const selectedDefault = options.find(
    item => item.value === searchParams.searchBy
  )

  return (
    <>
      <Stack
        padding={0}
        border="none"
        width="180px"
        marginRight={1}
        direction="row"
        paddingRight={1}
        alignItems="center"
        component="button"
        bgcolor="transparent"
        justifyContent="space-between"
        borderRight="1px solid #0030FF"
        sx={{ cursor: 'pointer' }}
        onClick={event => onOpenAnchor(event, 'searchBy')}
      >
        <Typography width="100px" textAlign="left">
          {selectedLabel?.label || selectedDefault?.label}
        </Typography>
        <ExpandMoreIcon className="select-icon" htmlColor="#0030FF" />
      </Stack>
      <Popover
        id={filterId}
        open={open}
        elevation={4}
        anchorEl={anchorEl}
        onClose={onCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          'div.MuiPopover-paper': {
            borderRadius: 4,
            marginTop: 2,
            padding: 2,
            width: isMobile ? '85%' : 'auto'
          }
        }}
      >
        {options.map(({ value, label }) => (
          <Typography
            key={value}
            component="div"
            borderRadius={1}
            onClick={() => onSelectedValue(value)}
            sx={{
              background: selected === value ? COLORS.honey2 : COLORS.white,
              width: '100px',
              padding: '8px',
              ':hover': { background: COLORS.gray8 }
            }}
          >
            {label}
          </Typography>
        ))}
      </Popover>
    </>
  )
}
