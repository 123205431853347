import { hookstate, useHookstate } from '@hookstate/core'
import { CreateCompanyAddress, UpdateProfileCompany } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation, useQueryClient } from 'react-query'
import { CompanyDetail, UpdateCompanyInfo } from 'types/companies'
import { get, removeEmptyKeys } from 'utils'
import { InitialValues } from '../generalInformation/editCompany'

const initialState = hookstate({
  edit: false
})

export default function useBegoppInformation(
  companyAddress?: CompanyDetail['addresses']
) {
  const hasAddrress = companyAddress && companyAddress.length > 0
  const { edit } = useHookstate(initialState)
  const { onNotify } = useNotification()

  const mutation = hasAddrress ? UpdateProfileCompany : CreateCompanyAddress

  const query = useQueryClient()
  const { mutate, isLoading } = useMutation(
    (data: UpdateCompanyInfo & { companyId: number }) =>
      mutation(data.companyId, data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Update company successfully'
        })
        onHandleCloseEdit()
        query.invalidateQueries('COMPANY_BY_ID')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onSubmit = (values: InitialValues) => {
    const updateAddress = {
      company: {
        name: values.companyName,
        phone: values.phoneNumber,
        url: values.url,
        addresses: [
          {
            id: Number(values.addressId),
            street: values.street,
            state: values.state,
            zipcode: values.zipcode,
            country: values.country,
            city: values.city
          },
          {
            id: Number(values.billingAddressId),
            street: values.billingStreet,
            state: values.billingState,
            zipcode: values.billingZipcode,
            country: values.billingCountry,
            city: values.billingCity
          }
        ]
      }
    }

    const createAddress = {
      company: {
        name: values.companyName,
        phone: values.phoneNumber,
        addresses: [
          removeEmptyKeys({
            ...updateAddress.company.addresses[0],
            id: '',
            type: 'headquarter'
          }),
          removeEmptyKeys({
            ...updateAddress.company.addresses[1],
            id: '',
            type: 'billing'
          })
        ]
      }
    }
    const data = hasAddrress ? updateAddress : createAddress

    mutate({ companyId: values.id, ...data })
  }

  const onHandleOpenEdit = () => {
    edit.set(true)
  }

  const onHandleCloseEdit = () => {
    edit.set(false)
  }

  return {
    edit: edit.value,
    isLoading,
    onSubmit,
    onHandleOpenEdit,
    onHandleCloseEdit
  }
}
