import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import useRefer from 'app/educationDetails/hooks/useRefer'
import Paper from 'components/paper'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'

export default function ListReferrals() {
  const { onNextStep, onBackStep, referrals } = useRefer()
  return (
    <Stack height="99%" justifyContent="space-between" gap={2}>
      <Box
        component={Paper}
        elevation={2}
        height="100%"
        overflow="auto"
        sx={{ ...StylesScrollBar }}
      >
        <Typography variant="h2" marginBottom={3}>
          These are the referrals you'll send the link to (
          {referrals.referred.length})
        </Typography>
        <Stack direction="row" flexWrap="wrap">
          {referrals.referred.map(({ phone, name, email, lastName }) => (
            <Stack
              key={phone}
              flexDirection="row"
              width={{ xs: '100%', md: '50%' }}
              marginBottom={4}
            >
              <Box
                width={30}
                height={30}
                marginRight={2}
                component={Avatar}
                variant="circular"
                bgcolor={COLORS.blue10}
              >
                <PersonIcon fontSize="small" />
              </Box>
              <Stack>
                <Typography variant="body2" color={COLORS.gray1}>
                  {`${name} ${lastName}`}
                </Typography>
                <Typography variant="body2" color={COLORS.gray1}>
                  {email}
                </Typography>
                {phone && (
                  <Typography variant="body2" color={COLORS.gray1}>
                    {phone}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Stack>
        <Button fullWidth variant="contained" onClick={onNextStep}>
          Continue
        </Button>
        <Button
          fullWidth
          variant="text"
          onClick={onBackStep}
          sx={{ marginTop: 2 }}
        >
          Back
        </Button>
      </Stack>
    </Stack>
  )
}
