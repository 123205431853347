import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import TextField from 'components/TextField'
import CurrencyField from 'components/currencyField'
import DragAndDropFile from 'components/dragAndDropFile'
import { Formik, Form, Field } from 'formik'
import { COLORS } from 'styles/colors'
import useCreditCard from '../creditCard/useCreditCard'
import useAch, { validationSchema } from './useAch'

export default function AddAch() {
  const { isLoading, onSubmit, onDropFile } = useAch()
  const { onCloseDialog } = useCreditCard()

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ amount: '', file: '', wireNumber: '' }}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue, handleChange }) => (
        <Form>
          <Stack position="relative">
            <CurrencyField
              name="amount"
              placeholder="Amount"
              onValueChange={(value, name) => {
                setFieldValue(name || 'amount', value)
              }}
            />
            {errors.amount && touched.amount ? (
              <Typography
                variant="h6"
                color={COLORS.red1}
                position="absolute"
                margin="60px 0 0 0"
                top="-10px"
              >
                {errors.amount}
              </Typography>
            ) : null}
          </Stack>
          <TextField
            sx={{
              width: '100%',
              marginBottom: 2
            }}
            name="wireNumber"
            variant="standard"
            onChange={handleChange('wireNumber')}
            placeholder="ACH/Wire transfer number"
          />
          <Box position="relative" marginTop={2}>
            <Typography
              variant="body2"
              fontSize={16}
              color={COLORS.gray5}
              marginY={2}
            >
              Attach your receipt
            </Typography>
            <Field
              name="imageLogo"
              as={DragAndDropFile}
              accept={{
                'application/pdf': ['.pdf', '.jpeg', '.png']
              }}
              onDrop={(file: any) => {
                onDropFile({ file })
                setFieldValue('file', file[0].name)
              }}
            />
            {touched.file && errors.file && (
              <Typography
                variant="h6"
                color={COLORS.red1}
                position="absolute"
                bottom="-25px"
              >
                {errors.file}
              </Typography>
            )}
          </Box>
          <Stack gap={2} direction="row" marginTop={6}>
            <Button fullWidth onClick={onCloseDialog}>
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
