import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { ChangePassword } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { useMutation } from 'react-query'
import type { UserChangePassword } from 'types/profile'
import { get } from 'utils'
import * as yup from 'yup'
import { ref } from 'yup'

const initialState = hookstate({
  open: false
})

export const validationSchema = yup.object({
  lastPassword: yup
    .string()
    .min(8, 'Min 8 characters')
    .required('Password required'),
  newPassword: yup
    .string()
    .min(8, 'Mínimo 8 carácteres')
    .notOneOf(
      [ref('lastPassword'), null],
      'The password must be different from the current one'
    )
    .required('Password required'),
  confirmPassword: yup
    .string()
    .notOneOf(
      [ref('lastPassword'), null],
      'The password must be different from the current one'
    )
    .oneOf([ref('newPassword'), null], 'Passwords must be the same')
    .required('Password required')
})

export const initialValues = {
  lastPassword: '',
  newPassword: '',
  confirmPassword: ''
}

export default function useChangePassword() {
  const { open } = useHookstate(initialState)
  const { user } = useAuth()
  const { onNotify } = useNotification()
  const { onNavigate } = useNavigation()
  const [canEdit, setCanEdit] = useState(false)
  const [showPassword, setShowPassword] = useState({
    lastPassword: false,
    newPassword: false,
    confirmPassword: false
  })

  const { mutate, isLoading } = useMutation(
    (data: UserChangePassword) => ChangePassword(user.id, data),
    {
      onSuccess: () => {
        onNotify({ severity: 'success', message: 'Password updated success' })
        onHandleEdit()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        onNotify({ severity: 'error', message: JSON.stringify(errorMessage) })
      }
    }
  )

  const onHandleEnterEMailCode = () => {
    onNavigate('/check-email', {
      search: {
        email: user.email
      }
    })
  }

  const onHandleCloseDialog = () => {
    open.set(false)
  }

  const onHandleClose = () => {
    open.set(false)
    onHandleEnterEMailCode()
  }

  const onHandleOpen = () => open.set(true)

  const onSubmit = (values: typeof initialValues) => {
    mutate({
      last: values.lastPassword,
      new: values.newPassword
    })
  }

  const onClickShowPassword = (
    param: 'lastPassword' | 'newPassword' | 'confirmPassword'
  ) => {
    setShowPassword(prevState => ({
      ...prevState,
      [param]: !prevState[param]
    }))
  }

  const onHandleEdit = () => setCanEdit(!canEdit)

  return {
    showPassword,
    onHandleEnterEMailCode,
    onHandleClose,
    onHandleOpen,
    onSubmit,
    onClickShowPassword,
    onHandleEdit,
    canEdit,
    isLoading,
    open: open.value,
    onHandleCloseDialog
  }
}
