import { useEffect, useState } from 'react'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { MenuItems } from 'types/general'
import { getMenu, routePermissions } from 'utils/catalogs'

const ROUTES = ['bonus', 'jobs']

type SubRoute = 'jobs' | 'bonus' | string

export default function useSidebar() {
  const { pathname, onNavigate } = useNavigation()
  const { userRole, user } = useAuth()

  const [openSubmenu, setOpenSubmenu] = useState<SubRoute>()
  const [selectedRoute, setSelectedRoute] = useState('')
  const [selectedSubRoute, setSelectedSubRoute] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null)

  const menuItems = getMenu(userRole)
  const routePermissionsByRole = routePermissions[userRole]

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedRoute('/dashboard')
    } else {
      setSelectedRoute(pathname)
    }
  }, [pathname])

  const onSelectedRoute = (route: MenuItems['route']) => {
    onValidateRoute(route)

    if (ROUTES.includes(route)) {
      const isOpen = openSubmenu === route ? '' : route

      const filteredSubmenu =
        menuItems.find(menu => menu.route === route) || ({} as MenuItems)

      const subRoute = filteredSubmenu.submenu
        ? filteredSubmenu.submenu?.filter(item =>
            routePermissionsByRole.includes(item.route)
          )[0].route
        : ''

      setOpenSubmenu(isOpen)

      if (isOpen) {
        setSelectedSubRoute(subRoute)
        onNavigate(`/${route}/${subRoute}`)
      }
    }

    if (!ROUTES.includes(route)) {
      setOpenSubmenu('')
    }
  }

  const onSelectedSubRoute = (
    route: MenuItems['route'],
    subroute: SubRoute
  ) => {
    setSelectedSubRoute(subroute)
    onNavigate(`/${route}/${subroute}`)
  }

  const onValidateRoute = (path: MenuItems['route']) => {
    if (userRole === 'employee' && path === 'employees') {
      return onNavigate(`/employees/${user.id}`)
    }
    onNavigate(path)
  }

  const onOpenProfile = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseProfile = () => {
    setAnchorEl(null)
  }

  return {
    onSelectedRoute,
    onSelectedSubRoute,
    onOpenProfile,
    onCloseProfile,
    selectedRoute,
    selectedSubRoute,
    openSubmenu,
    anchorEl
  }
}
