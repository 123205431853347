import { styled, css } from '@mui/material/styles'
import { COLORS } from 'styles/colors'
import { hexToRGB } from 'styles/utils'

export const MenuProfileLink = styled('div')<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  margin-bottom: 2px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: ${hexToRGB(COLORS.honey2, '0.3')};
  }
  ${props =>
    props.isSelected &&
    css`
      background: ${hexToRGB(COLORS.honey2, '0.3')};
    `}
`
