import { TableHeader } from 'components/table'

type BulckActionsModel = {
  page: number
  hideBulk?: boolean
  rowsPerPage: number
  totalPages: number
  children: React.ReactNode
  handleChangePage: (arg: number) => void
  handleChangeRowsPerPage: (arg: number) => void
}

export default function BulkActions({
  page,
  hideBulk,
  totalPages,
  rowsPerPage,
  children,
  handleChangePage,
  handleChangeRowsPerPage
}: BulckActionsModel) {
  return (
    <TableHeader
      page={page}
      hideBulk={hideBulk}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    >
      {children}
    </TableHeader>
  )
}
