import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Paper, Typography, Stack, IconButton } from '@mui/material'
import FaceBookIcon from 'components/icons/FaceBook'
import LinkedinIcon from 'components/icons/Linkedin'
import TwitterIcon from 'components/icons/Twitter'
import useAuth from 'hooks/useAuth'
import useCopy from 'hooks/useCopy'
import { COLORS } from 'styles/colors'
import useFetchDetail from '../hooks/useFetchDetail'

const urlToApply = process.env.REACT_APP_URL_APPLY_JOB
export default function ShareJob() {
  const { copy } = useCopy()
  const { details } = useFetchDetail()
  const { user } = useAuth()

  const hasBonus = Boolean(details.bonus)

  const companyUrl = `${urlToApply}/company/${user.company_id}/jobs/${details.id}/${user.id}`

  return (
    <Box component={Paper} marginTop={3}>
      <Typography variant="h3" marginBottom={3}>
        Share Job Link
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography
          component="a"
          target="_blank"
          href={companyUrl}
          textOverflow="ellipsis"
          overflow={{ xs: 'hidden', sm: 'auto' }}
          color={hasBonus ? COLORS.blue1 : COLORS.gray3}
          sx={{
            pointerEvents:
              hasBonus && details.status === 'published' ? 'auto' : 'none',
            userSelect: details.status === 'published' ? 'auto' : 'none'
          }}
        >
          {hasBonus ? companyUrl : 'N/A'}
        </Typography>

        <IconButton
          disabled={!hasBonus || details.status !== 'published'}
          onClick={() => copy(companyUrl)}
        >
          <ContentCopyIcon htmlColor={hasBonus ? COLORS.main : COLORS.gray8} />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        marginTop={2}
        borderTop={`1px solid ${COLORS.honey2}`}
      >
        <Box
          component="a"
          target="_blank"
          sx={{ pointerEvents: hasBonus ? 'auto' : 'none' }}
          href={`https://www.facebook.com/sharer/sharer.php?u=${companyUrl}`}
        >
          <FaceBookIcon sx={{ marginRight: 1 }} disabled={!hasBonus} />
        </Box>
        <Box
          component="a"
          target="_blank"
          sx={{ pointerEvents: hasBonus ? 'auto' : 'none' }}
          href={`http://twitter.com/share?text=${companyUrl}`}
        >
          <TwitterIcon sx={{ marginRight: 1 }} disabled={!hasBonus} />
        </Box>
        <Box
          component="a"
          target="_blank"
          sx={{ pointerEvents: hasBonus ? 'auto' : 'none' }}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${companyUrl}`}
        >
          <LinkedinIcon disabled={!hasBonus} />
        </Box>
      </Stack>
    </Box>
  )
}
