import useNavigation from 'hooks/useNavigation'
import CreateBonusFromTemplateStep from '../CreateBonusFromTemplateStep'
import NewBonusStep from '../NewBonusStep'

export default function BonusConfigStep({ hasBonus }: { hasBonus: boolean }) {
  const { searchParams } = useNavigation()

  if (searchParams.bonusId > 0 && !hasBonus) {
    return <CreateBonusFromTemplateStep />
  }
  return <NewBonusStep hasBonus={Boolean(hasBonus)} />
}
