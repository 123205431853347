import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { COLORS } from 'styles/colors'
import { RechargeTabs } from './tabs'

export default function RechargeDialog({
  open,
  accountNumber
}: {
  open: boolean
  accountNumber: string
}) {
  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle
        fontSize={20}
        fontWeight={500}
        marginBottom={4}
        textAlign="center"
        color={COLORS.gray1}
      >
        Funding your account
      </DialogTitle>
      <DialogContent>
        <RechargeTabs accountNumber={accountNumber} />
      </DialogContent>
    </Dialog>
  )
}
