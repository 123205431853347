import {
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Stack
} from '@mui/material'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Form } from 'formik'
import { COLORS } from 'styles/colors'
import { NewBonusTemplateProps } from 'types/bonusTemplate'

export default function JobStep({
  handleChange,
  setFieldValue,
  values,
  errors
}: NewBonusTemplateProps) {
  return (
    <Stack
      component={Form}
      height="100%"
      justifyContent="space-between"
      margin="0 16px 16px 16px"
    >
      <Box component={Paper} elevation={4} padding="16px">
        <Typography variant="h3">Fill the information</Typography>
        <Stack flexDirection="column" marginY={4}>
          <Typography variant="h5">Template name</Typography>
          <TextField
            sx={{
              width: '100%'
            }}
            variant="standard"
            onChange={handleChange('description')}
            name="description"
          />
        </Stack>
        <Box>
          <Typography variant="h5" marginBottom={2} color={COLORS.gray2}>
            Choose for who is the bonus...
          </Typography>
          <FormControlLabel
            name="is_for_referrer"
            control={<Checkbox />}
            label="Employee"
            checked={values.is_for_referrer}
            onChange={(e, checked) => {
              setFieldValue('is_for_referrer', checked)
            }}
            sx={{ width: '100%' }}
            componentsProps={{
              typography: { color: COLORS.gray1 }
            }}
          />
          <Typography variant="caption" color={COLORS.red1}>
            {errors.bonusTemplateType}
          </Typography>
          <FormControlLabel
            name="is_for_referral"
            checked={values.is_for_referral}
            control={<Checkbox />}
            label="Referral"
            onChange={(e, checked) => {
              setFieldValue('is_for_referral', checked)
            }}
            sx={{ width: '100%' }}
            componentsProps={{
              typography: { color: COLORS.gray1 }
            }}
          />
          <Typography variant="caption" color={COLORS.red1}>
            {errors.bonusTemplateType}
          </Typography>
        </Box>
      </Box>
      <Stack marginTop={2}>
        <Button fullWidth variant="contained" type="submit">
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}
