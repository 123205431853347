import { useState } from 'react'
import { SignUp, ValidateEmail } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import store from 'storejs'
import { get } from 'utils'
import { REGEX_NUMBERS } from 'utils/catalogs'
import * as yup from 'yup'

export const validationSchema = yup.object({
  codeEmail: yup
    .string()
    .matches(REGEX_NUMBERS, 'Only numbers')
    .required('Code required')
    .min(4, 'Minimun 4 characters')
    .max(4, 'Maximun 4 characters')
})

export default function useEnterEmailCode() {
  const { onNavigate, searchParams } = useNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const { onNotify } = useNotification()

  const onHandleRedirect = () => {
    onNavigate('/enter-smscode', {
      search: {
        email: searchParams.email
      }
    })
  }

  const onSubmit = (values: { codeEmail: string }) => {
    setIsLoading(true)
    const data = { email: searchParams.email, nip_email: values.codeEmail }
    ValidateEmail(data)
      .then(() => {
        onHandleRedirect()
        store.set('TEMPORAL_SESSION', data)
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
        store.set('TEMPORAL_SESSION', data)
      })
  }

  const onRequestNewCode = () => {
    const data = store.get('TEMPORAL_SESSION') || {}
    SignUp(data)
      .then(() => {
        onNotify({
          message: 'New code requested successfully.',
          severity: 'success'
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    onSubmit,
    isLoading,
    searchParams,
    onRequestNewCode
  }
}
