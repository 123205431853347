import PersonalInfo from 'app/profile/personalInfo'
import useNavigation from 'hooks/useNavigation'
import { Outlet } from 'react-router-dom'

const PROFILE = '/profile'

export default function Profile() {
  const { pathname } = useNavigation()
  const isProfile = pathname === PROFILE
  return <>{isProfile ? <PersonalInfo /> : <Outlet />}</>
}
