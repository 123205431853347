import Diversity from '@mui/icons-material/Diversity3'
import WorkIcon from '@mui/icons-material/Work'
import { Typography, Stack, Button } from '@mui/material'
import SearchBar from 'components/SearchBar'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
const users = ['company_owner', 'rewards_manager']

const ReferralsHeader = () => {
  const { onNavigate } = useNavigation()
  const startReferring = () => {
    onNavigate('/jobs/jobboard')
  }
  const { userRole } = useAuth()
  return (
    <Stack
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      justifyContent="space-between"
      marginTop={3}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <Diversity />
        <Typography marginLeft={2} marginRight={4} variant="h2">
          Referrals
        </Typography>
      </Stack>
      <Stack
        gap={3}
        alignItems="flex-end"
        width={{ xs: '100%' }}
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <SearchBar boxProps={{ width: { xs: '95%', md: '250px' } }} />
        {users.includes(userRole) && (
          <Button
            onClick={() => startReferring()}
            fullWidth
            variant="contained"
            startIcon={<WorkIcon />}
            children="Start referring"
            sx={{ maxWidth: '180px' }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default ReferralsHeader
