import { useState } from 'react'
import { Box, Button, FormControlLabel, Stack } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import MenuColumn from 'components/table/MenuColumn'
import { useFilterStore } from 'stores'

export type SelectedOption = OptionAction[0] & { checked: boolean }
type OptionAction = Array<{ id: number | string; label: string; name?: string }>

type SelectActionsModel = {
  options: OptionAction
  actionUID: string
  onApply: (arg: SelectedOption) => void
  onChange?: (arg: SelectedOption) => void
}

export default function SelectActions({
  options,
  actionUID,
  onChange,
  onApply
}: SelectActionsModel) {
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(
    {} as SelectedOption
  )
  const { filterId, popoverUid, anchorEl, open, onCloseAnchor } =
    useFilterStore()

  const onChangeValue = ({
    filter,
    checked
  }: {
    filter: Omit<SelectedOption, 'checked'>
    checked: boolean
  }) => {
    setSelectedOption({ ...filter, checked })
    onChange?.({ ...filter, checked })
  }
  const handleApply = () => {
    onApply(selectedOption)
    onCloseAnchor()
  }

  if (popoverUid !== actionUID) return null

  return (
    <MenuColumn
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseAnchor}
      id={filterId}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Box padding={2}>
        {options.map(({ id, label, name }) => {
          const value =
            selectedOption?.id === id ? selectedOption.checked : false
          return (
            <Stack key={id} direction="row" marginBottom={2}>
              <FormControlLabel
                onChange={(_, checked) =>
                  onChangeValue({
                    checked,
                    filter: { id, label, name }
                  })
                }
                checked={value}
                label={label}
                control={<Checkbox />}
                componentsProps={{
                  typography: {
                    variant: 'h5'
                  }
                }}
              />
            </Stack>
          )
        })}
        <Button
          fullWidth
          children="Apply"
          onClick={handleApply}
          disabled={!selectedOption?.checked}
        />
      </Box>
    </MenuColumn>
  )
}
