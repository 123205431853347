import { Stack } from '@mui/material'
import { Pie } from '@nivo/pie'
import useAuth from 'hooks/useAuth'
import { COLORS } from 'styles/colors'
import usePaymentsGraph from '../hooks/usePaymentsGraph'
import usePaymentsGraphE from '../hooks/usePaymentsGraphE'

const PIE_COLORS = {
  approved: COLORS.blue2,
  canceled: COLORS.red2,
  not_set: COLORS.blue4,
  pending: COLORS.honey8,
  paid: COLORS.green2,
  rejected: COLORS.red1,
  review: COLORS.honey4
}

export const LABEL = {
  approved: 'Approved',
  canceled: 'Canceled',
  not_set: 'Not set',
  pending: 'Pending',
  paid: 'Paid',
  rejected: 'Rejected',
  review: 'Review'
}
export const PiePayments = () => {
  const { userRole } = useAuth()
  const { dataPayment } =
    userRole == 'super_admin' ? usePaymentsGraph() : usePaymentsGraphE()

  const segments = Array.isArray(dataPayment.segments || [])
    ? dataPayment.segments
    : []

  const ordData = segments?.map((item: any) => {
    if (item.id == 'cancel') {
      item.ord = 0
    }
    if (item.id == 'not_set') {
      item.ord = 1
    }
    if (item.id == 'pending') {
      item.ord = 2
    }
    if (item.id == 'review') {
      item.ord = 3
    }
    if (item.id == 'paid') {
      item.ord = 4
    }
    return item
  })

  const filteredData = ordData?.map(item => {
    return {
      ...item,
      label: LABEL[item.label as keyof typeof LABEL],
      value: Math.round(item.value),
      color: PIE_COLORS[item.id as keyof typeof PIE_COLORS]
    }
  })

  const data = ordData
    ? filteredData.sort((a: any, b: any) => a.ord - b.ord)
    : [{ id: '', label: '', value: 0 }]

  return (
    <Stack position={'relative'}>
      <Pie
        width={564}
        height={310}
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        colors={data.map((item: any) => item.color)}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        valueFormat=">-"
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]]
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: -280,
            translateY: 60,
            itemsSpacing: 2,
            itemWidth: 200,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </Stack>
  )
}
